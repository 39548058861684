import { css } from '@emotion/react';
import { token } from '@atlaskit/tokens';

export interface BlockquoteProps {
  /** Text to be displayed */
  children: React.ReactNode;
}

/**
 * Displays Blockquote
 * @param {Blockquote} BlockquoteProps
 * @returns {React.ReactElement} React.ReactElement
 *
 * @example
 * <Blockquote children="Blockquote text" />
 */
export const Blockquote: React.FC<BlockquoteProps> = ({ children }) => {
  return <blockquote css={css(blockquoteStyles)}>{children}</blockquote>;
};

const fontSizeStyles = {
  fontSize: '1.5rem',
  lineHeight: '2.25rem',
  fontWeight: 600,
};

export const blockquoteStyles = {
  paddingLeft: 0,
  fontFamily: 'Inter, sans-serif',
  color: token('color.text'),
  ...fontSizeStyles,

  span: fontSizeStyles,

  '&:before, &:after': {
    content: "''",
  },
};
