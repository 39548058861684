import type { FunctionComponent } from 'react';
import Section from 'components/common/Section';
import { css } from '@compiled/react';
import YoutubeEmbed from 'components/common/YoutubeEmbed';
import { token } from '@atlaskit/tokens';
import { MOBILE, TABLET } from '../../../utils/constants';

const EVENTS_YOUTUBE = {
  ID: 'K8aVSOkXJeM',
  TITLE: 'Atlassian Community Events',
};

const renderYoutubeEmbed = () => {
  let videoWidth = 854;
  let videoHeight = 453;
  return (
    <div css={youtubeEmbedWrapperStyle}>
      <YoutubeEmbed
        videoId={EVENTS_YOUTUBE.ID}
        title={EVENTS_YOUTUBE.TITLE}
        width={videoWidth}
        height={videoHeight}
      />
    </div>
  );
};

const EventsSection: FunctionComponent = () => {
  return (
    <div css={backgroundContainerStyle}>
      <Section
        id="events"
        title="Community Events"
        heading="Connect and learn at local events"
        description="Find your people and network right where you are. Explore local events happening online, or meet up in person with inspiring folks."
        buttonData={{
          href: process.env.EVENTS_BASE_URL as string,
          text: 'Explore Events',
          target: '_self',
        }}
        eyebrowColor={token('color.text.subtlest')}
      >
        {renderYoutubeEmbed()}
      </Section>
      {/* Background - overlapping rectangles effect */}
      <div css={backgroundRectangleStyle}></div>
      <div css={backgroundRectangle2Style}></div>
      <div css={backgroundRectangle3Style}></div>
    </div>
  );
};

export default EventsSection;

// also relies on global.css body overflowX: hidden to prevent horizontal scroll
const backgroundContainerStyle = css({
  marginTop: '48px',
  position: 'relative',
  width: '100vw', // ensure the container takes up the full viewport width
  marginLeft: 'calc(-50vw + 50%)', // adjust position to start at the left edge of the screen
  overflowX: 'clip', // clip horizontal overflow to the viewport width
  padding: '64px 0', // top and bottom padding within main blue background rectangle
  marginBottom: '32px', // extra white space at the bottom of the section
    [TABLET.MEDIA_QUERY]: {
    marginTop: '24px',
  },
  [MOBILE.MEDIA_QUERY]: {
    marginTop: '20px',
  },
});

// main background rectangle (regular un-rotated horizontal rectangular background)
const backgroundRectangleStyle = css({
  position: 'absolute',
  top: 0,
  left: '50%',
  width: '100vw',
  height: '100%',
  backgroundColor: '#E9F2FE',
  zIndex: -1,
  transform: 'translateX(-50%)',
});

// create effect of overlapping rectangles
// top-right rectangle
const backgroundRectangle2Style = css({
  position: 'absolute',
  top: '-2%',
  left: '35%',
  width: '80vw',
  height: '80%',
  backgroundColor: '#E9F2FE',
  zIndex: -2,
  transform: 'rotate(2deg)',
});

// bottom-left rectangle
const backgroundRectangle3Style = css({
  position: 'absolute',
  top: '22%',
  left: '-20%',
  width: '80vw',
  height: '80%',
  backgroundColor: '#E9F2FE',
  zIndex: -3,
  transform: 'rotate(1deg)',
});

const youtubeEmbedWrapperStyle = css({
  margin: '0 auto',
  maxWidth: '854px',
  maxHeight: '480px',
  [TABLET.MEDIA_QUERY]: {
    padding: '0 72px',
  },
  [MOBILE.MEDIA_QUERY]: {
    padding: '0 24px',
  },
});
