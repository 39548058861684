"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var cookie_1 = require("./cookie");
var validate_1 = require("./validate");
var parseCachedXidItem = function (value) {
    try {
        var xid = JSON.parse(value);
        if (xid instanceof Array) {
            var valid = true;
            for (var _i = 0, xid_1 = xid; _i < xid_1.length; _i++) {
                var entry = xid_1[_i];
                valid = valid && validate_1.checkXidValid(entry);
            }
            return valid ? xid : undefined;
        }
        else {
            return validate_1.checkXidValid(xid) ? xid : undefined;
        }
    }
    catch (err) {
        return;
    }
};
function getFromLocalStorage(name) {
    try {
        return localStorage.getItem(name) || '';
    }
    catch (err) {
    }
}
function getXidFromCookie(name) {
    var cookieValue = cookie_1.getCookie(name);
    return parseCachedXidItem(cookieValue);
}
exports.getXidFromCookie = getXidFromCookie;
function getXidFromLocalStorage(name) {
    var lsValue = '';
    try {
        lsValue = localStorage.getItem(name) || '';
    }
    catch (err) {
    }
    return parseCachedXidItem(lsValue);
}
exports.getXidFromLocalStorage = getXidFromLocalStorage;
function setLocalstorage(name, value) {
    try {
        localStorage.setItem(name, value);
    }
    catch (err) {
    }
}
exports.setLocalstorage = setLocalstorage;
function getCache(name) {
    return cookie_1.getCookie(name) || getFromLocalStorage(name);
}
exports.getCache = getCache;
function getXidItemFromCache(name) {
    return getXidFromCookie(name) || getXidFromLocalStorage(name);
}
exports.getXidItemFromCache = getXidItemFromCache;
