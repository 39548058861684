import React from 'react';
import { css } from '@emotion/react';
import AddIcon from '@atlaskit/icon/glyph/add';
import { token } from '@atlaskit/tokens';

const ellipseStyles = css({
  width: '72px',
  height: '72px',
  backgroundColor: '#D3F1A7',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const PlusIcon: React.FC = () => {
  return (
    <div css={ellipseStyles}>
      <AddIcon label="Add" size="large" primaryColor={token('color.icon')} />
    </div>
  );
};
