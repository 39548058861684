import React from 'react';
import { Label, RequiredAsterisk } from '@atlaskit/form';
import { css } from '@emotion/react';

export interface TextFieldLabelProps {
  /** The message to be displayed */
  labelText: string;
  /** required astrisk or not */
  isRequired: boolean;
}

export const TextFieldLabel: React.FC<TextFieldLabelProps> = ({ labelText = 'Example label', isRequired = true }) => (
  <Label htmlFor="basic-textfield">
    <p css={labelStyles}>
      {labelText}
      {isRequired && <RequiredAsterisk />}
    </p>
  </Label>
);

const labelStyles = css({
  fontFamily: 'Inter',
  color: '#44546F',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '20px',
});
