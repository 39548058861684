/**
 * This component is a wrapper around the Link component from react-resource-router,
 * which sends an analytics event when the link or button is clicked or hovered.
 */
import React, { KeyboardEvent, MouseEvent, useContext } from 'react';
import { Link, LinkProps } from 'react-resource-router';
import { UITrackEventPayload } from '@atlassian/community-library';
import { UserContext } from 'context/UserContext';
import { sendUIEvent } from 'services/analytics';

interface LinkWithAnalyticsProps extends LinkProps {
  clickEventPayload?: UITrackEventPayload;
  hoverEventPayload?: UITrackEventPayload;
}

export const LinkWithAnalytics: React.FC<LinkWithAnalyticsProps> = ({
  onClick,
  onMouseEnter,
  clickEventPayload,
  hoverEventPayload,
  href,
  ...rest
}) => {
  const user = useContext(UserContext);

  const handleClick = (
    e: MouseEvent<Element, globalThis.MouseEvent> | KeyboardEvent<Element>,
  ): void => {
    // Always prevent the default action to control navigation manually
    e.preventDefault();
    if (rest.target === '_blank') {
      // Open the link in a new tab/window and return to avoid further actions
      window.open(href, '_blank', 'noopener noreferrer');
      return;
    }
    // Handle analytics and in-app navigation
    if (clickEventPayload) {
      const { action, actionSubject, actionSubjectId, attributes, source } =
        clickEventPayload;
      sendUIEvent(
        source,
        actionSubject,
        action,
        actionSubjectId,
        attributes,
        user?.user?.account_id,
      ).then(() => {
        if (href) {
          window.location.href = href;
        }
      });
    } else {
      if (href) {
        window.location.href = href;
      }
    }
  };
  const handleHover = (e: MouseEvent<Element, globalThis.MouseEvent>): void => {
    if (hoverEventPayload) {
      const { action, actionSubject, actionSubjectId, attributes, source } =
        hoverEventPayload;
      sendUIEvent(
        source,
        actionSubject,
        action,
        actionSubjectId,
        attributes,
        user?.user?.account_id,
      );
    }
    if (onMouseEnter) {
      onMouseEnter(e);
    }
  };

  return (
    <Link
      {...rest}
      href={href}
      onClick={handleClick}
      onMouseEnter={handleHover}
    />
  );
};
