import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Icon, TagProps } from '@components/shared';
import { InspectModeContentful, LinkTarget } from '@types';
import { withFocusInteraction } from '@utils/index';
import { css } from '@emotion/react';
import { token } from '@atlaskit/tokens';
import { PopupShare } from '@components/learning/PopupShare';
import { LearningProgressBar } from '@components/learning/LearningProgressBar';
import { CardContent } from '@components/learning/LearningContentCard/children/CardContent';
import { TagsContainer } from '@components/learning/LearningContentCard/children/TagsContainer';
import { CardImage } from '@components/learning/LearningContentCard/children/CardImage';
import { ContentType } from '@components/learning/types';
import { MIN_CARD_WIDTH, BODY_PADDING, LABELS_GAP_PX, MAX_TAGS_QTY } from './constants';

interface LearningProgress {
  /** Amount of completed resources */
  completedResources: number;
  /** Total amount of resources */
  resourceCount: number;
  /** Function to be called when the bookmark button is clicked */
  onSave: (isSaved: boolean) => void;
  /** Indicates whether the resource is saved or not */
  isSaved: boolean;
  /** Date when the course was completed */
  completionDate?: string;
}

export interface LearningContentCardProps {
  /** entryId and fieldId from contentful for the inspector mode */
  inspectMode?: InspectModeContentful;
  /** Title of the card */
  title: string;
  /** Description of the card */
  description?: string;
  /** Link that is used to redirect user */
  link: string;
  /** Event that gets calls when card is clicked */
  onClick: () => void;
  /** Specifies where the linked URL will be opened */
  target?: LinkTarget;
  /** Tag various labels displayed on top of the title of the card */
  tags: TagProps[];
  /** Optionally specify minimum height of the card in px */
  minHeight?: number;
  /** Optionally show loading state */
  isLoading?: boolean;
  /** items from MyLearning page */
  learningProgress?: LearningProgress;
  /** Function to be executed on generate pdf */
  onGeneratePdf?: () => void;
  /** Displayed element to show in the card */
  shouldDisplayShareButton?: boolean;
  /** Displayed element to show in the card */
  shouldDisplayBookmark?: boolean;
  /** Background image src */
  imageUrl?: string;
  /** Product icon src */
  productIcon?: string;
  /** Background color */
  backgroundColor?: string;
}

/**
 * Card component used to show different resources
 * @param {LearningContentCardProps} LearningContentCardProps
 * @returns {React.ReactElement} React.ReactElement
 *
 * @example
 * <LearningContentCard label="Text" />
 */
export const LearningContentCard: React.FC<LearningContentCardProps> = ({
  inspectMode,
  title,
  description,
  onClick,
  target,
  link,
  tags,
  minHeight = 248,
  isLoading = false,
  learningProgress,
  onGeneratePdf,
  shouldDisplayShareButton = false,
  shouldDisplayBookmark = false,
  productIcon,
  imageUrl,
  backgroundColor,
}: LearningContentCardProps): React.ReactElement => {
  const cardRef = useRef<HTMLDivElement>(null);
  const [lastLabelWidth, setLastLabelWidth] = useState<number | string>('auto');

  const clickHandler = useCallback(() => {
    onClick && onClick();
  }, [link, target, onClick]);

  const handleKeyDown = useCallback(withFocusInteraction(clickHandler), [clickHandler]);

  const handleOnSave = (event: React.MouseEvent<HTMLButtonElement>): void => {
    if (learningProgress) {
      event.stopPropagation();
      event.preventDefault();
      learningProgress.onSave(!learningProgress.isSaved);
    }
  };

  useEffect(() => {
    // truncating the last label if it doesn't fit in the card
    if (tags.length && cardRef.current) {
      const card = cardRef.current;
      const tagsContainer = card.querySelector('#tabs-container');

      const maxTagsContainerWidth = (card?.offsetWidth || MIN_CARD_WIDTH) - BODY_PADDING * 2;
      let firstTagsWidth = 0;
      let totalTagsWidth = 0;

      if (tagsContainer) {
        Array.from(tagsContainer.children)
          .slice(0, MAX_TAGS_QTY)
          .forEach((tag, i, arr) => {
            if (i < arr.length - 1) {
              firstTagsWidth += tag.clientWidth + LABELS_GAP_PX;
            }
            totalTagsWidth += tag.clientWidth;
          });

        const shouldTruncate =
          totalTagsWidth + (LABELS_GAP_PX * tagsContainer.children.length - 1) + BODY_PADDING * 2 > card?.offsetWidth;

        if (shouldTruncate) {
          setLastLabelWidth(maxTagsContainerWidth - firstTagsWidth - 2);
        }
      }
    }
  }, [cardRef.current]);

  if (isLoading) {
    return (
      <div
        css={[learningContentCardContainerStyles, loadingLearningContentCardContainerStyles]}
        data-testid="loading-learning-content-card"
        style={{ minHeight: `${minHeight}px` }}
      >
        <div css={[imageBaseStyles, noImageStyles]} />
        <div css={bodyContainerStyles}>
          <div css={labelContainerStyles}>
            <div>
              <div css={smallPillStyles} />
              <div css={[smallPillStyles, css({ marginLeft: '24px' })]} />
            </div>
          </div>
          <div css={titleAndDetailsContainerStyles}>
            <div css={css({ marginTop: '12px' })}>
              <div css={[bigPillStyles, css({ width: '212px' })]} />
              <div css={bigPillStyles} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <a
      href={link}
      target={target}
      rel="noopener noreferrer"
      css={linkContainerStyles}
      onClick={(e): void => e.preventDefault()}
      tabIndex={-1}
    >
      <div
        ref={cardRef}
        role="link"
        css={learningContentCardContainerStyles}
        onClick={clickHandler}
        onKeyDown={handleKeyDown}
        tabIndex={0}
        data-testid="learning-content-card"
        style={{ minHeight: `${minHeight}px` }}
      >
        <CardImage
          imageUrl={imageUrl}
          backgroundColor={backgroundColor}
          productIcon={productIcon}
          isLearningProgress={!!learningProgress}
        />
        <div css={[bodyContainerStyles, learningProgress && progressBodyContainerStyles]}>
          <TagsContainer tags={tags} lastLabelWidth={lastLabelWidth} />
          <CardContent
            title={title}
            description={description}
            inspectMode={inspectMode}
            isLearningProgress={!!learningProgress}
          />
          {learningProgress && (
            <div css={learningContentCardProgressContainerStyles} data-testid="learning-progress-content">
              <div css={learningContentCardProgressItemsContainerStyles}>
                <LearningProgressBar
                  completedResources={learningProgress.completedResources}
                  resourceCount={learningProgress.resourceCount}
                  completionDate={learningProgress.completionDate}
                  contentType={ContentType.LEARNING_PATH}
                />
                <div css={learningContentCardIconsContainerStyles}>
                  {shouldDisplayShareButton && (
                    <PopupShare
                      urlToShare={link}
                      alignToLeft
                      shareOnFb={false}
                      shareOnLinkedin={false}
                      showCopyLink={false}
                      shareOnPdf={true}
                      onGeneratePdf={onGeneratePdf}
                    />
                  )}
                  {shouldDisplayBookmark && (
                    <button
                      data-testid="save-content"
                      css={learningContentCardBookmarkContainerStyles}
                      onClick={handleOnSave}
                    >
                      {learningProgress.isSaved ? <Icon type="bookmark-filled" /> : <Icon type="bookmark" />}
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </a>
  );
};

const imageBaseStyles = css({
  width: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  borderRadius: '7px 7px 0px 0px',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  height: '80px',
});

const noImageStyles = css({
  background: token('color.background.accent.gray.subtlest'),
});

const bodyContainerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  padding: `${BODY_PADDING}px 0 0`,
});

const progressBodyContainerStyles = css({
  padding: `21px 0 0`,
});

const labelContainerStyles = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: `${LABELS_GAP_PX}px`,
  padding: `0 ${BODY_PADDING}px`,
  overflow: 'hidden',
});

const linkContainerStyles = css({
  textDecoration: 'none',

  '&:hover': {
    textDecoration: 'none',
  },
});

const titleAndDetailsContainerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: `0 ${token('space.300')} ${token('space.300')}`,
});

const learningContentCardProgressContainerStyles = css({
  borderTop: `1px solid ${token('color.border')}`,
});

const learningContentCardProgressItemsContainerStyles = css({
  display: 'flex',
  justifyContent: 'space-between',
  padding: `18px ${token('space.300')}`,
});

const learningContentCardIconsContainerStyles = css({
  display: 'flex',
  gap: token('space.300'),
});

const learningContentCardBookmarkContainerStyles = css({
  height: '24px',
  border: 'none',
  background: 'none',
  cursor: 'pointer',
  outline: 'none',

  '&:focus-visible': {
    outline: `2px solid ${token('color.border.selected')}`,
  },
});

const learningContentCardContainerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: token('color.background.input'),
  borderRadius: '8px',
  border: `1px solid ${token('color.border')}`,
  boxShadow: token('elevation.shadow.raised'),
  transition: 'box-shadow 0.3s ease-in-out',
  cursor: 'pointer',
  minWidth: `${MIN_CARD_WIDTH}px`,

  '&:hover': {
    boxShadow: token('elevation.shadow.overlay'),
  },

  '&:active': {
    outline: `1px solid ${token('color.border.focused')}`,
    border: `1px solid ${token('color.border.focused')}`,
  },
});

const loadingLearningContentCardContainerStyles = css({
  boxShadow: 'none',
  border: `1px solid ${token('color.background.disabled')}`,
  cursor: 'initial',

  '&:hover': {
    boxShadow: 'none',
  },

  '&:active': {
    outline: 'none',
    border: `1px solid ${token('color.background.disabled')}`,
  },
});

const smallPillStyles = css({
  width: '52px',
  height: '10.5px',
  backgroundColor: token('color.background.neutral'),
  borderRadius: '15px',
  marginLeft: token('space.100'),
  display: 'inline-block',
});

const bigPillStyles = css({
  maxWidth: '227px',
  height: '17.5px',
  backgroundColor: token('color.background.neutral'),
  borderRadius: '25px',
  marginTop: token('space.100'),
});
