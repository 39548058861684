import React, { ReactNode, useState, useRef } from 'react';
import { css } from '@emotion/react';
import { useOutsideClickHandler } from '@hooks/useOutsideClickHandler';
import { BREAKPOINTS } from '@constants/breakpoints';
import CloseIcon from '@components/shared/NavigationBar/assets/CloseIcon';
import HamburgerMenuIcon from '@components/shared/NavigationBar/assets/HamburgerMenuIcon';
import { IconButton } from '@atlaskit/button/new';
import { token } from '@atlaskit/tokens';
import { NavBarProfileItem } from './NavBarProfileItem';
import { AtlassianNavLogo } from './AtlassianNavLogo';
import { MenuItem } from './NavBarProfileItem/MenuItem';
import { NavBarExpandableItem } from './NavBarLinkItem/NavBarExpandableItem';

export interface NavigationBarProps {
  logoItem: ReactNode;
  leftItems?: ReactNode[];
  rightItems?: ReactNode[];
  /**
   * Controls whether the navigation bar should stick to the top of the viewport when scrolling
   * @default true
   */
  isSticky?: boolean;
  /** (optional) additional function to be called when the user clicks to open/close the mobile/tablet menu, called
   * immediately after the menu is opened/closed (can be used for sending analytics events)
   */
  onMenuToggleClick?: (isMenuOpen: boolean) => void;
  /** (optional) additional function to be called when the user clicks outside the open mobile/tablet menu to close it,
   * only called if the menu is open (can be used for sending analytics events)
   */
  onMenuOutsideClick?: () => void;
}

type ToggleButtonProps = {
  isOpen: boolean;
  toggleMenu: () => void;
};
const ToggleButton: React.FC<ToggleButtonProps> = ({ isOpen, toggleMenu }) => (
  <IconButton
    appearance="subtle"
    onClick={toggleMenu}
    icon={isOpen ? CloseIcon : HamburgerMenuIcon}
    label={isOpen ? 'Close menu' : 'Open menu'}
  />
);

export const NavigationBar: React.FC<NavigationBarProps> = ({
  leftItems,
  rightItems,
  logoItem,
  isSticky = true,
  onMenuToggleClick,
  onMenuOutsideClick,
}) => {
  // open state of mobile/tablet menu
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // ref for the toggle button - necessary for the useOutsideClickHandler
  // useOutsideClickHandler will close menu IF the user clicks outside of the menu or toggle button (necessary to include toggle button prevent double-click)
  // separately, toggle button (close button) should also close the menu
  const toggleRef = useRef(null);
  const handleToggle = (): void => {
    setIsMenuOpen(!isMenuOpen);
    if (onMenuToggleClick) {
      onMenuToggleClick(isMenuOpen);
    }
  };

  return (
    <nav css={[containerStyles, !isSticky && stickyStyles]} data-testid="navbar">
      <div css={innerContainerStyles}>
        <div css={logoWrapperStyles}>{logoItem}</div>
        <div css={navContentWrapperStyles}>
          {leftItems || rightItems ? (
            <div css={navMenuToggleButtonStyles} ref={toggleRef}>
              <ToggleButton isOpen={isMenuOpen} toggleMenu={handleToggle} />
            </div>
          ) : null}
          <DesktopNavItems leftItems={leftItems} rightItems={rightItems} />
          <SmallNavItems
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
            onMenuOutsideClick={onMenuOutsideClick}
            leftItems={leftItems}
            rightItems={rightItems}
            toggleRef={toggleRef}
          />
        </div>
      </div>
    </nav>
  );
};

interface NavItemsProps {
  leftItems: React.ReactNode[] | undefined;
  rightItems: React.ReactNode[] | undefined;
}

const MenuItems = ({ leftItems, rightItems }: NavItemsProps): React.ReactNode => (
  <>
    {leftItems?.map((item, index) => (
      <li css={[navItemWrapperStyles, leftItemStyles]} key={`left-${index}`} role="menuitem">
        {item}
      </li>
    ))}
    {rightItems?.map((item, index) => (
      <li css={[navItemWrapperStyles, rightItemStyles]} key={`right-${index}`} role="menuitem">
        {item}
      </li>
    ))}
  </>
);

const DesktopNavItems = ({ leftItems, rightItems }: NavItemsProps): React.ReactNode => (
  <ul css={desktopNavWrapperStyles} role="menubar" aria-orientation={'horizontal'}>
    <MenuItems leftItems={leftItems} rightItems={rightItems} />
  </ul>
);

interface SmallNavItemsProps extends NavItemsProps {
  isMenuOpen: boolean;
  setIsMenuOpen: (isMenuOpen: boolean) => void;
  onMenuOutsideClick: (() => void) | undefined;
  toggleRef: React.RefObject<HTMLDivElement>;
}

const SmallNavItems = ({
  isMenuOpen,
  setIsMenuOpen,
  onMenuOutsideClick,
  leftItems,
  rightItems,
  toggleRef,
}: SmallNavItemsProps): React.ReactNode => {
  const menuRef = useRef(null);
  // if user clicks outside of the mobile/tablet menu or toggle button, then close the menu
  useOutsideClickHandler([menuRef, toggleRef], () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
      if (onMenuOutsideClick) {
        onMenuOutsideClick();
      }
    }
  });
  return isMenuOpen ? (
    <div css={smallNavWrapperStyles}>
      <ul css={smallNavUlStyles} ref={menuRef} role="menubar" aria-orientation={'vertical'}>
        <MenuItems leftItems={leftItems} rightItems={rightItems} />
      </ul>
    </div>
  ) : null;
};

const navItemWrapperStyles = css({
  height: '100%',
  alignSelf: 'center',
  margin: '0 auto',
  padding: 0,
  [`@media (max-width: ${BREAKPOINTS.TABLET.MAX_WIDTH}px)`]: {
    '& > button': {
      width: '100%',
      justifyContent: 'center',
    },
  },
});

const containerStyles = css({
  backgroundColor: '#FFFFFF',
  position: 'sticky', // 'sticky' nav instead of 'fixed' prevents the nav from overlapping the scroll bar and creating extra width
  width: '100%',
  height: '68px',
  left: 0,
  top: 0,
  padding: '0 16px',
  zIndex: 100,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [`@media (max-width: ${BREAKPOINTS.TABLET.MAX_WIDTH}px)`]: {
    height: '56px',
    justifyContent: 'space-between',
  },
});

const stickyStyles = css({
  position: 'fixed',
});

const innerContainerStyles = css({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  maxWidth: '1232px',
  [`@media (min-width: ${BREAKPOINTS.DESKTOP.MIN_WIDTH}px)`]: {
    width: '1232px',
  },
});

const navContentWrapperStyles = css({
  flex: 1,
  [`@media (max-width: ${BREAKPOINTS.TABLET.MAX_WIDTH}px)`]: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const navMenuToggleButtonStyles = css({
  flex: 0,
  alignSelf: 'flex-end',
  [`@media (min-width: ${BREAKPOINTS.DESKTOP.MIN_WIDTH}px)`]: {
    display: 'none',
  },
  button: {
    '&:hover, &:active': {
      backgroundColor: 'transparent',
      color: token('color.icon.brand'),
    },
  },
});

const desktopNavWrapperStyles = css({
  backgroundColor: '#FFFFFF',
  listStyle: 'none',
  display: 'none',
  flexDirection: 'column',
  width: '100%',
  padding: '16px 12px',
  margin: 0,
  [`@media (min-width: ${BREAKPOINTS.DESKTOP.MIN_WIDTH}px)`]: {
    display: 'block',
    position: 'relative',
    gap: '8px',
    padding: 0,
    boxShadow: 'none',
    height: '42px',
  },
});

// mobile/tablet open menu
const smallNavWrapperStyles = css({
  backgroundColor: '#FFFFFF',
  position: 'absolute',
  right: 0,
  margin: 0,
  width: '100%',
  zIndex: 1000,
  [`@media (max-width: ${BREAKPOINTS.MOBILE.MAX_WIDTH}px)`]: {
    // full screen menu in mobile view
    height: '100%',
    position: 'fixed',
    right: 0,
    top: 0,
    marginTop: '56px',
  },
  [`@media ((min-width: ${BREAKPOINTS.TABLET.MIN_WIDTH}px) and (max-width: ${BREAKPOINTS.TABLET.MAX_WIDTH}px))`]: {
    // appearance of floating fixed-width menu in tablet view
    width: '375px',
    top: '100%',
    marginTop: '-9px',
    marginRight: '20px',
    borderRadius: '8px',
    boxShadow: '0 0 1px 0 #ccc,0 5px 20px -5px rgba(0,0,0,.1)',
  },
  [`@media (min-width: ${BREAKPOINTS.DESKTOP.MIN_WIDTH}px)`]: {
    display: 'none',
  },
});

const smallNavUlStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  listStyle: 'none',
  padding: '16px 0',
});

const logoWrapperStyles = css({
  margin: 0,
  padding: 0,
  paddingRight: '32px',
  flexShrink: 0, // don't shrink the logo
  flexGrow: 0,
});

const leftItemStyles = css({
  [`@media (max-width: ${BREAKPOINTS.TABLET.MAX_WIDTH}px)`]: {
    alignSelf: 'flex-start',
    width: '100%',
  },
  [`@media (min-width: ${BREAKPOINTS.DESKTOP.MIN_WIDTH}px)`]: {
    float: 'left',
    marginRight: '8px',
  },
});

const rightItemStyles = css({
  [`@media (max-width: ${BREAKPOINTS.TABLET.MAX_WIDTH}px)`]: {
    width: '100%',
  },
  [`@media (min-width: ${BREAKPOINTS.DESKTOP.MIN_WIDTH}px)`]: {
    float: 'right',
  },
});

export { NavBarProfileItem, AtlassianNavLogo as NavBarLogoItem, MenuItem, NavBarExpandableItem };
export type { Link as LinkType } from './NavBarLinkItem';
