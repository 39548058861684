import { token } from '@atlaskit/tokens';
import { css } from '@emotion/react';
import React, { useCallback } from 'react';
import { inspectorProps, withFocusInteraction } from '@utils/index';
import { InspectModeContentful } from '@types';
import { PopupShare } from '@components/learning/PopupShare';
import { LearningProgressBar } from '@components/learning/LearningProgressBar';
import { ContentType } from '@components/learning/types';
import { Icon } from '@components/shared';

interface LearningProgress {
  /** Amount of completed resources */
  completedResources: number;
  /** Total amount of resources */
  resourceCount: number;
  /** Function to be called when the bookmark button is clicked */
  onSave: (isSaved: boolean) => void;
  /** Indicates whether the resource is saved or not */
  isSaved: boolean;
  /** Date when the course was completed */
  completionDate?: string;
}

export interface LearningCourseCardProps {
  /** entryId and fieldId from contentful for the inspector mode */
  inspectMode?: InspectModeContentful;
  /** Title of the card */
  title: string;
  /** Link that is used to redirect user */
  link: string;
  /** Event that gets calls when card is clicked */
  onClick: () => void;
  /** Image URL */
  imageUrl?: string;
  /** Optionally specify minimum height of the card in px */
  minHeight?: number;
  /** items from MyLearning page */
  learningProgress?: LearningProgress;
  /** Optionally show loading state */
  isLoading?: boolean;
  /** Function to be executed on generate pdf */
  onGeneratePdf?: () => void;
  /** Displayed element to show in the card */
  shouldDisplayShareButton?: boolean;
  /** Displayed element to show in the card */
  shouldDisplayBookmark?: boolean;
}

/**
 * Card component used to show different resources
 * @param {LearningCourseCardProps} LearningCourseCardProps
 * @returns {React.ReactElement} React.ReactElement
 *
 * @example
 * <LearningCourseCard title="Text" />
 */
export const LearningCourseCard: React.FC<LearningCourseCardProps> = ({
  inspectMode,
  title,
  imageUrl,
  onClick,
  link,
  minHeight = 153,
  learningProgress,
  onGeneratePdf,
  shouldDisplayShareButton,
  shouldDisplayBookmark,
}: LearningCourseCardProps): React.ReactElement => {
  const onClickHandler = useCallback(() => {
    onClick && onClick();
  }, [onClick, link]);

  const onKeyDown = useCallback(withFocusInteraction(onClickHandler), [onClickHandler]);

  const handleSave = (event: React.MouseEvent<HTMLButtonElement>): void => {
    if (learningProgress) {
      event.stopPropagation();
      event.preventDefault();
      learningProgress.onSave(!learningProgress.isSaved);
    }
  };

  const isSaved = learningProgress?.completedResources === learningProgress?.resourceCount || learningProgress?.isSaved;

  return (
    <a href={link} css={linkContainerStyles} rel="noopener noreferrer" onClick={(e): void => e.preventDefault()}>
      <div
        css={learningCourseCardContainerStyles}
        onClick={onClickHandler}
        onKeyDown={onKeyDown}
        tabIndex={0}
        data-testid="learning-course-card"
        style={{ minHeight: `${minHeight}px` }}
      >
        <div css={bodyContainerStyles}>
          <div css={imageContainerStyles}>
            {imageUrl ? (
              <img css={[imageStyles]} data-testid="learning-course-card-image" src={imageUrl} alt="course-img" />
            ) : (
              <div data-testid="learning-course-card-no-image" css={noImageStyles} />
            )}
          </div>
          <div css={titleAndDetailsContainerStyles}>
            {title && (
              <p data-testid="title" {...inspectorProps('title', inspectMode)} css={titleStyles}>
                {title}
              </p>
            )}
          </div>
        </div>
        {learningProgress && (
          <div css={learningCourseCardProgressContainerStyles} data-testid="learning-course-card-progress-content">
            <div css={learningCourseCardProgressItemsContainerStyles}>
              <LearningProgressBar
                completedResources={learningProgress.completedResources}
                resourceCount={learningProgress.resourceCount}
                completionDate={learningProgress.completionDate}
                contentType={ContentType.COURSE}
              />
              <div css={learningCourseCardIconsContainerStyles}>
                {shouldDisplayShareButton && (
                  <PopupShare
                    urlToShare={link}
                    alignToLeft
                    shareOnFb={false}
                    shareOnLinkedin={false}
                    showCopyLink={false}
                    shareOnPdf={true}
                    onGeneratePdf={onGeneratePdf}
                  />
                )}
                {shouldDisplayBookmark && (
                  <button
                    data-testid="save-content"
                    css={learningCourseCardBookmarkContainerStyles}
                    onClick={handleSave}
                  >
                    {isSaved ? <Icon type="bookmark-filled" /> : <Icon type="bookmark" />}
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </a>
  );
};

const learningCourseCardContainerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: token('color.background.input'),
  borderRadius: '8px',
  border: `1px solid ${token('color.border')}`,
  boxShadow: token('elevation.shadow.raised'),
  transition: 'box-shadow 0.3s ease-in-out',
  cursor: 'pointer',
  width: '100%',
  height: '153px',

  '&:hover': {
    boxShadow: token('elevation.shadow.overlay'),
  },

  '&:active': {
    outline: `1px solid ${token('color.border.focused')}`,
    border: `1px solid ${token('color.border.focused')}`,
  },
});

const bodyContainerStyles = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: `${token('space.200')} ${token('space.300')} ${token('space.200')} ${token('space.075')}`,
  gap: token('space.200'),
  minHeight: '96px',
  justifyContent: 'flex-end',

  '@media (max-width: 1279px)': {
    justifyContent: 'flex-start',
    padding: `${token('space.050')} ${token('space.050')} ${token('space.050')} ${token('space.200')}`,
  },
});

const linkContainerStyles = css({
  textDecoration: 'none',

  '&:hover': {
    textDecoration: 'none',
  },
});

const imageContainerStyles = css({
  display: 'flex',
  width: '64px',
  height: '64px',
  justifyContent: 'center',
  alignItems: 'center',
  flexShrink: 0,
});

const imageStyles = css({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: '50%',
});

const noImageStyles = css({
  background: token('color.background.accent.gray.subtlest'),
  width: '64px',
  height: '64px',
  borderRadius: '50%',
});

const titleAndDetailsContainerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '275px',

  '@media (max-width: 1279px)': {
    width: '232px',
    flexGrow: 1,
    padding: `${token('space.300')} ${token('space.200')} ${token('space.300')} ${token('space.0')}`,
  },
});

const learningCourseCardProgressContainerStyles = css({
  borderTop: `1px solid ${token('color.border')}`,
});

const learningCourseCardProgressItemsContainerStyles = css({
  display: 'flex',
  justifyContent: 'space-between',
  padding: `${token('space.200')} ${token('space.300')}`,
});

const learningCourseCardIconsContainerStyles = css({
  display: 'flex',
  gap: token('space.300'),
});

const learningCourseCardBookmarkContainerStyles = css({
  height: '24px',
  border: 'none',
  background: 'none',
  cursor: 'pointer',
  outline: 'none',

  '&:focus-visible': {
    outline: `2px solid ${token('color.border.selected')}`,
  },
});

const titleStyles = css({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  marginTop: '20px',
  marginBottom: 0,
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 400,
  fontStyle: 'normal',
  lineHeight: '24px',
  color: token('color.text'),
});
