import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { css } from '@emotion/react';

import { Divider, Icon, IconSize } from '@components/shared';
import { Lesson } from '@components/learning/LessonList/index';
import { IconChange } from './IconChange';
import { LessonSection } from './LessonSection';
import { media } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

interface LessonOverviewProps extends Lesson {
  hasDivider?: boolean;
}

export const LessonOverview: React.FC<LessonOverviewProps> = ({
  lessonTitle,
  slug,
  duration,
  isCompleted,
  isCurrentLesson,
  hasDivider,
  sections,
  onNavigation,
}) => {
  const [isExpanded, setIsExpanded] = useState(isCurrentLesson);

  const expandByCard = (event: SyntheticEvent): void => {
    if (event.target === event.currentTarget) {
      setIsExpanded(!isExpanded);
    }
  };

  const expandByButton = (): void => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    setIsExpanded(isCurrentLesson);
  }, [isCurrentLesson]);

  const handleKeyDown = useCallback(
    (
      event: React.KeyboardEvent<HTMLSpanElement>,
      onNavigation: (options: { [key: string]: string }) => void,
      slug: string,
    ): void => {
      if (event.code === 'Space') {
        event.preventDefault();
        onNavigation({ lessonSlug: slug });
      }
    },
    [onNavigation, slug],
  );

  return (
    <section tabIndex={0} css={[lessonCardContainerStyles, !hasDivider && css({ marginBottom: '16px' })]} key={slug}>
      <div
        css={[lessonCardStyles, isCurrentLesson && currentLessonStyles, !isCurrentLesson && hoverLessonStyles]}
        onClick={expandByCard}
      >
        <button
          tabIndex={0}
          aria-label="expand lesson button"
          css={[
            expandButtonStyles,
            !isCurrentLesson && css({ borderColor: token('color.background.accent.gray.subtlest.hovered') }),
          ]}
          data-testid="expand-button"
          onClick={expandByButton}
        >
          {isExpanded ? (
            <Icon
              type="chevron-down"
              size={IconSize.MEDIUM}
              color={isCurrentLesson ? token('color.icon.selected') : token('color.icon.subtle')}
            />
          ) : (
            <Icon
              type="chevron-right"
              size={IconSize.MEDIUM}
              color={isCurrentLesson ? token('color.icon.selected') : token('color.icon.subtle')}
            />
          )}
        </button>
        <div css={mainContentColumnStyles}>
          <p css={[durationStyles, !isCurrentLesson && css({ color: token('color.text.subtlest') })]}>
            {duration.toUpperCase()}
          </p>
          <div css={lessonContainer}>
            <div css={lessonTitleStyles}>
              <span
                role="button"
                tabIndex={0}
                onKeyDown={(event): void => handleKeyDown(event, onNavigation, slug)}
                onClick={(): void => onNavigation({ lessonSlug: slug })}
              >
                {lessonTitle}
              </span>
            </div>
            <div css={css({ marginLeft: '1.5rem', display: 'flex' })}>
              <IconChange isCompleted={isCompleted} size={IconSize.MEDIUM} />
            </div>
          </div>
          <div css={css({ marginTop: '0.75rem' })}>
            {isExpanded &&
              sections.map((section) => (
                <LessonSection
                  {...section}
                  lessonSlug={slug}
                  onNavigation={onNavigation}
                  key={`section-${section.slug}`}
                />
              ))}
          </div>
        </div>
      </div>
      {hasDivider && (
        <div css={css({ marginTop: '16px', marginBottom: '16px' })}>
          <Divider height={2} color={token('color.border')} />
        </div>
      )}
    </section>
  );
};

const lessonCardContainerStyles = css({
  maxWidth: '25.25rem',

  [media.below.sm]: {
    maxWidth: '100%',
    paddingRight: '16px',
  },
});

const lessonCardStyles = css({
  width: '100%',
  display: 'flex',
  padding: '16px',
  borderRadius: '0.25rem',
  cursor: 'pointer',
});

const currentLessonStyles = css({
  backgroundColor: token('color.background.selected'),
  borderLeft: `4px solid ${token('color.text.brand')}`,
});

const hoverLessonStyles = css({
  ':hover': {
    backgroundColor: token('color.background.accent.gray.subtlest'),
  },
  ':hover button': {
    borderColor: token('color.background.accent.gray.subtlest.pressed'),
  },
});

const mainContentColumnStyles = css({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '0.75rem',
});

const expandButtonStyles = css({
  width: '1.5rem',
  height: '1.5rem',
  padding: 0,
  border: `1px solid ${token('color.background.accent.blue.subtlest.pressed')}`,
  borderRadius: '0.25rem',
  boxSizing: 'content-box',
  background: 'transparent',
  outline: 'none',
  cursor: 'pointer',

  ':focus-visible': {
    border: `2px solid ${token('color.border.selected')}`,
  },
});

const durationStyles = css({
  width: '100%',
  fontSize: '0.75rem',
  fontWeight: 400,
  lineHeight: '1.125rem',
  color: token('color.text.selected'),
  marginTop: 0,
  marginBottom: token('space.075'),
});

const lessonContainer = css({
  display: 'flex',
  alignItems: 'center',
});

const lessonTitleStyles = css({
  width: '100%',
  fontFamily: 'Charlie Display',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '1.5rem',

  span: {
    color: token('color.text'),
  },

  ':hover': {
    span: {
      color: token('color.text.selected'),
      textDecoration: 'none',
    },
  },
});
