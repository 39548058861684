import { useCallback } from 'react';
import { createHook } from 'react-sweet-state';
import { RouterStore } from '../router-store';
const createPathParamHook = createHook(RouterStore, {
  selector: (_ref, _ref2) => {
    let {
      match: {
        params
      }
    } = _ref;
    let {
      paramKey
    } = _ref2;
    return params[paramKey];
  }
});
/**
 * Utility hook for accessing URL path params
 */

export const usePathParam = paramKey => {
  const [paramVal, routerActions] = createPathParamHook({
    paramKey
  });
  const setPathParam = useCallback((newValue, updateType) => {
    routerActions.updatePathParam({
      [paramKey]: newValue
    }, updateType);
  }, [paramKey, routerActions]);
  return [paramVal, setPathParam];
};