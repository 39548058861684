import React, { useCallback } from 'react';
import { Image, ImageProps } from '@components/shared';
import { css } from '@emotion/react';

import { withFocusInteraction } from '@utils/index';
import { token } from '@atlaskit/tokens';

export interface CertificateCardProps {
  /** Image component rendered in the component */
  image: Omit<ImageProps, 'height' | 'width'>;
  /** Title of the Certificate */
  title: string;
  /** Link that is used to redirect to the certificate */
  link: string;
  /** Background color of the card top */
  backgroundColor: string;
  /** Optional onClick handler */
  onClick?: (link: string, href: string) => void;
}

/**
 * Component that renders a card used in different pages
 * @param {CertificateCardProps} CertificateCardProps
 * @returns {React.ReactElement} React.ReactElement
 *
 * @example
 * <CertificateCard title="text" backgroundColor="#1558BC" />
 */
export const CertificateCard: React.FC<CertificateCardProps> = ({ image, backgroundColor, title, link, onClick }) => {
  const clickHandler = useCallback(() => {
    if (onClick) {
      onClick(link, '_self');
    }
  }, [onClick, link]);

  const handleKeyDown = useCallback(withFocusInteraction(clickHandler), []);

  return (
    <div
      tabIndex={0}
      css={containerStyles}
      onClick={clickHandler}
      onKeyDown={handleKeyDown}
      data-testid="certificate-card"
    >
      <div css={[cardHeaderStyles, css({ backgroundColor })]} data-testid="certificate-card-background">
        <div css={imageContainerStyles}>
          <Image
            {...image}
            width={112}
            height={112}
            preventModal
            style={{
              objectFit: 'contain',
            }}
          />
        </div>
      </div>
      <div css={cardTitleStyles}>{title}</div>
    </div>
  );
};

const containerStyles = css({
  maxWidth: '280px',
  background: token('color.background.input'),
  borderRadius: '5px',
  boxShadow: token('elevation.shadow.raised'),
  transition: 'box-shadow 0.2s ease',

  '&:hover': {
    cursor: 'pointer',
    boxShadow: token('elevation.shadow.overlay'),
  },
});

const cardHeaderStyles = css({
  height: '66px',
  padding: '14px 16px 0',
  borderTopLeftRadius: '5px',
  borderTopRightRadius: '5px',
});

const imageContainerStyles = css({
  height: '112px',
  width: '112px',
});

const cardTitleStyles = css({
  padding: '0 16px 24px',
  marginTop: '76px',
  color: 'N800',
  lineHeight: '20px',
  fontSize: '16px',
  fontFamily: 'system-ui',
  fontWeight: 600,
  wordBreak: 'break-word',
});
