import { FunctionComponent } from 'react';
import { css } from '@compiled/react';
import EventsHero from 'components/events/EventsHero';
import { PageWithAnalytics } from 'components/common/analytics-wrapped/PageWithAnalytics';
import Section from 'components/common/Section';
import EventsCards from 'components/events/EventsCards';
import BenefitsCards from 'components/events/BenefitsCards';
import GetInvolved from 'components/events/GetInvolved';
import { Flags } from 'assets/images';
import { YouTubePlayer } from 'components/events/YoutubePlayer';

import {MOBILE, TABLET} from '../utils/constants';

const EventsPage: FunctionComponent = () => {
  return (
    <PageWithAnalytics eventScreenName="eventsScreen">
        <div css={containerStyles}> 
          <EventsHero />
          <section css={[mainStyles, eventsForEveryoneContainer]}>
            <Section heading="Events for everyone">
              <EventsCards />
            </Section>
          </section>
          <div>
            <section css={[videoSectionStyles]}>
              <div css={backgroundRectangleStyle}></div>
              <div css={backgroundRectangle2Style}></div>
              <div css={backgroundRectangle3Style}></div>
              <YouTubePlayer />
            </section>
          </div>
          <section css={[mainStyles, benefitsSectionStyles]}>
            <div css={flagsStyle}>
              <img src={Flags} alt="Flags" />
            </div>
            <Section heading="Join and enjoy the benefits">
              <BenefitsCards />
            </Section>
          </section>
          <div>
            <section css={readyToGetInvolvedStyles}>
              <div css={getInvolvedRectangleStyle}></div>
              <div css={getInvolvedRectangle2Style}></div>
              <GetInvolved />
            </section>
          </div>
        </div>
    </PageWithAnalytics>
  );
};
export default EventsPage;
const containerStyles = css({
  width: '100%',
  overflowX: 'hidden',
});
const flagsStyle = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  marginBottom: '32px',
  marginTop: '20px 0',
});
const eventsForEveryoneContainer = css({
    alignItems: 'center',
    paddingBottom: '120px', // accounting for rectangle overlay
    [TABLET.MEDIA_QUERY]: {
    paddingBottom: '80px', // accounting for rectangle overlay
  },
    [MOBILE.MEDIA_QUERY]: {
    paddingBottom: '60px',
  },
});
const mainStyles = css({
  padding: '80px 16px',
  display: 'flex',
  flexDirection: 'column',
  margin: '0 auto',
  width: '100%',
  maxWidth: '1024px',
  textAlign: 'left',
  [TABLET.MEDIA_QUERY]: {
    maxWidth: '720px',
    padding: '40px 0px',
  },
  [MOBILE.MEDIA_QUERY]: {
    padding: '40px 16px',

    width: '100%',
  },
});
const benefitsSectionStyles = css({
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
      paddingTop: '105px', // accounting for rectangle overlay
    [TABLET.MEDIA_QUERY]: {
    paddingTop: '65px', // accounting for rectangle overlay
  },
    [MOBILE.MEDIA_QUERY]: {
     paddingTop: '50px',
  },
});

const videoSectionStyles = css({
  backgroundColor: '#E9F2FE',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '80px auto',
    position: 'relative', 
    [TABLET.MEDIA_QUERY]: {
    padding: '40px 0px',
  },
  [MOBILE.MEDIA_QUERY]: {
    padding: '24px 16px',
  },
});

const readyToGetInvolvedStyles = css({
  backgroundColor: '#FFE48F',
  marginTop: '60px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: '50px',
  paddingBottom: '68px',
  position: 'relative', 

  [TABLET.MEDIA_QUERY]: {
    padding: '40px 0px',
    marginTop: '40px',
    },
  [MOBILE.MEDIA_QUERY]: {
    padding: '40px 16px',
    marginTop: '40px',
  },
});

const backgroundRectangleStyle = css({
  position: 'absolute',
  top: 0,
  left: '50%',
  width: '100vw',
  height: '100%',
  backgroundColor: '#E9F2FE',
  zIndex: -1,
  transform: 'translateX(-50%)',
});
const backgroundRectangle2Style = css({
  position: 'absolute',
  top: '-2%',
  left: '35%',
  width: '80vw',
  height: '80%',
  backgroundColor: '#E9F2FE',
  zIndex: -2,
  transform: 'rotate(2deg)',
});
const backgroundRectangle3Style = css({
  position: 'absolute',
  top: '22%',
  left: '-20%',
  width: '80vw',
  height: '80%',
  backgroundColor: '#E9F2FE',
  zIndex: -3,
  transform: 'rotate(1deg)',
});

// Background Rectangles for Overlapping Effect in the Get Involved Section
const getInvolvedRectangleStyle = css({
  position: 'absolute',
  top: 0,
  left: '50%',
  width: '100vw',
  height: '100%',
  backgroundColor: '#FFE48F',
  zIndex: -1,
  transform: 'translateX(-50%)',
});
const getInvolvedRectangle2Style = css({
  position: 'absolute',
  top: '-2%',
  left: '35%',
  width: '80vw',
  height: '80%',
  backgroundColor: '#FFE48F',
  zIndex: -2,
  transform: 'rotate(2deg)',
});
