export const transformData = (data, transformer) => Object.keys(data).reduce((acc, type) => {
  if (!acc[type]) {
    acc[type] = {};
  }

  Object.keys(data[type]).forEach(key => {
    const slice = data[type][key];
    acc[type][key] = transformer(slice);
  });
  return acc;
}, {});