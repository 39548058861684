"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InitializeAutoAtlAnalytics = void 0;
var _react = require("react");
var _analyticsNext = require("@atlaskit/analytics-next");
var _analyticsReactClient = require("../community-analytics/analytics-react-client");
var _initializer = require("./initializer");
var deepClone = function deepClone(obj) {
  return JSON.parse(JSON.stringify(obj));
};
var InitializeAutoAtlAnalyticsImpl = function InitializeAutoAtlAnalyticsImpl(_ref) {
  var _ref$pageViewProperti = _ref.pageViewProperties,
    pageViewProperties = _ref$pageViewProperti === void 0 ? {} : _ref$pageViewProperti,
    createAnalyticsEvent = _ref.createAnalyticsEvent;
  var analyticsClient = (0, _analyticsReactClient.useAnalyticsClient)();
  var ANALYTICS_WRITE_KEY = process.env['ANALYTICS_SOURCE_ID'] || '';
  (0, _react.useEffect)(function () {
    if (!analyticsClient) {
      return;
    }
    _initializer.Initializer.initWithPageAnalytics(ANALYTICS_WRITE_KEY, deepClone(pageViewProperties), Date.now(), analyticsClient.getAnonymousId(), createAnalyticsEvent || null);
  }, [analyticsClient, ANALYTICS_WRITE_KEY, createAnalyticsEvent, pageViewProperties]);
  return null;
};
var InitializeAutoAtlAnalytics = exports.InitializeAutoAtlAnalytics = (0, _analyticsNext.withAnalyticsEvents)()(InitializeAutoAtlAnalyticsImpl);