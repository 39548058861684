export const STATSIG_TARGET_APP = 'community-core_web';

// same breakpoints as upp-ui

export const MOBILE = {
  MAX_WIDTH: 767,
  MEDIA_QUERY: '@media (max-width: 767px)',
};

export const TABLET = {
  MIN_WIDTH: 768,
  MAX_WIDTH: 1279,
  MEDIA_QUERY: '@media (min-width: 768px) and (max-width: 1279px)',
};

export const DESKTOP = {
  MIN_WIDTH: 1280,
  MEDIA_QUERY: '@media (min-width: 1280px)',
};