import FeatureGates from '@atlaskit/feature-gate-js-client';

// ALL FEATURE GATES SHOULD BE CALLBACKS
// CHECK IF USER IS LOGGED IN - if not, do not check for the feature gate

/**
 * UPP-UI-FEATURE-GATE-TEST
 * @link https://switcheroo.atlassian.com/ui/gates/ab5c3a4c-7985-4532-bd93-cb0ae3160a25/key/upp-ui-feature-gate-test
 * @description This feature gate is used to test the feature gate functionality in UPP UI
 * @returns true if the user should see the feature
 */
export const uppUiFeatureGateTestIsActive = (): boolean =>
  FeatureGates.checkGate('upp-ui-feature-gate-test') ?? false;

/**
 * PRODUCT-ID-TO-NODE-ID-CHANGE
 * @link https://switcheroo.atlassian.com/ui/gates/02faeae7-dda3-4d18-9ab7-bbbbfc795ce2/key/product-id-to-node-id-change
 * @description This feature gate is used to test the feature gate functionality in UPP UI
 * @returns true if the user should see the feature
 */
export const productIdToNodeIdChangeIsActive = (): boolean => {
  if (process.env.REACT_APP_USE_MOCK_NETWORK_HANDLER === 'true') {
    return true;
  }

  return FeatureGates.checkGate('product-id-to-node-id-change') ?? false;
};

/** 
 * Champions 
 * @link https://switcheroo.atlassian.com/ui/gates/1c176618-c5ca-4dd5-849a-17e19a2d59e9/key/community-champions-page
 * 
*/
export const championsIsActive = (): boolean => FeatureGates.checkGate('community-champions-page') ?? false;

