import { JimmyImage } from 'assets/images';
import { css } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { MOBILE, TABLET } from '../../utils/constants';

const photoStyles = css`
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
`;

const sectionStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1022px;
  margin: 50px auto 30px auto;
  
  ${TABLET.MEDIA_QUERY} {
    flex-direction: column;
    text-align: center;
    padding: 30px 20px;
  }
  
  ${MOBILE.MEDIA_QUERY} {
    flex-direction: column;
    text-align: center;
    padding: 20px 15px;
  }
`;

const contentContainerStyles = css`
  flex: 1;
  max-width: 710px;
  
  ${TABLET.MEDIA_QUERY} {
    max-width: 100%;
    order: 2;
    margin-top: 30px;
  }
  
  ${MOBILE.MEDIA_QUERY} {
    max-width: 100%;
    order: 2;
    margin-top: 20px;
  }
`;

const headingStyles = css`
  color: #42526E;
  font-size: 14px;
  margin-bottom: 8px;
`;

const nameStyles = css`
  font-size: 24px;
  margin: 0 0 24px 0;
  color: ${token('color.text')};
  font-weight: 600;
  font-family: "Charlie Display";
`;

const quoteStyles = css`
  line-height: 1.5;
  margin: 0;
  color: #000;
  font-size: 24px;
  font-weight: 400;
  text-wrap: wrap;
  
  ${TABLET.MEDIA_QUERY} {
    font-size: 20px;
  }
  
  ${MOBILE.MEDIA_QUERY} {
    font-size: 18px;
  }
`;

const imageContainerStyles = css`
  
  ${TABLET.MEDIA_QUERY} {
    margin-left: 0;
    order: 1;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
  }
  
  ${MOBILE.MEDIA_QUERY} {
    margin-left: 0;
    order: 1;
    margin-bottom: 15px;
    width: 100%;
    text-align: center;
  }
`;

const imageWrapperStyles = css`
  position: relative;
  
  ${TABLET.MEDIA_QUERY} {
    display: inline-block;
  }
  
  ${MOBILE.MEDIA_QUERY} {
    display: inline-block;
    margin: 0 auto;
  }
`;

const imageStyles = css`
  width: 290px;
  height: 290px;
  position: relative;
  
  ${TABLET.MEDIA_QUERY} {
    width: 290px;
    height: 290px;
    margin: 0 auto;
  }
  
  ${MOBILE.MEDIA_QUERY} {
    width: 290px;
    height: 290px;
    margin: 0 auto;
  }
`;

const decorationStyles = css`
  position: absolute;
  top: -20px;
  left: -20px;
  width: 60px;
  height: 60px;
  
  ${TABLET.MEDIA_QUERY} {
    width: 50px;
    height: 50px;
    top: -15px;
    left: -15px;
  }
  
  ${MOBILE.MEDIA_QUERY} {
    width: 40px;
    height: 40px;
    top: -10px;
    left: -10px;
  }
`;

const heading = "COMMUNITY CHAMPION";
const name = "Jimmy Seddon";
const quote = "“I love the sense of belonging created through this community of passionate, like-minded individuals. This is a group of people that are not only extremely knowledgeable about the Atlassian products, but they are also a group of kind-hearted cheerleaders that want to raise others up and celebrate personal or professional achievements and accomplishments.”"

export const ChampionsQuote = () => {
  return (
    <section css={sectionStyles}>
      <div css={contentContainerStyles}>
        <div css={headingStyles}>
          {heading}
        </div>
        <h2 css={nameStyles}>
          {name}
        </h2>
        <p css={quoteStyles}>
          {quote}
        </p>
      </div>
      <div css={imageContainerStyles}>
        <div css={imageWrapperStyles}>
          <div css={imageStyles}>
            <img src={JimmyImage} css={photoStyles} alt="Jimmy Seddon" />
          </div>
          <div css={decorationStyles}>
            <svg viewBox="0 0 24 24" width="100%" height="100%">
              <path
                d="M12 0 L18 12 L12 24 L6 12 Z"
                fill="#172B4D"
              />
            </svg>
          </div>
        </div>
      </div>
    </section>
  );
};