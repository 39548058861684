import React, { ReactElement, ReactNode, useMemo } from 'react';
import {
  AnalyticsActivityType,
  APP_BUTTONS,
  CommunityApp,
  EventType,
  getAppButtons,
  HOME_HREF,
  PROFILE_DROPDOWN_ITEMS,
  PROFILE_URL,
  AppButton,
  SETTINGS_URL,
} from '@components/community/constants';
import {
  NavBarLogoItemWithAnalytics as NavBarLogoItem,
  NavBarLinkItemWithAnalytics as NavBarLinkItem,
  LoginButtonWithAnalytics as LoginButton,
  SignupButtonWithAnalytics as SignupButton,
  ProfileWithAnalytics as NavBarProfileItem,
  NavigationBarWithAnalytics as NavigationBar,
  MenuItemWithAnalytics,
  AnalyticsInfo,
} from '@components/community/CommunityNavigation/ComponentsWithAnalytics';
import { Link } from '@components/shared/NavigationBar/NavBarLinkItem';
import { MenuItemType } from '@components/shared/NavigationBar/NavBarProfileItem/MenuItem';
import { LoggedInUser } from '@components/shared/NavigationBar/NavBarProfileItem';
import { useMediaQuery } from '@hooks/useMediaQuery';
import { BREAKPOINTS } from '@/constants';
import { UITrackEventPayload } from '@components/community/types';
import { Inline, Stack } from '@atlaskit/primitives';

export interface CommunityNavigationProps {
  currentApp: CommunityApp;
  isLoggedIn: boolean;
  loginHref: string;
  signupHref: string;
  logoutHref: string;
  user?: LoggedInUser;
  showChampions?: boolean; // TODO: This prop is for feature gating. Clean up once fullly released.
  forumsLoggedInRedirectHref?: string;
  /** @type {SubMenuContent} (optional) map of app key to expandable content
   * - values should include the content to be displayed when the corresponding app button is clicked and expanded
   * - applies for only mobile/tablet menu
   * - if not provided, the menu items in the mobile/tablet menu will be un-expandable links that redirect user
   * directly to link */
  subMenuContentByApp?: SubMenuContent;
  /**
   * (optional) function called when an analytics event is to be sent
   */
  onAnalyticsSendEvent?: (type: EventType, payload: UITrackEventPayload, activityType: AnalyticsActivityType) => void;
  /**
   * Controls whether the navigation bar should stick to the top of the viewport when scrolling
   * @default true
   */
  isSticky?: boolean;
  /**
   * (optional) email of the current user, if user is logged in. Currently used for SSO / auth on Forums links.
   */
  email?: string;
}

export type SubMenuContent = {
  [key in CommunityApp]?: ReactNode;
};
export const CommunityLogo: React.FC<{
  subtitleHref: string;
  analyticsInfo?: AnalyticsInfo;
}> = ({ subtitleHref, analyticsInfo }) => {
  return (
    <NavBarLogoItem
      subtitleHref={subtitleHref}
      label={'Community homepage'}
      logoSubtitle="Community"
      analyticsInfo={analyticsInfo}
    />
  );
};

const renderAppButtons = (
  currentApp: CommunityApp,
  subMenuContentByApp: SubMenuContent | undefined,
  isLoggedIn: boolean,
  analyticsInfo?: AnalyticsInfo,
  forumsLoggedInRedirectHref?: string,
  email?: string,
): React.ReactElement[] => {
  const loginState: 'loggedIn' | 'loggedOut' = isLoggedIn ? 'loggedIn' : 'loggedOut';
  return getAppButtons(email).map((button) => {
    if (button.app === CommunityApp.FORUMS && isLoggedIn && forumsLoggedInRedirectHref) {
      button.href[loginState] += forumsLoggedInRedirectHref;
    }
    return (
      <NavBarLinkItem
        key={button.app}
        isSelected={button.app === currentApp}
        link={{
          text: button.label,
          href: button.href[loginState],
          target: button.target,
        }}
        expandableBodyContent={subMenuContentByApp?.[button.app]}
        destinationApp={button.app}
        analyticsInfo={analyticsInfo}
      />
    );
  });
};

const getUserDisplayMenuContent = (
  logoutHref: string,
  type: MenuItemType,
  profileHref: string,
  settingsHref: string,
  analyticsInfo?: AnalyticsInfo,
): ReactElement => {
  const subMenuItems = [
    { ...PROFILE_DROPDOWN_ITEMS.PROFILE, href: profileHref },
    { ...PROFILE_DROPDOWN_ITEMS.SETTINGS, href: settingsHref },
    { ...PROFILE_DROPDOWN_ITEMS.LOG_OUT, href: logoutHref },
  ];
  return (
    <>
      {subMenuItems.map((item, index) => (
        <MenuItemWithAnalytics link={item} type={type} key={`${item.text}-${index}`} analyticsInfo={analyticsInfo} />
      ))}
    </>
  );
};

const CommunityNavigation: React.FC<CommunityNavigationProps> = ({
  currentApp,
  isLoggedIn,
  user,
  loginHref,
  signupHref,
  logoutHref,
  subMenuContentByApp,
  onAnalyticsSendEvent,
  forumsLoggedInRedirectHref,
  isSticky = true,
  showChampions = false,
  email,
}) => {
  const loginState: 'loggedIn' | 'loggedOut' = isLoggedIn ? 'loggedIn' : 'loggedOut';
  const homeHref = useMemo(() => HOME_HREF[loginState], [loginState]);
  const profileHref = useMemo(() => PROFILE_URL[loginState], [loginState]);
  const settingsHref = useMemo(() => SETTINGS_URL[loginState], [loginState]);
  const isMobileOrTablet = useMediaQuery(`(max-width: ${BREAKPOINTS.TABLET.MAX_WIDTH}px)`);
  const analyticsInfo: AnalyticsInfo | undefined = onAnalyticsSendEvent
    ? { currentApp: currentApp, isLoggedIn, isMobileOrTablet, onAnalyticsSendEvent }
    : undefined;

  let leftItems = [
    ...renderAppButtons(
      currentApp as CommunityApp,
      subMenuContentByApp,
      isLoggedIn,
      analyticsInfo,
      forumsLoggedInRedirectHref,
      email,
    ),
  ];
  if (!showChampions) {
    leftItems = leftItems.filter((item) => item.key !== CommunityApp.CHAMPIONS);
  }
  const rightItems = [
    isLoggedIn && user ? (
      <NavBarProfileItem
        user={user}
        expandableMenuContent={getUserDisplayMenuContent(
          logoutHref,
          'subMenu',
          profileHref,
          settingsHref,
          analyticsInfo,
        )}
        profileMenuContent={getUserDisplayMenuContent(logoutHref, 'profile', profileHref, settingsHref, analyticsInfo)}
        analyticsInfo={analyticsInfo}
      />
    ) : isMobileOrTablet ? (
      <Stack space="space.100">
        <LoginButton href={loginHref} analyticsInfo={analyticsInfo} isMobile={isMobileOrTablet} />
        <SignupButton href={signupHref} analyticsInfo={analyticsInfo} isMobile={isMobileOrTablet} />
      </Stack>
    ) : (
      <Inline space="space.100">
        <LoginButton href={loginHref} analyticsInfo={analyticsInfo} />
        <SignupButton href={signupHref} analyticsInfo={analyticsInfo} />
      </Inline>
    ),
  ];

  return (
    <NavigationBar
      logoItem={<CommunityLogo subtitleHref={homeHref} key={'community-logo'} analyticsInfo={analyticsInfo} />}
      leftItems={leftItems}
      rightItems={rightItems}
      analyticsInfo={analyticsInfo}
      isSticky={isSticky}
    />
  );
};

export {
  AnalyticsActivityType,
  EventType,
  CommunityNavigation,
  CommunityApp,
  MenuItemWithAnalytics,
  NavBarLinkItem,
  APP_BUTTONS,
  getAppButtons,
};
export type { Link as LinkInfo, LoggedInUser, MenuItemType, AppButton };
