import { InspectModeContentful } from '@types';
import { inspectorProps } from '@utils/index';
import { css } from '@emotion/react';
import { token } from '@atlaskit/tokens';

export interface TagProps {
  /** entryId and fieldId from contentful for the inspector mode */
  inspectMode?: InspectModeContentful;
  /**  Text displayed on the tag */
  label: string;
  /** Function to be called when the tag is clicked */
  onClick?: () => void;
  /** Display tag as selected */
  selected?: boolean;
  /** Icon to be displayed before the text */
  iconBefore?: React.ReactNode;
  /** Icon to be displayed after the text */
  iconAfter?: React.ReactNode;
}

/**
 * Component tag to show specific info on label
 * @param {Tag} TagProps
 * @returns {React.ReactElement} React.ReactElement
 *
 * @example
 * <Tag>Some Label</Tag>
 */
export const Tag: React.FC<TagProps> = ({ inspectMode, label, onClick, selected, iconAfter, iconBefore }) => (
  <div
    {...inspectorProps('tag', inspectMode)}
    tabIndex={-1}
    css={[
      tagStyles,
      selected && selectedTagStyles,
      onClick && hoverableTagStyles,
      iconBefore && beforeIconTagStyles,
      iconAfter && afterIconTagStyles,
    ]}
    onClick={onClick}
  >
    {iconBefore && <span css={[iconStyles, beforeIconStyles]}>{iconBefore}</span>}
    {label}
    {iconAfter && <span css={[iconStyles, afterIconStyles]}>{iconAfter}</span>}
  </div>
);

export const tagListStyles = css({
  display: 'flex',
  flexWrap: 'wrap',
  gap: token('space.100'),
});

const tagStyles = css({
  display: 'flex',
  height: '24px',
  padding: `4px ${token('space.100')}`,
  border: `1px solid ${token('color.border')}`,
  borderRadius: '4px',
  alignItems: 'center',
  fontFamily: 'Charlie Text, sans-serif',
  fontSize: '12px',
  fontWeight: 600,
  lineHeight: '16px',
  textTransform: 'uppercase',
  color: token('color.text'),
  backgroundColor: token('color.background.input'),
  boxSizing: 'border-box',
  whiteSpace: 'nowrap',
});

const hoverableTagStyles = css({
  '&:hover': {
    borderColor: token('color.border.accent.gray'),
    cursor: 'pointer',
  },
});

const selectedTagStyles = css({
  borderColor: token('color.border.selected'),
});

const iconStyles = css({
  span: {
    width: '14px',
    height: '14px',
    marginBottom: '2px',
  },
});

const beforeIconStyles = css({
  marginRight: '0.125rem',
});

const afterIconStyles = css({
  marginLeft: '0.125rem',
});

const beforeIconTagStyles = css({
  paddingLeft: '0.375rem',
});

const afterIconTagStyles = css({
  paddingRight: '0.375rem',
});
