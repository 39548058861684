"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("../constants");
function getTimeZoneOffset() {
    try {
        return new Date().getTimezoneOffset() || constants_1.UNAVAILABLE;
        ;
    }
    catch (error) {
        return constants_1.ERROR;
    }
}
exports.getTimeZoneOffset = getTimeZoneOffset;
