"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var browser_1 = require("../detect/browser");
var canvas_1 = require("../detect/canvas");
var constants_1 = require("../detect/constants");
var font_1 = require("../detect/font");
var webGL_1 = require("../detect/webGL");
var __1 = require("..");
var murmurHash_1 = require("../helpers/murmurHash");
var UIDGenerator = (function () {
    function UIDGenerator() {
    }
    UIDGenerator.getDetails = function () {
        try {
            return {
                browser: {
                    browserDefaultEvalLength: browser_1.browserDefaultEvalLength(),
                    canWriteCookie: browser_1.canWriteCookie(),
                    getBrowserVendor: browser_1.getBrowserVendor(),
                    getHardwareConcurrency: browser_1.getHardwareConcurrency(),
                    getLanguage: browser_1.getLanguage(),
                    getPlatform: browser_1.getPlatform(),
                    getPlugins: browser_1.getPlugins(),
                    getProductSub: browser_1.getProductSub(),
                    getTimeZone: browser_1.getTimeZone(),
                    getTimeZoneOffset: browser_1.getTimeZoneOffset(),
                    getUserAgent: browser_1.getUserAgent(),
                    isIndexedDBAvailable: browser_1.isIndexedDBAvailable(),
                    isLocalStorageAvailable: browser_1.isLocalStorageAvailable(),
                    isSessionStorageAvailable: browser_1.isSessionStorageAvailable(),
                    isTestingBot: browser_1.isTestingBot(),
                    isWebDriver: browser_1.isWebDriver()
                },
                canvas: {
                    studyPrint: canvas_1.studyPrint()
                },
                font: {
                    jsFonts: (new font_1.DetectFonts()).detect(),
                },
                webGL: {
                    getWebGL: webGL_1.getWebGL()
                }
            };
        }
        catch (err) {
            return constants_1.ERROR;
        }
    };
    UIDGenerator.getValue = function (cachedUids) {
        try {
            var values = [
                browser_1.browserDefaultEvalLength(),
                browser_1.canWriteCookie(),
                browser_1.getBrowserVendor(),
                browser_1.getHardwareConcurrency(),
                browser_1.getLanguage(),
                browser_1.getPlatform(),
                browser_1.getPlugins(),
                browser_1.getProductSub(),
                browser_1.getTimeZone(),
                browser_1.getTimeZoneOffset(),
                browser_1.getUserAgent(),
                browser_1.isIndexedDBAvailable(),
                browser_1.isLocalStorageAvailable(),
                browser_1.isSessionStorageAvailable(),
                browser_1.isTestingBot(),
                browser_1.isWebDriver(),
                canvas_1.studyPrint(),
                (new font_1.DetectFonts()).result(),
                webGL_1.getWebGL()
            ];
            var value = murmurHash_1.x64hash128(values.join("|"), 64);
            var state = void 0;
            if (!cachedUids.length) {
                state = __1.XIDState.NEW;
            }
            else if (cachedUids[0].value === value) {
                state = __1.XIDState.EXISTING;
            }
            else {
                state = __1.XIDState.CHANGED;
            }
            return state === __1.XIDState.EXISTING ? cachedUids : tslib_1.__spreadArrays([{
                    type: __1.XIDItemType.UID,
                    createdAt: new Date().toISOString(),
                    state: state,
                    value: value,
                }], cachedUids);
        }
        catch (err) {
            return [{
                    type: __1.XIDItemType.UID,
                    state: __1.XIDState.ERROR,
                }];
        }
    };
    return UIDGenerator;
}());
exports.UIDGenerator = UIDGenerator;
