import React, { useCallback, useEffect, useRef, useMemo } from 'react';

// using 'plyr' instead of 'plyr/dist/plyr'
// will start executing DOM APIs right away
// and it breaks SSR
// @ts-ignore
import Plyr from 'plyr/dist/plyr';
import PlyrClass from 'plyr';

import { css } from '@emotion/react';
import { N800 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useDeviceSize } from '../../../hooks/useDeviceSize';

export interface VideoProps {
  /** Name of the video */
  name: string;
  /** Contentful id for the video */
  videoId: string;
  /** URL if video is hosted in BrandFolder */
  brandfolderUrl?: string;
  /** URL if video is hosted in CDN */
  orangeUrl?: string;
  /** URL if video is hosted in Vimeo */
  vimeoUrl?: string;
  /** URL is video is hosted on YouTube */
  youtubeUrl?: string;
  /** Takes in the captions for the video */
  captions: string;
  /** Sets chapters for video */
  chapters?: PlyrClass.MarkersPoints[];
  /** Sets whether the video controls are shown or not */
  controls: boolean;
  /** Sets video component width */
  width: string;
  /** Sets video component height */
  height: string;
  /** Sets the poster image for the video */
  poster?: string;
  /** onClick callback, sends the event of pause, play, captions enabled, captions disabled */
  onControlClick?: (type: 'play' | 'pause' | 'captionsenabled' | 'captionsdisabled') => void;
  /** Updates video progress in percentages */
  onProgressUpdate?: (percentage: number, name: string, videoId: string) => void;
}

const MOBILE_SCREEN_WIDTH = 480;

export const Video: React.FC<VideoProps> = ({
  brandfolderUrl,
  orangeUrl,
  vimeoUrl,
  youtubeUrl,
  captions,
  chapters,
  controls,
  width,
  height,
  videoId,
  name,
  poster = '',
  onControlClick,
  onProgressUpdate,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const deviceSize = useDeviceSize();

  const videoSrc = useMemo(
    () => orangeUrl || brandfolderUrl || vimeoUrl || youtubeUrl,
    [orangeUrl, brandfolderUrl, vimeoUrl, youtubeUrl],
  );

  const playerSource: PlyrClass.SourceInfo = {
    type: 'video',
    sources: [{ src: videoSrc! }],
    tracks: [{ kind: 'captions', label: 'English', srcLang: 'en', src: captions, default: true }],
  };

  const playerOptions: PlyrClass.Options = {
    invertTime: false,
    tooltips: { controls: true, seek: true },
    controls: [
      'play-large',
      'play',
      'progress',
      'current-time',
      'captions',
      'rewind',
      'fast-forward',
      'mute',
      'volume',
      'settings',
      'pip',
      'fullscreen',
      'seektime',
    ],
    quality: { default: 1080, options: [1080, 720, 576, 480, 360, 240] },
    markers: { enabled: true, points: chapters! },
  };

  const playerOptionsMobile: PlyrClass.Options = {
    invertTime: false,
    tooltips: { controls: true, seek: true },
    controls: [
      'play-small',
      'play',
      'progress',
      'current-time',
      'rewind',
      'fast-forward',
      'mute',
      'volume',
      'settings',
      'fullscreen',
      'seektime',
    ],
    quality: { default: 1080, options: [1080, 720, 576, 480, 360, 240] },
    markers: { enabled: true, points: chapters! },
  };

  const addEvents = useCallback(
    (instance: PlyrClass): void => {
      instance.once('loadeddata', (): void => {
        addEvent(instance, 'play');
        addEvent(instance, 'pause');
        addEvent(instance, 'captionsenabled');
        addEvent(instance, 'captionsdisabled');
      });

      instance.on('timeupdate', () => {
        const currentTime = instance.currentTime;
        const duration = instance.duration;
        const percentage = (currentTime / duration) * 100;

        onProgressUpdate && onProgressUpdate(+percentage.toFixed(2), name, videoId);
      });
    },
    [onControlClick],
  );

  const addEvent = useCallback(
    (instance: PlyrClass, event: 'play' | 'pause' | 'captionsenabled' | 'captionsdisabled') =>
      instance.on(event, () => onControlClick && onControlClick(event)),
    [],
  );

  useEffect(() => {
    const isMobileSize = deviceSize <= MOBILE_SCREEN_WIDTH;
    const player: PlyrClass = new Plyr(videoRef.current!, isMobileSize ? playerOptionsMobile : playerOptions);
    player.source = playerSource;
    player.poster = poster;
    addEvents(player);

    const plyrVideoContainers = document.getElementsByClassName('plyr--video');
    if (plyrVideoContainers.length) {
      Array.from(plyrVideoContainers).forEach((el) => (el.role = 'presentation'));
    }
  }, []);

  if (videoSrc === vimeoUrl) {
    return (
      <iframe
        role="document"
        title="video"
        tabIndex={0}
        css={css({
          backgroundColor: 'transparent',
          width: '100%',
          aspectRatio: '16 / 9',
          ':focus-visible': {
            border: `2px solid ${token('color.border.selected')}`,
          },
        })}
        src={vimeoUrl}
        allowFullScreen
        allow="autoplay"
      />
    );
  } else if (videoSrc === youtubeUrl) {
    return (
      <iframe
        role="document"
        title="video"
        tabIndex={0}
        css={css({
          backgroundColor: 'transparent',
          width: '100%',
          aspectRatio: '16 / 9',
          ':focus-visible': {
            border: `2px solid ${token('color.border.selected')}`,
          },
        })}
        src={youtubeUrl}
        allowFullScreen
        allow="autoplay"
      />
    );
  } else {
    return (
      <div css={videoPlayerStyles}>
        <video
          tabIndex={0}
          ref={videoRef}
          width={width}
          height={height}
          controls={controls}
          crossOrigin="anonymous"
          title="video"
        >
          <source src={videoSrc} type="video/mp4" />
          <track src={captions} kind="captions" label="captions" srcLang="en" default />
        </video>
      </div>
    );
  }
};

const videoPlayerStyles = css({
  '.plyr__time': {
    fontSize: '12px !important',
    fontWeight: '600 !important',
  },

  '--plyr-color-main': '#1D7AFC',
  '--plyr-badge-background': N800,
  '--plyr-audio-control-color': N800,
  '--plyr-menu-color': N800,
  '--plyr-menu-item-arrow-color': '#758195',
  '--plyr-menu-border-color': '#DCDFE4',
  '--plyr-tooltip-color': N800,

  ':focus-visible': {
    border: `2px solid ${token('color.border.selected')}`,
  },
});
