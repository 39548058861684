import { FunctionComponent, useContext, useEffect } from 'react';
import { css } from '@compiled/react';
import GetStartedCard from 'components/get-started-card/GetStartedCard';
import { LinkData } from 'components/common/analytics-wrapped/LinkData';
import { ForumsIcon, EventsIcon, LearningIcon } from 'assets/images';
import { uppUiFeatureGateTestIsActive } from 'services/statsig/featureGates';
import { uppTestExperimentIsActive } from 'services/statsig/experiments';
import { MOBILE, TABLET } from '../../../../utils/constants';
import { UserContext } from '../../../../context/UserContext';
import { getForumsSignInUrl } from '../../../../utils/url-generator';

// all process.env variables has to be declared this way to make sure atlaspack
// can inline them properly
const FORUMS_BASE_URL = process.env.FORUMS_BASE_URL;
const LEARNING_BASE_URL = process.env.LEARNING_BASE_URL;
const EVENTS_BASE_URL = process.env.EVENTS_BASE_URL;

const TITLE = 'Get started';

const GetStarted: FunctionComponent = () => {
  const data = useContext(UserContext);
  const user = data?.user;
  const isLoggedIn = !!user;
  const CARDS = [
    {
      id: 'forums',
      title: 'Forums',
      image: ForumsIcon,
      description:
        'Connect with other community members sharing knowledge and expertise.',
      linkData: {
        href: isLoggedIn
          ? getForumsSignInUrl({
              redirectUrl: FORUMS_BASE_URL,
              email: user?.email,
            })
          : FORUMS_BASE_URL,
        text: 'Find answers',
        target: '_self',
      } as LinkData,
    },
    {
      id: 'learning',
      title: 'Learning',
      image: LearningIcon,
      description:
        'Build knowledge and skills to boost your productivity and empower your team.',
      linkData: {
        href: LEARNING_BASE_URL || '',
        text: 'Start learning',
        target: '_self',
      } as LinkData,
    },
    {
      id: 'events',
      title: 'Events',
      image: EventsIcon,
      description:
        'Meet, learn, and swap product tips at a community-hosted event, in your city or online.',
      linkData: {
        href: EVENTS_BASE_URL || '',
        text: 'Find an event',
        target: '_self',
      } as LinkData,
    },
  ];

  useEffect(() => {
    // temporary statsig test - check if the feature gate and experiment are enabled
    if (uppUiFeatureGateTestIsActive()) {
      console.log(
        'upp-ui-feature-gate-test is enabled - in GetStarted component',
      );
    }
    if (uppTestExperimentIsActive()) {
      console.log('upp-test-experiment is enabled - in GetStarted component');
    }
  }, []);

  return (
    <div css={containerStyle}>
      <h1 css={headingStyle}>{TITLE}</h1>
      <div css={cardsContainerStyle}>
        {CARDS.map((card) => (
          <GetStartedCard key={card.id} {...card} />
        ))}
      </div>
    </div>
  );
};

const headingStyle = css({
  textAlign: 'center',
  fontFamily: 'Charlie Display,sans-serif',
  fontSize: '40px',
  fontWeight: 700,
  lineHeight: '48px',
  letterSpacing: '-0.12px',
  marginTop: '0px',
  '@media (max-width: 1279px)': {
    fontSize: '32px',
  },
});

const containerStyle = css({
  background: '#FFFFFF',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 'var(--Spacing-16, 16px)',
  border: '2px solid var(--neutral-new-200, #F0F1F2)',
  padding: '40px 24px',
  zIndex: '1',
  marginTop: '68px',
  [TABLET.MEDIA_QUERY]: {
    margin: '24px',
    paddingBottom: '24px',
  },
  [MOBILE.MEDIA_QUERY]: {
    margin: '16px',
    paddingBottom: '24px',
    paddingTop: '24px',
  },
});

const cardsContainerStyle = css({
  display: 'flex',
  gap: '24px',
  '& > *': {
    // ensure that individual cards maintain equal width
    flex: '1 1 0',
  },
  '@media (max-width: 767px)': {
    flexDirection: 'column',
  },
});

export default GetStarted;
