import React, { useState, useCallback, useEffect, useRef } from 'react';

import { Button, ButtonAppearance, Icon } from '@components/shared';
import { withFocusInteraction } from '@utils/index';
import { css } from '@emotion/react';
import { token } from '@atlaskit/tokens';

export interface PopupShareProps {
  /** Indicates position of the popover*/
  alignToLeft?: boolean;
  /** Shows the button to copy link to clipboard */
  showCopyLink?: boolean;
  /** Shows the button to share on Facebook */
  shareOnFb?: boolean;
  /** Shows the button to share on Linkedin */
  shareOnLinkedin?: boolean;
  /** Shows the button to share on PDF */
  shareOnPdf?: boolean;
  /** Function to be called when PDF button is clicked */
  onGeneratePdf?: () => void;
  /** Popup background color */
  backgroundColor?: string;
  /** Url to share on social networks*/
  urlToShare: string;
}

/**
 * Component that renders a popup to share url on social networks
 * @param {PopupShareProps} PopupShareProps
 * @returns {React.ReactElement} React.ReactElement
 *
 * @example
 * <PopupShare url="https://success.atlassian.com/" />
 */
export const PopupShare: React.FC<PopupShareProps> = ({
  showCopyLink = true,
  shareOnFb = true,
  shareOnLinkedin = true,
  shareOnPdf = false,
  onGeneratePdf,
  backgroundColor = '#FFFFFF',
  urlToShare,
  alignToLeft = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleButtonClick = (event: React.MouseEvent): void => {
    event.stopPropagation();
    event.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleOnGeneratePDF = (event: React.MouseEvent): void => {
    if (onGeneratePdf) {
      event.stopPropagation();
      event.preventDefault();
      onGeneratePdf();
      setIsOpen(!isOpen);
    }
  };

  const handleShare = (url: string): void => {
    window.open(url, '_blank');
  };

  const shareOnFacebook = (): void => {
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(urlToShare)}`;
    handleShare(facebookUrl);
  };

  const shareOnLinkedIn = (): void => {
    const linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(urlToShare)}`;
    handleShare(linkedinUrl);
  };

  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(urlToShare).then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    });
  }, [urlToShare]);

  const onKeyDownLinkedIn = useCallback(withFocusInteraction(shareOnLinkedIn), []);
  const onKeyDownFacebook = useCallback(withFocusInteraction(shareOnFacebook), []);
  const onKeyDownGeneratePdf = useCallback(
    withFocusInteraction(() => {
      if (onGeneratePdf) {
        onGeneratePdf();
      }
    }),
    [onGeneratePdf],
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div css={css({ width: 'fit-content', position: 'relative' })} data-testid="popup-share" ref={dropdownRef}>
      <Button
        label="Share"
        appearance={ButtonAppearance.ICON}
        onClick={handleButtonClick}
        selected={isOpen}
        iconBefore={<Icon type="share" />}
        iconType="share"
      />
      {isOpen && (
        <div
          data-testid="popup-share-dropdown"
          css={[
            popupShareDropdownStyles,
            [alignToLeft && css({ width: '176px', right: 0 }), shareOnPdf && css({ width: '220px' })],
          ]}
          style={{ backgroundColor }}
        >
          {showCopyLink && (
            <button onClick={copyToClipboard} css={copyToClipboardOptionStyles} aria-label="copy to clipboard">
              <Icon type="link" size="small" />
              <span
                css={css({
                  marginLeft: '0.5rem',
                  fontSize: '0.875rem',
                  lineHeight: '1.25rem',
                  fontFamily: 'Charlie Text, sans-serif',
                  color: token('color.text'),
                })}
              >
                Copy Link
              </span>
              {isCopied && <span css={isCopiedStyles}>Copied!</span>}
            </button>
          )}
          {shareOnLinkedin && (
            <div tabIndex={0} css={shareOnLinkedInOptionStyles} onClick={shareOnLinkedIn} onKeyDown={onKeyDownLinkedIn}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1"
                viewBox="0 0 512 512"
                style={{
                  fillRule: 'evenodd',
                  clipRule: 'evenodd',
                  strokeLinejoin: 'round',
                  strokeMiterlimit: '2',
                  width: '1rem',
                  height: '1rem',
                }}
                xmlSpace="preserve"
              >
                <g id="g5891">
                  <path
                    d="M512,64c0,-35.323 -28.677,-64 -64,-64l-384,0c-35.323,0 -64,28.677 -64,64l0,384c0,35.323 28.677,64 64,64l384,0c35.323,0 64,-28.677 64,-64l0,-384Z"
                    id="background"
                    style={{ fill: '#2867b2' }}
                  />
                  <g id="shapes">
                    <rect height="257.962" id="rect11" style={{ fill: '#fff' }} width="85.76" x="61.053" y="178.667" />
                    <path
                      d="M104.512,54.28c-29.341,0 -48.512,19.29 -48.512,44.573c0,24.752 18.588,44.574 47.377,44.574l0.554,0c29.903,0 48.516,-19.822 48.516,-44.574c-0.555,-25.283 -18.611,-44.573 -47.935,-44.573Z"
                      id="path13-0"
                      style={{ fill: '#fff', fillRule: 'nonzero' }}
                    />
                    <path
                      d="M357.278,172.601c-45.49,0 -65.866,25.017 -77.276,42.589l0,-36.523l-85.738,0c1.137,24.197 0,257.961 0,257.961l85.737,0l0,-144.064c0,-7.711 0.554,-15.42 2.827,-20.931c6.188,-15.4 20.305,-31.352 43.993,-31.352c31.012,0 43.436,23.664 43.436,58.327l0,138.02l85.741,0l0,-147.93c0,-79.237 -42.305,-116.097 -98.72,-116.097Z"
                      id="path15"
                      style={{ fill: '#fff', fillRule: 'nonzero' }}
                    />
                  </g>
                </g>
              </svg>{' '}
              <span css={css({ marginLeft: '0.5rem', fontSize: '0.875rem', lineHeight: '1.25rem' })}>
                Share on Linkedin
              </span>
            </div>
          )}
          {shareOnFb && (
            <div tabIndex={0} css={shareOnFacebookOptionStyles} onClick={shareOnFacebook} onKeyDown={onKeyDownFacebook}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1"
                viewBox="0 0 512 512"
                style={{
                  fillRule: 'evenodd',
                  clipRule: 'evenodd',
                  strokeLinejoin: 'round',
                  strokeMiterlimit: '2',
                  width: '1rem',
                  height: '1rem',
                }}
                xmlSpace="preserve"
              >
                <g>
                  <path
                    d="M512,256c0,-141.385 -114.615,-256 -256,-256c-141.385,0 -256,114.615 -256,256c0,127.777 93.616,233.685 216,252.89l0,-178.89l-65,0l0,-74l65,0l0,-56.4c0,-64.16 38.219,-99.6 96.695,-99.6c28.009,0 57.305,5 57.305,5l0,63l-32.281,0c-31.801,0 -41.719,19.733 -41.719,39.978l0,48.022l71,0l-11.35,74l-59.65,0l0,178.89c122.385,-19.205 216,-125.113 216,-252.89Z"
                    fill="#1877f2"
                    fillRule="nonzero"
                  />
                  <path
                    d="M355.65,330l11.35,-74l-71,0l0,-48.022c0,-20.245 9.917,-39.978 41.719,-39.978l32.281,0l0,-63c0,0 -29.297,-5 -57.305,-5c-58.476,0 -96.695,35.44 -96.695,99.6l0,56.4l-65,0l0,74l65,0l0,178.89c13.033,2.045 26.392,3.11 40,3.11c13.608,0 26.966,-1.065 40,-3.11l0,-178.89l59.65,0Z"
                    fill="#fff"
                    fillRule="nonzero"
                  />
                </g>
              </svg>
              <span css={css({ marginLeft: '0.5rem', fontSize: '0.875rem', lineHeight: '1.25rem' })}>
                Share on Facebook
              </span>
            </div>
          )}
          {shareOnPdf && onGeneratePdf && (
            <div
              tabIndex={0}
              css={shareOnPdfOptionStyles}
              onClick={handleOnGeneratePDF}
              onKeyDown={onKeyDownGeneratePdf}
            >
              <span css={shareOnPdfTextStyles}>Export to PDF</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const popupShareDropdownStyles = css({
  position: 'absolute',
  marginTop: '0.5rem',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '0.25rem',
  zIndex: 2,
  color: token('color.text'),
  boxShadow: token('elevation.shadow.overlay'),
});

const copyToClipboardOptionStyles = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
  paddingLeft: '0.75rem',
  paddingRight: '1rem',
  border: 'none',
  transition: 'background',
  transitionDuration: '300ms',
  transitionTimingFunction: 'cubic-bezier(0, 0, 0.2, 1)',
  cursor: 'pointer',
  backgroundColor: 'transparent',

  ':hover': {
    backgroundColor: token('color.background.neutral.subtle.hovered'),
  },
});

const isCopiedStyles = css({
  position: 'absolute',
  backgroundColor: token('color.background.accent.gray.subtle.pressed'),
  color: token('color.text'),
  fontSize: '0.688rem',
  lineHeight: '0.875rem',
  padding: '0.25rem',
  borderRadius: '0.25rem',
  marginTop: '0.25rem',
  right: '1rem',
});

const shareOnLinkedInOptionStyles = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
  paddingLeft: '0.75rem',
  paddingRight: '1rem',
  border: 'none',
  transition: 'background',
  transitionDuration: '300ms',
  transitionTimingFunction: 'cubic-bezier(0, 0, 0.2, 1)',
  cursor: 'pointer',
  backgroundColor: 'transparent',

  ':hover': {
    backgroundColor: token('color.background.neutral.subtle.hovered'),
  },
});

const shareOnFacebookOptionStyles = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
  paddingLeft: '0.75rem',
  paddingRight: '1rem',
  border: 'none',
  transition: 'background',
  transitionDuration: '300ms',
  transitionTimingFunction: 'cubic-bezier(0, 0, 0.2, 1)',
  cursor: 'pointer',
  backgroundColor: 'transparent',

  ':hover': {
    backgroundColor: token('color.background.neutral.subtle.hovered'),
  },
});

const shareOnPdfOptionStyles = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: `${token('space.200')} ${token('space.200')} ${token('space.200')} 24px`,
  gap: '8px',
  width: '220px',
  border: 'none',
  transition: 'background',
  transitionDuration: '300ms',
  transitionTimingFunction: 'cubic-bezier(0, 0, 0.2, 1)',
  cursor: 'pointer',
  backgroundColor: 'transparent',
  boxSizing: 'border-box',

  ':hover': {
    backgroundColor: token('color.background.neutral.subtle.hovered'),
  },
});

const shareOnPdfTextStyles = css({
  fontSize: '16px',
  lineHeight: '20px',
  fontFamily: 'Inter, sans-serif',
  fontWeight: '400',
});
