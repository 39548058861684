import _extends from "@babel/runtime/helpers/extends";
import { parsePath } from 'history';
import { createActionsHook, createContainer, createHook, createStore, createSubscriber, defaultRegistry, batch } from 'react-sweet-state';
import { DEFAULT_ACTION, DEFAULT_HISTORY, DEFAULT_MATCH, DEFAULT_ROUTE } from '../../common/constants';
import { findRouterContext, isServerEnvironment, generatePath as generatePathUsingPathParams, generateLocationFromPath, warmupMatchRouteCache } from '../../common/utils';
import { getRelativePath, isExternalAbsolutePath, updateQueryParams, getRelativeURLFromLocation, shouldReload } from './utils';
export const INITIAL_STATE = {
  action: DEFAULT_ACTION,
  basePath: '',
  location: DEFAULT_HISTORY.location,
  history: DEFAULT_HISTORY,
  match: DEFAULT_MATCH,
  onPrefetch: undefined,
  query: {},
  route: DEFAULT_ROUTE,
  routes: [],
  unlisten: null,
  plugins: []
};
const actions = {
  /**
   * Bootstraps the store with initial data.
   *
   */
  bootstrapStore: props => _ref => {
    let {
      setState,
      dispatch
    } = _ref;
    const {
      basePath = '',
      history,
      initialRoute,
      onPrefetch,
      routes,
      plugins
    } = props;
    const routerContext = findRouterContext(initialRoute ? [initialRoute] : routes, {
      location: history.location,
      basePath
    });
    setState(_extends({}, routerContext, {
      basePath,
      history,
      onPrefetch,
      routes,
      location: history.location,
      action: history.action,
      plugins
    }));

    if (!isServerEnvironment()) {
      dispatch(actions.listen());
    }
  },

  /**
   * Starts listening to browser history and sets the unlisten function in state.
   * Will request route resources on route change.
   *
   */
  listen: () => _ref2 => {
    let {
      getState,
      setState
    } = _ref2;
    const {
      history,
      unlisten
    } = getState();
    if (unlisten) unlisten();
    const stopListening = history.listen(function () {
      for (var _len = arguments.length, update = new Array(_len), _key = 0; _key < _len; _key++) {
        update[_key] = arguments[_key];
      }

      const location = update.length === 2 ? update[0] : update[0].location;
      const action = update.length === 2 ? update[1] : update[0].action;
      const {
        plugins,
        routes,
        basePath,
        match: currentMatch,
        route: currentRoute,
        query: currentQuery
      } = getState();
      const nextContext = findRouterContext(routes, {
        location,
        basePath
      });
      const prevContext = {
        route: currentRoute,
        match: currentMatch,
        query: currentQuery
      };
      const shouldReloadByPlugin = new Map(plugins.map(plugin => [plugin.id, shouldReload({
        context: nextContext,
        prevContext,
        pluginId: plugin.id
      })]));
      /* Explicitly batch update
       * as we need resources cleaned + route changed + resource fetch started together
       * If we do not batch, React might be re-render when route changes but resource
       * fetching has not started yet, making the app render with data null */

      batch(() => {
        plugins.forEach(p => {
          if (shouldReloadByPlugin.get(p.id)) {
            p.beforeRouteLoad == null ? void 0 : p.beforeRouteLoad({
              context: prevContext,
              nextContext
            });
          }
        });
        setState(_extends({}, nextContext, {
          location,
          action
        }));
        plugins.forEach(p => {
          if (shouldReloadByPlugin.get(p.id)) {
            p.routeLoad == null ? void 0 : p.routeLoad({
              context: nextContext,
              prevContext
            });
          }
        });
      });
    });
    setState({
      unlisten: stopListening
    });
  },
  push: path => _ref3 => {
    let {
      getState
    } = _ref3;
    const {
      history,
      basePath
    } = getState();

    if (isExternalAbsolutePath(path)) {
      window.location.assign(path);
    } else {
      history.push(getRelativePath(path, basePath));
    }
  },
  pushTo: function pushTo(route, attributes) {
    if (attributes === void 0) {
      attributes = {};
    }

    return _ref4 => {
      let {
        getState
      } = _ref4;
      const {
        history,
        basePath
      } = getState();
      const location = generateLocationFromPath(route.path, _extends({}, attributes, {
        basePath
      }));
      warmupMatchRouteCache(route, location.pathname, attributes.query, basePath);
      history.push(location);
    };
  },
  replace: path => _ref5 => {
    let {
      getState
    } = _ref5;
    const {
      history,
      basePath
    } = getState();

    if (isExternalAbsolutePath(path)) {
      window.location.replace(path);
    } else {
      history.replace(getRelativePath(path, basePath));
    }
  },
  replaceTo: function replaceTo(route, attributes) {
    if (attributes === void 0) {
      attributes = {};
    }

    return _ref6 => {
      let {
        getState
      } = _ref6;
      const {
        history,
        basePath
      } = getState();
      const location = generateLocationFromPath(route.path, _extends({}, attributes, {
        basePath
      }));
      warmupMatchRouteCache(route, location.pathname, attributes.query, basePath);
      history.replace(location);
    };
  },
  goBack: () => _ref7 => {
    let {
      getState
    } = _ref7;
    const {
      history
    } = getState(); // history@4 uses goBack(), history@5 uses back()

    if ('goBack' in history) {
      history.goBack();
    } else if ('back' in history) {
      history.back();
    } else {
      throw new Error('History does not support goBack');
    }
  },
  goForward: () => _ref8 => {
    let {
      getState
    } = _ref8;
    const {
      history
    } = getState(); // history@4 uses goForward(), history@5 uses forward()

    if ('goForward' in history) {
      history.goForward();
    } else if ('forward' in history) {
      history.forward();
    } else {
      throw new Error('History does not support goForward');
    }
  },
  registerBlock: blocker => _ref9 => {
    let {
      getState
    } = _ref9;
    const {
      history
    } = getState();
    return history.block(blocker);
  },
  getContext: () => _ref10 => {
    let {
      getState
    } = _ref10;
    const {
      query,
      route,
      match
    } = getState();
    return {
      query,
      route,
      match
    };
  },
  getBasePath: () => _ref11 => {
    let {
      getState
    } = _ref11;
    const {
      basePath
    } = getState();
    return basePath;
  },
  updateQueryParam: function updateQueryParam(params, updateType) {
    if (updateType === void 0) {
      updateType = 'push';
    }

    return _ref12 => {
      let {
        getState
      } = _ref12;
      const {
        query: existingQueryParams,
        history,
        location
      } = getState();

      const updatedQueryParams = _extends({}, existingQueryParams, params); // remove undefined keys


      Object.keys(updatedQueryParams).forEach(key => updatedQueryParams[key] === undefined && delete updatedQueryParams[key]);
      const existingPath = updateQueryParams(location, existingQueryParams);
      const updatedPath = updateQueryParams(location, updatedQueryParams);

      if (updatedPath !== existingPath) {
        history[updateType](updatedPath);
      }
    };
  },
  updatePathParam: function updatePathParam(params, updateType) {
    if (updateType === void 0) {
      updateType = 'push';
    }

    return _ref13 => {
      let {
        getState
      } = _ref13;
      const {
        history,
        location,
        route: {
          path
        },
        match: {
          params: existingPathParams
        },
        basePath
      } = getState();
      const pathWithBasePath = basePath + path;

      const updatedPathParams = _extends({}, existingPathParams, params);

      const updatedPath = generatePathUsingPathParams(pathWithBasePath, updatedPathParams);

      const updatedLocation = _extends({}, location, {
        pathname: updatedPath
      });

      const existingRelativePath = getRelativeURLFromLocation(location);
      const updatedRelativePath = getRelativeURLFromLocation(updatedLocation);

      if (updatedRelativePath !== existingRelativePath) {
        history[updateType](updatedRelativePath);
      }
    };
  },
  loadPlugins: () => _ref14 => {
    let {
      getState
    } = _ref14;
    const {
      plugins,
      match,
      query,
      route
    } = getState();
    plugins.forEach(p => p.routeLoad == null ? void 0 : p.routeLoad({
      context: {
        match,
        query,
        route
      }
    }));
  },
  prefetchRoute: (path, nextContext) => _ref15 => {
    let {
      getState
    } = _ref15;
    const {
      plugins,
      routes,
      basePath,
      onPrefetch
    } = getState();
    const {
      route,
      match,
      query
    } = getRouterState();

    if (!nextContext && !isExternalAbsolutePath(path)) {
      const location = parsePath(getRelativePath(path, basePath));
      nextContext = findRouterContext(routes, {
        location,
        basePath
      });
    }

    if (nextContext == null) return;
    const nextLocationContext = nextContext;
    batch(() => {
      plugins.forEach(p => p.routePrefetch == null ? void 0 : p.routePrefetch({
        context: {
          route,
          match,
          query
        },
        nextContext: nextLocationContext
      }));
      if (onPrefetch) onPrefetch(nextLocationContext);
    });
  }
};
export const RouterStore = createStore({
  initialState: INITIAL_STATE,
  actions,
  name: 'router'
});
export const RouterContainer = createContainer(RouterStore, {
  displayName: 'RouterContainer',
  onInit: () => (_ref16, props) => {
    let {
      dispatch
    } = _ref16;
    dispatch(actions.bootstrapStore(props));
    !isServerEnvironment() && dispatch(actions.loadPlugins());
  },
  onCleanup: () => () => {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.warn(`Warning: react-resource-router has been unmounted! Was this intentional? Resources will be refetched when the router is mounted again.`);
    }
  }
});
export const RouteResourceEnabledSubscriber = createSubscriber(RouterStore, {
  selector: state => Boolean(state.route && state.route.resources)
});
export const useRouterStore = createHook(RouterStore);
export const useRouterStoreActions = createActionsHook(RouterStore);
/**
 * Utility to create custom hooks without re-rendering on route change
 */

export function createRouterSelector(selector) {
  const useHook = createHook(RouterStore, {
    selector
  });
  return function useRouterSelector() {
    return useHook(...arguments)[0];
  };
}
export const getRouterStore = () => // @ts-ignore calling `getStore` without providing a scopeId
defaultRegistry.getStore(RouterStore); // @ts-ignore accessing private store property

export const getRouterState = () => getRouterStore().storeState.getState();