import React, { useCallback, useEffect, useRef, useState } from 'react';
import { withFocusInteraction } from '@utils/index';
import { css } from '@emotion/react';
import { token } from '@atlaskit/tokens';

export interface CheckboxProps {
  /**  A string representing the label text of the checkbox */
  label?: React.ReactNode;
  /** A boolean value representing whether the checkbox is checked or not */
  isChecked?: boolean;
  /** An optional function that will be called when the state of the checkbox changes */
  onChange?: () => void;
  /** Optional classnames for the checkbox input */
  checkboxStyle?: string;
  /** Optional classnames for the label of the input */
  labelStyle?: string;
  /** Optional type of checkbox */
  type?: CheckboxType;
  /** type to control whether checkbox is large */
  size?: CheckboxSize.DEFAULT | CheckboxSize.LARGE;
  /** Boolean to control whether checkbox is indeterminate */
  isIndeterminate?: boolean;
  /** Boolean to control whether radio is required */
  isRequired?: boolean;
  /** Boolean to control the checkbox label fontWeight*/
  isBold?: boolean;
  /** TabIndex to allow correctly tabbing through the checkboxes for accessibility*/
  tabIndex?: number;
}

export enum CheckboxType {
  DEFAULT = 'DEFAULT',
  /** TODO: remove success styles in scope of the QUIZ component update */
  SUCCESS = 'SUCCESS',
  DANGER = 'DANGER',
  DISABLED = 'DISABLED',
}

export enum CheckboxSize {
  DEFAULT = 'DEFAULT',
  LARGE = 'LARGE',
}

/**
 * Renders a checkbox with some text
 * @param {Checkbox} CheckboxProps
 * @returns {React.ReactElement} React.ReactElement
 *
 * @example
 * <Checkbox label="Checkbox label" />
 */
export const Checkbox: React.FC<CheckboxProps> = ({
  label,
  isChecked,
  onChange,
  type = CheckboxType.DEFAULT,
  size = CheckboxSize.DEFAULT,
  isIndeterminate = false,
  isRequired = false,
  isBold,
  tabIndex = 0,
}) => {
  const [userHasInteracted, setUserHasInteracted] = useState(false);
  const checkboxRef = useRef<HTMLInputElement>(null);

  const handleChange = useCallback(() => {
    setUserHasInteracted(true);
    if (onChange) {
      onChange();
    }
  }, [onChange]);

  const handleKeyDown = useCallback(withFocusInteraction(handleChange), [isChecked]);

  useEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = isIndeterminate && !userHasInteracted;
    }
  }, [isIndeterminate, userHasInteracted]);

  return (
    <label
      onKeyDown={handleKeyDown}
      css={[
        labelStyles,
        size === CheckboxSize.LARGE && labelLargeStyles,
        type === CheckboxType.DISABLED && variants.label.DISABLED,
        isBold && css({ fontWeight: 700 }),
        !isBold && css({ fontWeight: 400 }),
      ]}
    >
      <input
        ref={checkboxRef}
        tabIndex={-1}
        type="checkbox"
        role="checkbox"
        checked={isChecked}
        onChange={handleChange}
        css={inputCheckboxStyles}
      />
      {label}
      <span
        tabIndex={tabIndex}
        onKeyDown={handleKeyDown}
        css={[
          variants.checkbox[type],
          checkmarkStyles,
          size === CheckboxSize.LARGE && checkmarkLargeStyles,
          isIndeterminate && !userHasInteracted && indeterminateCheckmarkStyles,
          isChecked && checkedCheckmarkStyles,
          isChecked && type === CheckboxType.DANGER && css({ backgroundColor: token('color.border.danger') }),
        ]}
      />
      {isRequired && (
        <span css={[requiredMarkStyles, size === CheckboxSize.LARGE && css({ marginLeft: '2px' })]}>*</span>
      )}
    </label>
  );
};

const requiredMarkStyles = css({
  color: token('color.text.subtle'),
  marginLeft: '6px',
});

const labelStyles = css({
  display: 'flex',
  position: 'relative',
  paddingLeft: '22px',
  marginBottom: 0,
  userSelect: 'none',
  color: token('color.text'),
  fontFamily: 'Inter, sans-serif',
  fontSize: '16px',
  lineHeight: '22px',

  '&:hover': {
    cursor: 'pointer',
  },
});

const labelLargeStyles = css({
  paddingLeft: '28px',
  fontSize: '20px',
  lineHeight: '32px',
});

const inputCheckboxStyles = css({
  position: 'absolute',
  opacity: 0,
  cursor: 'pointer',
  height: 0,
  width: 0,
});

const checkmarkStyles = css({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '16px',
  height: '16px',
  marginTop: '3px',
  backgroundColor: token('color.background.input'),
  border: `1px solid ${token('color.border.input')}`,
  borderRadius: '2px',
  cursor: 'pointer',

  '&:after': {
    content: '""',
    position: 'absolute',
    display: 'none',
  },
});

const checkedCheckmarkStyles = css({
  backgroundColor: token('color.background.selected.bold'),
  borderColor: token('color.border.selected'),

  '&:after': {
    display: 'block',
    left: '4px',
    top: '1px',
    width: '4px',
    height: '7px',
    border: `solid ${token('color.text.inverse')}`,
    borderWidth: '0 2px 2px 0',
    transform: 'rotate(45deg)',
  },
});

const checkmarkLargeStyles = css({
  width: '20px',
  height: '20px',
  marginRight: '8px',
  marginTop: '6px',

  '&:after': {
    left: '5px !important',
    top: '1px',
    width: '6px !important',
    height: '10px !important',
    border: `solid ${token('color.text.inverse')}`,
    borderWidth: '0 2px 2px 0',
    transform: 'rotate(45deg)',
  },
});

const indeterminateCheckmarkStyles = css({
  backgroundColor: token('color.background.selected.bold'),
  borderColor: token('color.border.selected'),

  '&:after': {
    display: 'block',
    left: '2.5px',
    top: '-1px',
    width: '9px',
    height: '7px',
    borderBottom: `2px solid ${token('color.text.inverse')}`,
  },
});

const variants = {
  label: {
    DISABLED: css({ pointerEvents: 'none', opacity: '31%' }),
  },
  checkbox: {
    DEFAULT: css({
      '&:hover': {
        borderColor: token('color.border.selected'),
      },
    }),
    SUCCESS: css({
      '&, &:hover': {
        borderColor: token('color.border.selected'),
      },
    }),
    DANGER: css({
      '&, &:hover': {
        borderColor: token('color.border.danger'),
      },
    }),
    DISABLED: css({ pointerEvents: 'none', opacity: '31%' }),
  },
};
