"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("../constants");
function getTimeZone() {
    try {
        if (window.Intl && window.Intl.DateTimeFormat) {
            return (new window.Intl.DateTimeFormat()).resolvedOptions().timeZone;
        }
        return constants_1.UNAVAILABLE;
    }
    catch (error) {
        return constants_1.ERROR;
    }
}
exports.getTimeZone = getTimeZone;
