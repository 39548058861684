import { css } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { MOBILE, TABLET } from '../../utils/constants';

type HeroHeadingsProps = {
  headerText: string;
  subheaderText: string;
};

const HeroHeadings: React.FC<HeroHeadingsProps> = ({
  headerText,
  subheaderText,
}) => (
  <>
    <h1 css={headingStyle}>{headerText}</h1>
    <h2 css={subheadingStyle}>{subheaderText}</h2>
  </>
);

const headingStyle = css({
  fontSize: '56px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '64px',
  letterSpacing: '-0.12px',
  color: token('color.text'),
  fontFeatureSettings: `'liga' off, 'clig' off`,
  fontFamily: 'Charlie Display, sans-serif',
  textAlign: 'center',
  zIndex: 1,
  marginTop: '0', // css-reset adds additional margin to the first element; in SSR, it's this one - reset margin
  '@media (max-width: 1279px)': {
    fontSize: '48px',
    lineHeight: '52px',
    letterSpacing: '-0.144px',
  },
  '@media (max-width: 767px)': {
    padding: '0 50px',
  },
});

const subheadingStyle = css({
  textAlign: 'center',
  marginTop: '16px',
  zIndex: 1,
  fontFamily: 'Inter, sans-serif',
  fontStyle: 'normal',
  fontSize: '20px',
  lineHeight: '32px',
  color: token('color.text'),
  '@media (max-width: 1279px)': {
    marginTop: '8px',
  },
  [MOBILE.MEDIA_QUERY]: {
    width: '100%',
    padding: '0 50px',
    fontSize: '16px',
    lineHeight: '24px',
  },
  [TABLET.MEDIA_QUERY]: {
    width: '70%', 
  },
});

export default HeroHeadings;
