import { css } from '@emotion/react';
import { media } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

export interface ListProps {
  /** indicates the type of the list */
  type?: 'ordered' | 'unordered';
  /** indicates the type of the list */
  textStyleType?: 'regular' | 'italic' | 'bold' | 'boldItalic';
  /** Item to be displayed */
  children: React.ReactNode;
}

/**
 * Displays paragraph type elements in a structured and stylized way
 * @param {List} ListProps
 * @returns {React.ReactElement} React.ReactElement
 *
 * @example
 *  <List>
 *    <li>item-1</li>
 *      ...
 *  </List>
 */
export const List: React.FC<ListProps> = ({ type = 'unordered', textStyleType = 'regular', children }) => {
  if (type === 'ordered') {
    return (
      <ol
        aria-label="ordered"
        css={[listStyles, css(variantsListStyles.list[type]), variantsListStyles.textStyle[textStyleType]]}
      >
        {children}
      </ol>
    );
  }
  return (
    <ul
      aria-label="unordered"
      css={[listStyles, css(variantsListStyles.list[type]), variantsListStyles.textStyle[textStyleType]]}
    >
      {children}
    </ul>
  );
};

const fontSizeStyles = {
  fontSize: '1.25rem',
  lineHeight: '2rem',

  [media.below.md]: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
};

const listStyles = css({
  fontFamily: 'Inter, sans-serif',
  letterSpacing: '0.3%',
  color: token('color.text'),
  ...fontSizeStyles,

  span: fontSizeStyles,
});

export const variantsListStyles = {
  list: {
    unordered: {
      listStyleType: 'disc',
      paddingLeft: '1.25rem',

      ul: {
        listStyleType: 'circle',
        paddingLeft: '1.25rem',
      },

      'ul ul': {
        listStyleType: 'square',
        paddingLeft: '1.25rem',
      },

      'ul:not(:first-child), ul li, li + li': {
        marginTop: 0,
      },
    },
    ordered: {
      listStyleType: 'decimal',
      paddingLeft: '1.25rem',

      ol: {
        listStyleType: 'lower-alpha',
        paddingLeft: '1.25rem',
      },

      'ol ol': {
        listStyleType: 'lower-roman',
        paddingLeft: '1.25rem',
      },

      'ol:not(:first-child), ol li, li + li': {
        marginTop: 0,
      },
    },
  },
  textStyle: {
    regular: css({
      fontWeight: 400,
    }),
    italic: css({
      fontStyle: 'italic',
    }),
    bold: css({
      fontWeight: 700,
    }),
    boldItalic: css({
      fontWeight: 700,
      fontStyle: 'italic',
    }),
  },
};
