import { Icon, Text } from '@components/shared';
import { css } from '@emotion/react';
import { media } from '@atlaskit/primitives/responsive';
import { token } from '@atlaskit/tokens';

export interface SidebarProps {
  /** It indicates if the sidebar is visible in the page or not */
  isVisible?: boolean;
  /** Function to call when toggle menu visibility*/
  onChangeMenuVisibility?: (menuVisible: boolean) => void;
  /**Takes in content as children */
  children: React.ReactNode;
}

/**
 * Component that renders a sidebar menu to execute navigation functions on Learning app pages
 * @param {SidebarNavProps} SidebarProps
 * @returns {React.ReactElement} React.ReactElement
 *
 * @example
 * <SidebarNav sidebarTitle="title" />
 */

export const Sidebar: React.FC<SidebarProps> = ({ onChangeMenuVisibility, isVisible = true, children }) => {
  const toggleMenu = (): void => {
    if (onChangeMenuVisibility) {
      onChangeMenuVisibility(!isVisible);
    }
  };

  return (
    <div css={sidebarNavContainerStyles}>
      <button
        data-testid="button-toggle-menu-toggle"
        css={[
          buttonToggleMenuStyles,
          !isVisible && buttonToggleMenuClosedStyles,
          isVisible && buttonToggleMenuOpenStyles,
        ]}
        onClick={toggleMenu}
        aria-label="toggle menu"
      >
        {isVisible ? (
          <Icon type="chevron-left" color={token('color.icon')} />
        ) : (
          <Icon type="chevron-right" color={token('color.icon')} />
        )}
      </button>
      <div css={mobileButtonToggleMenuContainerStyles}>
        <button
          data-testid="button-toggle-menu-show"
          css={mobileButtonToggleMenuStyles}
          onClick={toggleMenu}
          aria-label="show menu"
        >
          {isVisible ? (
            <Icon type="dropdown-cross" color={token('color.icon')} />
          ) : (
            <Icon type="menu-icon" color={token('color.icon')} />
          )}
          {isVisible ? (
            <div css={css({ span: { color: token('color.icon') } })}>
              <Text children="Close" />
            </div>
          ) : (
            <div css={css({ span: { color: token('color.icon') } })}>
              <Text children="Menu" />
            </div>
          )}
        </button>
      </div>
      <div
        css={[isVisible && sidebarOpenStyles, !isVisible && sidebarClosedStyles, sidebarStyles]}
        data-testid="sidebar"
      >
        {isVisible && children}
      </div>
    </div>
  );
};

const sidebarNavContainerStyles = css({
  position: 'absolute',
  height: '100%',
  opacity: 1,
  display: 'block',
  transform: 'translateX(0px)',

  [media.below.sm]: {
    width: '100%',
    position: 'absolute',
  },
});

const sidebarStyles = css({
  transition: 'all',
  transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
  transitionDuration: '300ms',
  height: '100%',
  paddingTop: '1.75rem',
  paddingRight: '7px',
  paddingLeft: '0.5rem',
  top: 0,
  bottom: 0,
  left: 0,
  borderRightWidth: '1px',
  borderStyle: 'solid',
  borderColor: token('color.border'),
  borderTop: 'none',
  borderLeft: 'none',
  backgroundColor: token('elevation.surface.sunken'),
  overflowY: 'auto',
  overflowX: 'hidden',
});

const sidebarClosedStyles = css({
  width: '1.25rem',

  [media.below.sm]: {
    top: 0,
    borderRightWidth: 0,
    paddingTop: 0,
    height: 0,
    width: '100%',
    position: 'absolute',
  },
});

const sidebarOpenStyles = css({
  display: 'block',
  transform: 'translateX(0px)',
  paddingTop: '1.75rem',
  position: 'fixed',

  [media.below.sm]: {
    width: '100vw',
    marginTop: '3rem',
    position: 'absolute',
  },
  [media.above.sm]: {
    width: '26.25rem',
    position: 'absolute',
  },
  [media.below.md]: {
    boxShadow: `0 0 0 10000px ${token('color.blanket')}`,
  },
  [media.above.lg]: {
    position: 'relative',
    boxShadow: 'none',
  },
});

const mobileButtonToggleMenuContainerStyles = css({
  display: 'none',
  alignItems: 'center',
  justifyContent: 'align-start',
  width: '100vw',
  paddingLeft: '1rem',
  height: '3rem',
  borderBottom: `1px solid ${token('color.border')}`,
  borderLeft: 'none',
  borderRight: 'none',
  backgroundColor: token('color.background.input'),
  position: 'relative',
  zIndex: 200,

  [media.below.sm]: {
    display: 'flex',
  },
});

const mobileButtonToggleMenuStyles = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 0,
  border: 'none',
  background: 'transparent',
  outline: 'none',
});

const buttonToggleMenuStyles = css({
  transition: 'all',
  transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
  transitionDuration: '300ms',
  border: 'none',
  borderRadius: '9999px',
  backgroundColor: token('color.background.input'),
  width: '1.5rem',
  height: '1.5rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: token('elevation.shadow.overlay'),
  position: 'absolute',
  top: '1.75rem',
  zIndex: 200,
  cursor: 'pointer',

  [media.below.sm]: {
    display: 'none',
  },
});

const buttonToggleMenuOpenStyles = css({
  marginLeft: '25.5rem',
});

const buttonToggleMenuClosedStyles = css({
  marginLeft: '0.5rem',
});
