import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';

import { css } from '@emotion/react';
import { media } from '@atlaskit/primitives/responsive';
import { ImageProps } from '@components/shared/Image';
import { token } from '@atlaskit/tokens';
import React, { useCallback } from 'react';

export interface ProgressBadgeProps {
  /** Numerical percentage of progress*/
  progress: number;
  /** Color of the progress bar*/
  pathColor: string;
  /** Title of the badge*/
  title: string;
  /** Color of the background in the progress bar and inner circle*/
  trailColor?: string;
  /** Image component rendered in the component */
  image?: Omit<ImageProps, 'height' | 'width'>;
  /** Function to call when navigating to the course page */
  onCourseNavigation?: () => void;
}

/**
 * Component that renders a card used in different pages
 * @param {ProgressBadgeProps} ProgressBadgeProps
 * @returns {React.ReactElement} React.ReactElement
 *
 * @example
 * <ProgressBadge progress={70} pathColor={Y50} title={'Configuring Jira Service Management for ITSM Projects'} />
 */
export const ProgressBadge: React.FC<ProgressBadgeProps> = ({
  progress,
  pathColor,
  trailColor = token('color.border'),
  image,
  title,
  onCourseNavigation,
}) => {
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLParagraphElement>): void => {
      if (event.code === 'Space') {
        event.preventDefault();
        onCourseNavigation && onCourseNavigation();
      }
    },
    [onCourseNavigation],
  );

  return (
    <div css={progressBadgeContainerStyles}>
      <div css={css({ maxWidth: '64px', [media.above.sm]: { maxWidth: '85px' } })} data-testid="progress-circle">
        <CircularProgressbarWithChildren
          strokeWidth={4}
          value={progress}
          styles={buildStyles({
            pathColor,
            trailColor,
          })}
        >
          <div css={imageBadgeContainerStyles}>
            {image ? (
              <img css={imageStyles} data-testid="badge-image" src={image.src} alt={image.alt} />
            ) : (
              <div css={[css({ backgroundColor: trailColor }), innerCircleBackgroundStyles]} />
            )}
          </div>
        </CircularProgressbarWithChildren>
      </div>
      <div>
        <p tabIndex={0} onKeyDown={handleKeyDown} css={titleStyles} onClick={onCourseNavigation}>
          {title}
        </p>
      </div>
    </div>
  );
};

const progressBadgeContainerStyles = css({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
});

const imageBadgeContainerStyles = css({
  width: '100%',
  height: '100%',
  padding: '8px',
});

const imageStyles = css({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  borderRadius: '50%',
});

const innerCircleBackgroundStyles = css({
  borderRadius: '9999px',
  width: '100%',
  height: '100%',
});

const titleStyles = css({
  color: token('color.text'),
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '28px',
  fontFamily: 'Charlie Display, sans-serif',
  paddingRight: '16px',
  margin: 0,

  '&:hover': {
    cursor: 'pointer',
    color: token('color.text.selected'),
  },
});
