"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var browserDefaultEvalLength_1 = require("./browserDefaultEvalLength");
exports.browserDefaultEvalLength = browserDefaultEvalLength_1.browserDefaultEvalLength;
var canWriteCookie_1 = require("./canWriteCookie");
exports.canWriteCookie = canWriteCookie_1.canWriteCookie;
var getBrowserVendor_1 = require("./getBrowserVendor");
exports.getBrowserVendor = getBrowserVendor_1.getBrowserVendor;
var getHardwareConcurrency_1 = require("./getHardwareConcurrency");
exports.getHardwareConcurrency = getHardwareConcurrency_1.getHardwareConcurrency;
var getLanguage_1 = require("./getLanguage");
exports.getLanguage = getLanguage_1.getLanguage;
var getPlatform_1 = require("./getPlatform");
exports.getPlatform = getPlatform_1.getPlatform;
var getPlugins_1 = require("./getPlugins");
exports.getPlugins = getPlugins_1.getPlugins;
var getProductSub_1 = require("./getProductSub");
exports.getProductSub = getProductSub_1.getProductSub;
var getTimeZone_1 = require("./getTimeZone");
exports.getTimeZone = getTimeZone_1.getTimeZone;
var getTimeZoneOffset_1 = require("./getTimeZoneOffset");
exports.getTimeZoneOffset = getTimeZoneOffset_1.getTimeZoneOffset;
var getUserAgent_1 = require("./getUserAgent");
exports.getUserAgent = getUserAgent_1.getUserAgent;
var isIndexedDBAvailable_1 = require("./isIndexedDBAvailable");
exports.isIndexedDBAvailable = isIndexedDBAvailable_1.isIndexedDBAvailable;
var isLocalStorageAvailable_1 = require("./isLocalStorageAvailable");
exports.isLocalStorageAvailable = isLocalStorageAvailable_1.isLocalStorageAvailable;
var isSessionStorageAvailable_1 = require("./isSessionStorageAvailable");
exports.isSessionStorageAvailable = isSessionStorageAvailable_1.isSessionStorageAvailable;
var isTestingBot_1 = require("./isTestingBot");
exports.isTestingBot = isTestingBot_1.isTestingBot;
var isWebDriver_1 = require("./isWebDriver");
exports.isWebDriver = isWebDriver_1.isWebDriver;
