"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fontsToCheck = [
    "Adobe Garamond",
    "Adobe Hebrew",
    "Agency FB",
    "Airstream",
    "Andale Mono",
    "Aparajita",
    "Arial",
    "BN Manson Nights",
    "Baskerville",
    "BlackChancery",
    "Book Antiqua",
    "Bookman Old Style",
    "CG Times",
    "Calibri",
    "Cambria",
    "Cambria Math",
    "Century",
    "Century Gothic",
    "Century Schoolbook",
    "Chalkboard",
    "Comic Sans",
    "Comic Sans MS",
    "Consolas",
    "Cooper Black",
    "Corbel",
    "Courier",
    "Courier New",
    "Curlz MT",
    "DS Sans Serif",
    "Degrassi",
    "Didot",
    "Ebrima",
    "GOST Common",
    "Geneva",
    "Georgia",
    "Gulim",
    "Heiti TC",
    "Helvetica",
    "Helvetica Neue",
    "Hoefler Text",
    "Impact",
    "Kalinga",
    "Letter Gothic",
    "Lithos Pro",
    "Lucida Bright",
    "Lucida Calligraphy",
    "Lucida Console",
    "Lucida Fax",
    "Lucida Handwriting",
    "Lucida Sans",
    "MS Gothic",
    "MS Outlook",
    "MS PGothic",
    "MYRIAD",
    "Malgun Gothic",
    "Mangal",
    "MapInfo Miscellaneous",
    "Meiryo UI",
    "Monaco",
    "Monotype Corsiva",
    "NSimSun",
    "OSAKA",
    "Palatino",
    "Pickwick",
    "Playbill",
    "Pristina",
    "Segoe Print",
    "Segoe Script",
    "Segoe UI",
    "Shonar Bangla",
    "Snell Roundhand",
    "Styllo",
    "Tahoma",
    "Times",
    "Times New Roman",
    "Trebuchet MS",
    "Verdana",
    "Wingdings"
];
