import { FunctionComponent } from 'react';
import { css } from '@compiled/react';
import BenefitsCard from './BenefitsCard';
import { Discounts, InsideScoop, Networking, NewSkills, Swag } from 'assets/images';
import {DESKTOP,MOBILE, TABLET} from '../../utils/constants';

const CARDS = [
  {
    id: 'inside-scoop',
    title: 'Inside scoop',
    image: InsideScoop,
    description:
      'Be the first to know about the latest product updates and announcements from Atlassian.',
  },
  {
    id: 'skills-knowledge',
    title: 'New skills and knowledge',
    image: NewSkills,
    description:
      'Deep dive into interesting topics through presentations led by other users, Atlassian experts, and even Atlassians.',
  },
  {
    id: 'swag',
    title: 'All the swag',
    image: Swag,
    description:
      'Enjoy free food, drink, and swag from Atlassian and local Solution Partners.',
  },
  {
    id: 'networking',
    title: 'Networking opportunities',
    image: Networking,
    description:
      "Meet other Atlassian enthusiasts in your city and learn each other's best practices.",
  },
  {
    id: 'discounts',
    title: 'Exclusive discounts',
    image: Discounts,
    description:
      'Save on certification exams and get early access discounts to Atlassian events and conferences.',
  },
];
const BenefitsCards: FunctionComponent = () => {
  return (
    <div css={containerStyles}>
      {CARDS.map((card) => (
        <BenefitsCard {...card} key={card.id} />
      ))}
    </div>
  );
};
const containerStyles = css({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center', 
   gap: '32px', 
 [DESKTOP.MEDIA_QUERY]: {
    '& > *': {
      flex: '1 1 calc(33.333% - 48px)', 
      maxWidth: 'calc(33.333% - 48px)',
    },
  },
  [TABLET.MEDIA_QUERY]: {
     gap: '16px 32px',
    '& > *': {
      flex: '1 1 calc(50% - 48px)', 
      maxWidth: 'calc(50% - 48px)',
    },
  },
   [MOBILE.MEDIA_QUERY]: {
    gap: '16px', 
    maxWidth: '290px', 
    margin: '0 auto', 
    '& > *': {
      flex: '1 1 100%', 
      maxWidth: '100%',
    },
  },
});
export default BenefitsCards;