import React from 'react';
import { css } from '@emotion/react';
import { media } from '@atlaskit/primitives';

import { Icon } from '@components/shared';
import { token } from '@atlaskit/tokens';

export interface SectionMessageProps {
  /** Descriptive markdown text of the panel */
  children: React.ReactNode;
  /** Appearance changes the color, and icon of the panel */
  appearance?: SectionMessageAppearance;
  /** Should the panel be full width */
  fullWidth?: boolean;
}

export enum SectionMessageAppearance {
  INFORMATION = 'information',
  INFORMATION_WHITE = 'information_white',
}

/**
 * Component that renders a customizable info panel based on @atlaskit/section-message
 * @param {SectionMessageProps} SectionMessageProps
 * @returns {React.ReactElement} React.ReactElement
 *
 * @example
 * <SectionMessage children="text" />
 */

export const SectionMessage: React.FC<SectionMessageProps> = ({ children, appearance, fullWidth = false }) => {
  const isInfoWhite = appearance === SectionMessageAppearance.INFORMATION_WHITE;

  return (
    <div
      css={[
        sectionMessageContainerStyles,
        isInfoWhite && informationWhiteStyles,
        !isInfoWhite && informationStyles,
        !fullWidth && css({ maxWidth: '720px' }),
      ]}
      data-testid={`section-message${isInfoWhite ? '-white' : ''}`}
    >
      <div css={iconContainerStyles}>
        <Icon type="info" alt="info icon" color={token('color.icon.accent.blue')} />
      </div>
      <div css={descriptionStyles}>{children}</div>
    </div>
  );
};

const sectionMessageContainerStyles = css({
  display: 'flex',
  width: '100%',
  borderRadius: '3px',
  padding: token('space.300'),
});

const informationStyles = css({
  backgroundColor: token('color.background.information'),
});

const informationWhiteStyles = css({
  border: `1px solid ${token('color.border')}`,
  backgroundColor: token('elevation.surface'),
});

const iconContainerStyles = css({
  marginTop: token('space.050'),
  marginRight: token('space.300'),

  [media.below.md]: {
    marginRight: token('space.200'),
  },
});

const descriptionStyles = css({
  color: token('color.text'),
  fontFamily: 'Inter, sans-serif',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '32px',
  marginTop: 0,
  marginBottom: 0,

  p: {
    marginTop: 0,
    marginBottom: 0,
  },

  a: {
    fontSize: '20px',
    lineHeight: '32px',
  },

  [media.below.md]: {
    fontSize: '16px',
    lineHeight: '24px',
  },
});
