import FeatureGates from '@atlaskit/feature-gate-js-client';

// ALL EXPERIMENTS SHOULD BE CALLBACKS
// CHECK IF USER IS LOGGED IN - if not, do not check for the experiment

/**
 * UPP-TEST-EXPERIMENT
 * @link https://switcheroo.atlassian.com/ui/gates/5b2b6947-655d-4e90-947c-d7679e081d40/key/upp_test_experiment
 * @description This experiment is testing the statsig implementation
 * @returns true if the user should see the new experience
 */
export const uppTestExperimentIsActive = (): boolean =>
  FeatureGates.getExperimentValue<boolean>(
    'upp_test_experiment',
    'new_experience',
    false,
  );
