"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var cookie_1 = require("../helpers/cookie");
var types_1 = require("../api/types");
var cache_1 = require("../helpers/cache");
var domain_1 = require("../helpers/domain");
var uuid_1 = require("../helpers/uuid");
var UID_REFRESH_PERIOD = 1e3 * 60 * 60 * 24 * 7;
var XC_REFRESH_PERIOD = 1e3 * 60 * 60 * 24 * 30;
exports.XC_CURRENT = 'atl_xid.xc';
var XID_REFRESHED_AT = 'atl_xid.ts';
var XID_CURRENT = 'atl_xid.current';
var UIDS_ALL = 'atl_xid.uid';
var UIDS_STORE_LIMIT = 20;
var Storage = (function () {
    function Storage() {
    }
    Storage.getCachedXID = function () {
        try {
            var currentXID = cache_1.getXidItemFromCache(XID_CURRENT);
            var refreshedAt = cache_1.getCache(XID_REFRESHED_AT);
            var isFresh = false;
            if (currentXID.length > 0 && currentXID[0].type === types_1.XIDItemType.UID) {
                isFresh = parseInt(refreshedAt || '', 10) >= (new Date().getTime() - UID_REFRESH_PERIOD);
            }
            else if (currentXID.length > 0 && currentXID[0].type === types_1.XIDItemType.XC) {
                isFresh = parseInt(refreshedAt || '', 10) >= (new Date().getTime() - XC_REFRESH_PERIOD);
            }
            if (isFresh) {
                for (var _i = 0, currentXID_1 = currentXID; _i < currentXID_1.length; _i++) {
                    var entry = currentXID_1[_i];
                    entry.state = types_1.XIDState.EXISTING;
                }
                return currentXID;
            }
            else {
                return [];
            }
        }
        catch (err) {
            return [];
        }
    };
    Storage.saveXid = function (xid) {
        try {
            cookie_1.setCookie(XID_REFRESHED_AT, new Date().getTime());
            cache_1.setLocalstorage(XID_REFRESHED_AT, new Date().getTime().toString());
            var xidCache = [];
            for (var _i = 0, xid_1 = xid; _i < xid_1.length; _i++) {
                var entry = xid_1[_i];
                if (entry.value && entry.createdAt) {
                    xidCache.push({
                        type: entry.type,
                        value: entry.value,
                        createdAt: entry.createdAt,
                    });
                    break;
                }
            }
            cookie_1.setCookie(XID_CURRENT, JSON.stringify(xidCache));
            cache_1.setLocalstorage(XID_CURRENT, JSON.stringify(xidCache));
        }
        catch (err) {
            console.error('Error setting xid cookie.');
        }
    };
    Storage.updateXc = function (env, xc) {
        try {
            if (env === types_1.Environment.PROD && domain_1.isAtlassianComDomain()) {
                return Storage.processAtlassianComDomainXc(xc);
            }
            else {
                Storage.saveXcLocalstorage(xc);
            }
        }
        catch (err) {
            console.error('Error setting xc cookie.');
        }
        return xc;
    };
    Storage.getUids = function () {
        var uid = cache_1.getXidItemFromCache(UIDS_ALL) || [];
        for (var _i = 0, uid_1 = uid; _i < uid_1.length; _i++) {
            var entry = uid_1[_i];
            entry.state = types_1.XIDState.EXISTING;
        }
        return uid;
    };
    Storage.saveUids = function (uid) {
        try {
            var uidCache = [];
            for (var _i = 0, uid_2 = uid; _i < uid_2.length; _i++) {
                var entry = uid_2[_i];
                if (entry.value && entry.createdAt) {
                    uidCache.push({
                        type: entry.type,
                        value: entry.value,
                        createdAt: entry.createdAt,
                    });
                }
            }
            uidCache.slice(0, UIDS_STORE_LIMIT);
            cache_1.setLocalstorage(UIDS_ALL, JSON.stringify(uidCache));
        }
        catch (err) {
            console.error('Error setting uid cookie.');
        }
    };
    Storage.saveXcLocalstorage = function (xc) {
        if (xc.value && xc.createdAt) {
            var currentXcCache = cache_1.getXidFromLocalStorage(exports.XC_CURRENT);
            if (currentXcCache && currentXcCache.value === xc.value) {
                return;
            }
            else if (currentXcCache && currentXcCache.value !== xc.value) {
                xc.state = types_1.XIDState.CHANGED;
            }
            var xidCache = {
                type: xc.type,
                value: xc.value,
                createdAt: xc.createdAt,
            };
            cache_1.setLocalstorage(exports.XC_CURRENT, JSON.stringify(xidCache));
        }
    };
    Storage.processAtlassianComDomainXc = function (xc) {
        if (xc.value && xc.createdAt) {
            var currentXcCache = cache_1.getXidFromCookie(exports.XC_CURRENT);
            if (currentXcCache) {
                cookie_1.setCookie(exports.XC_CURRENT, JSON.stringify(currentXcCache), cookie_1.getCrossDomainCookieOption());
                var currentXc = tslib_1.__assign({ state: types_1.XIDState.EXISTING }, currentXcCache);
                Storage.saveXcLocalstorage(currentXc);
                return currentXc;
            }
            else {
                var newXcCache = {
                    value: uuid_1.uuidv4(),
                    createdAt: new Date().toISOString(),
                    type: types_1.XIDItemType.XC,
                };
                cookie_1.setCookie(exports.XC_CURRENT, JSON.stringify(newXcCache), cookie_1.getCrossDomainCookieOption());
                var newXc = tslib_1.__assign({ state: types_1.XIDState.NEW }, newXcCache);
                Storage.saveXcLocalstorage(newXc);
                return newXc;
            }
        }
        else {
            return xc;
        }
    };
    return Storage;
}());
exports.Storage = Storage;
