"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.shouldUseStargate = exports.getXidEnvironment = exports.getUppApiPathFromEnvironment = exports.getGASv3Environment = exports.getEnvironment = void 0;
var _analyticsWebClient = require("@atlassiansox/analytics-web-client");
var _atlCrossDomainTracking = require("@atlassian/atl-cross-domain-tracking");
var _constants = require("../../constants");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var ENV_TO_ANALYTICS_ENV_TYPE = _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, _constants.ENVIRONMENT.LOCAL, _analyticsWebClient.envType.LOCAL), _constants.ENVIRONMENT.DEV, _analyticsWebClient.envType.DEV), _constants.ENVIRONMENT.STAGING, _analyticsWebClient.envType.STAGING), _constants.ENVIRONMENT.PROD, _analyticsWebClient.envType.PROD);
var ENV_TO_XID_ENV = _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, _constants.ENVIRONMENT.LOCAL, _atlCrossDomainTracking.Environment.LOCAL), _constants.ENVIRONMENT.DEV, _atlCrossDomainTracking.Environment.DEV), _constants.ENVIRONMENT.STAGING, _atlCrossDomainTracking.Environment.STAGE), _constants.ENVIRONMENT.PROD, _atlCrossDomainTracking.Environment.PROD);
var getEnvironment = exports.getEnvironment = function getEnvironment(env) {
  var _process$env$CLIENT_L, _ref;
  if (env) {
    return env;
  }
  var processEnv = (_process$env$CLIENT_L = process.env['CLIENT_LIBRARY_ENV']) !== null && _process$env$CLIENT_L !== void 0 ? _process$env$CLIENT_L : process.env['NODE_ENV'];
  return (_ref = processEnv) !== null && _ref !== void 0 ? _ref : _constants.ENVIRONMENT.LOCAL;
};
var getGASv3Environment = exports.getGASv3Environment = function getGASv3Environment(environment) {
  return ENV_TO_ANALYTICS_ENV_TYPE[environment !== null && environment !== void 0 ? environment : getEnvironment()];
};
var getXidEnvironment = exports.getXidEnvironment = function getXidEnvironment(environment) {
  return ENV_TO_XID_ENV[environment !== null && environment !== void 0 ? environment : getEnvironment()];
};
var shouldUseStargate = exports.shouldUseStargate = function shouldUseStargate(environment) {
  switch (environment !== null && environment !== void 0 ? environment : getEnvironment()) {
    case _constants.ENVIRONMENT.PROD:
    case _constants.ENVIRONMENT.STAGING:
      return true;
    case _constants.ENVIRONMENT.DEV:
    default:
      return false;
  }
};
var getUppApiPathFromEnvironment = exports.getUppApiPathFromEnvironment = function getUppApiPathFromEnvironment(env) {
  var environment = getEnvironment(env);
  switch (environment) {
    case _constants.ENVIRONMENT.DEV:
      return '/gateway/api/graphql?serviceOverride=upp_service_api=https://upp-service-api.ap-southeast-2.dev.atl-paas.net/api/graphql';
    default:
      return '/gateway/api/graphql';
  }
};