"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parse = function (url) {
    var a = document.createElement('a');
    a.href = url;
    return {
        href: a.href,
        host: a.host || location.host,
        port: ('0' === a.port || '' === a.port) ? exports.port(a.protocol) : a.port,
        hash: a.hash,
        hostname: a.hostname || location.hostname,
        pathname: a.pathname.charAt(0) !== '/' ? '/' + a.pathname : a.pathname,
        protocol: !a.protocol || ':' === a.protocol ? location.protocol : a.protocol,
        search: a.search,
        query: a.search.slice(1)
    };
};
exports.isAbsolute = function (url) {
    return 0 === url.indexOf('//') || !!~url.indexOf('://');
};
exports.isRelative = function (url) {
    return !exports.isAbsolute(url);
};
exports.isCrossDomain = function (url) {
    var finalUrl = exports.parse(url);
    var location = exports.parse(window.location.href);
    return finalUrl.hostname !== location.hostname
        || finalUrl.port !== location.port
        || finalUrl.protocol !== location.protocol;
};
exports.port = function (protocol) {
    switch (protocol) {
        case 'http:':
            return 80;
        case 'https:':
            return 443;
        default:
            return location.port;
    }
};
exports.getParam = function (name) {
    var b = window.location.href.match('[?&]' + name + '=([^&#]+)');
    return b ? b.pop() : '';
};
