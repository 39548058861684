type AuthQueryParamValues = {
  email?: string;
  redirectUrl?: string;
  prompt?: string;
  experience?: string;
};

const buildAuthQueryParams = ({
  email,
  redirectUrl,
  prompt = 'none',
  experience = 'entry-auth',
}: AuthQueryParamValues): string[] => {
  const queryParams = [];
  if (email) {
    queryParams.push(`login_hint=${email}`);
  }
  if (experience) {
    queryParams.push(`application=community--${experience}`);
  }
  if (redirectUrl) {
    queryParams.push(`referer=${redirectUrl}`);
  }
  if (prompt) {
    queryParams.push(`prompt=${prompt}`);
  }
  return queryParams;
};

export const getForumsSignInUrl = (queryParamValues: AuthQueryParamValues): string => {
  const queryParams = buildAuthQueryParams(queryParamValues);
  return `/forums/s/plugins/common/feature/oauth2sso_v2/sso_login_redirect?${queryParams.join('&')}`;
};
