import { css } from '@compiled/react';
import { TABLET, MOBILE } from '../../utils/constants';

export const YouTubePlayer = () => (
  <iframe
  css={iframeStyle}
    width="640"
    height="360"
    src="https://www.youtube.com/embed/K8aVSOkXJeM"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  ></iframe>
);

const iframeStyle = css({
  width: '858px',    
  height: '455px',   
  [TABLET.MEDIA_QUERY]: {
    width: '662px',  
    height: '351px',
  },
  [MOBILE.MEDIA_QUERY]: {
    width: '343px', 
    height: '189px', 
  },
});