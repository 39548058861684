import { token } from '@atlaskit/tokens';
import { css } from '@compiled/react';
import InfoIcon from '@atlaskit/icon/glyph/info';
import {
  List1Icon1,
  List1Icon3,
  List1Icon4,
  List1Icon2,
  List1Icon5,
  List2Icon1,
  List2Icon2,
  List2Icon3,
  List2Icon4,
  List2Icon5,
  ArrowImage,
} from '../../assets/images';
import { MOBILE, TABLET } from '../../utils/constants';

export const ChampionsAdventure = () => {
  return (
    <section css={adventureSectionStyles}>
      <div css={decorationContainerStyles}>
        <div css={backgroundRectangleStyles}></div>
        <div css={backgroundRectangle2Styles}></div>
        <div css={backgroundRectangle3Styles}></div>
      </div>

      <div css={containerStyles}>
        <h2 css={headingStyles}>{heading}</h2>
        <p css={subheadingStyles}>{subheading}</p>

        <div css={arrowsContainerStyles}>
          <img src={ArrowImage} alt="Left arrow" css={leftArrowStyles} />
          <img src={ArrowImage} alt="Right arrow" css={rightArrowStyles} />
        </div>

        <div css={columnsContainerStyles}>
          <div css={columnStyles}>
            <h3 css={columnHeadingStyles}>{list1Heading}</h3>
            <ul css={listStyles}>
              {list1Items.map((item, index) => (
                <li key={index} css={listItemStyles}>
                  <img src={item.iconSrc} alt="" css={iconStyles} />
                  <span css={textStyles}>{item.text}</span>
                </li>
              ))}
            </ul>
          </div>

          <div css={columnStyles}>
            <div css={columnHeadingContainerStyles}>
              <h3 css={columnHeadingStyles}>{list2Heading}</h3>
              {list2Heading === 'Success Champion' && (
                <span css={betaBadgeStyles}>
                  <InfoIcon label="Beta" />{' '}
                  <span css={betaTextStyles}>Beta</span>
                </span>
              )}
            </div>
            <ul css={listStyles}>
              {list2Items.map((item, index) => (
                <li key={index} css={listItemStyles}>
                  <img src={item.iconSrc} alt="" css={iconStyles} />
                  <span css={textStyles}>{item.text}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

const heading = 'Choose your own adventure';
const subheading =
  'How will you lead? Get involved with the larger Atlassian Community, or start with your own team.';

const list1Heading = 'Community Champion';
const list2Heading = 'Success Champion';

const list1Items = [
  {
    iconSrc: List1Icon1,
    text: 'Host local Atlassian Community Events from virtual meetups to local user-led conferences.',
  },
  {
    iconSrc: List1Icon2,
    text: 'Share your knowledge by answering questions online, authoring content, moderating online groups, or leading new discussions.',
  },
  {
    iconSrc: List1Icon3,
    text: 'Build your reputation as an expert by presenting at Atlassian Community Events.',
  },
  {
    iconSrc: List1Icon4,
    text: 'Create original thought leadership or learning content on social media as a creator.',
  },
  {
    iconSrc: List1Icon5,
    text: 'Help develop new certification exams as a Certification SME (Subject Matter Expert).',
  },
];

const list2Items = [
  {
    iconSrc: List2Icon1,
    text: 'Help improve ways of working and productivity in your organization.',
  },
  {
    iconSrc: List2Icon2,
    text: 'Host Atlassian-focused company user group meetings, or knowledge-sharing events.',
  },
  {
    iconSrc: List2Icon3,
    text: 'Help create Atlassian training content for your users.',
  },
  {
    iconSrc: List2Icon4,
    text: 'Support admins and users to get their first Atlassian Certification, or work towards one of our specialist designations.',
  },
  {
    iconSrc: List2Icon5,
    text: "Use your Atlassian expertise to contribute to your organization's center of excellence.",
  },
];

const adventureSectionStyles = css`
  background-color: ${token('color.background.accent.blue.bolder')};
  padding: 64px 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 80px;
  margin-bottom: 60px;
  overflow: visible;
`;

const decorationContainerStyles = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

// Primary rectangle that covers the full width of the component
const backgroundRectangleStyles = css`
  position: absolute;
  top: 0;
  left: 50%;
  width: 100vw;
  height: 100%;
  background-color: ${token('color.background.accent.blue.bolder')};
  z-index: -1;
  transform: translateX(-50%);
`;

// Top tilted rectangle that creates the top triangle effect
const backgroundRectangle2Styles = css`
  position: absolute;
  top: -2%;
  left: 35%;
  width: 80vw;
  height: 80%;
  background-color: ${token('color.background.accent.blue.bolder')};
  z-index: -2;
  transform: rotate(1deg);
`;

// Bottom tilted rectangle that creates the bottom triangle effect
const backgroundRectangle3Styles = css`
  position: absolute;
  top: 22%;
  left: -20%;
  width: 80vw;
  height: 80%;
  background-color: ${token('color.background.accent.blue.bolder')};
  z-index: -3;
  transform: rotate(1deg);
`;

const containerStyles = css`
  background-color: white;
  border-radius: 12px;
  padding: 3rem 2rem;
  max-width: 1200px;
  width: 100%;
`;

const headingStyles = css`
  font-family: "Charlie Display";
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  margin: 0 0 1rem 0;
  color: ${token('color.text')};
`;

const subheadingStyles = css`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  margin: 0 auto 2rem auto;
  max-width: 800px;
`;

const arrowsContainerStyles = css`
  display: flex;
  justify-content: center;
  margin: 2rem 0 8px;
  gap: 8rem;

  ${TABLET.MEDIA_QUERY} {
    display: none;
  }

  ${MOBILE.MEDIA_QUERY} {
    display: none;
  }
`;

const leftArrowStyles = css`
  width: 40px;
  height: auto;
`;

const rightArrowStyles = css`
  width: 40px;
  height: auto;
  transform: scaleX(-1);
`;

const betaBadgeStyles = css`
  display: flex;
  align-items: center;
  color: ${token('color.icon.discovery')};
  gap: 4px;
`;

const columnsContainerStyles = css`
  display: flex;
  flex-direction: row;
  gap: 2rem;

  ${TABLET.MEDIA_QUERY} {
    flex-direction: column;
  }

  ${MOBILE.MEDIA_QUERY} {
    flex-direction: column;
  }
`;

const columnStyles = css`
  flex: 1;
  padding: 1rem;
`;

const columnHeadingStyles = css`
  font-family: 'Charlie Display';
  color: ${token('color.text')};
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  margin: 0 0 1.5rem 0;
`;

const columnHeadingContainerStyles = css`
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 0.5rem;
`;

const listStyles = css`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const listItemStyles = css`
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.5rem;
  gap: 1rem;
`;

const iconStyles = css`
  width: 48px;
  height: 48px;
  flex-shrink: 0;
`;

const textStyles = css`
  flex: 1 0 0;
  color: #000;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const betaTextStyles = css`
  color: ${token('color.text')};
`;
