import _extends from "@babel/runtime/helpers/extends";
import matchPath from './matchPath';
import matchQuery from './matchQuery';
/* This should match what react-router does to compute a root match. */

const computeRootMatch = pathname => ({
  path: '/',
  url: '/',
  params: {},
  isExact: pathname === '/',
  query: {}
});

function execRouteMatching(route, pathname, queryObj, basePath) {
  const pathMatch = route.path ? matchPath(pathname, {
    path: route.path,
    exact: route.exact,
    basePath
  }) : computeRootMatch(pathname);
  let match = pathMatch;

  if (pathMatch && route.query) {
    match = matchQuery(route.query, queryObj, pathMatch);
  } else if (pathMatch) {
    match = _extends({}, pathMatch, {
      query: {}
    });
  }

  if (match) {
    return {
      match,
      route
    };
  }

  return null;
}

export default execRouteMatching;