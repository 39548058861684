import '@atlaskit/css-reset/dist/bundle.css';
import './assets/css/global.css';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { App } from 'components/App';
import {
  FeatureGatesBootstrapValues,
  initializeFeatureGatesClient,
} from './utils/initializeFeatureGates';
import { UserProvider } from './context/UserContext';
import { User } from 'typings/types';
import { getAtlassianUser } from './services/http/user';
import { createBrowserHistory } from 'history';
import { routes } from './routes';
import { Router } from 'react-resource-router';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ConsentValidator } from '@atlassian/community-library';
import WelcomeIllustration from './assets/images/welcome-image.png';
import { InitValidator } from './components/InitValidator/InitValidator';
import { getAuthUrl } from './utils/url-generator';

declare global {
  interface Window {
    // IS_STATIC indicates that the page is being rendered statically
    // IS_STATIC = true means that the page is NOT SSR; not generated on the server with each request
    // but rather during the build process
    IS_STATIC: boolean;
  }
}

const isStatic = typeof window !== 'undefined' && window.IS_STATIC;
const ONETRUST_SCRIPT_DOMAIN = process.env.REACT_APP_ONETRUST_SCRIPT_DOMAIN;
const ONETRUST_GUID = process.env.REACT_APP_ONETRUST_GUID;
const IS_NON_PROD = process.env.REACT_APP_ENVI !== 'production';

const typedGlobalThis = globalThis as typeof globalThis & {
  __FEATURE_GATES_VALUES__: FeatureGatesBootstrapValues;
  __CURRENT_USER__: User;
  __IS_CONSENTED__: boolean;
  __ACCOUNTS_INITIALIZED__: boolean;
};
const currentUserId = typedGlobalThis.__CURRENT_USER__?.account_id ?? undefined;
const emailId = typedGlobalThis.__CURRENT_USER__?.email ?? undefined;

const onCancelConsent = () => {
  if (typeof window !== 'undefined') {
    window.location.replace(
      getAuthUrl('logout', {
        redirectUrl: encodeURIComponent(window.location.href),
        email: emailId,
        experience: 'entry-auth',
      }),
    );
  }
};

const Root = (
  <Router
    routes={routes}
    history={createBrowserHistory()}
    onPrefetch={({ route }) => console.log('Prefetching route', route.name)}
  >
    <UserProvider
      userInfo={typedGlobalThis.__CURRENT_USER__}
      userAccountsInitialized={typedGlobalThis.__ACCOUNTS_INITIALIZED__}
    >
      <ConsentValidator
        onLogout={onCancelConsent}
        onConsentError={() => {}} //TODO: implement hasConsentError
        aaid={currentUserId}
        isUserConsented={typedGlobalThis.__IS_CONSENTED__}
        coverImageSrc={WelcomeIllustration}
      >
        <InitValidator>
          <HelmetProvider>
            <Helmet>
              <script
                src={`https://${ONETRUST_SCRIPT_DOMAIN}/assets/atl-onetrust-wrapper.min.js`}
                type="text/javascript"
                data-domain={ONETRUST_GUID}
                data-test={IS_NON_PROD}
                data-geofence="false"
                data-sync="true"
              ></script>
            </Helmet>
            <App />
          </HelmetProvider>
        </InitValidator>
      </ConsentValidator>
    </UserProvider>
  </Router>
);

if (isStatic) {
  // CSR
  let root = createRoot(document.getElementById('root') as HTMLElement);
  getAtlassianUser().then((user) => root.render(Root));
} else {
  // SSR
  initializeFeatureGatesClient().then(() =>
    hydrateRoot(document.getElementById('core-root') as HTMLElement, Root),
  );
}
