"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("../constants");
function browserDefaultEvalLength() {
    try {
        return eval.toString().length || 0;
    }
    catch (err) {
        return constants_1.ERROR;
    }
}
exports.browserDefaultEvalLength = browserDefaultEvalLength;
