import React, { useState, useCallback } from 'react';
import { Button, ButtonAppearance } from '@components/shared';
import { FormFooter, Label } from '@atlaskit/form';
import TextArea from '@atlaskit/textarea';
import { LoadingButton } from '@atlaskit/button';
import { css } from '@emotion/react';
import { token } from '@atlaskit/tokens';

export interface SubmitFeedbackProps {
  onSubmit: () => Promise<any>;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  isLoading: boolean;
  isSubmitted: boolean;
  hasError: boolean;
  opinion: string;
}

export const SubmitFeedback: React.FC<SubmitFeedbackProps> = ({
  onSubmit,
  onChange,
  isLoading,
  isSubmitted,
  hasError,
  opinion,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isYesSelected, setIsYesSelected] = useState(false);
  const [isNoSelected, setIsNoSelected] = useState(false);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
      e.preventDefault();
      if (onChange) {
        onChange(e);
      }
    },
    [onChange],
  );

  const handleYes = useCallback(() => {
    setIsOpen(true);
    setIsYesSelected(true);
    setIsNoSelected(false);
  }, []);

  const handleNo = useCallback(() => {
    setIsOpen(true);
    setIsNoSelected(true);
    setIsYesSelected(false);
  }, []);

  const handleCancel = useCallback(() => {
    setIsOpen(false);
    setIsYesSelected(false);
    setIsNoSelected(false);
  }, []);

  const renderButtons: () => React.ReactNode = () => {
    if (isLoading && !isSubmitted) {
      return (
        <LoadingButton appearance="primary" isLoading>
          Submit
        </LoadingButton>
      );
    }
    if (isSubmitted) {
      return null;
    }
    return (
      <>
        <Button type="submit" appearance={ButtonAppearance.PRIMARY} label="Submit" onClick={onSubmit} />
        <Button appearance={ButtonAppearance.LINK} label="Cancel" onClick={handleCancel} />
      </>
    );
  };

  const renderFeedbackBox: () => React.ReactNode = () => {
    if (!isOpen) {
      return null;
    }
    if (!isSubmitted) {
      return (
        <div
          css={{
            label: { fontSize: '13.7143px', color: token('color.text.subtlest') },
            textArea: {
              borderColor: `${token('color.border')} !important`,
              '&:focus': { borderColor: `${token('color.border.focused')} !important` },
            },
          }}
        >
          <Label htmlFor="area">Send us your opinion</Label>
          <TextArea placeholder="Begin typing in here..." defaultValue={opinion} onChange={handleChange} />
          <FormFooter>{renderButtons()}</FormFooter>
        </div>
      );
    }
    if (!hasError) {
      return <p css={feedbackStyles}>Thanks for your feedback!</p>;
    }
    return <p css={feedbackStyles}>Error submitting feedback. Try again later.</p>;
  };

  return (
    <>
      {!isSubmitted && (
        <div>
          <div css={css({ display: 'flex', alignItems: 'center', marginBottom: '10px' })}>
            <p css={labelStyles}>Was this content helpful?</p>
            <div css={css({ marginLeft: '1rem', display: 'flex' })}>
              <span css={css({ marginRight: '0.5rem' })}>
                <Button
                  label="Yes"
                  onClick={handleYes}
                  appearance={ButtonAppearance.PRIMARY}
                  disabled={isYesSelected}
                />
              </span>
              <Button label="No" onClick={handleNo} appearance={ButtonAppearance.PRIMARY} disabled={isNoSelected} />
            </div>
          </div>
        </div>
      )}

      {renderFeedbackBox()}
    </>
  );
};

const labelStyles = css({
  marginTop: 0,
  marginBottom: 0,
  fontFamily: 'Charlie Display, sans-serif',
  fontSize: '1.25rem',
  fontWeight: 600,
  lineHeight: '1.75rem',
  color: token('color.text'),
});

const feedbackStyles = css({
  fontWeight: 600,
  fontSize: '1.125rem',
  lineHeight: '1.5rem',
  width: '13.813rem',
  height: '1.563rem',
});
