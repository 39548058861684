"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var cookie_1 = require("./cookie");
var url_1 = require("./url");
var getLevels = function (url) {
    var host = url_1.parse(url).hostname;
    var parts = host.split('.');
    var last = parts[parts.length - 1];
    var levels = [];
    if (parts.length === 4 && !isNaN(parseInt(last, 10))) {
        return levels;
    }
    if (parts.length <= 1) {
        return levels;
    }
    for (var i = parts.length - 2; i >= 0; --i) {
        levels.push(parts.slice(i).join('.'));
    }
    return levels;
};
exports.domain = function (url) {
    var domainLevels = getLevels(url);
    for (var _i = 0, domainLevels_1 = domainLevels; _i < domainLevels_1.length; _i++) {
        var domainLevel = domainLevels_1[_i];
        var cname = '__tld__';
        var opts = { domain: '.' + domainLevel };
        cookie_1.setCookie(cname, 1, opts);
        if (cookie_1.getCookie(cname)) {
            cookie_1.setCookie(cname, null, opts);
            return domainLevel;
        }
    }
    return '';
};
exports.inIframe = function () {
    try {
        return window.self !== window.top;
    }
    catch (e) {
        return true;
    }
};
exports.isAtlassianComDomain = function () {
    return /atlassian\.com$/.test(window.location.hostname);
};
