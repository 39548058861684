import React from 'react';
import { Image, ImageProps, Link, LinkProps, LinkStyle } from '@components/shared';
import { css } from '@emotion/react';
import { N70 } from '@atlaskit/theme/colors';
import { media } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

export interface EmptyStateProps {
  /** empty state description */
  description: string;
  /** ImageProps */
  image?: ImageProps;
  /** LinkProps */
  redirectLink?: LinkProps;
}

/**
 * Displays empty state component
 * @param {EmptyStateProps} EmptyStateProps
 * @returns {React.ReactElement} React.ReactElement
 *
 * @example
 * <EmptyState
 *       image={{ src: 'https://images.ctfassets.net/xkfunaz6zr9g/23WT5Y5DTtS9sGsd8Eg9j8/e98ce3325850c6c4da5e31998af81499/badgeEmptyState.svg', alt: 'empty state' }}
 *       description="You don’t have any learning badges yet"
 *       redirectLink={{ link: 'https://www.atlassian.com/', label: 'Learn a new skill' }}
 *     />
 */
export const EmptyState: React.FC<EmptyStateProps> = ({
  image,
  description,
  redirectLink,
}: EmptyStateProps): React.ReactElement => (
  <div tabIndex={0} css={containerStyles} data-testid="empty-state">
    {image && (
      <div css={imageContainerStyles}>
        <Image src={image.src} alt={image.alt} preventModal height={96} />
      </div>
    )}

    <div css={descriptionContainerStyles}>
      <p css={descriptionStyles}>{description}</p>
      {redirectLink && (
        <Link
          label={redirectLink.label}
          link={redirectLink.link}
          style={LinkStyle.SEMIBOLD}
          fontColor={token('color.link')}
          css={css({ fontFamily: 'system-ui' })}
        />
      )}
    </div>
  </div>
);

const containerStyles = css({
  width: '100%',
  display: 'flex',
  padding: '24px',
  border: '1px dashed',
  borderColor: N70,
  borderRadius: '4px',
  alignItems: 'center',
});

const imageContainerStyles = css({
  width: '64px',

  [media.above.sm]: {
    width: '96px',
  },
});

const descriptionContainerStyles = css({
  marginLeft: '16px',
  lineHeight: '23px',
});

const descriptionStyles = css({
  paddingBottom: '2px',
  margin: 0,
  fontFamily: 'system-ui',
  fontSize: '16px',
  color: token('color.text'),
  fontWeight: 'normal',
  wordBreak: 'break-word',
  lineHeight: 1.714,
});
