"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("../constants");
function getContext() {
    if (window.WebGLRenderingContext) {
        var canvasElement = document.createElement("canvas");
        var webGLcontexts = ["webgl", "experimental-webgl", "moz-webgl", "webkit-3d"];
        for (var _i = 0, webGLcontexts_1 = webGLcontexts; _i < webGLcontexts_1.length; _i++) {
            var contextName = webGLcontexts_1[_i];
            try {
                var canvasRenderingContext = canvasElement.getContext(contextName);
                if (canvasRenderingContext &&
                    canvasRenderingContext instanceof WebGLRenderingContext &&
                    typeof canvasRenderingContext.getParameter === "function") {
                    return {
                        name: contextName,
                        context: canvasRenderingContext,
                    };
                }
            }
            catch (p) {
                return constants_1.ERROR;
            }
        }
    }
    return constants_1.UNAVAILABLE;
}
exports.getContext = getContext;
