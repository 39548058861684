const isShallowEqual = (o1, o2) => {
  const objKeys1 = Object.keys(o1);
  const objKeys2 = Object.keys(o2);
  if (objKeys1.length !== objKeys2.length) return false;
  return objKeys1.every(key => o1[key] === o2[key]);
};

export const isSameRoute = _ref => {
  let {
    prevContextMatch,
    nextContextMatch
  } = _ref;
  return prevContextMatch.path === nextContextMatch.path && isShallowEqual(prevContextMatch.query, nextContextMatch.query) && isShallowEqual(prevContextMatch.params, nextContextMatch.params);
};