import { css } from '@emotion/react';
import { media } from '@atlaskit/primitives';
import { blockquoteStyles } from '../Blockquote';
import { variantsListStyles } from '../List';
import { token } from '@atlaskit/tokens';

export interface TextProps {
  /** Name of the color to be applied on the background text */
  backgroundColor?: string;
  /** indicates the font-weight of text */
  fontWeight?: 'regular' | 'semibold' | 'bold';
  /** indicates the size of text */
  size?: 'small' | 'regular' | 'large';
  /** Text to be displayed */
  children: React.ReactNode;
  /** color to be applied on the text */
  color?: string;
}

/**
 * Displays paragraph type elements in a structured and stylized way
 * @param {Text} TextProps
 * @returns {React.ReactElement} React.ReactElement
 *
 * @example
 * <Text>Text</Text>
 */
export const Text: React.FC<TextProps> = ({
  fontWeight = 'regular',
  size = 'regular',
  color = token('color.text.accent.gray.bolder'),
  children,
  backgroundColor,
}) => (
  <span
    css={[
      textDefaultStyles,
      backgroundColor && css({ backgroundColor, padding: '4px' }),
      color && css({ color }),
      variantsStyles.fontWeight[fontWeight],
      variantsStyles.size[size],
    ]}
  >
    {children}
  </span>
);

const textDefaultStyles = css({
  fontFamily: 'Inter, sans-serif',

  blockquote: blockquoteStyles,

  ul: variantsListStyles.list.unordered,

  ol: variantsListStyles.list.ordered,
});

const variantsStyles = {
  size: {
    small: css({
      fontSize: '14px',
      lineHeight: '20px',

      [media.above.sm]: {
        fontSize: '14px',
        lineHeight: '20px',
      },
    }),
    regular: css({
      fontSize: '14px',
      lineHeight: '22px',

      [media.above.sm]: {
        fontSize: '16px',
        lineHeight: '24px',
      },
    }),
    large: css({
      fontSize: '16px',
      lineHeight: '24px',

      [media.above.sm]: {
        fontSize: '20px',
        lineHeight: '32px',
      },
    }),
  },
  fontWeight: {
    regular: css({ fontWeight: 400 }),
    semibold: css({ fontWeight: 600 }),
    bold: css({ fontWeight: 700 }),
  },
};
