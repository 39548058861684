import ModalDialog from '@atlaskit/modal-dialog';
import { css } from '@emotion/react';
import React, { ReactNode } from 'react';
import { media } from '@atlaskit/primitives/responsive';
import { Button, ButtonAppearance, ButtonProps, Icon, ButtonSize } from '@components/shared';
import { token } from '@atlaskit/tokens';

enum DialogWidth {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  XLARGE = 'x-large',
}

type WidthType = 'small' | 'medium' | 'large' | 'x-large';

export interface MlModalProps {
  /** Callback function called when the modal dialog is requesting to be closed */
  closeModal: () => void;
  /** Modal Button props */
  actionButton: ButtonProps;
  /** Image link of the modal dialog */
  imageLink?: string;
  /** modal heading text */
  heading: string;
  /** modal subheading element */
  subheading: ReactNode;
  /** modal survey text element */
  surveyText?: ReactNode;
  /** Width of the modal dialog */
  width?: WidthType | number;
}

/**
 * Component that renders a inline code in topic body
 * @param {MlModalProps} MlModalProps
 * @returns {React.ReactElement} React.ReactElement
 *
 * @example
 * <Modal closeModal={onCloseModal} label="text" actionButton={{ label: "button text", }}/>
 */

export const Modal: React.FC<MlModalProps> = ({
  closeModal,
  width = DialogWidth.LARGE,
  imageLink,
  actionButton,
  heading,
  subheading,
  surveyText,
}: MlModalProps): React.ReactElement => (
  <ModalDialog onClose={closeModal} width={width}>
    <div css={modalContainerStyles}>
      <span css={modalCloseButtonStyles}>
        <Button
          label="close modal button"
          appearance={ButtonAppearance.ICON}
          iconBefore={<Icon type="cross" />}
          onClick={closeModal}
        />
      </span>
      <div css={modalBodyStyles}>
        <span css={modalImageContainerStyles}>
          {imageLink && <img src={imageLink} alt="modal-image" width={120} height={120} css={imageStyles} />}
        </span>
        <p data-testid="modal-heading" css={modalHeadingContainerStyles}>
          {heading}
        </p>
        <span data-testid="modal-subheading" css={modalSubheadingContainerStyles}>
          {subheading}
        </span>
        {surveyText && (
          <span data-testid="modal-survey-text" css={[modalSubheadingContainerStyles, surveyTextStyles]}>
            {surveyText}
          </span>
        )}
        <span css={modalActionButtonContainerStyles}>
          <Button
            appearance={ButtonAppearance.PRIMARY}
            size={ButtonSize.LARGE}
            onClick={actionButton.onClick}
            label={actionButton.label}
            iconBefore={actionButton.iconBefore}
            iconAfter={actionButton.iconAfter}
          />
        </span>
      </div>
    </div>
  </ModalDialog>
);

const imageStyles = css({
  borderRadius: '50%',
});

const modalContainerStyles = css({
  padding: `${token('space.400')} ${token('space.500')}`,

  [media.below.sm]: {
    padding: '16px',
  },
});

const modalCloseButtonStyles = css({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',

  [media.below.sm]: {
    padding: '8px',
  },
});

const modalImageContainerStyles = css({
  marginBottom: '24px',

  [media.below.sm]: {
    marginBottom: '16px',
  },
});

const modalBodyStyles = css({
  maxWidth: '640px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: `${token('space.200')} auto 0`,

  [media.below.sm]: {
    marginTop: 0,
  },
});

const modalHeadingContainerStyles = css({
  fontFamily: 'Charlie Display, sans-serif',
  fontSize: '32px',
  fontWeight: 600,
  lineHeight: '40px',
  marginTop: 0,
  marginBottom: '24px',
  textAlign: 'center',

  [media.below.sm]: {
    marginTop: 0,
    marginBottom: '16px',
    fontSize: '24px',
    lineHeight: '32px',
  },
});

const modalSubheadingContainerStyles = css({
  fontFamily: 'Inter, sans-serif',
  fontSize: '20px',
  fontWeight: 400,
  lineHeight: '32px',
  color: token('color.text'),
  textAlign: 'center',

  [media.below.sm]: {
    marginBottom: '32px',
    fontSize: '16px',
    lineHeight: '24px',
  },
});

const surveyTextStyles = css({
  marginTop: token('space.200'),

  a: {
    fontSize: '20px',
  },
});

const modalActionButtonContainerStyles = css({
  marginTop: token('space.400'),

  [media.below.sm]: {
    margin: 0,
  },
});
