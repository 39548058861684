import { useCallback } from 'react';
import { createHook } from 'react-sweet-state';
import { RouterStore } from '../router-store';
const createQueryParamHook = createHook(RouterStore, {
  selector: (_ref, _ref2) => {
    let {
      query
    } = _ref;
    let {
      paramKey
    } = _ref2;
    return query[paramKey];
  }
});
/**
 * Utility hook for accessing URL query params
 */

export const useQueryParam = paramKey => {
  const [paramVal, routerActions] = createQueryParamHook({
    paramKey
  });
  const setQueryParam = useCallback((newValue, updateType) => {
    routerActions.updateQueryParam({
      [paramKey]: newValue
    }, updateType);
  }, [paramKey, routerActions]);
  return [paramVal, setQueryParam];
};