"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function getHardwareConcurrency() {
    try {
        var result = parseInt(navigator.hardwareConcurrency.toString(), 10);
        return isNaN(result) ? 1 : result;
    }
    catch (t) {
        return 1;
    }
}
exports.getHardwareConcurrency = getHardwareConcurrency;
