import React from 'react';
import { css } from '@emotion/react';
import { PlusIcon } from './assets/AddIcon';
import { token } from '@atlaskit/tokens';

const contentStyles = css({
  textAlign: 'center',
  padding: '17px 48px',
  fontFamily: 'Inter, sans-serif',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
});

const textStyles = css({
  marginTop: '16px',
  color: token('color.icon'),
  fontSize: '16px',
  lineHeight: '24px',
});

export const UnifiedAccountLinkAccountCard: React.FC = () => {
  return (
    <div css={contentStyles}>
      <PlusIcon />
      <div css={textStyles}>Link account</div>
    </div>
  );
};
