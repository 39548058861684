import ModalDialog from '@atlaskit/modal-dialog';
import { Label } from '@atlaskit/form';
import Button from '@atlaskit/button/new';
import Select from '@atlaskit/select';
import TextArea from '@atlaskit/textarea';
import { css } from '@emotion/react';
import React, { ChangeEvent } from 'react';
import { media } from '@atlaskit/primitives/responsive';
import { Button as CustomButton, ButtonAppearance, Checkbox, Link } from '@components/shared';
import { token } from '@atlaskit/tokens';

enum DialogWidth {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  XLARGE = 'x-large',
}

type WidthType = 'small' | 'medium' | 'large' | 'x-large';

export interface ReportIssueModalProps {
  /** Boolean indicating if the user is participating in research */
  isParticipatingInResearch: boolean;
  /** Boolean indicating if the user consents to be contacted */
  consentToContact: boolean;
  /** Selected value in the select input */
  selectValue?: { label: string; value: string };
  /** Value of the text area */
  textAreaValues?: string;
  /** Heading text for the modal */
  heading?: string;
  /** Width of the modal */
  width?: WidthType | number;
  /** Options for the select input */
  options?: { label: string; value: string }[];
  /** Boolean indicating if the submit button is disabled */
  isDisabled: boolean;
  /** Callback function to close the modal */
  closeModal: () => void;
  /** Callback function to submit the form */
  submit: () => void;
  /** Callback function when the select value changes */
  onSelectChange: (args: { label: string; value: string }) => void;
  /** Callback function when the text area value changes */
  onTextChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  /** Callback function when the user opts to participate in research */
  onParticipatingInResearch: () => void;
  /** Callback function when the user consents to be contacted */
  onConsentToContact: () => void;
}

/**
 * ReportIssueModal component renders a modal dialog for reporting issues.
 *
 * @param {boolean} isParticipatingInResearch - Indicates if the user is participating in research.
 * @param {boolean} consentToContact - Indicates if the user consents to be contacted.
 * @param {Object} selectValue - The selected value from the dropdown.
 * @param {string} selectValue.label - The label of the selected value.
 * @param {string} selectValue.value - The value of the selected value.
 * @param {string} textAreaValues - The value of the text area input.
 * @param {string} heading - The heading text of the modal.
 * @param {DialogWidth} [width=DialogWidth.LARGE] - The width of the modal dialog.
 * @param {Array} options - The options for the dropdown select.
 * @param {boolean} isDisabled - Indicates if the submit button is disabled.
 * @param {Function} closeModal - Function to close the modal.
 * @param {Function} submit - Function to submit the form.
 * @param {Function} onSelectChange - Function to handle changes in the select input.
 * @param {Function} onTextChange - Function to handle changes in the text area input.
 * @param {Function} onParticipatingInResearch - Function to handle changes in the research participation checkbox.
 * @param {Function} onConsentToContact - Function to handle changes in the consent to contact checkbox.
 *
 * @returns {React.ReactElement} The rendered ReportIssueModal component.
 */

export const ReportIssueModal: React.FC<ReportIssueModalProps> = ({
  isParticipatingInResearch,
  consentToContact,
  selectValue,
  textAreaValues,
  heading,
  width = DialogWidth.LARGE,
  options,
  isDisabled,
  closeModal,
  submit,
  onSelectChange,
  onTextChange,
  onParticipatingInResearch,
  onConsentToContact,
}: ReportIssueModalProps): React.ReactElement => (
  <ModalDialog onClose={closeModal} width={width}>
    <div css={modalContainerStyles}>
      <div css={modalBodyStyles}>
        <p data-testid="modal-heading" css={modalHeadingContainerStyles}>
          {heading}
        </p>
        <p css={requiredFieldsStyles}>
          Required fields are marked with an asterisk{' '}
          <span css={[requiredFieldsStyles, css({ color: token('color.text.accent.red') })]}>*</span>
        </p>
        <div css={css({ marginBottom: token('space.050') })}>
          <Label htmlFor="problem-to-report">
            What problem would you like to report? <span css={css({ color: token('color.text.accent.red') })}>*</span>
          </Label>
        </div>
        <div css={selectContainerStyles}>
          <Select
            inputId="problem-to-report"
            options={options}
            value={selectValue}
            onChange={(e): void => onSelectChange(e as { label: string; value: string })}
            placeholder="Select problem"
          />
        </div>
        {selectValue && (
          <>
            <div css={css({ marginBottom: token('space.050') })}>
              <Label htmlFor="problem-to-report">
                Provide any other details <span css={css({ color: token('color.text.accent.red') })}>*</span>
              </Label>
            </div>
            <div css={detailsStyles}>
              <TextArea
                maxHeight="156px"
                placeholder="Start typing..."
                onChange={onTextChange}
                value={textAreaValues}
              />
            </div>
            <div css={css({ marginBottom: token('space.100') })}>
              <Checkbox
                label="I'd like to participate in product research"
                onChange={onParticipatingInResearch}
                isChecked={isParticipatingInResearch}
              />
            </div>
            <div css={policyContainerStyles}>
              <Checkbox
                label={
                  <span>
                    Yes, Atlassian teams can contact me to learn about my experiences to improve Atlassian products and
                    services. I acknowledge the Atlassian Privacy Policy
                    <Link
                      label="Atlassian Privacy Policy."
                      target="_blank"
                      link="https://www.atlassian.com/legal/privacy-policy#what-this-policy-covers"
                      onClick={(): void => {
                        window.open(
                          'https://www.atlassian.com/legal/privacy-policy#what-this-policy-covers',
                          '_blank',
                          'noopener',
                        );
                      }}
                    />
                  </span>
                }
                onChange={onConsentToContact}
                isChecked={consentToContact}
              />
            </div>
          </>
        )}
      </div>
      <span css={modalCloseButtonStyles}>
        <div css={customButtonWrapperStyles}>
          <Button appearance={ButtonAppearance.SUBTLE} onClick={closeModal}>
            Cancel
          </Button>
        </div>
        <CustomButton label="Submit" appearance={ButtonAppearance.PRIMARY} disabled={isDisabled} onClick={submit} />
      </span>
    </div>
  </ModalDialog>
);

const modalContainerStyles = css({
  padding: token('space.300'),

  [media.below.sm]: {
    padding: token('space.200'),
  },
});

const modalHeadingContainerStyles = css({
  fontFamily: 'Charlie Display, sans-serif',
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: '32px',
});

const requiredFieldsStyles = css({
  color: token('color.text.subtlest'),
  fontFamily: 'Inter, sans-serif',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  marginBottom: token('space.300'),
});

const selectContainerStyles = css({
  width: '100%',
  maxWidth: '420px',
  marginBottom: token('space.300'),
});

const detailsStyles = css({
  width: '100%',
  marginBottom: token('space.300'),
});

const modalCloseButtonStyles = css({
  display: 'flex',
  justifyContent: 'flex-end',

  [media.below.sm]: {
    padding: token('space.100'),
  },
});

const modalBodyStyles = css({
  maxWidth: '640px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  margin: `${token('space.200')} auto 0`,

  [media.below.sm]: {
    marginTop: 0,
  },
});

const customButtonWrapperStyles = css({
  marginRight: token('space.100'),

  button: {
    height: '100%',
    color: token('color.text'),
    fontSize: '16px',
    lineHeight: '20px',
    padding: `10px ${token('space.150')}`,
    fontWeight: 600,
  },
});

const policyContainerStyles = css({
  display: 'flex',
  a: {
    marginLeft: '5px',
  },
});
