import { css } from '@emotion/react';

import { LessonList, Sidebar, LessonListProps } from '@components/index';
import { ProgressBadge, ProgressBadgeProps } from '@components/learning/ProgressBadge';
import { token } from '@atlaskit/tokens';

export interface LessonSidebarProps extends ProgressBadgeProps, LessonListProps {
  /** It indicates if the sidebar is visible in the page or not */
  isVisible?: boolean;
  /** Function to call when toggle menu visibility*/
  onChangeMenuVisibility?: (menuVisible: boolean) => void;
  /** Function to call when navigating to the course page */
  onCourseNavigation?: () => void;
}

/**
 * Component that renders a sidebar menu to execute navigation functions on Learning app pages
 * @param {LessonSidebar} LessonSidebarProps
 * @returns {React.ReactElement} React.ReactElement
 *
 * @example
 * <LessonSidebar title="title" />
 */

export const LessonSidebar: React.FC<LessonSidebarProps> = ({
  lessons,
  progress,
  pathColor,
  title,
  image,
  isVisible,
  onChangeMenuVisibility,
  onCourseNavigation,
}) => (
  <Sidebar isVisible={isVisible} onChangeMenuVisibility={onChangeMenuVisibility}>
    <div css={css({ marginLeft: '16px' })}>
      <div css={css({ marginBottom: '16px', lineHeight: '24px' })}>
        <span css={[subheadingStyles, css({ marginBottom: '24px', marginTop: '24px' })]}>Current course</span>
      </div>
      <ProgressBadge
        progress={progress}
        pathColor={pathColor}
        title={title}
        image={image}
        onCourseNavigation={onCourseNavigation}
      />
      <div css={css({ marginBottom: '24px', marginTop: '24px', lineHeight: '24px' })}>
        <span css={subheadingStyles}>Lessons in this course</span>
      </div>
    </div>
    <LessonList lessons={lessons} />
  </Sidebar>
);

const subheadingStyles = css({
  fontFamily: 'Charlie Text, sens-serif',
  fontSize: '12px',
  fontWeight: 600,
  lineHeight: '16px',
  textTransform: 'uppercase',
  color: token('color.text'),
});
