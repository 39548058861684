import type { FunctionComponent } from 'react';
import Section from '../../common/Section';
import {
  JiraLogoMark,
  RovoLogoMark,
  LearningIllustration,
} from 'assets/images';
import { css } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import CourseCard from '../../course-card/CourseCard';
import { MOBILE, TABLET } from '../../../utils/constants';

const LearningSection: FunctionComponent = () => {
  return (
    <Section
      id="learning"
      title="Learning"
      heading="Grow your skills with flexible learning"
      description="Our free on-demand courses help you learn Atlassian products and get work done faster. Level up with instructor-led training and Atlassian certifications."
      buttonData={{
        href: process.env.LEARNING_BASE_URL as string,
        text: 'Explore Learning',
        target: '_self',
      }}
    >
      <div css={learningContentStyles}>
        <img src={LearningIllustration} css={learningImageStyle} />
        <div css={courseCardHolderStyles}>
          <CourseCard
            id="jira-software"
            title="Get started quickly with Jira"
            href={
              process.env.LEARNING_BASE_URL +
              '/course/get-started-quickly-with-jira'
            }
            icon={JiraLogoMark}
          />
          <CourseCard
            id="rovo-chat"
            title="Get help from Rovo Chat and Agents"
            href={
              process.env.LEARNING_BASE_URL +
              '/course/get-help-from-rovo-chat-and-agents'
            }
            icon={RovoLogoMark}
          />
        </div>
      </div>
    </Section>
  );
};

export default LearningSection;

const learningContentStyles = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: token('space.400'),
  '@media (max-width: 1279px)': {
    flexWrap: 'wrap',
    padding: '24px',
  },
});

const learningImageStyle = css({
  gap: '24px',
  maxWidth: '100%',
  '@media (max-width: 1279px)': {
    width: '380px',
  },
});

const courseCardHolderStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: token('space.400'),
  paddingLeft: token('space.300'),
  justifyContent: 'stretch',
  [TABLET.MEDIA_QUERY]: {
    flexDirection: 'row',
    paddingLeft: '0px',
  },
  [MOBILE.MEDIA_QUERY]: {
    width: '100%',
    paddingLeft: '0px',
  },
});
