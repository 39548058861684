export const BREAKPOINTS = {
  MOBILE: {
    MAX_WIDTH: 767,
  },
  TABLET: {
    MIN_WIDTH: 768,
    MAX_WIDTH: 1279,
  },
  DESKTOP: {
    MIN_WIDTH: 1280,
  },
};
