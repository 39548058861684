import { css } from '@emotion/react';
import { token } from '@atlaskit/tokens';

export interface ProgressBarProps {
  /** Numerical percentage of progress*/
  progress: number;
}
/**
 *  It provides a graphical representation to visualize the progress or completion of a task or process
 * @param {ProgressLessonsProps} ProgressBarProps
 * @returns {React.ReactElement} React.ReactElement
 *
 * @example
 * <ProgressBar progress={70}  />
 */
export const ProgressBar: React.FC<ProgressBarProps> = ({ progress }) => (
  <div data-testid="progress-bar" css={progressBarContainerStyles}>
    <div data-testid={`progress-${progress}`} css={[progressBarContentStyles, css({ width: `${progress}%` })]} />
  </div>
);

const progressBarContainerStyles = css({
  width: '100%',
  height: '8px',
  backgroundColor: token('color.background.accent.gray.subtler'),
  borderRadius: '100px',
  overflow: 'hidden',
});

const progressBarContentStyles = css({
  height: '8px',
  backgroundColor: token('color.text.success'),
  borderRadius: '100px',
});
