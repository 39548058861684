"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("../constants");
var getContext_1 = require("./getContext");
function getWebGL() {
    try {
        var gl = getContext_1.getContext();
        if (typeof gl === "undefined") {
            return constants_1.UNAVAILABLE;
        }
        if (gl && typeof gl === "object" && gl.context) {
            var glContext = gl.context;
            var rendererInfo = glContext.getExtension("WEBGL_debug_renderer_info");
            if (rendererInfo !== null) {
                return [
                    glContext.getParameter(rendererInfo.UNMASKED_RENDERER_WEBGL),
                    glContext.getParameter(glContext.MAX_COMBINED_TEXTURE_IMAGE_UNITS),
                    glContext.getParameter(glContext.MAX_CUBE_MAP_TEXTURE_SIZE)
                ].join("|");
            }
            return constants_1.UNAVAILABLE;
        }
    }
    catch (p) {
        return constants_1.ERROR;
    }
}
exports.getWebGL = getWebGL;
