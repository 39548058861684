/**
 * @see https://github.com/jsdom/jsdom/releases/tag/12.0.0
 * @see https://github.com/jsdom/jsdom/issues/1537
 */
const isJsDomEnvironment = () => {
  var _navigator, _navigator2;

  return window.name === 'nodejs' || ((_navigator = navigator) == null ? void 0 : _navigator.userAgent.includes('Node.js')) || ((_navigator2 = navigator) == null ? void 0 : _navigator2.userAgent.includes('jsdom'));
};

export const isServerEnvironment = () => {
  if (typeof window === 'undefined' || typeof process !== 'undefined' && process.versions != null && process.versions.node != null) {
    return true;
  }

  if (isJsDomEnvironment()) {
    return true;
  }

  return false;
};