import React from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { media } from '@atlaskit/primitives/responsive';
import { Tab, ButtonSize } from '../../shared';
import { token } from '@atlaskit/tokens';

export interface LessonsTabContentProps {
  /** Label of each tab */
  tabLinks: string[];
  /** it indicates the index of the tab with status active */
  activeTab: number;
  /** function to execute when clicking tab label */
  onClickTab: (index: number) => void;
  /** Content to be shown inside the panel*/
  children?: React.ReactNode;
  /** Indicates the size of tab */
  size?: LessonsTabContentSize.DEFAULT | LessonsTabContentSize.WIDE;
}

export enum LessonsTabContentSize {
  DEFAULT = 'default',
  WIDE = 'wide',
}

/**
 * Component that presents content depending on selected tab
 * @param {LessonsTabContentProps} LessonsTabContentProps
 * @returns {React.ReactElement} React.ReactElement
 *
 * @example
 * <LessonsTabContent tabLinks={['tab1','tab2']} activeTab="1"}/>
 */

export const LessonsTabContent: React.FC<LessonsTabContentProps> = ({
  tabLinks,
  activeTab = 0,
  onClickTab,
  children,
  size = LessonsTabContentSize.DEFAULT,
}: LessonsTabContentProps): React.ReactElement => (
  <div css={[tabsContainerStyles['base'], tabsContainerStyles[`${size}-size`]]} data-testid="navtab">
    <ul css={tabsButtonGroupContainerStyles}>
      {tabLinks.map((tab, index) => (
        <li
          key={`li-navtab-${tab}-${index}`}
          data-testid={`button-${tab}${index === activeTab ? '-selected' : ''}`}
          css={tabsButtonContainerStyles}
        >
          <Tab
            size={ButtonSize.LARGE}
            label={tab}
            onClick={(): void => onClickTab(index)}
            selected={index === activeTab}
          />
        </li>
      ))}
    </ul>
    <div css={tabsContentContainerStyles}>{children}</div>
  </div>
);

const tabsContainerStyles = {
  base: css({
    width: '100%',
    borderRadius: '8px',
    boxShadow: token('elevation.shadow.raised'),

    [media.below.md]: {
      maxWidth: '720px',
    },
  }),

  'default-size': css({
    maxWidth: '720px',
  }),

  'wide-size': css({
    maxWidth: '1020px',
  }),
} as { [key: string]: SerializedStyles };

const tabsButtonGroupContainerStyles = css({
  padding: '18px',
  overflowX: 'auto',
  listStyleType: 'none',
  borderBottom: `1px solid ${token('color.border')}`,
  margin: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',

  [media.below.xs]: {
    padding: '12px',
  },
});

const tabsButtonContainerStyles = css({
  margin: '6px',

  [media.below.xs]: {
    margin: '4px',
  },
});

const tabsContentContainerStyles = css({
  fontFamily: 'Inter, sans-serif',
  fontSize: '20px',
  fontWeight: 400,
  lineHeight: '32px',
  letterSpacing: '-0.06px',
  padding: '24px',
  color: token('color.text'),

  a: {
    fontSize: '20px',
    lineHeight: '32px',
  },

  [media.below.xs]: {
    padding: '16px',
    fontSize: '16px',
    lineHeight: '24px',
  },
});
