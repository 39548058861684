import React from 'react';

export const AtlassianCommunityLogo: React.FC = () => (
  <svg viewBox="0 0 406 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_511_7308)">
      <path
        d="M396.155 27.8221H394.244L388.065 11.4229H391.206L396.667 26.4205L402.129 11.4229H405.269L398.818 28.3828C396.906 33.3937 395.814 35.9867 391.479 35.9867C390.011 35.9867 389.192 35.8465 388.338 35.4961V32.9031C389.328 33.2185 390.455 33.3236 391.308 33.3236C393.697 33.3236 394.756 31.6416 396.155 27.8221Z"
        fill="#101214"
      />
      <path
        d="M382.705 23.5062C382.705 25.048 383.593 26.0992 385.402 26.0992C386.085 26.0992 386.733 25.9591 387.245 25.854V28.7624C386.733 28.9025 386.153 29.0427 385.265 29.0427C381.613 29.0427 379.838 26.8351 379.838 23.5763V14.2203H377.073V11.417H379.838V7.70264H382.705V11.417H387.245V14.2203H382.705V23.5062Z"
        fill="#101214"
      />
      <path
        d="M370.731 6.79681C370.731 5.46524 371.585 4.69434 372.779 4.69434C373.974 4.69434 374.827 5.46524 374.827 6.79681C374.827 8.12837 373.974 8.89928 372.779 8.89928C371.585 8.89928 370.731 8.12837 370.731 6.79681ZM371.278 28.9428V11.4222H374.213V28.9428H371.278Z"
        fill="#101214"
      />
      <path
        d="M367.184 18.6738V28.9408H364.248V18.3584C364.248 15.2047 363.019 13.803 360.22 13.803C357.49 13.803 355.612 15.6602 355.612 19.1994V28.9408H352.677V11.4202H355.612V14.2936C356.705 12.2262 358.718 11.0698 361.005 11.0698C364.931 11.0698 367.184 13.8381 367.184 18.6738Z"
        fill="#101214"
      />
      <path
        d="M333.733 21.6899V11.4229H336.669V22.0053C336.669 25.159 337.898 26.5606 340.697 26.5606C343.427 26.5606 345.305 24.7035 345.305 21.1643V11.4229H348.24V28.9434H345.305V26.0701C344.212 28.1375 342.199 29.2938 339.912 29.2938C335.986 29.2938 333.733 26.5256 333.733 21.6899Z"
        fill="#101214"
      />
      <path
        d="M318.416 19.1994V28.9408H315.48V18.3584C315.48 15.2047 314.252 13.803 311.453 13.803C308.722 13.803 306.845 15.6602 306.845 19.1994V28.9408H303.909V11.4202H306.845V14.2936C307.937 12.2262 309.951 11.0698 312.238 11.0698C315.139 11.0698 317.119 12.5766 317.972 15.3448C318.928 12.6467 321.215 11.0698 324.048 11.0698C327.871 11.0698 329.987 13.733 329.987 18.6738V28.9408H327.052V19.1994C327.052 15.5901 325.823 13.803 323.024 13.803C320.293 13.803 318.416 15.6602 318.416 19.1994Z"
        fill="#101214"
      />
      <path
        d="M288.249 19.1994V28.9408H285.314V18.3584C285.314 15.2047 284.085 13.803 281.286 13.803C278.555 13.803 276.678 15.6602 276.678 19.1994V28.9408H273.742V11.4202H276.678V14.2936C277.77 12.2262 279.784 11.0698 282.071 11.0698C284.972 11.0698 286.952 12.5766 287.805 15.3448C288.761 12.6467 291.048 11.0698 293.881 11.0698C297.704 11.0698 299.82 13.733 299.82 18.6738V28.9408H296.885V19.1994C296.885 15.5901 295.656 13.803 292.857 13.803C290.126 13.803 288.249 15.6602 288.249 19.1994Z"
        fill="#101214"
      />
      <path
        d="M262.275 29.2912C257.155 29.2912 254.151 25.4017 254.151 20.1455C254.151 14.8893 257.155 11.0698 262.275 11.0698C267.361 11.0698 270.331 14.8893 270.331 20.1455C270.331 25.4017 267.361 29.2912 262.275 29.2912ZM262.275 13.8731C258.623 13.8731 257.019 16.8166 257.019 20.1455C257.019 23.4744 258.623 26.4879 262.275 26.4879C265.893 26.4879 267.464 23.4744 267.464 20.1455C267.464 16.8166 265.893 13.8731 262.275 13.8731Z"
        fill="#101214"
      />
      <path
        d="M251.998 24.88V27.9285C250.598 28.8746 248.38 29.2951 246.059 29.2951C238.686 29.2951 234.521 24.7398 234.521 17.4863C234.521 10.478 238.686 5.57227 245.99 5.57227C248.175 5.57227 250.36 5.99276 251.964 7.18416V10.2327C250.36 9.18151 248.585 8.62085 245.99 8.62085C240.734 8.62085 237.594 12.195 237.594 17.4863C237.594 22.7775 240.836 26.2816 246.161 26.2816C248.516 26.2816 250.36 25.7209 251.998 24.88Z"
        fill="#101214"
      />
      <path
        d="M119.605 12.4499C119.605 15.8465 121.102 18.5153 127.168 19.7284C130.713 20.5371 131.501 21.1032 131.501 22.3163C131.501 23.5294 130.713 24.2572 128.192 24.2572C125.277 24.2572 121.811 23.2059 119.526 21.8311V27.3304C121.338 28.22 123.702 29.2713 128.192 29.2713C134.494 29.2713 136.936 26.3599 136.936 22.1546M136.936 22.2354C136.936 18.1918 134.888 16.3318 128.98 15.0378C125.75 14.3099 124.962 13.5821 124.962 12.5308C124.962 11.2368 126.144 10.6707 128.271 10.6707C130.87 10.6707 133.391 11.4794 135.833 12.6116V7.35493C134.1 6.46534 131.501 5.81836 128.428 5.81836C122.599 5.81836 119.605 8.40627 119.605 12.6116"
        fill="#1868DB"
      />
      <path
        d="M200.274 5.979V29.0276H205.08V11.4783L207.049 16.088L213.824 29.0276H219.811V5.979H215.085V20.8595L213.273 16.4924L207.837 5.979H200.274Z"
        fill="#1868DB"
      />
      <path d="M169.865 5.979H164.666V29.0276H169.865V5.979Z" fill="#1868DB" />
      <path
        d="M158.679 22.1554C158.679 18.1117 156.631 16.2517 150.723 14.9577C147.493 14.2299 146.705 13.502 146.705 12.4507C146.705 11.1567 147.887 10.5906 150.014 10.5906C152.614 10.5906 155.134 11.3993 157.577 12.5316V7.27485C155.843 6.38526 153.244 5.73828 150.171 5.73828C144.342 5.73828 141.348 8.32619 141.348 12.5316C141.348 15.9282 142.845 18.597 148.911 19.8101C152.456 20.6188 153.244 21.1849 153.244 22.398C153.244 23.6111 152.456 24.3389 149.935 24.3389C147.02 24.3389 143.554 23.2876 141.27 21.9127V27.412C143.081 28.3016 145.445 29.353 149.935 29.353C156.159 29.353 158.679 26.4416 158.679 22.1554Z"
        fill="#1868DB"
      />
      <path d="M79.1143 5.979V29.0276H89.828L91.4824 24.0135H84.3136V5.979H79.1143Z" fill="#1868DB" />
      <path d="M57.9219 5.979V10.9122H63.6727V29.0276H68.9508V10.9122H75.1742V5.979H57.9219Z" fill="#1868DB" />
      <path
        d="M50.2806 5.97412H43.4269L35.6279 29.0227H41.615L42.7179 25.1408C44.0572 25.5452 45.4752 25.7878 46.8932 25.7878C48.3112 25.7878 49.7292 25.5452 51.0684 25.1408L52.1713 29.0227H58.1584C58.0796 29.0227 50.2806 5.97412 50.2806 5.97412ZM46.8144 20.9355C45.7903 20.9355 44.8449 20.7737 43.9784 20.5311L46.8144 10.503L49.6504 20.5311C48.7838 20.7737 47.8385 20.9355 46.8144 20.9355Z"
        fill="#1868DB"
      />
      <path
        d="M108.339 5.97363H101.485L93.6074 29.0222H99.5945L100.697 25.1404C102.037 25.5447 103.455 25.7873 104.873 25.7873C106.291 25.7873 107.709 25.5447 109.048 25.1404L110.151 29.0222H116.138L108.339 5.97363ZM104.873 20.935C103.849 20.935 102.903 20.7733 102.037 20.5306L104.873 10.5025L107.709 20.5306C106.842 20.7733 105.897 20.935 104.873 20.935Z"
        fill="#1868DB"
      />
      <path
        d="M188.694 5.97363H181.84L174.041 29.0222H180.028L181.131 25.1404C182.47 25.5447 183.888 25.7873 185.306 25.7873C186.724 25.7873 188.142 25.5447 189.481 25.1404L190.584 29.0222H196.571L188.694 5.97363ZM185.306 20.935C184.282 20.935 183.337 20.7733 182.47 20.5306L185.306 10.5025L188.142 20.5306C187.276 20.7733 186.252 20.935 185.306 20.935Z"
        fill="#1868DB"
      />
      <g clipPath="url(#clip1_511_7308)">
        <path
          d="M25.4715 28.1859L13.6868 3.70842C13.4549 3.1784 13.2229 3.08203 12.9445 3.08203C12.7125 3.08203 12.4342 3.1784 12.1558 3.66024C10.4855 6.40673 9.74318 9.58688 9.74318 12.9116C9.74318 17.5372 12.0166 21.8738 15.3571 28.8605C15.7283 29.6314 16.0067 29.776 16.6562 29.776H24.8219C25.4251 29.776 25.7498 29.5351 25.7498 29.0532C25.7498 28.8123 25.7034 28.6678 25.4715 28.1859ZM8.3513 16.6217C7.42338 15.1762 7.145 15.0799 6.91302 15.0799C6.68104 15.0799 6.54185 15.1762 6.12429 16.0435L0.231981 28.2823C0.0463961 28.6678 0 28.8123 0 29.005C0 29.3905 0.324773 29.776 1.02071 29.776H9.32562C9.88237 29.776 10.2999 29.2941 10.5319 28.3786C10.8103 27.2222 10.9031 26.2104 10.9031 25.0058C10.9031 21.6329 9.4648 18.3564 8.3513 16.6217Z"
          fill="#1868DB"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_511_7308">
        <rect width="406" height="37" fill="white" />
      </clipPath>
      <clipPath id="clip1_511_7308">
        <rect width="25.7498" height="26.694" fill="white" transform="translate(0 3.08154)" />
      </clipPath>
    </defs>
  </svg>
);
