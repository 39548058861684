"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var set = function (name, value, options) {
    var str = encode(name) + '=' + encode((value || '').toString());
    if (value == null) {
        options.maxage = -1;
    }
    var expiry;
    if (options.maxage) {
        expiry = new Date(+(new Date()) + options.maxage);
    }
    if (options.path) {
        str += '; path=' + options.path;
    }
    if (options.domain) {
        str += '; domain=' + options.domain;
    }
    if (expiry) {
        str += '; expires=' + expiry.toUTCString();
    }
    if (options.secure) {
        str += '; secure';
    }
    if (options.sameSite) {
        str += '; samesite=' + options.sameSite;
    }
    document.cookie = str;
    return str;
};
var all = function () {
    try {
        return parse(document.cookie);
    }
    catch (err) {
        if (typeof console !== 'undefined' && typeof console.error === 'function') {
            console.error(err.stack || err);
        }
        return {};
    }
};
function get(name) {
    return all()[name];
}
function parse(str) {
    var obj = {};
    var pairs = str.split(/ *; */);
    if (pairs[0] === '') {
        return obj;
    }
    pairs.forEach(function (item) {
        var pair = item.split('=');
        var key = decode(pair[0]);
        var value = decode(pair[1]);
        if (key && value) {
            obj[key] = value;
        }
    });
    return obj;
}
function encode(value) {
    try {
        return encodeURIComponent(value);
    }
    catch (e) {
        console.log('error `encode(%o)` - %o', value, e);
        return "";
    }
}
function decode(value) {
    try {
        return decodeURIComponent(value);
    }
    catch (e) {
        console.log('error `decode(%o)` - %o', value, e);
        return "";
    }
}
function getCookie(name) {
    return get(name);
}
exports.getCookie = getCookie;
function getAllCookies() {
    return all();
}
exports.getAllCookies = getAllCookies;
function setCookie(name, value, options) {
    var finalOption = tslib_1.__assign({ maxage: 31536000000, secure: true, path: '/', expires: null, domain: null, sameSite: 'lax' }, options);
    return set(name, value, finalOption);
}
exports.setCookie = setCookie;
function getCrossDomainCookieOption() {
    var domain = window.location.hostname.split('.').slice(-2).join('.');
    return { sameSite: 'None', domain: domain };
}
exports.getCrossDomainCookieOption = getCrossDomainCookieOption;
