/**
 * Horizontal line acting as divider with custom color and height
 * @param {DividerProps} DividerProps
 * @returns {React.ReactElement} React.ReactElement
 *
 * @example
 * <Divider color={token('color.border')} />
 */
import { token } from '@atlaskit/tokens';

export const Divider: React.FC<DividerProps> = ({ color, height = 1 }) => {
  const styles = {
    height: height + 'px',
    border: `0 solid ${token('color.border')}`,
    margin: 0,
    backgroundColor: color,
  };
  return <hr data-testid="divider" style={styles} />;
};

export interface DividerProps {
  /** Horizontal divider color */
  color: string;
  /** Indicates the thickness of the divider in px */
  height?: number;
}
