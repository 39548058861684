import { useMemo } from 'react';

import { GlyphProps } from '@atlaskit/icon';
import ChevronDown from '@atlaskit/icon/glyph/chevron-down';
import ChevronUp from '@atlaskit/icon/glyph/chevron-up';
import ChevronLeft from '@atlaskit/icon/glyph/chevron-left';
import ChevronRight from '@atlaskit/icon/glyph/chevron-right';
import ChevronDownCircle from '@atlaskit/icon/glyph/chevron-down-circle';
import ArrowUpCircle from '@atlaskit/icon/glyph/arrow-up-circle';
import CheckCircleOutline from '@atlaskit/icon/glyph/check-circle-outline';
import CheckCircle from '@atlaskit/icon/glyph/check-circle';
import ArrowRight from '@atlaskit/icon/glyph/arrow-right';
import Cross from '@atlaskit/icon/glyph/cross';
import DropdownCross from '@atlaskit/icon/glyph/editor/close';
import EmojiFrequentIcon from '@atlaskit/icon/glyph/emoji/frequent';
import StarFilledIcon from '@atlaskit/icon/glyph/star-filled';
import StarIcon from '@atlaskit/icon/glyph/star';
import BitbucketBuildsIcon from '@atlaskit/icon/glyph/bitbucket/builds';
import EditorMediaWrapRightIcon from '@atlaskit/icon/glyph/editor/media-wrap-right';
import EditorTableIcon from '@atlaskit/icon/glyph/editor/table';
import EditorOpenIcon from '@atlaskit/icon/glyph/editor/open';
import CopyIcon from '@atlaskit/icon/glyph/copy';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import ImageIcon from '@atlaskit/icon/glyph/image';
import DownloadIcon from '@atlaskit/icon/glyph/download';
import MediaServicesZoomInIcon from '@atlaskit/icon/glyph/media-services/zoom-in';
import MediaServicesZoomOutIcon from '@atlaskit/icon/glyph/media-services/zoom-out';
import SearchIcon from '@atlaskit/icon/glyph/search';
import OverviewIcon from '@atlaskit/icon/glyph/overview';
import AddIcon from '@atlaskit/icon/glyph/add';
import AddCircleIcon from '@atlaskit/icon/glyph/add-circle';
import EditIcon from '@atlaskit/icon/glyph/edit';
import EditFilledIcon from '@atlaskit/icon/glyph/edit-filled';
import SettingsIcon from '@atlaskit/icon/glyph/settings';
import InfoIcon from '@atlaskit/icon/glyph/info';
import QuestionIcon from '@atlaskit/icon/glyph/question';
import CheckIcon from '@atlaskit/icon/glyph/check';
import CheckCircleIcon from '@atlaskit/icon/glyph/check-circle';
import EmojiSymbolsIcon from '@atlaskit/icon/glyph/emoji/symbols';
import LocationIcon from '@atlaskit/icon/glyph/location';
import ChevronLeftLargeIcon from '@atlaskit/icon/glyph/chevron-left-large';
import HipchatChevronUpIcon from '@atlaskit/icon/glyph/hipchat/chevron-up';
import HipchatChevronDownIcon from '@atlaskit/icon/glyph/hipchat/chevron-down';
import ExportIcon from '@atlaskit/icon/glyph/export';
import LinkIcon from '@atlaskit/icon/glyph/link';
import ShareIcon from '@atlaskit/icon/glyph/share';
import MenuIcon from '@atlaskit/icon/glyph/menu';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import ArrowLeftCircleIcon from '@atlaskit/icon/glyph/arrow-left-circle';
import ArrowRightCircleIcon from '@atlaskit/icon/glyph/arrow-right-circle';
import RefreshIcon from '@atlaskit/icon/glyph/refresh';
import EmojiAtlassianIcon from '@atlaskit/icon/glyph/emoji/atlassian';
import FilterIcon from '@atlaskit/icon/glyph/filter';
import ThumbsUpIcon from '@atlaskit/icon/core/thumbs-up';
import ThumbsDownIcon from '@atlaskit/icon/core/thumbs-down';
import { css } from '@emotion/react';

export interface IconProps {
  /** Type of the icon */
  type: IconType;
  /** Size of the icon */
  size?: GlyphProps['size'];
  /** Color of the icon */
  color?: string;
  /** Rotation of the icon */
  rotation?: IconRotation;
  /** Text used to describe what the icon is in context. This text is invisible */
  alt?: GlyphProps['label'];
  /** Color of the icon when hovered using a mouth*/
  hoverColor?: string;
  /** Color of the icon when pressed on a touch screen */
  activeColor?: string;
}

export type IconType =
  | 'filter'
  | 'chevron-down'
  | 'chevron-up'
  | 'chevron-left'
  | 'chevron-right'
  | 'chevron-down-circle'
  | 'arrow-up-circle'
  | 'check-circle'
  | 'check-circle-outline'
  | 'check-circle-filled'
  | 'arrow-right'
  | 'arrow-left-circle'
  | 'arrow-right-circle'
  | 'atlassian-logo'
  | 'cross'
  | 'dropdown-cross'
  | 'menu-icon'
  | 'emoji-frequent'
  | 'star-filled'
  | 'star'
  | 'bitbucket-builds'
  | 'editor-media-wrap-right'
  | 'editor-table'
  | 'editor-open'
  | 'copy'
  | 'warning'
  | 'image'
  | 'download'
  | 'zoom-in'
  | 'zoom-out'
  | 'search'
  | 'overview'
  | 'add'
  | 'add-circle'
  | 'edit'
  | 'edit-filled'
  | 'error'
  | 'settings'
  | 'refresh'
  | 'info'
  | 'question'
  | 'check'
  | 'emoji-symbols'
  | 'location'
  | 'chevron-left-large'
  | 'chevron-right-large'
  | 'hipchat-chevron-up'
  | 'hipchat-chevron-down'
  | 'export'
  | 'link'
  | 'share'
  | 'bookmark'
  | 'bookmark-filled'
  | 'gap-completed'
  | 'one'
  | 'two'
  | 'three'
  | 'four'
  | 'five'
  | 'six'
  | 'seven'
  | 'eight'
  | 'nine'
  | 'zero'
  | 'thumb-down'
  | 'thumb-up';

export const icons: Record<IconType, (props: GlyphProps) => JSX.Element> = {
  filter: (props) => <FilterIcon {...props} />,
  'chevron-down': (props) => <ChevronDown {...props} />,
  'chevron-up': (props) => <ChevronUp {...props} />,
  'chevron-left': (props) => <ChevronLeft {...props} />,
  'chevron-right': (props) => <ChevronRight {...props} />,
  'chevron-down-circle': (props) => <ChevronDownCircle {...props} />,
  'arrow-up-circle': (props) => <ArrowUpCircle {...props} />,
  'check-circle': (props) => <CheckCircle {...props} />,
  'check-circle-outline': (props) => <CheckCircleOutline {...props} />,
  'arrow-right': (props) => <ArrowRight {...props} />,
  'arrow-left-circle': (props) => <ArrowLeftCircleIcon {...props} />,
  'arrow-right-circle': (props) => <ArrowRightCircleIcon {...props} />,
  'atlassian-logo': (props) => <EmojiAtlassianIcon {...props} />,
  cross: (props) => <Cross {...props} />,
  'dropdown-cross': (props) => <DropdownCross {...props} />,
  'menu-icon': (props) => <MenuIcon {...props} />,
  'emoji-frequent': (props) => <EmojiFrequentIcon {...props} />,
  'star-filled': (props) => <StarFilledIcon {...props} />,
  star: (props) => <StarIcon {...props} />,
  'bitbucket-builds': (props) => <BitbucketBuildsIcon {...props} />,
  'editor-media-wrap-right': (props) => <EditorMediaWrapRightIcon {...props} />,
  'editor-table': (props) => <EditorTableIcon {...props} />,
  'editor-open': (props) => <EditorOpenIcon {...props} />,
  copy: (props) => <CopyIcon {...props} />,
  warning: (props) => <WarningIcon {...props} />,
  image: (props) => <ImageIcon {...props} />,
  download: (props) => <DownloadIcon {...props} />,
  'zoom-in': (props) => <MediaServicesZoomInIcon {...props} />,
  'zoom-out': (props) => <MediaServicesZoomOutIcon {...props} />,
  search: (props) => <SearchIcon {...props} />,
  overview: (props) => <OverviewIcon {...props} />,
  add: (props) => <AddIcon {...props} />,
  refresh: (props) => <RefreshIcon {...props} />,
  'add-circle': (props) => <AddCircleIcon {...props} />,
  edit: (props) => <EditIcon {...props} />,
  'edit-filled': (props) => <EditFilledIcon {...props} />,
  error: (props) => <ErrorIcon {...props} />,
  settings: (props) => <SettingsIcon {...props} />,
  info: (props) => <InfoIcon {...props} />,
  question: (props) => <QuestionIcon {...props} />,
  check: (props) => <CheckIcon {...props} />,
  'check-circle-filled': (props) => <CheckCircleIcon {...props} />,
  'emoji-symbols': (props) => <EmojiSymbolsIcon {...props} />,
  location: (props) => <LocationIcon {...props} />,
  'chevron-left-large': (props) => <ChevronLeftLargeIcon {...props} />,
  'chevron-right-large': (props) => <ChevronLeftLargeIcon {...props} />,
  'hipchat-chevron-up': (props) => <HipchatChevronUpIcon {...props} />,
  'hipchat-chevron-down': (props) => <HipchatChevronDownIcon {...props} />,
  export: (props) => <ExportIcon {...props} />,
  link: (props) => <LinkIcon {...props} />,
  share: (props) => <ShareIcon {...props} />,
  bookmark: () => (
    <svg
      aria-label="bookmark"
      data-testid="bookmark"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icons/bookmark_24px">
        <path
          id="bookmark"
          d="M12 18L7.8 19.8C7.13333 20.0833 6.5 20.0292 5.9 19.6375C5.3 19.2458 5 18.6917 5 17.975V5C5 4.45 5.19583 3.97917 5.5875 3.5875C5.97917 3.19583 6.45 3 7 3H17C17.55 3 18.0208 3.19583 18.4125 3.5875C18.8042 3.97917 19 4.45 19 5V17.975C19 18.6917 18.7 19.2458 18.1 19.6375C17.5 20.0292 16.8667 20.0833 16.2 19.8L12 18Z"
          fill="transparent"
          stroke="#626F86"
          strokeWidth="1.5"
        ></path>
      </g>
    </svg>
  ),
  'bookmark-filled': () => (
    <svg
      aria-label="bookmark filled"
      data-testid="bookmark-filled"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icons/bookmark_24px">
        <path
          id="bookmark"
          d="M12 18L7.8 19.8C7.13333 20.0833 6.5 20.0292 5.9 19.6375C5.3 19.2458 5 18.6917 5 17.975V5C5 4.45 5.19583 3.97917 5.5875 3.5875C5.97917 3.19583 6.45 3 7 3H17C17.55 3 18.0208 3.19583 18.4125 3.5875C18.8042 3.97917 19 4.45 19 5V17.975C19 18.6917 18.7 19.2458 18.1 19.6375C17.5 20.0292 16.8667 20.0833 16.2 19.8L12 18Z"
          fill="#626F86"
          stroke="#626F86"
          strokeWidth="1.5"
        ></path>
      </g>
    </svg>
  ),
  'gap-completed': () => (
    <svg
      data-testid="gap-completed"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon / &#60;CheckCircleIcon&#62;">
        <path
          id="gap"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM9.3824 11.0689C9.50441 11.1213 9.61475 11.1975 9.707 11.293L11 12.586L14.293 9.29302C14.3852 9.19751 14.4956 9.12133 14.6176 9.06892C14.7396 9.01651 14.8708 8.98892 15.0036 8.98777C15.1364 8.98662 15.2681 9.01192 15.391 9.0622C15.5138 9.11248 15.6255 9.18673 15.7194 9.28063C15.8133 9.37452 15.8875 9.48617 15.9378 9.60907C15.9881 9.73196 16.0134 9.86364 16.0122 9.99642C16.0111 10.1292 15.9835 10.2604 15.9311 10.3824C15.8787 10.5044 15.8025 10.6148 15.707 10.707L11.707 14.707C11.5195 14.8945 11.2652 14.9998 11 14.9998C10.7348 14.9998 10.4805 14.8945 10.293 14.707L8.293 12.707C8.19749 12.6148 8.1213 12.5044 8.0689 12.3824C8.01649 12.2604 7.9889 12.1292 7.98775 11.9964C7.98659 11.8636 8.0119 11.732 8.06218 11.6091C8.11246 11.4862 8.18671 11.3745 8.2806 11.2806C8.3745 11.1867 8.48615 11.1125 8.60904 11.0622C8.73194 11.0119 8.86362 10.9866 8.9964 10.9878C9.12918 10.9889 9.2604 11.0165 9.3824 11.0689Z"
          fill="#22A06B"
        />
      </g>
    </svg>
  ),
  'thumb-down': (props) => <ThumbsDownIcon {...props} />,
  'thumb-up': (props) => <ThumbsUpIcon {...props} />,
  one: () => (
    <span css={iconNumberStyles} data-testid="one">
      1
    </span>
  ),
  two: () => (
    <span css={iconNumberStyles} data-testid="two">
      2
    </span>
  ),
  three: () => (
    <span css={iconNumberStyles} data-testid="three">
      3
    </span>
  ),
  four: () => (
    <span css={iconNumberStyles} data-testid="four">
      4
    </span>
  ),
  five: () => (
    <span css={iconNumberStyles} data-testid="five">
      5
    </span>
  ),
  six: () => (
    <span css={iconNumberStyles} data-testid="six">
      6
    </span>
  ),
  seven: () => (
    <span css={iconNumberStyles} data-testid="seven">
      7
    </span>
  ),
  eight: () => (
    <span css={iconNumberStyles} data-testid="eight">
      8
    </span>
  ),
  nine: () => (
    <span css={iconNumberStyles} data-testid="nine">
      9
    </span>
  ),
  zero: () => (
    <span css={iconNumberStyles} data-testid="zero">
      0
    </span>
  ),
};

export enum IconSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  XLARGE = 'xlarge',
}

export enum IconRotation {
  ZERO = '0deg',
  NINETY = '90deg',
  ONE_EIGHTY = '180deg',
  TWO_SEVENTY = '270deg',
}

/**
 * Customizable icons based on @atlaskit/icons
 * @param {IconProps} IconProps
 * @returns {React.ReactElement} React.ReactElement
 *
 * @example
 * <Icon type="cross" alt="Read more" />
 */
export const Icon: React.FC<IconProps> = ({
  type,
  size = IconSize.MEDIUM,
  rotation = IconRotation.ZERO,
  alt = '',
  color,
  hoverColor,
  activeColor,
}) => {
  const icon = useMemo(() => icons[type], [type]);
  return (
    <span
      css={[
        baseStyles,
        rotationStyles[rotation],
        hoverColor && { '&:hover': { color: hoverColor } },
        activeColor && { '&:active': { color: activeColor } },
      ]}
      data-testid={`icon-${type}`}
    >
      {icon ? icon({ size, label: alt, primaryColor: color || undefined, testId: type }) : null}
    </span>
  );
};

const baseStyles = css({
  width: 'fit-content',
  height: 'fit-content',
  display: 'inline-flex',
});

const rotationStyles = {
  '0deg': css({ transform: 'rotate(0deg)' }),
  '90deg': css({ transform: 'rotate(90deg)' }),
  '180deg': css({ transform: 'rotate(180deg)' }),
  '270deg': css({ transform: 'rotate(270deg)' }),
};

const iconNumberStyles = css({
  fontSize: '16px',
});
