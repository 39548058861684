import React from 'react';
import { css } from '@emotion/react';
import { UnifiedAccountTagContainer } from '../UnifiedAccountTagContainer';

const loggedInContentStyles = css({
  backgroundColor: '#626F86',
  color: '#FFFFFF',
});

export const UnifiedAccountTagLoggedIn: React.FC = () => (
  <UnifiedAccountTagContainer>
    <div css={[loggedInContentStyles, baseContentStyles]}>LOGGED IN</div>
  </UnifiedAccountTagContainer>
);

const baseContentStyles = css({
  width: '84px',
  height: '28px',
  fontFamily: 'Charlie Text',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '6px 8px',
  boxSizing: 'border-box',
  fontSize: '12px',
  fontWeight: '600',
  borderRadius: '4px',
});
