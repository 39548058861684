import React, { useCallback } from 'react';
import { css } from '@emotion/react';

import { IconChange } from './IconChange';
import { IconSize } from '@components/shared';
import { token } from '@atlaskit/tokens';

export interface Section {
  sectionTitle: string;
  slug: string;
  lessonSlug: string;
  isCompleted: boolean;
  onNavigation: (options: { [key: string]: string }) => void;
}

export const LessonSection: React.FC<Section> = ({ sectionTitle, slug, lessonSlug, isCompleted, onNavigation }) => {
  const handleKeyDown = useCallback(
    (
      event: React.KeyboardEvent<HTMLSpanElement>,
      onNavigation: (options: { [key: string]: string }) => void,
      slug: string,
      lessonSlug: string,
    ): void => {
      if (event.code === 'Space') {
        event.preventDefault();
        onNavigation({ sectionSlug: slug, lessonSlug });
      }
    },
    [onNavigation, slug, lessonSlug],
  );

  return (
    <div css={sectionStyles}>
      <IconChange isCompleted={isCompleted} size={IconSize.SMALL} />
      <div css={sectionLinkStyles}>
        <span
          role="link"
          tabIndex={0}
          onKeyDown={(event): void => handleKeyDown(event, onNavigation, slug, lessonSlug)}
          onClick={(): void => onNavigation({ sectionSlug: slug, lessonSlug })}
        >
          {sectionTitle}
        </span>
      </div>
    </div>
  );
};

const sectionStyles = css({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '0.75rem',
});

const sectionLinkStyles = css({
  marginLeft: '11px',

  span: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    lineHeight: '1.5rem',
    fontWeight: 400,
    color: token('color.text'),
  },

  ':hover': {
    span: {
      color: token('color.text.selected'),
      textDecoration: 'none',
    },
  },
});
