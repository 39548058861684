import React from 'react';
import { css } from '@compiled/react';
import { UITrackEventPayload } from '@atlassian/community-library';
import { LinkWithAnalytics as Link } from 'components/common/analytics-wrapped/LinkWithAnalytics';

type CourseCardProps = {
  id: string;
  title: string;
  icon: string;
  href: string;
};

const CourseCard: React.FC<CourseCardProps> = ({ id, title, icon, href }) => (
  <Link
    href={href}
    target="_self"
    css={linkStyle}
    clickEventPayload={getEventPayload('clicked', href, title, id)}
    hoverEventPayload={getEventPayload('hovered', href, title, id)}
  >
    <div css={courseCardStyle}>
      <img src={icon} css={iconStyle} alt={title} />
      <div css={titleStyle}>{title}</div>
    </div>
  </Link>
);

const getEventPayload = (
  action: string,
  href: string,
  label: string,
  id: string,
): UITrackEventPayload => {
  return {
    action,
    actionSubject: 'card',
    actionSubjectId: 'communityHomeCourseCard',
    source: 'communityHomeLearningSection',
    attributes: {
      href,
      label,
      communityCourseId: id,
    },
  } as UITrackEventPayload;
};

export default CourseCard;

const linkStyle = css({
  ':hover': {
    textDecoration: 'none',
    boxShadow:
      '0px 0px 1px 0px rgba(9, 30, 66, 0.31), 0px 8px 12px 0px rgba(9, 30, 66, 0.15)',
  },
  flex: '1',
  borderRadius: '12px',
  boxShadow:
    '0px 0px 1px 0px rgba(9, 30, 66, 0.31), 0px 1px 1px 0px rgba(9, 30, 66, 0.25)',
});

const titleStyle = css({
  width: '100%',
  color: 'var(--neutral-new-1000, #292A2E)',
  fontFamily: 'Charlie Display,sans-serif',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '32px',
  textAlign: 'left',
});

const courseCardStyle = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  padding: '24px',
  gap: '16px',
  minWidth: '500px',
  '@media (max-width: 1279px)': {
    minHeight: '192px',
    minWidth: '0',
  },
});

const iconStyle = css({
  height: '58px',
});
