import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { fg } from '@atlaskit/platform-feature-flags';
import getIncreasedContrastTheme from './get-increased-contrast-theme';
export var getThemePreferences = function getThemePreferences(themeState) {
  var colorMode = themeState.colorMode,
    contrastMode = themeState.contrastMode,
    dark = themeState.dark,
    light = themeState.light,
    shape = themeState.shape,
    spacing = themeState.spacing,
    typography = themeState.typography;
  var autoColorModeThemes = [light, dark];
  var themePreferences = [];
  if (colorMode === 'auto') {
    if (contrastMode !== 'no-preference' && fg('platform_increased-contrast-themes')) {
      autoColorModeThemes.forEach(function (normalTheme) {
        var increasedContrastTheme = getIncreasedContrastTheme(normalTheme);
        if (increasedContrastTheme) {
          autoColorModeThemes.push(increasedContrastTheme);
        }
      });
    }
    themePreferences.push.apply(themePreferences, autoColorModeThemes);
  } else {
    themePreferences.push(themeState[colorMode]);
    if (contrastMode !== 'no-preference' && fg('platform_increased-contrast-themes')) {
      var increasedContrastTheme = getIncreasedContrastTheme(themeState[colorMode]);
      if (increasedContrastTheme) {
        themePreferences.push(increasedContrastTheme);
      }
    }
  }
  [shape, spacing, typography].forEach(function (themeId) {
    if (themeId) {
      themePreferences.push(themeId);
    }
  });
  return _toConsumableArray(new Set(themePreferences));
};
export var getThemeOverridePreferences = function getThemeOverridePreferences(_themeState) {
  var themeOverridePreferences = [];
  return _toConsumableArray(new Set(themeOverridePreferences));
};