"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("../constants");
function getPlugins() {
    try {
        var MSIE = "Microsoft Internet Explorer";
        var NETSCAPE = "Netscape";
        var isIE = navigator.appName === MSIE;
        var isNetscape = navigator.appName === NETSCAPE;
        var isTrident = /Trident/.test(navigator.userAgent);
        if (isIE || isNetscape && isTrident) {
            return constants_1.UNAVAILABLE;
        }
        var navigatorPlugins = navigator.plugins;
        var s = "";
        for (var i = 0; i < navigatorPlugins.length; i++) {
            var plugin = navigator.plugins[i];
            var name_1 = plugin.name;
            var fileName = plugin.filename;
            var pluginMimeTypes = plugin.length;
            for (var j = 0; j < pluginMimeTypes; j++) {
                var o = plugin[j];
                if (o) {
                    s = s + name_1 + "," + fileName + "," + o.type + "|";
                }
            }
        }
        return s;
    }
    catch (error) {
        return constants_1.ERROR;
    }
}
exports.getPlugins = getPlugins;
