"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getUserIsConsentedQuery = exports.getUserIsAllowedQuery = exports.USER_INFO_QUERY = void 0;
var getUserIsAllowedQuery = exports.getUserIsAllowedQuery = function getUserIsAllowedQuery(emailId) {
  return "\n  query USER_ALLOWED_QUERY {\n    unified {\n      gating {\n        isEmailIdAllowed(emailId: \"".concat(emailId, "\") {\n          ... on UnifiedQueryError {\n            identifier\n            message\n            extensions {\n              errorType\n              statusCode\n            }\n          }\n          ... on UnifiedAccessStatus {\n            id\n            status\n          }\n        }\n      }\n    }\n  }\n");
};
var USER_INFO_QUERY = exports.USER_INFO_QUERY = "query USER_INFO_QUERY {\n  me {\n    user {\n      ... on AtlassianAccountUser {\n        email\n        accountId\n        orgId\n        picture\n      }\n    }\n  }\n}";
var getUserIsConsentedQuery = exports.getUserIsConsentedQuery = function getUserIsConsentedQuery(aaid) {
  return "\n  query USER_CONSENTED_QUERY {\n    unified {\n      consent {\n      getConsent(value: \"".concat(aaid, "\", type: \"AAID\") {\n        ... on UnifiedConsentStatus {\n          id\n          createdAt\n          type\n          updatedAt\n          value\n          consentObj {\n            consentKey\n            consentStatus\n            consenthubStatus\n            createdAt\n            updatedAt\n            uppConsentStatus\n          }\n        }\n        ... on UnifiedQueryError {\n          identifier\n          message\n          code\n        }\n      }\n    }\n  }\n}");
};