import { useState } from 'react';
import { Icon } from '@components/shared';
import { css } from '@emotion/react';
import { N800, N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

export interface InlineCodeProps {
  /** Code text to display **/
  code?: string;
}

/**
 * Component that renders a inline code in topic body
 * @param {InlineCodeProps} InlineCodeProps
 * @returns {React.ReactElement} React.ReactElement
 *
 * @example
 * <InlineCodeProps code="code" />
 */
export const InlineCode: React.FC<InlineCodeProps> = ({ code = '' }) => {
  const lines = code ? (code as string).split(/\r\n|\r|\n/) : null;

  const [isCopied, setIsCopied] = useState(false);

  const copy = (code: string): void => {
    navigator.clipboard.writeText(code);
    setIsCopied(true);
  };

  return (
    <code data-testid="code-block" css={inlineCodeStyles}>
      <button
        css={[
          inlineCodeButtonStyles,
          isCopied
            ? css({ backgroundColor: token('color.background.selected.bold.pressed'), border: '2px solid' })
            : css({
                backgroundColor: token('color.background.accent.gray.subtlest'),
                border: `2px solid ${token('color.border.inverse')}`,
                '&:hover': {
                  backgroundColor: token('color.background.accent.gray.subtlest.hovered'),
                  border: '2px solid transparent',
                },
              }),
        ]}
        aria-label="Copy"
        aria-haspopup="true"
        type="button"
        tabIndex={0}
        onClick={(): void => copy(code as string)}
        onMouseOut={(): void => setIsCopied(false)}
      >
        {/*This className is just for targeting by the button's hover>*/}
        <span className="tooltipText" css={tooltipTextStyles}>
          {isCopied ? 'Copied!' : 'Copy'}
        </span>
        <Icon type="copy" color={isCopied ? N0 : N800} />
      </button>
      <span>
        {lines?.map((line, index) => (
          <span css={codeLinesStyles} key={index}>
            <span css={lineNumberStyles}>{index + 1}</span>
            <span css={lineTextStyles}>{line}</span>
          </span>
        ))}
      </span>
    </code>
  );
};

const inlineCodeStyles = css({
  position: 'relative',
  display: 'flex',
  maxWidth: '100%',
  margin: '20px 0',
  flexDirection: 'column',
  borderRadius: '3px',
  overflowWrap: 'normal',
  backgroundColor: token('color.background.accent.gray.subtlest'),
});

const inlineCodeButtonStyles = css({
  position: 'absolute',
  right: '8px',
  top: '6px',
  display: 'flex',
  padding: '2px',
  borderRadius: '4px',
  transition: 'opacity 0.2s ease 0s',
  opacity: 0,
  zIndex: 1,
  cursor: 'pointer',

  '&:hover .tooltipText': {
    opacity: 1,
  },

  '&:hover': {
    opacity: 1,
  },
});

const tooltipTextStyles = css({
  position: 'absolute',
  top: '-30px',
  padding: '2px 6px',
  marginLeft: '-5px',
  borderRadius: '6px',
  backgroundColor: token('color.background.selected.bold.pressed'),
  color: token('color.text.inverse'),
  textAlign: 'center',
  fontSize: '11px',
  opacity: 0,
  zIndex: 1,
  transition: 'opacity 0.4s ease 0s',

  '&:hover': {
    opacity: 1,
  },
});

const codeLinesStyles = css({
  display: 'flex',
  lineHeight: '20px',
  whiteSpace: 'pre',
  fontSize: '12px',
});

const lineNumberStyles = css({
  padding: '4px 8px',
  marginRight: '8px',
  backgroundColor: token('color.background.accent.gray.subtler'),
});

const lineTextStyles = css({
  padding: '4px 8px 4px 0',
});
