import { findRouterContext } from '../../common/utils';
export const invokePluginLoad = (plugins, _ref) => {
  let {
    routes,
    history,
    basePath
  } = _ref;
  const context = findRouterContext(routes, {
    location: history.location,
    basePath
  });
  plugins.forEach(p => {
    p.routeLoad == null ? void 0 : p.routeLoad({
      context
    });
  });
};