import { InspectModeContentful } from '@types';
import { inspectorProps } from '@utils/index';
import { css, SerializedStyles } from '@emotion/react';
import { media } from '@atlaskit/primitives/responsive';
import { N0 } from '@atlaskit/theme/colors';
import React, { useCallback } from 'react';

export enum InputStatus {
  CORRECT = 'CORRECT',
  INCORRECT = 'INCORRECT',
  SELECTED = 'SELECTED',
  DEFAULT = 'DEFAULT',
  DISABLED = 'DISABLED',
}

export interface QuizInputsProps {
  /** entryId and fieldId from contentful for the inspector mode */
  inspectMode?: InspectModeContentful;
  text?: string;
  elementId?: string;
  inputStatus: InputStatus;
  children?: JSX.Element;
  onClick?: () => void;
}

export const QuizInputs: React.FC<QuizInputsProps> = ({
  inspectMode,
  text,
  elementId,
  inputStatus = InputStatus.DEFAULT,
  onClick,
}) => {
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>): void => {
      if (event.code === 'Space') {
        event.preventDefault();
        onClick && onClick();
      }
    },
    [onClick],
  );

  return (
    <div tabIndex={0} onKeyDown={handleKeyDown} css={[quizInputsContainerStyles, quizInputsVariantStyles[inputStatus]]}>
      <div
        css={[
          quizInputBorderStyles['DEFAULT'],
          quizInputBorderStyles[inputStatus],
          quizInputsVariantStyles[inputStatus],
        ]}
      ></div>
      <div
        {...inspectorProps('title', inspectMode)}
        id={elementId ? elementId + '-quiz-input' : ''}
        css={[quizInputsContentStyles, quizInputsVariantStyles[inputStatus]]}
        onClick={onClick}
      >
        <span css={quizInputsTextStyles}>{text}</span>
      </div>
    </div>
  );
};
const quizInputsContainerStyles = css({
  width: '50%',
  display: 'flex',
  cursor: 'pointer',
  borderRadius: '8px',
  boxShadow: '0px 1px 1px 0px rgba(9, 30, 66, 0.25), 0px 0px 1px 0px rgba(9, 30, 66, 0.31)',
  justifyContent: 'center',
});

const quizInputsContentStyles = css({
  maxWidth: '300px',
  width: '100%',
  padding: '16px',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  border: '1px solid rgba(9, 30, 66, 0.14)',
  borderLeft: 'none',
  borderRadius: '0 8px 8px 0',
});

const quizInputsTextStyles = css({
  fontFamily: 'Inter, sans-serif',
  fontSize: '16px',
  lineHeight: '24px',
  width: '100%',

  [media.below.xs]: {
    fontSize: '14px',
    lineHeight: '20px',
  },
});

const quizInputBorderStyles = {
  DEFAULT: css({
    maxHeight: '100%',
    width: '8px',
    borderRadius: '8px 0 0 8px',
    border: '1px solid rgba(9, 30, 66, 0.14)',
    borderRight: 'none',
    backgroundColor: N0,

    [media.below.xs]: {
      width: '4px',
    },
  }),

  CORRECT: css({
    backgroundColor: '#22A06B',
    border: '1px solid #22A06B',
  }),

  INCORRECT: css({
    backgroundColor: '#E2483D',
    border: '1px solid #E2483D',
  }),

  SELECTED: css({
    backgroundColor: '#0C66E4',
    border: '1px solid #0C66E4',
  }),
} as { [key: string]: SerializedStyles };

const quizInputsVariantStyles = {
  DEFAULT: css({
    color: '#000000',
    backgroundColor: N0,
    fontWeight: 400,
  }),

  CORRECT: css({
    fontWeight: 700,
  }),

  INCORRECT: css({
    fontWeight: 700,
  }),

  SELECTED: css({
    fontWeight: 700,
  }),

  DISABLED: css({
    color: '#626F86',
    fontWeight: 400,
    backgroundColor: '#F7F8F9',
    border: '1px solid #F7F8F9',
    boxShadow: 'none',
    transition: 'all',
    transitionDuration: '500ms',
    transitionTimingFunction: 'cubic-bezier(0, 0, 0.2, 1)',
    pointerEvents: 'none',
  }),
} as { [key: string]: SerializedStyles };
