import _extends from "@babel/runtime/helpers/extends";
import { getDefaultStateSlice } from './utils';
export const getSliceForResource = (state, props) => {
  const {
    type,
    key
  } = props;
  const slice = state.data[type] && state.data[type][key];
  return slice ? _extends({}, slice) : getDefaultStateSlice();
};
export const getResourceStoreContext = state => state.context;