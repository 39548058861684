import { useContext, type FunctionComponent } from 'react';
import Section from '../../common/Section';
import {
  ForumsQuestionLeft,
  ForumsQuestionRight,
  Gesture,
} from 'assets/images';
import { css } from '@compiled/react';
import { UserContext } from '../../../context/UserContext';
import { getForumsSignInUrl } from '../../../utils/url-generator';

const ForumsSection: FunctionComponent = () => {
  const data = useContext(UserContext);
  const user = data?.user;
  const isLoggedIn = !!(user);
  return (
    <Section
      id="forums"
      title="Community Forums"
      heading="Get advice and connect"
      description="Community Forums are great for getting the answers you need from experts and peers. Find solutions or ask your own question."
      buttonData={{
        href: isLoggedIn ? getForumsSignInUrl({
          redirectUrl: process.env.FORUMS_BASE_URL as string,
          email: user?.email,
        }) : process.env.FORUMS_BASE_URL as string,
        text: 'Explore Forums',
        target: '_self',
      }}
    >
      <div css={contentStyle}>
        <img
          css={forumsQuestionLeftStyle}
          src={ForumsQuestionLeft}
          alt="How do Confluence page and space permissions work together?"
        />
        <img
          css={forumsQuestionRightStyle}
          src={ForumsQuestionRight}
          alt="What’s your favorite way to display your full Jira portfolio for your executive teams?"
        />
        <img css={forumsGestureStyle} src={Gesture} />
      </div>
    </Section>
  );
};

export default ForumsSection;

const contentStyle = css({
  position: 'relative',
  width: '100%',
  maxWidth: '860px',
  minHeight: '400px',
  margin: '0 auto',
  '@media (max-width: 1279px)': {
    maxWidth: '640px',
    minHeight: '367px',
  },
  '@media (max-width: 767px)': {
    maxWidth: '375px',
    minHeight: '308px',
  },
});

const forumsQuestionLeftStyle = css({
  position: 'absolute',
  top: '0',
  left: '0',
  '@media (max-width: 1279px)': {
    left: '10px',
    width: '391px',
  },
  '@media (max-width: 767px)': {
    left: '5px',
    width: '280px',
  },
});

const forumsQuestionRightStyle = css({
  position: 'absolute',
  top: '155px',
  left: '360px',
  '@media (max-width: 1279px)': {
    top: '140px',
    left: '220px',
    width: '383px',
  },
  '@media (max-width: 767px)': {
    top: '100px',
     left: '66px', 
    width: '274px',
  },
});

const forumsGestureStyle = css({
  position: 'absolute',
  top: '120px',
  left: '720px',
  '@media (max-width: 1279px)': {
    top: '130px',
    left: '520px',
    width: '44px',
  },
  '@media (max-width: 767px)': {
    top: '20px',
    left: '260px',
  },
});
