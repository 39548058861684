import _extends from "@babel/runtime/helpers/extends";
import { DEFAULT_RESOURCE_MAX_AGE } from '../create-resource/constants';
import { getExpiresAt } from '../expires-at';
import { generateTimeGuard } from '../generate-time-guard';
import { TimeoutError } from '../timeout-error';
import { DEFAULT_PREFETCH_MAX_AGE } from './constants';
export function createLoadingSlice(_ref) {
  var _promiseOrData, _resource$maxAge;

  let {
    context,
    dependencies,
    options,
    resource,
    routerStoreContext
  } = _ref;
  const {
    type,
    getData
  } = resource;
  const {
    prefetch,
    timeout
  } = options; // hard errors in dependencies or getData are converted into softer async error

  let promiseOrData;

  try {
    promiseOrData = getData(_extends({}, routerStoreContext, {
      isPrefetch: !!prefetch,
      dependencies: dependencies()
    }), context);
  } catch (error) {
    promiseOrData = Promise.reject(error);
  } // ensure the promise includes any timeout error


  const timeoutGuard = timeout ? generateTimeGuard(timeout) : null; // check if getData was sync, by looking for a Promise-like shape

  const data = typeof ((_promiseOrData = promiseOrData) == null ? void 0 : _promiseOrData.then) === 'function' ? undefined : promiseOrData;
  const promise = timeout ? Promise.race([promiseOrData, timeoutGuard == null ? void 0 : timeoutGuard.promise]).then(maybeData => {
    if (timeoutGuard && !timeoutGuard.isPending) {
      throw new TimeoutError(type);
    }

    (timeoutGuard == null ? void 0 : timeoutGuard.timerId) && clearTimeout(timeoutGuard.timerId);
    return maybeData;
  }) : // if we already have a result, wrap it so consumers can access it via same API
  data !== undefined ? Promise.resolve(data) : promiseOrData;
  const resourceMaxAge = getExpiresAt((_resource$maxAge = resource.maxAge) != null ? _resource$maxAge : DEFAULT_RESOURCE_MAX_AGE);
  return {
    promise,
    data,
    expiresAt: prefetch ? Math.max(resourceMaxAge, getExpiresAt(DEFAULT_PREFETCH_MAX_AGE)) : resourceMaxAge
  };
}