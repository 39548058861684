import { NavBarLinkItem, NavBarLinkItemProps } from '@components/shared/NavigationBar/NavBarLinkItem';
import { CommunityApp } from '@components/community/constants';

export interface CommunityNavBarLinkItemProps extends NavBarLinkItemProps {
  destinationApp?: CommunityApp;
}

export const CommunityNavBarLinkItem: React.FC<CommunityNavBarLinkItemProps> = ({ ...rest }) => {
  return <NavBarLinkItem {...rest} />;
};
