import { useCallback, useRef } from 'react';
export const useTimeout = delay => {
  const timeoutId = useRef();
  const schedule = useCallback(callback => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    timeoutId.current = setTimeout(callback, delay);
  }, [delay]);
  const cancel = useCallback(() => {
    clearTimeout(timeoutId.current);
  }, []);
  return {
    schedule,
    cancel
  };
};