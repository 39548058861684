import React, { MouseEvent, MouseEventHandler, useCallback } from 'react';
import { LinkTarget } from '@types';
import { IconType, Icon } from '@components/index';
import { css } from '@emotion/react';
import { token } from '@atlaskit/tokens';
import { media } from '@atlaskit/primitives';

export interface LinkProps extends Omit<React.HTMLAttributes<HTMLAnchorElement>, 'style'> {
  /** Displaying text of the link */
  label: string;
  /** Specifies where the linked URL will be opened */
  target?: LinkTarget;
  /** css class that indicates the font color of the link */
  fontColor?: string;
  /** URL to be redirected to when the user clicks the hyperlink */
  link: string;
  /** Style of the hyperlink */
  style?: LinkStyle;
  /** Icon to be rendered in the hyperlink */
  icon?: IconType;
  /** Optional onClick handler */
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  /** Link size */
  size?: LinkSize;
}

/**
 * @description Style of the hyperlink
 * @enum {string} REGULAR | SEMIBOLD
 * @example LinkStyle.REGULAR
 */
export enum LinkStyle {
  REGULAR = 'regular',
  SEMIBOLD = 'semibold',
}

export enum LinkSize {
  LARGE = 'large',
  MEDIUM = 'medium',
}
/**
 * Hyperlink to redirect the user to another URL
 * @param {LinkProps} LinkProps
 * @returns {React.ReactElement} React.ReactElement
 *
 * @example
 * <Link label="Read more" />
 */
export const Link: React.FC<LinkProps> = ({
  label,
  fontColor = token('color.text.accent.blue'),
  target = '_self',
  link,
  style = LinkStyle.REGULAR,
  icon,
  onClick,
  size = LinkSize.MEDIUM,
  ...props
}) => {
  const clickHandler = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      e.stopPropagation();
      e.preventDefault();
      if (onClick) {
        onClick(e);
      }
    },
    [onClick],
  );

  const onKeyDown = (e: React.KeyboardEvent<HTMLAnchorElement>) => (handler: any) => {
    if (e.key === 'Enter' || e.key === ' ') {
      handler(e);
    }
  };

  return (
    <a
      target={target}
      href={link}
      rel={target === '_blank' ? 'noopener noreferrer' : ''}
      tabIndex={0}
      css={[
        sizeStyles[size],
        variantStyles[style].base,
        baseStyles,
        variantStyles[style].hover,
        css({ color: fontColor }),
      ]}
      style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}
      onClick={clickHandler}
      onKeyDown={(e): void => onKeyDown(e)(clickHandler)}
      {...props}
    >
      <span>{label}</span>
      {icon && (
        <span css={iconContainerStyles}>
          <Icon type={icon} size="medium" />
        </span>
      )}
    </a>
  );
};

const baseStyles = css({
  display: 'inline',
  alignItems: 'center',
  justifyContent: 'center',
  columnGap: '2px',
  textDecoration: 'none',

  [media.below.sm]: {
    fontSize: '16px !important',
    lineHeight: '20px !important',
  },
});

const sizeStyles = {
  medium: {
    fontSize: '16px',
    lineHeight: '24px',
  },

  large: {
    fontSize: '20px',
    lineHeight: '24px',
  },
};

const variantStyles = {
  regular: {
    base: css({
      fontWeight: 'normal',
    }),
    hover: css({
      '&:hover': {
        color: token('color.link'),
        textDecoration: 'underline',
        cursor: 'pointer',
        textUnderlineOffset: '4px',
        textDecorationThickness: '2px',
        textDecorationColor: token('color.link'),
      },
    }),
  },

  semibold: {
    base: css({
      fontWeight: 600,
    }),
    hover: css({
      '&:hover': {
        color: token('color.link'),
        textDecoration: 'underline',
        cursor: 'pointer',
        textUnderlineOffset: '4px',
        textDecorationThickness: '2px',
        textDecorationColor: token('color.link'),
      },
    }),
  },
};

const iconContainerStyles = {
  display: 'inline',
  verticalAlign: 'middle',
};
