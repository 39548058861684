/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React, { Fragment } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
var buttonGroupStyles = css({
  display: 'inline-flex',
  gap: "var(--ds-space-050, 4px)",
  // eslint-disable-next-line @atlaskit/design-system/no-nested-styles, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  '> *': {
    flex: '1 0 auto'
  }
});
export default function ButtonGroup(_ref) {
  var children = _ref.children,
    testId = _ref.testId,
    label = _ref.label,
    titleId = _ref.titleId;
  return jsx("div", {
    css: buttonGroupStyles,
    "data-testid": testId,
    role: "group",
    "aria-label": label,
    "aria-labelledby": titleId
  }, React.Children.map(React.Children.toArray(children), function (child, idx) {
    if (!child) {
      return null;
    }
    return jsx(Fragment, {
      key: idx
    }, child);
  }));
}