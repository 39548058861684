import { InspectModeContentful } from '@types';
import { inspectorProps } from '@utils/index';
import { css } from '@emotion/react';
import { media } from '@atlaskit/primitives/responsive';
import { N50 } from '@atlaskit/theme/colors';
import { PopupShare } from '../PopupShare';
import { Button, ButtonAppearance, Divider, Heading, Icon, Tag, tagListStyles } from '@components/shared';
import { token } from '@atlaskit/tokens';
import { ContentType } from '@components/learning/types';

export enum ProgressStatus {
  NOT_STARTED = 'not_started',
  IN_PROGRESS = 'in_progress',
  FINISHED = 'finished',
}

export const defineProgressStatus = (progressStatus: string, contentTypeLabel: string): string => {
  switch (progressStatus) {
    case ProgressStatus.NOT_STARTED:
      return `Start ${contentTypeLabel}`;
    case ProgressStatus.IN_PROGRESS:
      return `Continue ${contentTypeLabel}`;
    case ProgressStatus.FINISHED:
      return `Review ${contentTypeLabel}`;
    default:
      return `Start ${contentTypeLabel}`;
  }
};

export interface LearningHeaderProps {
  /** entryId and fieldId from contentful for the inspector mode */
  inspectMode?: InspectModeContentful;
  /** Tag which indicates whether this  Course page or LearningPath page */
  contentType: ContentType;
  /** progressStatus indicates the progress state of learning path or course*/
  progressStatus: ProgressStatus;
  /** teaser of the course/learning path */
  tagLine: string;
  /** Main title */
  heading: string;
  /** Indicates whether user is logged in or not */
  anonymousView?: boolean;
  /** Url of the course to be share */
  urlToShare: string;
  /** Function to be executed on click sign in button  */
  onSignIn?: () => void;
  /** Function to be executed on start course*/
  onStartCourse?: () => void;
  /** Indicates loading state for sign in button */
  signInButtonLoading?: boolean;
  /** Indicates loading state for start course button */
  startButtonLoading?: boolean;
  /** Indicates user loading state for loading button */
  isLoading: boolean;
  /** Indicates when bookmark icon should be fulfilled */
  isSaved?: boolean;
  /** Function to be executed on save button */
  onSave?: () => void;
  /** Indicates when feedback button is shown*/
  shouldShowFeedback?: boolean;
  /** Handles navigation to the feedback page*/
  onStartFeedback?: () => void;
}

/**
 * Header that describes the course
 * @param {LearningHeaderProps} LearningHeaderProps
 * @returns {React.ReactElement} React.ReactElement
 *
 * @example
 * <LearningHeader heading="title" />
 */
export const LearningHeader: React.FC<LearningHeaderProps> = ({
  inspectMode,
  heading,
  anonymousView = true,
  urlToShare,
  startButtonLoading,
  signInButtonLoading,
  onSignIn,
  onStartCourse,
  isLoading,
  contentType,
  progressStatus,
  tagLine,
  isSaved,
  onSave,
  shouldShowFeedback,
  onStartFeedback,
}: LearningHeaderProps): React.ReactElement => {
  const handleSignIn = (): void => {
    if (onSignIn) {
      onSignIn();
    }
  };
  const handleStartCourse = (): void => {
    if (onStartCourse) {
      onStartCourse();
    }
  };
  const handleOnSave = (): void => {
    if (onSave) {
      onSave();
    }
  };

  const contentTypeLabel = contentType === ContentType.COURSE ? 'course' : 'learning path';
  const status = defineProgressStatus(progressStatus, contentTypeLabel);

  return (
    <section data-testid="learning-header" css={learningHeaderContainerStyles}>
      <div css={[tagListStyles]}>
        <Tag label={contentTypeLabel} />
      </div>
      <div css={css({ margin: `${token('space.300')} 0` })} {...inspectorProps('title', inspectMode)}>
        <Heading level={1}>{heading}</Heading>
      </div>
      <div>
        <p css={tagLineStyles}>{tagLine}</p>
      </div>
      <div css={css({ marginTop: token('space.300'), marginBottom: token('space.400') })}>
        <Divider color={N50} />
      </div>
      <div
        css={css({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        })}
      >
        {isLoading ? (
          <Button label="" isLoading={isLoading} />
        ) : anonymousView ? (
          <div css={css({ display: 'flex' })}>
            <Button
              label="Sign In"
              appearance={ButtonAppearance.PRIMARY}
              onClick={handleSignIn}
              isLoading={signInButtonLoading}
            />
            <div css={css({ marginLeft: token('space.200') })}>
              <Button
                label={status}
                appearance={ButtonAppearance.SECONDARY}
                onClick={handleStartCourse}
                isLoading={startButtonLoading}
              />
            </div>
          </div>
        ) : (
          !anonymousView && (
            <div css={css({ display: 'flex' })}>
              <Button
                label={status}
                appearance={ButtonAppearance.PRIMARY}
                onClick={handleStartCourse}
                isLoading={startButtonLoading}
              />
              {shouldShowFeedback && (
                <div css={css({ marginLeft: token('space.200') })}>
                  <Button
                    label="Take survey"
                    appearance={ButtonAppearance.SECONDARY}
                    onClick={onStartFeedback}
                    isLoading={startButtonLoading}
                  />
                </div>
              )}
            </div>
          )
        )}
        <div css={css({ display: 'flex' })}>
          {!anonymousView && (
            <button
              css={learningCourseCardBookmarkContainerStyles}
              onClick={handleOnSave}
              aria-label={isSaved ? 'unsave' : 'save'}
            >
              {isSaved ? <Icon type="bookmark-filled" /> : <Icon type="bookmark" />}
            </button>
          )}
          <PopupShare urlToShare={urlToShare} alignToLeft />
        </div>
      </div>
    </section>
  );
};

const learningHeaderContainerStyles = css({
  width: '100%',
  paddingTop: `${token('space.600')}`,
  paddingBottom: `${token('space.400')}`,

  [media.above.lg]: {
    paddingTop: token('space.1000'),
    paddingBottom: `${token('space.400')}`,
  },
});

const tagLineStyles = css({
  color: token('color.text'),
  fontFamily: 'Inter, sans-serif',
  fontSize: '20px',
  fontWeight: 400,
  lineHeight: '32px',
});

const learningCourseCardBookmarkContainerStyles = css({
  height: '24px',
  border: 'none',
  background: 'none',
  cursor: 'pointer',
  outline: 'none',
});
