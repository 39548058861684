export const generateTimeGuard = duration => {
  const promiseState = {
    timerId: null,
    isPending: true,
    promise: undefined
  };
  promiseState.promise = new Promise(r => {
    const timerId = setTimeout(() => {
      promiseState.isPending = false;
      r();
    }, duration);
    promiseState.timerId = timerId;
  });
  return promiseState;
};