/**
 * This component wraps page components, sending analytics events (e.g. screen - page viewed), and setting
 * Atlassian user info for the analytics client.
 */
import React, {ReactNode, useContext, useEffect } from 'react';
import { UserContext } from 'context/UserContext';
import { sendScreenEvent } from 'services/analytics';

/**
 * Props for the PageWithAnalytics component
 * @property {string} eventScreenName - the name of the screen to send the page viewed event for
 */
interface PageWithAnalyticsProps {
  eventScreenName: string;
  children: ReactNode;
}
export const PageWithAnalytics: React.FC<PageWithAnalyticsProps> = ({
  eventScreenName,
  children,
}) => {
  const user = useContext(UserContext);
  useEffect(() => {
    sendScreenEvent(eventScreenName, {}, user?.user?.account_id);
  }, [eventScreenName, user?.user?.account_id]);
  return <>{children}</>;
};
