import React from 'react';
import { css } from '@emotion/react';
import { TextFieldProps } from '@atlaskit/textfield';

export const TextField: React.FC<TextFieldProps> = ({
  isInvalid = false,
  onChange = (): void | null => {
    return null;
  },
  value,
  isDisabled = false,
  isRequired = false,
  ...rest
}) => (
  <input
    css={[inputStyles, isInvalid && invalidStyles, isDisabled && disabledStyles]}
    onChange={onChange}
    disabled={isDisabled}
    value={value}
    required={isRequired}
    {...rest}
    role="input"
  ></input>
);

const inputStyles = css({
  width: '100%',
  height: '42px',
  fontWeight: 400,
  display: 'flex',
  padding: '8px 12px',
  alignItems: 'center',
  gap: '6px',
  alignSelf: 'stretch',
  borderRadius: '4px',
  border: '1px solid #8590A2',
  background: '#FFFFFF',
  fontFamily: 'inter',
  fontSize: '16px',
  '&:hover': {
    background: '#F7F8F9',
  },
  '&:focus': {
    borderColor: 'inherit',
    boxShadow: 'none',
    border: '2px solid #388BFF',
    margin: '-1px',
  },
});

const invalidStyles = css({
  border: '2px solid #C9372C',
  margin: '-1px',
});

const disabledStyles = css({
  color: '#091E424F',
  border: '1px solid #091E420F',
  background: '#091E4208',
});
