"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "InitializeAutoAtlAnalytics", {
  enumerable: true,
  get: function get() {
    return _initializeAutoAtlAnalytics.InitializeAutoAtlAnalytics;
  }
});
var _initializeAutoAtlAnalytics = require("./initialize-auto-atl-analytics");