import React from 'react';
import { Heading, HeadingProps, ImageProps, Link, LinkSize, LinkStyle, Text } from '@components/shared';

import { css } from '@emotion/react';
import { token } from '@atlaskit/tokens';
import { media } from '@atlaskit/primitives';
import { LinkTarget } from '@types';

type FlatContentCardImage = Omit<ImageProps, 'height' | 'width'>;

export enum FlatContentCardStyle {
  TALL = 'tall',
  FULL = 'full',
}

export enum FlatContentCardType {
  ROUND = 'round',
  SQUARE = 'square',
}

export interface FlatContentCardPropsBase {
  /** Title of the Card */
  title: string;
  /** Content text */
  text?: string;
  /** Link that is used to redirect */
  link: string;
  /** Label for the link */
  linkLabel?: string;
  /** Style of the card */
  style?: FlatContentCardStyle;
  /** Type of the card */
  type?: FlatContentCardType;
  /** Level of heading */
  headingLevel?: HeadingProps['level'];
  /** Background color */
  bgColor?: string;
  /** Function to be called when learn more link is clicked */
  onClick?: (title: string) => void;
  /** Specifies where the linked URL will be opened */
  target?: LinkTarget;
  /** Custom max width */
  maxWidth?: number;
}

export interface SquareFlatContentCardProps extends FlatContentCardPropsBase {
  /** Image component rendered in the component */
  image: FlatContentCardImage;
}

export type FlatContentCardProps = FlatContentCardPropsBase &
  (
    | ({ type: FlatContentCardType.SQUARE } & SquareFlatContentCardProps)
    | ({ type?: FlatContentCardType } & { image?: FlatContentCardImage })
  );

/**
 * Component that renders a card used in different pages
 * @param {FlatContentCardPropsBase} FlatContentCardPropsBase
 * @returns {React.ReactElement} React.ReactElement
 *
 * @example
 * <FlatContentCard title="Card title" text="Learn more card text" link="https://atlassian.com" style='tall' />
 */

export const FlatContentCard: React.FC<FlatContentCardProps> = ({
  image,
  title,
  text,
  link,
  linkLabel = 'Learn more',
  style = FlatContentCardStyle.TALL,
  type = FlatContentCardType.ROUND,
  headingLevel,
  bgColor = 'initial',
  onClick,
  target = '_self',
  maxWidth,
}) => {
  const roundStyles = image ? variantStyles[style].container.withImage : variantStyles[style].container.withoutImage;
  const additionalContainerStyles = type === FlatContentCardType.SQUARE ? squareTypeStyles.container : roundStyles;
  const defaultHeadingLevel = type === FlatContentCardType.SQUARE ? 3 : 4;

  const handleClick = (title: string): void => {
    onClick && onClick(title);
  };

  return (
    <div
      css={[
        containerStyles,
        additionalContainerStyles,
        css({ backgroundColor: bgColor }),
        maxWidth && css({ maxWidth }),
      ]}
      data-testid="learn-more-card"
    >
      {image && (
        <img
          src={image.src}
          alt={image.alt}
          css={[
            imageBaseStyles,
            type === FlatContentCardType.SQUARE && imageSquareStyles,
            style === FlatContentCardStyle.FULL && fullStyleImageStyles,
          ]}
        />
      )}
      <div css={[contentContainerStyles, type === FlatContentCardType.SQUARE && contentContainerSquareStyles]}>
        <div
          css={css([
            textStyles,
            variantStyles[style].heading,
            type === FlatContentCardType.SQUARE && squareTypeStyles.heading,
          ])}
        >
          <Heading level={headingLevel || defaultHeadingLevel} children={title} fontWeight={600} />
        </div>
        {type === FlatContentCardType.ROUND && (
          <div css={[css([textStyles, variantStyles[style].text, textContainerStyles])]}>
            <Text children={text} />
          </div>
        )}
        <Link
          label={linkLabel}
          link={link}
          onClick={(): void => handleClick(title)}
          icon="arrow-right"
          fontColor={token('color.text.brand')}
          style={LinkStyle.SEMIBOLD}
          size={LinkSize.LARGE}
          target={target}
        />
      </div>
    </div>
  );
};

const containerStyles = css({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  padding: `${token('space.400')} 0`,

  [media.below.sm]: {
    padding: `${token('space.300')} 0`,
  },
});

const textStyles = css({
  [media.above.md]: {
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    'text-overflow': 'ellipsis',
  },
});

const imageBaseStyles = css({
  height: '80px',
  objectFit: 'contain',
  borderRadius: '50%',
  marginBottom: token('space.200'),
  maxWidth: '100%',
});

const imageSquareStyles = css({
  borderRadius: 0,
  marginBottom: token('space.300'),
});

const fullStyleImageStyles = css({
  width: '100%',
  height: 'auto',
  maxHeight: '260px',
  marginBottom: token('space.500'),
  borderRadius: 'initial',
});

const variantStyles = {
  [FlatContentCardStyle.TALL]: {
    container: {
      withImage: css({
        [media.above.md]: {
          height: '352px',
        },
      }),
      withoutImage: css({
        [media.above.md]: {
          height: '264px',
        },
      }),
    },
    heading: css({
      [media.above.md]: {
        '-webkit-line-clamp': '2',
      },
    }),
    text: css({
      [media.above.md]: {
        '-webkit-line-clamp': '3',
      },
    }),
  },
  [FlatContentCardStyle.FULL]: {
    container: {
      withImage: css({
        [media.above.md]: {
          height: 'auto',
        },
      }),
      withoutImage: css({
        [media.above.md]: {
          height: 'auto',
        },
      }),
    },
    heading: css({
      [media.above.md]: {
        '-webkit-line-clamp': 'none',
      },
    }),
    text: css({
      [media.above.md]: {
        '-webkit-line-clamp': 'none',
      },
    }),
  },
};

const squareTypeStyles = {
  container: css({
    backgroundColor: token('color.background.input'),
    padding: token('space.300'),
    height: '320px',
    maxWidth: '324px',
    borderRadius: '8px',

    [media.below.md]: {
      minHeight: '360px',
      maxWidth: 'unset',
      height: '100%',
    },

    [media.below.sm]: {
      minHeight: '264px',
      padding: token('space.300'),
    },
  }),
  heading: css({
    marginBottom: token('space.300'),
  }),
};

const contentContainerStyles = css({
  marginBottom: token('space.025'),
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
});

const contentContainerSquareStyles = css({
  justifyContent: 'space-between',

  a: {
    fontSize: '20px',
    lineHeight: '24px',
  },
});

const textContainerStyles = css({
  marginTop: token('space.100'),
  marginBottom: token('space.400'),

  [media.below.sm]: {
    marginBottom: token('space.300'),
  },
});
