import React from 'react';
import { css } from '@emotion/react';
import { BREAKPOINTS } from '@constants/breakpoints';
import { token } from '@atlaskit/tokens';
import { AtlassianIcon, AtlassianLogo } from '@atlaskit/logo';

export interface NavBarLogoItemProps {
  /** @type {string} the href to redirect to when the subtitle is clicked */
  subtitleHref: string;
  /** @type {string} the label to be used for screen readers */
  label: string;
  /** @type {string} the subtitle to be displayed next to the Atlassian logo (Desktop & Mobile view).*/
  logoSubtitle: string;
}

export const AtlassianNavLogo: React.FC<NavBarLogoItemProps> = ({ subtitleHref, label, logoSubtitle }) => {
  return (
    <span css={containerStyles}>
      <a href="https://www.atlassian.com" target="_blank" rel="noopener noreferrer">
        <span css={smallAtlassianLogoStyles}>
          <AtlassianIcon testId="mobile" />
        </span>
        <span css={bigAtlassianLogoStyles}>
          <AtlassianLogo testId="desktop" />
        </span>
      </a>
      <span css={dividerStyles}></span>
      <a href={subtitleHref} css={subtitleStyles} data-testid="logoSubtitle" aria-label={label}>
        <span> {logoSubtitle} </span>
      </a>
    </span>
  );
};

const containerStyles = css({
  display: 'flex',
  alignItems: 'center',
  background: 'none',
  fontFamily: 'Charlie Display',
});

const smallAtlassianLogoStyles = css({
  [`@media (max-width: ${BREAKPOINTS.TABLET.MAX_WIDTH}px)`]: {
    display: 'flex',
    alignItems: 'center',
    svg: {
      color: token('color.icon.brand'),
      transform: 'scale(0.74)', // approximately 17 x 17px excluding padding
    },
  },
  [`@media (min-width: ${BREAKPOINTS.DESKTOP.MIN_WIDTH}px)`]: {
    display: 'none',
  },
});

const bigAtlassianLogoStyles = css({
  [`@media (min-width: ${BREAKPOINTS.DESKTOP.MIN_WIDTH}px)`]: {
    display: 'flex',
    alignItems: 'center',
    span: {
      width: '148px',
      height: '24px',
    },
    svg: {
      color: token('color.icon.brand'),
    },
  },
  [`@media (max-width: ${BREAKPOINTS.TABLET.MAX_WIDTH}px)`]: {
    display: 'none',
  },
});

const dividerStyles = css({
  width: '1px',
  height: '32px',
  backgroundColor: '#DDDEE1',
  marginLeft: '24px',
  marginRight: '16px',
  [`@media (max-width: ${BREAKPOINTS.TABLET.MAX_WIDTH}px)`]: {
    marginLeft: '8px',
    marginRight: '16px',
  },
});

const subtitleStyles = css({
  fontSize: '22px',
  fontWeight: 600,
  color: token('color.text'),
  textDecoration: 'none',
  padding: '6px 8px',
  '&:hover': {
    color: token('color.border.brand'),
    textDecoration: 'none',
  },
});
