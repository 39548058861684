import React from 'react';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@atlaskit/modal-dialog';
import Button from '@atlaskit/button/new';
import { Text } from '@atlaskit/primitives';
import { css } from '@emotion/react';
import { AtlassianLogo } from '@atlaskit/logo';
import { token } from '@atlaskit/tokens';
import { ButtonAppearance, Heading } from '@components/shared';

interface LicensesProps {
  packageName: string;
  version: string;
  description: string;
  link: string;
}

export interface LicenseModalProps {
  /** modal title */
  modalTitle?: string;
  /** modal body title */
  bodyTitle?: string;
  /** modal body description */
  bodyDescription?: React.ReactElement;
  /** close modal handler */
  closeModal?: () => void;
  /** (optional) list of licenses to show in the modal window */
  licenses?: LicensesProps[];
}

export const LicenseModal: React.FC<LicenseModalProps> = ({
  modalTitle,
  bodyTitle,
  bodyDescription,
  closeModal,
  licenses,
}) => {
  return (
    <Modal onClose={closeModal} shouldScrollInViewport={false}>
      <ModalHeader>
        {modalTitle && (
          <Heading level={3} fontWeight={600}>
            {modalTitle}
          </Heading>
        )}
      </ModalHeader>
      <ModalBody>
        <span css={css({ color: token('color.icon.brand') })}>
          <AtlassianLogo testId="desktop" />
        </span>
        <div css={spacingContainerStyles}>
          <Heading level={4} fontWeight={500}>
            {bodyTitle}
          </Heading>
        </div>
        <div css={spacingContainerStyles}>
          <Text>{bodyDescription}</Text>
        </div>
        <div css={spacingContainerStyles}>
          <Heading level={4} fontWeight={500}>
            Attributions for Open Source Libraries
          </Heading>
        </div>
        <div css={spacingContainerStyles}>
          {licenses?.length &&
            licenses.map((license) => (
              <div css={css({ padding: `${token('space.100')} 0` })}>
                <Text key={license.packageName}>
                  <a css={licenseTitleStyles} href={license.link}>{`${license.packageName} ${license.version}`}</a>
                  <p css={css({ margin: 0 })}>{license.description}</p>
                </Text>
              </div>
            ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button appearance={ButtonAppearance.PRIMARY} onClick={closeModal}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const spacingContainerStyles = css({
  padding: `${token('space.100')} 0`,
});

const licenseTitleStyles = css({
  fontWeight: 600,
});
