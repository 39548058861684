import { DEFAULT_CACHE_MAX_LIMIT, DEFAULT_RESOURCE_BROWSER_ONLY, DEFAULT_RESOURCE_MAX_AGE } from './constants';
/**
 * Utility method to created async versions of getData functions
 */

const handleGetDataLoader = asyncImport => async function () {
  const {
    default: getDataFn
  } = await asyncImport();
  return getDataFn(...arguments);
};

export const createResource = arg => {
  var _getData, _arg$depends;

  return {
    type: arg.type,
    getKey: arg.getKey,
    getData: (_getData = arg.getData) != null ? _getData : handleGetDataLoader(arg.getDataLoader),
    maxAge: typeof arg.maxAge === 'number' ? arg.maxAge : DEFAULT_RESOURCE_MAX_AGE,
    maxCache: typeof arg.maxCache === 'number' ? arg.maxCache : DEFAULT_CACHE_MAX_LIMIT,
    isBrowserOnly: typeof arg.isBrowserOnly === 'boolean' ? arg.isBrowserOnly : DEFAULT_RESOURCE_BROWSER_ONLY,
    depends: (_arg$depends = arg.depends) != null ? _arg$depends : null
  };
};