import React, { useCallback, useEffect, useRef } from 'react';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import { css, SerializedStyles } from '@emotion/react';
import { token } from '@atlaskit/tokens';

export interface CommonModalProps {
  isOpen: boolean;
  toggleModal: (isOpen: boolean) => void;
  modalDescriptionLabel: string;
  children?: React.ReactNode;
  styles?: SerializedStyles;
}

export const CommonModal: React.FC<CommonModalProps> = ({
  isOpen,
  toggleModal,
  modalDescriptionLabel: modalDescription,
  children,
  styles,
}) => {
  const closeModal = useCallback((e: unknown) => {
    (e as Event)?.stopPropagation();
    toggleModal(false);
  }, []);
  const modalRef = useRef<HTMLDivElement>(null);
  const focusOnOpen = useCallback(() => {
    if (isOpen) {
      modalRef?.current?.focus();
    }
  }, []);

  useEffect(() => {
    focusOnOpen();
    const handleEsc = (event: KeyboardEvent): void => {
      if (event.key === 'Escape') {
        toggleModal(false);
      }
    };
    document.addEventListener('keyup', handleEsc, false);

    return () => {
      document.removeEventListener('keyup', handleEsc, false);
    };
  }, []);

  useEffect(() => {
    focusOnOpen();
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    isOpen && (
      <div css={[backdropStyles, styles && styles]}>
        <div
          aria-modal={true}
          role="dialog"
          ref={modalRef}
          aria-label={modalDescription}
          tabIndex={-1}
          css={modalStyles}
        >
          <button type="button" css={closeButtonStyles} onClick={closeModal}>
            <CrossIcon label="Close Modal" size="medium" primaryColor={token('color.icon')} />
          </button>
          <div css={modalSlotStyles}>{children}</div>
        </div>
      </div>
    )
  );
};

const backdropStyles = css({
  '&, *': {
    boxSizing: 'border-box',
  },
  backgroundColor: 'gray',
  position: 'fixed',
  padding: '16px',
  width: '100vw',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  left: 0,
  top: 0,
  zIndex: 100000000,
});

const modalStyles = css({
  position: 'relative',
  padding: '16px',
  paddingTop: '48px',
  maxWidth: '100%',
  maxHeight: '100%',
  borderRadius: '8px',
  backgroundColor: token('color.background.input'),
  boxShadow: '0px 0px 1px 0px rgba(9, 30, 66, 0.31), 0px 1px 1px 0px rgba(9, 30, 66, 0.25)',
  zIndex: 200000000,
});

const closeButtonStyles = css({
  position: 'absolute',
  top: '16px',
  right: '16px',
  border: 'none',
  outline: 'none',
  background: 'transparent',
  padding: 0,
  cursor: 'pointer',
  borderRadius: '4px',
  '&:hover': {
    backgroundColor: token('color.background.neutral.subtle.hovered'),
  },
  '&:focus svg': {
    color: token('color.icon.selected'),
  },
  '&:active': {
    backgroundColor: token('color.background.neutral.subtle.pressed'),
  },
  '&:disabled': {
    backgroundColor: token('color.background.accent.gray.subtle.hovered'),
  },
});

const modalSlotStyles = css({
  minWidth: '100px',
  minHeight: '25px',
  fontSize: '16px',
});
