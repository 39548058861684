"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("../constants");
function canWriteCookie() {
    try {
        document.cookie = "cookietest=1";
        if (document.cookie.indexOf("cookietest=") !== -1) {
            document.cookie = "cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT";
            return constants_1.YES;
        }
        return constants_1.NO;
    }
    catch (t) {
        return constants_1.ERROR;
    }
}
exports.canWriteCookie = canWriteCookie;
