import React from 'react';
import { css } from '@emotion/react';
import { Tag, TagProps } from '@components/shared';
import { LABELS_GAP_PX, BODY_PADDING, MAX_TAGS_QTY } from '../constants';

interface TagsContainerProps {
  /** Array of tag objects to be displayed */
  tags: TagProps[];
  /** Width of the last tag, used for truncation when container width is exceeded */
  lastLabelWidth: number | string;
}

export const TagsContainer: React.FC<TagsContainerProps> = ({ tags, lastLabelWidth }) =>
  tags.length > 0 && (
    <div css={labelContainerStyles} id="tabs-container">
      {tags.slice(0, MAX_TAGS_QTY).map((tag, index, arr) => (
        <div
          css={index === arr.length - 1 && css([{ '& > div': { width: lastLabelWidth } }, truncatedLabelStyles])}
          key={tag.label + index}
        >
          <Tag {...tag} />
        </div>
      ))}
    </div>
  );

const labelContainerStyles = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: `${LABELS_GAP_PX}px`,
  padding: `0 ${BODY_PADDING}px`,
  overflow: 'hidden',
});

const truncatedLabelStyles = css({
  '& > div': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
  },
});
