import { FunctionComponent } from 'react';
import { css } from '@compiled/react';
import {
  HeroGALeftCluster,
  HeroGARightCluster,
  HeroGAMobileBottomCluster,
  HeroGAMobileTopCluster,
} from 'assets/images';
import HeroHeadings from "components/common/HeroHeadings";

const HomepageHero: FunctionComponent = () => {
  const headerText = 'Create your best work life, together';
  const subheaderText =
    'Learn, connect, and grow with the Atlassian Community.';
  return (
    <section css={heroBackgroundStyle}>
      <img src={HeroGALeftCluster} css={heroLeftClusterStyle} />
      <img src={HeroGARightCluster} css={heroRightClusterStyle} />
      <img src={HeroGAMobileTopCluster} css={heroMobileTopClusterStyle} />
      <img src={HeroGAMobileBottomCluster} css={heroMobileBottomClusterStyle} />
      <div css={heroContentStyle}>
        <HeroHeadings headerText={headerText} subheaderText={subheaderText} />
      </div>
    </section>
  );
};

const heroBackgroundStyle = css({
  height: '580px',
  backgroundColor: '#F8F8F8',
  clipPath: 'polygon(0 0, 100% 0, 100% 90%, 0% 100%)',
  paddingTop: '68px' /* offsets header */,
  marginBottom: '-230px',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '50%',
  position: 'relative',
  overflow: 'hidden',
  '@media (max-width: 1279px)': {
    height: '631px',
  },
});

const heroContentStyle = css({
  width: '600px',
  height: '420px',
  display: 'flex',
  margin: '0 auto',
  marginTop: '-56px',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  '@media (max-width: 1279px)': {
    marginTop: '-36px',
    maxWidth: '100%',
  },
});

/* images positioning/sizing */
const heroLeftClusterStyle = css({
  width: '480px',
  position: 'absolute',
  left: '-120px',
  top: '-120px',
  '@media (max-width: 1279px)': {
    display: 'none',
  },
});

const heroRightClusterStyle = css({
  width: '480px',
  position: 'absolute',
  right: '-100px',
  top: '-100px',
  '@media (max-width: 1279px)': {
    display: 'none',
  },
});

const heroMobileTopClusterStyle = css({
  display: 'none',
  width: '600px',
  position: 'absolute',
  top: '-30px',
  left: '0',
  transition: 'all .5s ease-in-out',
  '@media (max-width: 1279px)': {
    display: 'block',
  },
  '@media (max-width: 767px)': {
    width: '500px',
    top: '-20px',
    left: '0',
  },
});

const heroMobileBottomClusterStyle = css({
  display: 'none',
  width: '550px',
  position: 'absolute',
  bottom: '-100px',
  right: '0',
  transition: 'all .5s ease-in-out',
  '@media (max-width: 1279px)': {
    display: 'block',
  },
  '@media (max-width: 767px)': {
    width: '500px',
    bottom: '-90px',
    right: '-100px',
  },
});

export default HomepageHero;
