import React, { MouseEventHandler, ReactNode } from 'react';
import { css } from '@emotion/react';
import { useMediaQuery } from '@hooks/useMediaQuery';
import { BREAKPOINTS } from '@constants/breakpoints';
import { NavBarExpandableItem } from '@components/shared/NavigationBar/NavBarLinkItem/NavBarExpandableItem';
import { token } from '@atlaskit/tokens';

export interface NavBarLinkItemProps {
  /* CSS class name to be applied to the component */
  className?: string;
  /** True if the link is selected (ie. user is currently on this page) */
  isSelected?: boolean;
  /** @type {Link} the link object to be displayed */
  link: Link;
  /** (optional) Function to be called when the link is clicked */
  onClick?: MouseEventHandler<HTMLElement>;
  /** (optional) Content to be displayed if/when the link is expanded, only in mobile/tablet view */
  expandableBodyContent?: ReactNode;
}

// TODO: Extract Link to a shared location
export interface Link {
  text: string;
  href?: string;
  onClick?: MouseEventHandler<HTMLElement>;
  target?: string;
  id?: string;
}

export interface LinkButtonProps {
  /** @type {Link} the link object to be displayed */
  link: Link;
  /** True if the button should redirect to a new page defined in Link */
  shouldRedirect?: boolean;
  /** True if the button is selected (ie. user is currently on this page) */
  isSelected?: boolean;
}

export const LinkButton: React.FC<LinkButtonProps> = ({ link, shouldRedirect, isSelected }) => {
  const { text, href, onClick, target } = link;
  return (
    <a
      css={[linkStyles.default, isSelected && linkStyles.selected]}
      href={shouldRedirect ? href : undefined}
      onClick={onClick}
      target={target || '_self'}
    >
      {text}
    </a>
  );
};

export const NavBarLinkItem: React.FC<NavBarLinkItemProps> = ({ isSelected = false, link, expandableBodyContent }) => {
  const isBreakpoint = useMediaQuery(`(max-width: ${BREAKPOINTS.TABLET.MAX_WIDTH}px)`);
  if (isBreakpoint) {
    return (
      <NavBarExpandableItem
        headerContent={<LinkButton link={link} isSelected={isSelected} shouldRedirect={!expandableBodyContent} />}
        bodyContent={expandableBodyContent}
      />
    );
  }
  return (
    <div css={buttonDivStyles}>
      <LinkButton link={link} isSelected={isSelected} shouldRedirect={true} />
    </div>
  );
};

const buttonDivStyles = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px 12px',
});

const linkStyles = {
  default: css({
    color: token('color.text'),
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '20px', // center vertically within parent div
    '&:hover': {
      color: token('color.text.selected'),
      textDecoration: 'none',
    },
    '&:active': {
      color: token('color.text.information'),
    },
    [`@media (max-width: ${BREAKPOINTS.TABLET.MAX_WIDTH}px)`]: {
      '&:hover, &:active': {
        color: token('color.text.brand'),
        textDecoration: 'none',
      },
    },
  }),
  selected: css({
    [`@media (min-width: ${BREAKPOINTS.DESKTOP.MIN_WIDTH}px)`]: {
      color: token('color.text.selected'),
    },
  }),
};
