import React from 'react';

import { css } from '@emotion/react';

import { InspectModeContentful } from '@types';
import { inspectorProps } from '@utils/index';
import { media } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

export interface HeadingProps {
  /** Text to be displayed */
  children: React.ReactNode;
  /** Level of the heading 1-4 */
  level: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  /** entryId and fieldId from contentful for the inspector mode */
  inspectMode?: InspectModeContentful;
  /** To provide a reference point or anchor that can be used for navigation */
  id?: string;
  color?: string;
  /** To provide a specific font weight */
  fontWeight?: number;
}

/**
 * Displays heading
 * @param {Heading} HeadingProps
 * @returns {React.ReactElement} React.ReactElement
 *
 * @example
 * <Heading level={1}>Title</Heading>
 */
export const Heading: React.FC<HeadingProps> = ({
  children,
  level,
  inspectMode,
  id,
  color = token('color.text'),
  fontWeight,
}): React.ReactElement => {
  const HeadingElement = levelToHeadingElementsMap[level] as React.ElementType;
  const headingStyles = levelToHeadingStylesMap[level];

  const additionalProps = inspectMode ? inspectorProps(Object.keys(inspectMode)[0], inspectMode) : {};

  return (
    <HeadingElement
      id={id}
      css={[headingStyles, fontWeight && css({ fontWeight })]}
      {...additionalProps}
      style={{ color }}
    >
      {children}
    </HeadingElement>
  );
};

const h0Styles = css({
  fontFamily: '"Charlie Display", sans-serif',
  fontWeight: 700,
  fontSize: '48px',
  lineHeight: '52px',
  letterSpacing: '-0.144px',
  margin: 0,

  [media.above.sm]: {
    fontSize: '56px',
    lineHeight: '64px',
    letterSpacing: '-0.168px',
  },
});

const h1Styles = css({
  fontFamily: '"Charlie Display", sans-serif',
  fontWeight: 700,
  fontSize: '2.5rem',
  lineHeight: '3rem',
  margin: 0,
  letterSpacing: '-0.144px',

  [media.above.sm]: {
    fontSize: '3rem',
    lineHeight: '3.5rem',
    letterSpacing: '-0.168px',
  },
});

const h2Styles = css({
  fontFamily: '"Charlie Display", sans-serif',
  fontWeight: 700,
  fontSize: '2rem',
  lineHeight: '2.5rem',
  margin: 0,
  letterSpacing: '-0.096px',

  [media.above.sm]: {
    fontSize: '2.5rem',
    lineHeight: '3rem',
    letterSpacing: '-0.12px',
  },
});

const h3Styles = css({
  fontFamily: '"Charlie Display", sans-serif',
  fontWeight: 700,
  fontSize: '1.5rem',
  lineHeight: '2rem',
  margin: 0,
  letterSpacing: 'normal',

  [media.above.sm]: {
    fontSize: '2rem',
    lineHeight: '2.5rem',
    letterSpacing: 'normal',
  },
});

const h4Styles = css({
  fontFamily: '"Charlie Display", sans-serif',
  fontWeight: 600,
  fontSize: '1.25rem',
  lineHeight: '1.75rem',
  margin: 0,
  letterSpacing: 'normal',

  [media.above.sm]: {
    fontSize: '1.5rem',
    lineHeight: '2rem',
    letterSpacing: 'normal',
  },
});

const h5Styles = css({
  fontFamily: '"Charlie Display", sans-serif',
  fontWeight: 600,
  fontSize: '1rem',
  lineHeight: '1.5rem',
  margin: 0,
  letterSpacing: 'normal',

  [media.above.sm]: {
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    letterSpacing: 'normal',
  },
});

const h6Styles = css({
  fontFamily: '"Charlie Display", sans-serif',
  fontWeight: 600,
  fontSize: '0.875rem',
  lineHeight: '1.375rem',
  textTransform: 'unset',
  margin: 0,
  letterSpacing: 'normal',

  [media.above.sm]: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    letterSpacing: 'normal',
  },
});

const levelToHeadingElementsMap = {
  0: 'h1',
  1: 'h1',
  2: 'h2',
  3: 'h3',
  4: 'h4',
  5: 'h5',
  6: 'h6',
};

const levelToHeadingStylesMap = {
  0: h0Styles,
  1: h1Styles,
  2: h2Styles,
  3: h3Styles,
  4: h4Styles,
  5: h5Styles,
  6: h6Styles,
};
