import React, { ReactNode } from 'react';
import { css } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { ButtonGroup } from '@atlaskit/button';
import SectionButton from './SectionButton';
import { Eyebrow } from '@atlassian/learning-components';
import { UITrackEventPayload } from '@atlassian/community-library';
import { LinkData } from './analytics-wrapped/LinkData';
import { MOBILE } from '../../utils/constants';

type SectionProps = {
  id?: string;
  children: ReactNode;
  title?: string;
  heading?: string;
  description?: string;
  buttonData?: LinkData;
  eyebrowColor?: string;
};

const Section: React.FC<SectionProps> = ({
  id,
  children,
  title,
  heading,
  description,
  buttonData,
  eyebrowColor,
}) => {
  return (
    <section css={sectionStyles}>
      <header css={headerStyles}>
        {title && (
          <Eyebrow
            size="medium"
            fontWeight="regular"
            color={eyebrowColor ?? token('color.text.subtle')}
          >
            {title}
          </Eyebrow>
        )}
        {heading && <h2 css={headingStyles}>{heading}</h2>}
        {description && <p css={descriptionStyles}>{description}</p>}
      </header>
      <div css={[containerStyles, buttonData && containerWithButtonStyles]}>
        {children}
      </div>
      {buttonData && (
        <ButtonGroup>
          <SectionButton
            href={buttonData.href}
            text={buttonData.text}
            target={buttonData.target}
            clickEventPayload={getButtonEventPayload(
              'clicked',
              buttonData.href,
              buttonData.text,
              id,
            )}
            hoverEventPayload={getButtonEventPayload(
              'hovered',
              buttonData.href,
              buttonData.text,
              id,
            )}
            hasArrow={true}
          />
        </ButtonGroup>
      )}
    </section>
  );
};

const getButtonEventPayload = (
  action: string,
  href: string,
  label: string,
  id?: string,
): UITrackEventPayload => {
  return {
    action,
    actionSubject: 'button',
    actionSubjectId: 'communityHomeSectionButton',
    source: 'communityHomeSection',
    attributes: {
      href,
      label,
      communitySectionId: id,
    },
  } as UITrackEventPayload;
};

export default Section;

const sectionStyles = css({
  textAlign: 'center',
  fontFeatureSettings: `'liga' off, 'clig' off`,
  fontFamily: 'Charlie Display,sans-serif',
  [MOBILE.MEDIA_QUERY]: {
    padding: '0 16px',
  },
});

const headerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  marginBottom: '8px',
  width: '664px',
  margin: '0 auto',
  [MOBILE.MEDIA_QUERY]: {
    width: '100%',
  },
});

const headingStyles = css({
  color: token('color.text'),
  fontSize: '40px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '48px' /* 120% */,
  letterSpacing: '-0.12px',
  margin: '0 auto',
  '@media (max-width: 1279px)': {
    fontSize: '32px',
  },
  [MOBILE.MEDIA_QUERY]: {
    width: '340px',
    fontSize: '32px',
  },
});

const descriptionStyles = css({
  fontFamily: 'Inter, sans-serif',
  color: 'var(--neutral-new-800, #505258)',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '32px' /* 133.333% */,
  margin: '0 auto',
  '@media (max-width: 1279px)': {
    fontSize: '20px',
  },
  [MOBILE.MEDIA_QUERY]: {
    fontSize: '16px',
    lineHeight: '24px',
  },
});

const containerStyles = css({
  marginBottom: `0px`,
  marginTop: `32px`,
  [MOBILE.MEDIA_QUERY]: {
    marginTop: `16px`,
  },
});

// if this Section has a button, add some margin to the container
const containerWithButtonStyles = css({
  margin: '40px 0',
  '@media (max-width: 1279px)': {
    margin: '24px 0',
  },
});