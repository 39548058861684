import ModalDialog from '@atlaskit/modal-dialog';
import { css } from '@emotion/react';
import React from 'react';
import Button from '@atlaskit/button/new';
import { PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL } from '@components/community/constants';

export interface WelcomeModalProps {
  onAccept: () => void;
  /** Callback function to close the modal */
  onCancel: () => void;
  /** The source of the cover image */
  coverImageSrc: string;
}

/**
 * Welcome Modal
 * @param {WelcomeModalProps} WelcomeModalProps
 * @returns {React.ReactElement} React.ReactElement
 *
 * @example
 * <WelcomeModal onAccept={handleAccept} onCancel={handleClose} coverImageSrc={imageSrc} />
 */
export const WelcomeModal: React.FC<WelcomeModalProps> = ({
  onAccept,
  onCancel,
  coverImageSrc,
}: WelcomeModalProps): React.ReactElement => (
  <ModalDialog
    onClose={onCancel}
    width="auto"
    shouldCloseOnEscapePress={false}
    shouldCloseOnOverlayClick={false}
    shouldScrollInViewport={true}
  >
    <div css={modalContainerStyles}>
      <div css={modalImageContainerStyles}>
        <img src={coverImageSrc} alt="cover" css={coverImageStyles} />
      </div>
      <div css={modalBodyStyles}>
        <h1 css={modalHeadingContainerStyles}>Welcome to the new Atlassian Community</h1>
        <p css={modalSubheadingContainerStyles}>Online forums and learning are now in one easy-to-use experience.</p>
        <p css={modalSubheadingContainerStyles}>
          By continuing, you accept the updated{' '}
          <a href={TERMS_OF_SERVICE_URL} target="_blank">
            Community Terms of Use
          </a>{' '}
          and acknowledge the{' '}
          <a href={PRIVACY_POLICY_URL} target="_blank">
            Privacy Policy
          </a>
          . Your public name, photo, and achievements may be publicly visible and available in search engines.
        </p>
        <div css={modalButtonContainerStyles}>
          <div css={buttonStyle}>
            <Button appearance="subtle" onClick={onCancel}>
              {' '}
              Cancel and logout
            </Button>
          </div>

          <div css={buttonStyle}>
            <Button appearance="primary" onClick={onAccept}>
              Accept and continue
            </Button>
          </div>
        </div>
      </div>
    </div>
  </ModalDialog>
);

const buttonStyle = css({
  fontSize: '16px',
  '& span': {
    fontWeight: '600',
  },
});

const coverImageStyles = css({
  width: '100%',
  height: 'auto',
  display: 'block',
});

const modalContainerStyles = css({
  maxWidth: '600px',
  margin: '0 auto',
});

const modalImageContainerStyles = css({
  width: '100%',
  overflow: 'hidden',
});

const modalBodyStyles = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '24px',
});

const modalHeadingContainerStyles = css({
  fontFamily: 'Charlie Display, sans-serif',
  fontSize: '32px',
  paddingTop: '24px',
  fontWeight: 700,
  textAlign: 'center',
});

const modalSubheadingContainerStyles = css({
  fontSize: '16px',
  marginTop: '16px',
  fontFamily: 'Inter',
  textAlign: 'center',
  marginBottom: '24px',
});

const modalButtonContainerStyles = css({
  paddingTop: '16px',
  display: 'flex',
  justifyContent: 'center',
  gap: '8px',
});
