import React, { MouseEvent, MouseEventHandler, useCallback } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { media } from '@atlaskit/primitives/responsive';
import { token } from '@atlaskit/tokens';

export enum TabsSize {
  MEDIUM = 'medium',
  LARGE = 'large',
}

export interface TabProps {
  /** Displaying text of the button */
  label: string;
  /** Optional onClick handler */
  onClick?: MouseEventHandler<HTMLElement>;
  /** Url for buttons being used as a link */
  iconBefore?: React.ReactElement;
  /** Icon to render after label */
  iconAfter?: React.ReactElement;
  /** Button size */
  size?: 'medium' | 'large';
  /**Indicates if the button is in loading state */
  isLoading?: boolean;
  /**Indicates if the button is selected.Default: true */
  selected?: boolean;
}

/**
 * @param TabProps
 * @returns {React.ReactElement} React.ReactElement
 *
 * @example
 * <Tab label="CTA" />
 */
export const Tab: React.FC<TabProps> = ({
  label,
  onClick,
  iconBefore,
  iconAfter,
  size = TabsSize.MEDIUM,
  selected = false,
  isLoading,
}): React.ReactElement => {
  const clickHandler = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      if (onClick) {
        onClick(e);
      }
    },
    [onClick],
  );

  return (
    <button
      tabIndex={0}
      onClick={clickHandler}
      data-testid={`tab${selected ? '-selected' : ''}`}
      css={[
        tabStyles['basic'],
        selected && tabStyles['selected'],
        !iconBefore && !iconAfter && tabSpacingStyles[size],
        iconBefore && tabSpacingStyles[`${size}-icon-before`],
        iconAfter && tabSpacingStyles[`${size}-icon-after`],
      ]}
    >
      {iconBefore && !isLoading && (
        <span css={[tabIconContainerStyles, css({ marginRight: '0.25rem' })]}>{iconBefore}</span>
      )}
      <span css={[isLoading && css({ marginRight: '0.5rem' })]}>{label}</span>
      {iconAfter && !isLoading && (
        <span css={[tabIconContainerStyles, css({ marginLeft: '0.25rem' })]}>{iconAfter}</span>
      )}
    </button>
  );
};

const tabStyles = {
  basic: css({
    display: 'flex',
    alignItems: 'center',
    borderRadius: '3.125rem',
    fontFamily: 'Charlie Display, sans-serif',
    fontSize: '1.25rem',
    fontWeight: 600,
    lineHeight: '1.75rem',
    color: token('color.text'),
    border: `0.125rem solid ${token('color.border')}`,
    cursor: 'pointer',
    outline: 'none',
    background: 'transparent',

    [media.below.xs]: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
      padding: '0.5rem 1rem',
    },

    '&:hover': {
      backgroundColor: token('color.background.accent.gray.subtlest'),
      borderColor: token('color.border'),
      color: token('color.text'),
    },

    '&:focus-visible': {
      border: `2px solid ${token('color.border.selected')}`,
    },
  }),

  selected: css({
    backgroundColor: token('color.background.neutral.bold.pressed'),
    color: token('color.text.inverse'),
    border: '0.125rem solid',

    '&:hover': {
      backgroundColor: token('color.background.brand.boldest'),
      color: token('color.text.inverse'),
      border: '0.125rem solid',
    },
  }),
};

const tabSpacingStyles = {
  medium: css({
    padding: '0.375rem 1.5rem',
  }),
  'medium-icon-before': css({
    padding: '0.375rem 1.5rem 0.375rem 1rem',
  }),
  'medium-icon-after': css({
    padding: '0.375rem 1rem 0.375rem 1.5rem',
  }),
  large: css({
    padding: '0.625rem 1.5rem',
  }),
  'large-icon-before': css({
    padding: '0.625rem 1.5rem 0.625rem 1rem',
  }),
  'large-icon-after': css({
    padding: '0.625rem 1rem 0.625rem 1.5rem',
  }),
} as { [key: string]: SerializedStyles };

const tabIconContainerStyles = css({
  width: '2rem',
  height: '2rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
