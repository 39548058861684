export const mockRouteResourceResponse = {
  loading: false,
  error: null,
  data: {
    foo: 'bar'
  },
  promise: null,
  expiresAt: Date.now(),
  accessedAt: Date.now(),
  key: ''
};