import { token } from '@atlaskit/tokens';
import { css } from '@emotion/react';
export interface UnifiedAccountContainerCardProps {
  /** Content to be rendered inside the card */
  children: React.ReactNode;
  /** Test identifier */
  testId?: string;
  /** Click event handler */
  onClick?: () => void;
  /** For add account card styling */
  isAddAccount?: boolean;
}
export const UnifiedAccountContainerCard: React.FC<UnifiedAccountContainerCardProps> = ({
  children,
  testId,
  onClick,
  isAddAccount = false,
}) => {
  return (
    <button
      data-testid={testId}
      css={[
        unstyledButtonStyles,
        cardContainerStyles,
        isAddAccount && addAccountStyles,
        isAddAccount ? hoverEffectStyles : noPointerCursorStyles,
      ]}
      role="button"
      onClick={isAddAccount ? onClick : undefined}
    >
      {children}
    </button>
  );
};
const unstyledButtonStyles = css({
  display: 'flex',
  textAlign: 'inherit',
  textDecoration: 'none',
  background: 'none',
  color: 'inherit',
  border: 'none',
  padding: 0,
  fontFamily: token('font.family.brand.body'),
});
const cardContainerStyles = css({
  position: 'relative',
  textDecoration: 'none',
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  boxShadow: token('elevation.shadow.raised'),
  borderRadius: token('space.100'),
  background: token('elevation.surface'),
  alignItems: 'center',
  zIndex: 1,
  justifyContent: 'center',
  height: '194px',
  transition: 'box-shadow 0.2s ease',
  width: '300px', // PLACEHOLDER WIDTH FOR MOBILE, CHANGE WHEN DESIGNS ARE FINAL
  '@media (min-width: 768px) and (max-width: 1279px)': {
    width: '290px', // PLACEHOLDER WIDTH FOR TABLET, CHANGE WHEN DESIGNS ARE FINAL
  },
  '@media (min-width: 1280px)': {
    width: '290px',
  },
});
const hoverEffectStyles = css({
  cursor: 'pointer',
  '&:hover': {
    boxShadow: token('elevation.shadow.overlay'),
  },
  '&:active': {
    outline: `2px solid ${token('color.border.selected')}`,
  },
});
const noPointerCursorStyles = css({
  cursor: 'default',
});
const addAccountStyles = css({
  border: `1px dashed ${token('color.border')}`,
  boxShadow: 'none',
});
