import React, { ReactNode } from 'react';
import { css } from '@emotion/react';
import { token } from '@atlaskit/tokens';

export interface Field {
  name: string;
  value?: string | ReactNode;
}

export interface MetadataProps {
  fields: Field[];
}

export const Metadata: React.FC<MetadataProps> = ({ fields }: MetadataProps): React.ReactElement => (
  <div css={metadataContainerStyles} data-testid="metadata">
    <div css={metadataListStyles}>
      {fields.map(
        (field: Field) =>
          field.value && (
            <div css={metadataItemsStyles} key={field.name}>
              <p css={metadataTitleStyles}>{field.name}</p>
              <div css={metadataValueStyles}>{field.value}</div>
            </div>
          ),
      )}
    </div>
  </div>
);

const metadataContainerStyles = css({
  display: 'flex',
  padding: token('space.400'),
  alignItems: 'flex-start',
  flexDirection: 'column',
  backgroundColor: token('color.background.input'),
  borderRadius: '8px',
  boxShadow: token('elevation.shadow.raised'),
});

const metadataListStyles = css({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: token('space.200'),
});

const metadataItemsStyles = css({
  display: 'flex',
  minHeight: '24px',
  gap: token('space.300'),
  alignItems: 'center',
});

const metadataTitleStyles = css({
  margin: 0,
  minWidth: '88px',
  color: '#000',
  fontFamily: 'Charlie Text, sans-serif',
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '18px',
  textTransform: 'uppercase',
});

const metadataValueStyles = css({
  margin: 0,
  color: '#000',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
});
