import _extends from "@babel/runtime/helpers/extends";

var _Object$fromEntries;

import { getDefaultStateSlice } from '../get-default-state-slice';
import { getPrefetchSlice, getResourceState } from '../manage-resource-state'; // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/fromEntries

const fromEntries = (_Object$fromEntries = Object.fromEntries) != null ? _Object$fromEntries : entries => Object.assign({}, ...entries.map(_ref => {
  let [k, v] = _ref;
  return {
    [k]: v
  };
}));

const matchType = a => b => {
  const [{
    type: typeA
  }] = Array.isArray(a) ? a : [a];
  const [{
    type: typeB
  }] = Array.isArray(b) ? b : [b];
  return typeA === typeB;
};

export class ResourceDependencyError extends Error {
  constructor(message) {
    super(message);
    this.name = 'ResourceDependencyError';
  }

}
export const executeTuples = (routeResources, tuples) => _ref2 => {
  var _routeResources$some;

  let {
    getState,
    setState,
    dispatch
  } = _ref2;

  if (getState().executing) {
    throw new Error('execution is already in progress');
  } // check if there are resources and if so if there are any resources with dependencies


  const hasDependentResources = (_routeResources$some = routeResources == null ? void 0 : routeResources.some(_ref3 => {
    let {
      depends
    } = _ref3;
    return depends == null ? void 0 : depends.length;
  })) != null ? _routeResources$some : false;

  if (!hasDependentResources) {
    return tuples.map(_ref4 => {
      let [, action] = _ref4;
      return dispatch(action);
    });
  } // some resources might not be listed on the route resources so must be dispatched separate from executing state


  const listedTuples = tuples.filter(_ref5 => {
    let [resource] = _ref5;
    return routeResources == null ? void 0 : routeResources.some(matchType(resource));
  });

  if (listedTuples.length === 0) {
    return tuples.map(_ref6 => {
      let [, action] = _ref6;
      return dispatch(action);
    });
  } // accumulate the dependency types for all executing resources
  // find downstream resources that may also execute and include their dependencies too
  // include the resource type as well in the list
  // we don't validate that these are legal dependencies


  const [dependentTypes] = routeResources.reduce((acc, _ref7) => {
    let {
      type,
      depends
    } = _ref7;
    const [dependencies, executableTypes] = acc;
    const isExecutable = executableTypes.includes(type) || !!(depends != null && depends.some(dependency => executableTypes.includes(dependency)));
    return isExecutable ? [depends ? [...dependencies, ...depends, type] : dependencies, [...executableTypes, type]] : acc;
  }, [[], listedTuples.map(_ref8 => {
    let [{
      type
    }] = _ref8;
    return type;
  })]).filter((v, i, a) => a.indexOf(v) === i); // resources that are completely independent are preferable to be dispatched separate from executing state

  const dependentTuples = listedTuples.filter(_ref9 => {
    let [{
      type
    }] = _ref9;
    return dependentTypes.includes(type);
  });

  if (dependentTuples.length === 0) {
    return tuples.map(_ref10 => {
      let [, action] = _ref10;
      return dispatch(action);
    });
  } // additionally find all direct dependencies of these executing or possibly executing resources
  // we don't validate that these are legal dependencies


  const dependentAndDependencyTypes = routeResources.filter(_ref11 => {
    let {
      type
    } = _ref11;
    return dependentTypes.includes(type);
  }).reduce((acc, _ref12) => {
    let {
      type,
      depends
    } = _ref12;
    return depends ? [...acc, ...depends, type] : [...acc, type];
  }, []).filter((v, i, a) => a.indexOf(v) === i); // setup executing tuples in route resource order
  // this state allows actions to call executeForDependents() to influence actions that follow
  // the list includes dependency resources so that getDependencies() may validate without needing route resources
  // we use the resource definition of the given tuple where present or otherwise the definition from route resources

  const executingTuples = routeResources.filter(_ref13 => {
    let {
      type
    } = _ref13;
    return dependentAndDependencyTypes.includes(type);
  }).map(resource => {
    var _tuples$find;

    return (_tuples$find = tuples.find(matchType(resource))) != null ? _tuples$find : [resource, null];
  });
  setState({
    executing: executingTuples
  }); // dispatch sequentially during which executeForDependents() can cause tuples to change

  const executedResults = executingTuples.map((_ref14, i) => {
    var _getState$executing;

    let [{
      type: expectedType
    }] = _ref14;
    const latestTuple = (_getState$executing = getState().executing) == null ? void 0 : _getState$executing[i];
    const [{
      type: latestType
    }, maybeAction] = latestTuple != null ? latestTuple : [{}];

    if (latestType !== expectedType) {
      setState({
        executing: null
      });
      throw new Error('execution reached an inconsistent state');
    }

    return maybeAction ? dispatch(maybeAction) : undefined;
  });
  setState({
    executing: null
  }); // pick existing execution result or dispatch any remaining independent actions

  return tuples.map(_ref15 => {
    let [resource, action] = _ref15;
    const index = executingTuples.findIndex(matchType(resource));
    return index < 0 ? dispatch(action) : executedResults[index];
  });
};
export const actionWithDependencies = (routeResources, resource, action) => _ref16 => {
  let {
    dispatch
  } = _ref16;
  return dispatch(executeTuples(routeResources, [[resource, action]]))[0];
};
export const mapActionWithDependencies = (routeResources, resources, actionCreator) => executeTuples(routeResources, resources.map(resource => [resource, actionCreator(resource)]));
export const executeForDependents = (resource, actionCreator) => _ref17 => {
  var _tuples$findIndex;

  let {
    getState,
    setState
  } = _ref17;
  const {
    executing: tuples
  } = getState(); // find the given resource in the currently executing tuples

  const indexForResource = (_tuples$findIndex = tuples == null ? void 0 : tuples.findIndex(matchType(resource))) != null ? _tuples$findIndex : -1;

  if (indexForResource < 0) {
    return;
  } // find dependent resources following given resource and revise their action


  const executing = tuples.map((tuple, i) => {
    var _tupleResource$depend;

    const [tupleResource] = tuple;
    return i > indexForResource && (_tupleResource$depend = tupleResource.depends) != null && _tupleResource$depend.includes(resource.type) ? [tupleResource, actionCreator(tupleResource)] : tuple;
  });
  setState({
    executing
  });
};
export const getDependencies = (resource, routerStoreContext, options) => _ref18 => {
  var _tuples$findIndex2;

  let {
    getState,
    dispatch
  } = _ref18;
  const {
    type,
    depends
  } = resource; // optimise the case of no dependencies

  if (!(depends != null && depends.length)) {
    return {};
  }

  const {
    executing: tuples,
    context: resourceStoreContext
  } = getState(); // dependent resource cannot be called outside execution state
  // find the given resource type in the currently executing tuples

  const indexForResource = (_tuples$findIndex2 = tuples == null ? void 0 : tuples.findIndex(matchType(resource))) != null ? _tuples$findIndex2 : -1;

  if (indexForResource < 0) {
    throw new ResourceDependencyError(`Missing resource: "${type}" has dependencies so must not be missing`);
  } // find tuples index for all the dependency elements


  const dependencyIndexTuples = depends.map(dependency => [dependency, tuples.findIndex(matchType({
    type: dependency
  }))]); // we rely on executing tuples including all dependencies of the caller resource

  dependencyIndexTuples.forEach(_ref19 => {
    let [dependency, index] = _ref19;

    if (index < 0) {
      throw new ResourceDependencyError(`Missing resource: "${type}" depends "${dependency}" which is missing`);
    }

    if (index > indexForResource) {
      throw new ResourceDependencyError(`Illegal dependency: "${type}" depends "${dependency}" so "${dependency}" must precede "${type}"`);
    }
  });
  return fromEntries(dependencyIndexTuples.map(_ref20 => {
    let [dependencyType, index] = _ref20;
    const {
      getKey
    } = tuples[index][0];
    const key = getKey(routerStoreContext, resourceStoreContext);
    let slice = dispatch(getResourceState(dependencyType, key)) || getDefaultStateSlice(); // when prefetching we provide only the promise to dependands

    const prefetchSlice = options.prefetch ? dispatch(getPrefetchSlice(dependencyType, key)) : undefined;

    if (prefetchSlice) {
      slice = _extends({}, slice, {
        // not spreading prefetchSlice to persist previous slice data
        promise: prefetchSlice.promise,
        expiresAt: prefetchSlice.expiresAt,
        loading: true
      });
    }

    return [dependencyType, slice];
  }));
};