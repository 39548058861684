import React from 'react';
import AtlaskitLozenge from '@atlaskit/lozenge';

export interface LozengeProps {
  /** Appearance of the lozenge, changes the background color and font color */
  appearance?: LozengeAppearance;
  /** Text to be rendered inside the lozenge */
  text: string;
  /** Reverts the background color with the font color, making it look bolder if set to true */
  isBold?: boolean;
}

/**
 * Component that renders a lozenge / status label based on @atlaskit/lozenge
 * @param {LozengeProps} LozengeProps
 * @returns {React.ReactElement} React.ReactElement
 *
 * @example
 * <Lozenge text="DEFAULT" />
 */
export const Lozenge: React.FC<LozengeProps> = ({ appearance = 'default', text, isBold }) => (
  <AtlaskitLozenge isBold={isBold} appearance={appearance} testId="lozenge">
    {text?.toUpperCase()}
  </AtlaskitLozenge>
);

export type LozengeAppearance = 'default' | 'inprogress' | 'moved' | 'new' | 'removed' | 'success';
