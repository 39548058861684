import { getForumsSignInUrl } from '@components/community/utils';

export enum Environment {
  'DEVELOPMENT' = 'development',
  'STAGING' = 'staging',
  'PRODUCTION' = 'production',
}

type IdentiyUrl = {
  [Environment.DEVELOPMENT]: {
    loggedIn: string;
    loggedOut: string;
  };
  [Environment.STAGING]: {
    loggedIn: string;
    loggedOut: string;
  };
  [Environment.PRODUCTION]: {
    loggedIn: string;
    loggedOut: string;
  };
};

export const IDENTITY_URL: IdentiyUrl = {
  [Environment.DEVELOPMENT]: {
    loggedIn: 'https://id.stg.internal.atlassian.com',
    loggedOut: 'https://id.stg.internal.atlassian.com',
  },
  [Environment.STAGING]: {
    loggedIn: 'https://id.stg.internal.atlassian.com',
    loggedOut: 'https://id.stg.internal.atlassian.com',
  },
  [Environment.PRODUCTION]: {
    loggedIn: 'https://id.atlassian.com',
    loggedOut: 'https://id.atlassian.com',
  },
};

export const HOME_HREF: ProductHref = {
  loggedIn: '/',
  loggedOut: '/',
};

export enum CommunityApp {
  'HOME' = 'home',
  'LEARNING' = 'learning',
  'FORUMS' = 'forums',
  'EVENTS' = 'events',
  'PROFILE' = 'unified-portable-profile',
  'CHAMPIONS' = 'champions',
}

export const getAppButtons = (email?: string): AppButton[] => [
  {
    app: CommunityApp.FORUMS,
    label: 'Forums',
    target: '_self',
    href: {
      loggedIn: getForumsSignInUrl({ email, redirectUrl: '/forums' }),
      loggedOut: '/forums',
    },
  },
  {
    app: CommunityApp.LEARNING,
    label: 'Learning',
    href: {
      loggedIn: '/learning',
      loggedOut: '/learning',
    },
  },
  {
    app: CommunityApp.EVENTS, // events splash page
    label: 'Events',
    target: '_self',
    href: {
      loggedIn: '/events',
      loggedOut: '/events',
    },
  },
  {
    app: CommunityApp.CHAMPIONS, // champions splash page
    label: 'Champions',
    target: '_self',
    href: {
      loggedIn: '/champions',
      loggedOut: '/champions',
    },
  },
];
// TODO: Remove this constant - still being used externally by learning-app-fe
// Once learning-app-fe is updated to use getAppButtons(), this constant can be removed
export const APP_BUTTONS: AppButton[] = getAppButtons();

export const APP_URLS = {
  [CommunityApp.FORUMS]: '/forums',
  [CommunityApp.LEARNING]: '/learning',
  [CommunityApp.EVENTS]: '/events',
  [CommunityApp.PROFILE]: '/user',
  [CommunityApp.CHAMPIONS]: '/champions',
};

export const PROFILE_URL: ProductHref = {
  loggedIn: '/user',
  loggedOut: '/user',
};

export const SETTINGS_URL: ProductHref = {
  loggedIn: '/user/settings',
  loggedOut: '/user/settings',
};

export type ProductHref = {
  loggedIn: string;
  loggedOut: string;
};

export interface AppButton {
  app: CommunityApp;
  label: string;
  href: ProductHref;
  target?: string;
}

export const LOG_IN: string = 'Log in';
export const SIGN_UP: string = 'Sign up';
export const PROFILE_DROPDOWN_ITEMS = {
  PROFILE: { text: 'Profile', id: 'profile' },
  LOG_OUT: { text: 'Log out', id: 'logout' },
  SETTINGS: { text: 'Settings', id: 'settings' },
};

export enum EventType {
  TRACK = 'track',
  UI = 'ui',
  OPERATIONAL = 'operational',
  SCREEN = 'screen',
  IDENTIFY = 'identify',
}

export enum AnalyticsActivityType {
  NAV_LOGO_CLICK = 'NAV_LOGO_CLICK',
  NAV_ITEM_CLICK = 'NAV_ITEM_CLICK',
  LOG_IN_CLICK = 'LOG_IN_CLICK',
  SIGN_UP_CLICK = 'SIGN_UP_CLICK',
  LOG_OUT_CLICK = 'LOG_OUT_CLICK',
  PROFILE_MENU_OPEN = 'PROFILE_MENU_OPEN',
  PROFILE_MENU_CLOSE = 'PROFILE_MENU_CLOSE',
  PROFILE_MENU_ITEM_CLICK = 'PROFILE_MENU_ITEM_CLICK',
  MOBILE_MENU_OPEN = 'MOBILE_MENU_OPEN',
  MOBILE_MENU_CLOSE = 'MOBILE_MENU_CLOSE',
  // MOBILE_PROFILE_MENU_EXPAND = 'MOBILE_PROFILE_EXPAND',
  // MOBILE_PROFILE_MENU_CLOSE = 'MOBILE_PROFILE_CLOSE',
}

export const TERMS_OF_SERVICE_URL = 'https://www.atlassian.com/legal/training-terms-and-policies';
export const PRIVACY_POLICY_URL = 'https://www.atlassian.com/legal/privacy-policy#what-this-policy-covers';
