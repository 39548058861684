"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hasConsentMutationError = hasConsentMutationError;
exports.isUnifiedQueryError = isUnifiedQueryError;
function isUnifiedQueryError(response) {
  return 'identifier' in response;
}
function hasConsentMutationError(response) {
  return response.errors != null && Array.isArray(response.errors) && response.errors.length > 0;
}