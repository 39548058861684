import { useEffect, useState } from 'react';

// Default to a common desktop device width (is used to handle window object not being available during SSR)
const DEFAULT_WIDTH = 1440;

export const useDeviceSize = (): number => {
  const [width, setWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : DEFAULT_WIDTH);

  const handleWindowResize = (): void => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    if (width === DEFAULT_WIDTH && width !== window.innerWidth) {
      handleWindowResize();
    }

    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return width;
};
