import React from 'react';
import { token } from '@atlaskit/tokens';
import { css } from '@emotion/react';
import { calculateLearningProgress } from '@utils/index';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { Icon } from '@components/shared';
import { ContentType } from '@components/learning/types';

export interface LearningProgressBarProps {
  /**
   * The number of completed resources as a number.
   */
  completedResources: number;
  /**
   * The total number of resources as a number.
   */
  resourceCount: number;
  /**
   * The date when the course was completed as a string.
   */
  completionDate?: string;
  /**
   * The type of content as a ContentType.
   */
  contentType?: ContentType;
}

/**
 * A component that displays a learning progress bar.
 *
 * @param {LearningProgressBarProps} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * <LearningProgressBar completedResources=3 resourceCount=5 />
 */
export const LearningProgressBar: React.FC<LearningProgressBarProps> = ({
  completedResources,
  resourceCount,
  completionDate,
  contentType,
}) => {
  const getProgressText = (): string => {
    switch (contentType) {
      case ContentType.COURSE:
        return `${completedResources} of ${resourceCount} lessons`;
      case ContentType.LEARNING_PATH:
        return `${completedResources} of ${resourceCount} courses`;
      default:
        return `${Math.round(calculateLearningProgress(completedResources, resourceCount))}%`;
    }
  };

  if (completedResources === resourceCount) {
    return (
      <div data-testid="learning-progress-bar-container" css={progressBarContainerStyles}>
        <div css={progressBarStyles}>
          <Icon type="gap-completed" />
        </div>
        <p css={[progressTitleStyles, css({ color: token('color.text.success') })]}>Completed {completionDate}</p>
      </div>
    );
  }

  return (
    <div data-testid="learning-progress-bar-container" css={progressBarContainerStyles}>
      <div css={progressBarStyles}>
        <CircularProgressbarWithChildren
          strokeWidth={10}
          value={calculateLearningProgress(completedResources, resourceCount)}
          styles={buildStyles({
            pathColor: completedResources === 0 ? token('color.border') : token('color.text.success'),
          })}
        />
      </div>
      <p css={progressTitleStyles}>{getProgressText()}</p>
    </div>
  );
};

const progressBarContainerStyles = css({
  display: 'flex',
  alignItems: 'center',
  gap: token('space.100'),
});

const progressBarStyles = css({
  display: 'flex',
  maxWidth: '20px',
  width: '100%',
});

const progressTitleStyles = css({
  marginTop: 0,
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  fontFamily: 'Inter, sans-serif',
  color: token('color.text.subtle'),
});
