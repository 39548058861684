import { ReactNode } from 'react';

import { css } from '@emotion/react';
import { media } from '@atlaskit/primitives/responsive';

/**
 * @description Number of lesson columns allowed
 * @enum {string} TWO | THREE | FOUR
 * @example Columns.TWO
 */
export enum Columns {
  TWO = 'two',
  THREE = 'three',
  FOUR = 'four',
}

export interface LessonColumnsProps {
  /** Number of lesson columns to show a grid  */
  columns?: Columns;
  /** Elements to render inside grid */
  children: ReactNode;
}

/**
 * Component that renders a customizable layout to show html elements by columns
 * @param {LessonColumnsProps} LessonColumnsProps
 * @returns {React.ReactElement} React.ReactElement
 *
 * @example
 * <LessonColumns columns="two" />
 */
export const LessonColumns: React.FC<LessonColumnsProps> = ({
  columns = Columns.TWO,
  children,
}: LessonColumnsProps) => (
  <section css={sectionStyles}>
    <div data-testid="lesson-columns" css={[lessonColumnsStyles, lessonColumnsMDStyles[columns]]}>
      {children}
    </div>
  </section>
);

const sectionStyles = css({
  textOverflow: 'ellipsis',

  [media.above.lg]: {
    textOverflow: 'clip',
  },
});

const lessonColumnsStyles = css({
  display: 'grid',
  columnGap: '0.5rem',
  gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
  fontSize: '16px',

  [media.above.xs]: {
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
  },
});

const lessonColumnsMDStyles = {
  [Columns.TWO]: css({ [media.above.sm]: { gridTemplateColumns: 'repeat(2, minmax(0, 1fr))' } }),
  [Columns.THREE]: css({ [media.above.sm]: { gridTemplateColumns: 'repeat(3, minmax(0, 1fr))' } }),
  [Columns.FOUR]: css({ [media.above.sm]: { gridTemplateColumns: 'repeat(4, minmax(0, 1fr))' } }),
};
