import React, { useCallback, useEffect, useRef } from 'react';
// using 'plyr' instead of 'plyr/dist/plyr'
// will start executing DOM APIs right away
// and it breaks SSR
// @ts-ignore
import Plyr from 'plyr/dist/plyr';
import PlyrClass from 'plyr';
import { css } from '@emotion/react';
import { B300, N0, N500, N60 } from '@atlaskit/theme/colors';
import { Icon } from '@components/shared';
import { token } from '@atlaskit/tokens';

export interface AudioPlayerProps {
  /** Url to audio file */
  source: string;
  /** Closes audio player */
  onClose: () => void;
  /** Flag to track if the audio player is opened */
  isOpened: boolean;
  /** onClick callback, sends the event of pause and play */
  onControlClick?: (type: 'play' | 'pause') => void;
  /** Updates audio progress in percentages */
  onProgressUpdate?: (percentage: number) => void;
}

/**
 * Displays custom audio player
 * @param {AudioPlayer} AudioPlayerProps
 * @returns {React.ReactElement} React.ReactElement
 *
 * @example
 * <AudioPlayer source="" />
 */

export const AudioPlayer: React.FC<AudioPlayerProps> = ({
  source,
  isOpened,
  onClose,
  onControlClick,
  onProgressUpdate,
}) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const focusRef = useRef<HTMLDivElement>(null);

  const playerSource: PlyrClass.SourceInfo = {
    type: 'audio',
    sources: [{ src: source }],
  };

  const playerOptions: PlyrClass.Options = {
    hideControls: true,
    controls: ['play', 'progress', 'current-time'],
  };

  const addEvents = useCallback(
    (instance: PlyrClass): void => {
      addEvent(instance, 'play');
      addEvent(instance, 'pause');
    },
    [onControlClick],
  );

  const addEvent = useCallback((instance: PlyrClass, event: 'play' | 'pause') => {
    instance.on(event, () => onControlClick && onControlClick(event));

    instance.on('timeupdate', () => {
      const currentTime = instance.currentTime;
      const duration = instance.duration;
      const percentage = (currentTime / duration) * 100;

      onProgressUpdate && onProgressUpdate(+percentage.toFixed(2));
    });
  }, []);

  useEffect(() => {
    const player: PlyrClass = new Plyr(audioRef.current!, playerOptions);
    player.source = playerSource;
    addEvents(player);
    //To avoid: aria-label attribute is not well supported on a div with no valid role attribute.
    const plyrTimer = document.getElementsByClassName('plyr__time')[0];
    if (plyrTimer) {
      plyrTimer.removeAttribute('aria-label');
    }

    if (isOpened) {
      focusRef.current?.focus();
    }

    const plyrAudioContainer = document.getElementsByClassName('plyr--audio')[0];
    if (plyrAudioContainer) {
      plyrAudioContainer.role = 'presentation';
    }
  }, [isOpened]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.code === 'Space') {
        event.preventDefault();
        onClose();
      }
    },
    [onClose],
  );

  return (
    isOpened && (
      <div ref={focusRef} tabIndex={0} css={audioPlayerStyles}>
        <figure data-testid="audio-player" css={figureStyles}>
          <audio tabIndex={0} role="presentation" ref={audioRef}></audio>
          <div tabIndex={0} onKeyDown={handleKeyDown} onClick={onClose} css={closeIconStyles} data-testid="close-audio">
            <Icon type="cross" color={token('color.icon.subtle')} alt="close-audio" />
          </div>
        </figure>
      </div>
    )
  );
};

const audioPlayerStyles = css({
  borderRadius: '8px',
  maxWidth: '340px',
  padding: '24px',
  boxShadow: token('elevation.shadow.overlay'),
  backgroundColor: token('color.background.input'),

  '.plyr--audio': {
    width: '100%',
    '--plyr-color-main': B300,
    '--plyr-audio-control-color': N0,
    '--plyr-control-icon-size': '16px',
    '--plyr-range-thumb-height': '8px',
    '--plyr-range-track-height': '8px',
    '--plyr-range-thumb-shadow': 'none',
    '--plyr-audio-progress-buffered-background': N60,
    '--plyr-font-family': 'Charlie Text',
    '--plyr-tooltip-background': N500,
    '--plyr-tooltip-color': N0,
    '--plyr-tooltip-radius': '8px',
    '--plyr-control-spacing': '16px',
    '--plyr-range-thumb-background': B300,
  },
  '.plyr--audio > .plyr__controls': {
    padding: 0,
  },
  '.plyr--audio > .plyr__controls > .plyr__control': {
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '16px',
    backgroundColor: token('color.background.information.bold'),
    borderRadius: '50%',
  },
  '.plyr__progress': {
    marginRight: '16px',
  },
  '.icon--not-pressed': {
    marginRight: '-3px',
  },
  '.plyr__time': {
    fontFamily: 'Inter, sans-serif',
    color: token('color.text.subtlest'),
    fontSize: '14px',
    fontWeight: 600,
  },

  '.plyr--audio:focus, .plyr--audio:focus-visible': {
    border: `2px solid ${token('color.border.selected')}`,
  },
});

const figureStyles = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: 0,
});

const closeIconStyles = css({
  display: 'flex',
  cursor: 'pointer',
  marginLeft: '4px',
});
