import { qs } from 'url-parse';
import { DEFAULT_MATCH, DEFAULT_ROUTE } from '../../constants';
import { generatePath } from '../generate-path';
import matchRoute from '../match-route';
export const createRouterContext = function createRouterContext(route, options) {
  if (options === void 0) {
    options = {};
  }

  const {
    params = {},
    query = {},
    basePath = ''
  } = options;
  const pathname = generatePath(route.path, params);
  const matchedRoute = matchRoute([route], pathname, query, basePath);
  return {
    route,
    match: matchedRoute ? matchedRoute.match : DEFAULT_MATCH,
    query
  };
};
export const findRouterContext = (routes, options) => {
  const {
    location,
    basePath = ''
  } = options;
  const {
    pathname,
    search
  } = location;
  const query = qs.parse(search);
  const matchedRoute = matchRoute(routes, pathname, query, basePath);
  return {
    query,
    route: matchedRoute ? matchedRoute.route : DEFAULT_ROUTE,
    match: matchedRoute ? matchedRoute.match : DEFAULT_MATCH
  };
};