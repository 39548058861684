import React, { ReactNode, useState } from 'react';
import { css } from '@emotion/react';
import ChevronDown from '@atlaskit/icon/utility/chevron-down';
import ChevronUp from '@atlaskit/icon/utility/chevron-up';
import { token } from '@atlaskit/tokens';

interface NavBarExpandableItemProps {
  headerContent: ReactNode;
  expandedHeaderContent?: ReactNode;
  // if there's no body, then don't show accordion
  bodyContent?: ReactNode;
  /** (optional) additional function to be called when the user clicks on the item to expand it, called immediately
   * after the menu is opened/closed (can be used for sending analytics events)
   */
  onExpandClick?: (isOpen: boolean) => void;
}

export const NavBarExpandableItem: React.FC<NavBarExpandableItemProps> = ({
  headerContent,
  expandedHeaderContent,
  bodyContent,
  onExpandClick,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const onExpandClickHandler = (): void => {
    setIsOpen(!isOpen);
    if (onExpandClick) {
      onExpandClick(isOpen);
    }
  };
  return (
    <div css={containerStyles} role="group">
      {bodyContent ? (
        <>
          <div css={[headerStyles, isOpen && headerSelectedStyles]} role="treeitem" onClick={onExpandClickHandler}>
            {isOpen && expandedHeaderContent ? expandedHeaderContent : headerContent}
            <span css={isOpen && chevronSelectedStyles}>
              {isOpen ? <ChevronUp label={'Collapse item'} /> : <ChevronDown label={'Expand item'} />}
            </span>
          </div>
          {isOpen && <ul css={bodyContentContainerStyles}>{bodyContent}</ul>}
        </>
      ) : (
        <div css={headerStyles} role="treeitem">
          {headerContent}
        </div>
      )}
    </div>
  );
};

const containerStyles = css({
  display: 'flex',
  fontSize: '1rem',
  alignItems: 'flex-start',
  flexDirection: 'column',
  width: '100%',
});

const headerStyles = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
  height: '32px',
  width: '100%',
  padding: '0 24px',
});

const headerSelectedStyles = css({
  a: {
    color: token('color.text.selected'),
  },
});

const bodyContentContainerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '8px',
  paddingTop: '8px',
  paddingLeft: '0', // remove default padding from ul
  margin: 0,
  listStyle: 'none',
});

const chevronSelectedStyles = css({
  svg: {
    color: token('color.text.selected'),
  },
});
