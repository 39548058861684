export const isFromSsr = _ref => {
  let {
    expiresAt
  } = _ref;
  return expiresAt === null;
};

const isFresh = resource => {
  if (isFromSsr(resource)) {
    return true;
  }

  return Date.now() < Number(resource.expiresAt);
};

export const shouldUseCache = resource => {
  var _resource$error;

  if ((resource == null ? void 0 : (_resource$error = resource.error) == null ? void 0 : _resource$error.name) === 'TimeoutError') {
    return false;
  }

  if (resource.loading) {
    return true;
  }

  if (isFresh(resource)) {
    return true;
  }

  return false;
};