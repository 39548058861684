import React from 'react';
import { css } from '@emotion/react';
import { UnifiedAccountTagContainer } from '../UnifiedAccountTagContainer';
import StarFilledIcon from './assets/StarFilledIcon';

const primaryContentStyles = css({
  backgroundColor: '#FFFFFF',
  color: '#172B4D',
  border: '1px solid #091E4224',
});

const iconWrapperStyles = css({
  marginRight: '2px',
  display: 'flex',
  alignItems: 'center',
});

export const UnifiedAccountTagPrimary: React.FC = () => (
  <UnifiedAccountTagContainer>
    <div css={[primaryContentStyles, baseContentStyles]}>
      <div css={iconWrapperStyles}>
        <StarFilledIcon />
      </div>
      PRIMARY
    </div>
  </UnifiedAccountTagContainer>
);

const baseContentStyles = css({
  width: '84px',
  height: '28px',
  fontFamily: 'Charlie Text',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '6px 8px',
  boxSizing: 'border-box',
  fontSize: '12px',
  fontWeight: '600',
  borderRadius: '4px',
});
