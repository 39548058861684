import { css } from '@emotion/react';
import { useState, Dispatch, SetStateAction, useEffect } from 'react';

import { AccordionTab } from './children/AccordionTab';
import { media } from '@atlaskit/primitives';

export interface AccordionTabProps {
  /** Title of the tab. Clicking the title expands and collapses the tab */
  title: string;
  /** Index of the tab */
  index?: number;
  /** Content to be shown inside the tab */
  children: React.ReactNode;
  /** Indicates wether top border is needed for fist tab or not */
  isFirstTab?: boolean;
  /** Determines the mode wether user is able to have multiple tabs opened or just one at a time */
  shouldSingleTabBeOpen?: boolean;
  /** Determines  wether tab is expanded or closed when shouldSingleTabBeOpen is true */
  isExpanded: boolean;
  /** Function to set the expansion map */
  setExpansionMap?: Dispatch<SetStateAction<AccordionTabProps[]>>;
  /** Function to set the tab state */
  setTabState?: Dispatch<SetStateAction<{ [key: number]: string } | undefined>>;
}

export interface AccordionProps {
  /** An array of objects where each object represents a tab and includes title and its content (children). */
  tabsContent: AccordionTabProps[];
  /** Determines the width of the accordion component */
  size: AccordionSize;
  /** Determines  wether user is able to have multiple tabs opened or just one at a time */
  shouldSingleTabBeOpen: boolean;
  /** Function to be called when tab is expanded or collapsed */
  onChange: (tabState: { [key: number]: string } | undefined) => void;
}

export enum AccordionSize {
  DEFAULT = 'default',
  WIDE = 'wide',
}

/**
 * Component that renders accordion with multiple tabs
 * @param {AccordionProps} AccordionProps
 * @returns {React.ReactElement} React.ReactElement
 *
 * @example
 * <Accordion tabsContent={tabsContent} />
 */

export const Accordion: React.FC<AccordionProps> = ({ tabsContent, size, shouldSingleTabBeOpen, onChange }) => {
  const [expansionMap, setExpansionMap] = useState<AccordionTabProps[]>([]);
  const [tabState, setTabState] = useState<{ [key: number]: string }>();

  useEffect(() => {
    const initialExpansionMap = tabsContent.map((tabContent) => ({ ...tabContent, isExpanded: false }));
    setExpansionMap(initialExpansionMap);
  }, []);

  useEffect(() => {
    if (!tabState) {
      return;
    }

    onChange(tabState);
  }, [expansionMap, setExpansionMap]);

  return (
    <div
      css={[
        baseAccordionStyles,
        AccordionSize.DEFAULT === size && accordionDefaultStyles,
        AccordionSize.WIDE === size && accordionWideStyles,
      ]}
      data-testid="lesson-accordion"
    >
      {expansionMap.map(({ title, children, isExpanded }, index) => (
        <AccordionTab
          key={title}
          title={title}
          isFirstTab={index === 0}
          index={index}
          isExpanded={isExpanded}
          shouldSingleTabBeOpen={shouldSingleTabBeOpen}
          setTabState={setTabState}
          setExpansionMap={setExpansionMap}
        >
          {children}
        </AccordionTab>
      ))}
    </div>
  );
};

const baseAccordionStyles = css({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const accordionDefaultStyles = css({
  maxWidth: '720px',
});

const accordionWideStyles = css({
  maxWidth: '1020px',

  [media.below.md]: {
    maxWidth: '720px',
  },
});
