const svgStringDesktopFooterWider = `<svg width="3597" height="220" viewBox="0 0 3597 220" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1461_26831)">
<path d="M2132.54 314.529C2146.63 313.033 2161.59 314.218 2177.19 318.272C2192.78 322.325 2207.65 328.995 2219.68 337.581L2245.71 236.527C2232.41 230.415 2218.06 225.333 2202.74 221.351C2187.42 217.369 2172.81 214.822 2158.49 213.604L2132.48 314.564L2132.54 314.529Z" fill="#BF63F3"/>
<path d="M1905.63 13.0095L1932.77 57.7351C1902.11 76.8306 1895.31 95.1589 1908.88 117.522C1922.45 139.884 1942.19 142.885 1973.45 124.776L2000.59 169.502C1939.18 206.46 1898.36 193.057 1867.63 142.412C1836.9 91.7667 1844.25 50.0145 1905.66 13.0565L1905.63 13.0095Z" fill="#BF63F3"/>
<path d="M2653.64 67.1608C2651.12 70.158 2648.74 73.5509 2646.59 77.2837C2644.43 81.0165 2642.62 84.8928 2641.3 88.6374C2633.3 110.896 2640.22 131.445 2658.16 146.049C2661.21 148.541 2664.61 150.872 2668.29 152.996C2671.97 155.121 2675.99 157.022 2679.83 158.505C2701.49 166.665 2722.75 162.381 2737.96 144.444C2740.54 141.43 2742.94 137.998 2745.14 134.187C2747.35 130.375 2749.05 126.695 2750.38 123.013C2758.29 100.911 2751.37 80.3628 2733.5 65.6405C2730.31 63.0181 2726.79 60.6186 2722.96 58.4028C2719.12 56.187 2715.6 54.5204 2711.88 53.1059C2690.25 44.8669 2668.94 49.1675 2653.72 67.2065L2653.64 67.1608Z" fill="#FCA700"/>
<path d="M1895.14 242.555C1892.93 245.176 1890.84 248.144 1888.96 251.41C1887.07 254.676 1885.48 258.069 1884.33 261.347C1877.35 280.834 1883.48 298.855 1899.28 311.691C1901.96 313.881 1904.95 315.93 1908.19 317.8C1911.43 319.669 1914.97 321.344 1918.34 322.652C1937.4 329.85 1956.07 326.142 1969.39 310.457C1971.66 307.822 1973.76 304.819 1975.69 301.484C1977.61 298.149 1979.1 294.929 1980.27 291.705C1987.17 272.356 1981.05 254.335 1965.3 241.396C1962.5 239.091 1959.4 236.981 1956.03 235.031C1952.65 233.081 1949.55 231.613 1946.28 230.365C1927.26 223.099 1908.54 226.821 1895.21 242.595L1895.14 242.555Z" fill="#1868DB"/>
<path d="M2377.56 187.36C2332.41 205.776 2303.33 188.768 2293.49 181.225L2286.73 268.152C2295.75 275.232 2321.35 289.599 2365.56 271.568C2407.5 254.47 2435.57 267.92 2447.25 275.96L2454 189.016C2443.32 181.668 2418.37 170.713 2377.56 187.36Z" fill="#1868DB"/>
<path d="M1956.63 392.322L2067.8 363.764L2103.25 229.834L2021.88 185.761L1956.63 392.322Z" fill="#82B536"/>
<path d="M1814.81 214.821C1777.79 204.861 1754.37 195.571 1741.02 184.518L1735.75 299.502C1749.51 304.079 1764.42 308.373 1780.76 312.342C1816.54 322.129 1838.14 331.724 1849.86 343.657L1855.25 225.91C1842.76 221.962 1829.36 218.25 1814.81 214.821Z" fill="#FCA700"/>
<path d="M1717.71 131.43L1767.34 121.105L1788.77 77.5333L1765.89 33.5097L1715.9 22.1945L1676.46 52.1123L1677.27 100.718L1717.71 131.43Z" fill="#82B536"/>
<path d="M2287.3 0.329368C2292.26 17.6796 2294.03 36.6867 2292.33 57.0839C2290.62 77.481 2285.46 97.5178 2277.28 114.391L2410.19 125.941C2414.99 107.988 2418.26 88.9353 2419.94 68.8981C2421.61 48.8609 2421.63 30.0094 2420.04 11.8036L2287.25 0.264012L2287.3 0.329368Z" fill="#82B536"/>
<path d="M2732.21 188.352C2731.07 192.109 2730.22 196.176 2729.74 200.467C2729.26 204.759 2729.16 209.049 2729.49 213.022C2731.28 236.704 2746.68 252.942 2770.15 259.359C2774.14 260.46 2778.41 261.273 2782.85 261.786C2787.29 262.299 2791.96 262.473 2796.29 262.331C2820.59 261.339 2839.19 249.028 2846.17 226.497C2847.37 222.7 2848.22 218.588 2848.72 214.207C2849.21 209.825 2849.28 205.76 2848.99 201.838C2847.18 178.337 2831.78 162.098 2808.32 155.546C2804.15 154.377 2799.74 153.548 2795.11 153.013C2790.48 152.478 2786.38 152.326 2782.2 152.484C2757.91 153.385 2739.25 165.736 2732.31 188.363L2732.21 188.352Z" fill="#82B536"/>
<path d="M2889.07 147.28C2915.86 136.154 2934.18 130.899 2947.27 131.344L2897.58 60.2224C2887.23 63.8352 2876.31 68.1465 2864.68 73.3134C2838.72 83.9714 2821.36 88.1994 2808.84 86.4762L2859.72 159.306C2869.02 155.908 2878.77 151.943 2889.07 147.28Z" fill="#BF63F3"/>
<path d="M1500.61 92.78C1499.48 96.4691 1498.65 100.462 1498.18 104.676C1497.71 108.89 1497.62 113.103 1497.94 117.004C1499.7 140.257 1514.82 156.202 1537.86 162.503C1541.78 163.584 1545.97 164.382 1550.33 164.886C1554.69 165.39 1559.28 165.56 1563.52 165.421C1587.38 164.447 1605.65 152.359 1612.51 130.235C1613.69 126.507 1614.52 122.47 1615 118.167C1615.49 113.864 1615.56 109.873 1615.27 106.022C1613.5 82.9459 1598.37 67.0012 1575.34 60.5673C1571.24 59.4199 1566.92 58.6058 1562.37 58.0804C1557.82 57.5551 1553.8 57.4053 1549.7 57.5605C1525.84 58.4455 1507.52 70.573 1500.7 92.7908L1500.61 92.78Z" fill="#FCA700"/>
<path d="M2476.23 171.901L2512.57 187.615L2580.4 132.718L2540.7 72.2981L2469.59 84.8102L2476.23 171.901Z" fill="#FCA700"/>
<path d="M1590.18 276.392C1581.18 284.413 1582.82 297.295 1599.18 315.611C1600.94 317.584 1603.22 319.181 1604.98 321.154L1565.44 356.405C1561.81 353.394 1557.79 348.885 1552.5 342.967C1511.68 297.27 1513.72 256.222 1543.27 229.879C1565.29 210.248 1585.75 208.965 1606.41 210.958C1621.55 211.808 1632.33 212.828 1640.38 205.651C1649.37 197.63 1647.78 184.796 1631.38 166.432L1625.63 159.998L1664.69 125.169C1668.74 128.65 1672.77 133.158 1677.63 138.606C1718.87 184.774 1717.35 225.357 1687.28 252.165C1665.26 271.796 1644.39 272.609 1624.1 271.038C1609.01 270.236 1598.18 269.168 1590.13 276.345L1590.18 276.392Z" fill="#BF63F3"/>
<path d="M2627.7 309.01L2674.17 285.958L2684.11 237.19L2650.04 199.408L2597.6 201.079L2566.29 240.945L2579.68 288.97L2627.7 309.01Z" fill="#1868DB"/>
<path d="M2872.12 290.73C2867.27 321.394 2860.18 348.844 2852.92 371.879L2952.23 418.074C2962.37 387.895 2972.61 350.569 2979.35 307.997C2986.08 265.424 2987.84 226.732 2987.52 194.869L2878.89 207.578C2878.69 231.751 2876.97 260.066 2872.12 290.73Z" fill="#1868DB"/>
<path d="M2029.07 76.709L2081.8 60.3305C2094.17 97.6481 2111.48 109.727 2137.85 101.538C2164.21 93.3484 2172.04 73.4588 2160.83 35.7799L2213.56 19.4014C2237.02 93.7899 2213.37 132.978 2153.67 151.525C2093.96 170.071 2052.58 151.08 2029.12 76.6918L2029.07 76.709Z" fill="#1868DB"/>
<path d="M3183.71 263.373L3218.75 257.454C3262.92 249.994 3285.29 225.656 3295.17 194.436C3275.45 168.157 3246.29 152.584 3202.18 160.035L3140.03 170.531L3156.62 268.008L3183.67 263.44L3183.71 263.373Z" fill="#82B536"/>
<path d="M3507.45 240.161L3557.25 236.478L3583.87 196.676L3567.29 150.712L3519.96 133.206L3477.54 157.345L3471.98 204.936L3507.45 240.161Z" fill="#FCA700"/>
<path d="M3409.05 183.497L3432.31 152.723L3395.5 75.4747L3328.88 98.91L3324.57 169.298L3409.05 183.497Z" fill="#BF63F3"/>
<path d="M3096.28 193.6L3071.36 147.313L2999.69 124.541L3013.98 207.767L3075.67 265.657L3096.28 193.6Z" fill="#FCA700"/>
<path d="M3604.18 274.336L3620.24 305.08C3640.49 343.822 3667.68 358.475 3696.55 359.996C3711.89 335.532 3715.4 304.987 3695.17 266.29L3666.68 211.774L3591.71 250.587L3604.11 274.315L3604.18 274.336Z" fill="#1868DB"/>
<path d="M3419.15 246.402C3387.51 238.086 3359.1 229.101 3335.23 220.826L3290.48 267.987C3321.77 279.173 3360.41 291.729 3404.34 303.274C3448.28 314.818 3488.09 322.876 3520.82 328.514L3504.79 265.379C3479.94 260.853 3450.8 254.717 3419.15 246.402Z" fill="#1868DB"/>
<path d="M3194.92 119.479C3224.2 117.038 3243.38 117.413 3255.57 121.325L3234.28 46.2045C3223.2 46.5499 3211.35 47.3509 3198.52 48.703C3170.19 50.9595 3152.29 49.9514 3141.1 45.084L3162.9 122.009C3172.9 121.566 3183.52 120.75 3194.92 119.479Z" fill="#1868DB"/>
<path d="M1080.67 276.053C1089.16 285.428 1102.4 283.893 1120.89 267.24C1122.88 265.447 1124.47 263.118 1126.46 261.324L1163.76 302.525C1160.75 306.228 1156.2 310.327 1150.22 315.707C1104.1 357.253 1061.73 354.658 1033.86 323.868C1013.08 300.924 1011.27 279.808 1012.82 258.521C1013.33 242.923 1014.12 231.819 1006.53 223.431C998.038 214.056 984.845 215.548 966.31 232.244L959.816 238.093L922.961 197.386C926.452 193.256 931.003 189.157 936.501 184.204C983.099 142.231 1024.98 144.284 1053.34 175.616C1074.12 198.56 1075.46 220.103 1074.33 241.006C1073.87 256.561 1073.03 267.708 1080.63 276.096L1080.67 276.053Z" fill="#82B536"/>
<path d="M625.543 193.797C639.63 192.301 654.593 193.486 670.188 197.54C685.782 201.593 700.654 208.263 712.683 216.849L738.712 115.795C725.409 109.683 711.058 104.601 695.739 100.619C680.42 96.6367 665.808 94.0903 651.489 92.8723L625.485 193.832L625.543 193.797Z" fill="#BF63F3"/>
<path d="M1266.86 164.218L1230.53 158.808L1182.68 226.256L1232.61 271.992L1293.45 242.75L1266.86 164.218Z" fill="#FCA700"/>
<path d="M399.069 336.98L422.334 306.205L385.52 228.958L318.902 252.393L314.592 322.781L399.069 336.98Z" fill="#BF63F3"/>
<path d="M388.137 121.823C385.928 124.444 383.843 127.412 381.957 130.678C380.072 133.944 378.484 137.337 377.334 140.615C370.355 160.102 376.477 178.123 392.278 190.959C394.96 193.149 397.955 195.199 401.193 197.068C404.431 198.938 407.967 200.612 411.344 201.92C430.4 209.118 449.068 205.41 462.395 189.725C464.659 187.09 466.763 184.087 468.689 180.752C470.614 177.418 472.102 174.197 473.267 170.973C480.168 151.624 474.045 133.603 458.303 120.664C455.502 118.359 452.403 116.249 449.026 114.299C445.649 112.349 442.55 110.881 439.278 109.634C420.261 102.367 401.539 106.09 388.207 121.863L388.137 121.823Z" fill="#1868DB"/>
<path d="M870.565 66.6285C825.409 85.0438 796.327 68.0356 786.485 60.4931L779.735 147.42C788.749 154.5 814.346 168.867 858.558 150.836C900.496 133.738 928.568 147.188 940.25 155.228L947.002 68.2839C936.315 60.9356 911.368 49.9809 870.565 66.6285Z" fill="#1868DB"/>
<path d="M743.463 217.247C740.532 228.564 734.449 235.337 724.723 239.407C714.173 243.821 704.241 242.98 695.082 237.49L605.16 275.114C646.399 339.254 703.508 358.315 762.522 333.623C817.497 310.622 843.195 267.293 832.726 179.9L743.381 217.282L743.463 217.247Z" fill="#1868DB"/>
<path d="M449.631 271.59L560.8 243.032L596.253 109.102L514.879 65.0287L449.631 271.59Z" fill="#82B536"/>
<path d="M307.812 94.0893C270.79 84.1287 247.365 74.8387 234.016 63.7859L228.75 178.77C242.51 183.347 257.418 187.641 273.761 191.61C309.542 201.397 331.135 210.992 342.854 222.925L348.246 105.178C335.764 101.23 322.362 97.5184 307.812 94.0893Z" fill="#FCA700"/>
<path d="M1225.21 67.6199C1224.07 71.377 1223.22 75.4437 1222.74 79.7352C1222.26 84.0267 1222.16 88.3172 1222.49 92.2903C1224.28 115.972 1239.68 132.21 1263.15 138.627C1267.14 139.728 1271.41 140.541 1275.85 141.054C1280.29 141.567 1284.96 141.741 1289.29 141.599C1313.59 140.607 1332.19 128.296 1339.17 105.765C1340.37 101.968 1341.22 97.8564 1341.72 93.4746C1342.21 89.0927 1342.28 85.0281 1341.99 81.1056C1340.18 57.6047 1324.78 41.3663 1301.32 34.8139C1297.15 33.6454 1292.74 32.8162 1288.11 32.2812C1283.48 31.7462 1279.38 31.5936 1275.2 31.7517C1250.91 32.653 1232.25 45.0039 1225.31 67.631L1225.21 67.6199Z" fill="#82B536"/>
<path d="M83.1762 155.66C74.1789 163.681 75.8177 176.563 92.1789 194.879C93.9408 196.852 96.2182 198.449 97.9802 200.422L58.4398 235.673C54.8145 232.662 50.7872 228.153 45.5013 222.235C4.68234 176.538 6.72077 135.49 36.2695 109.147C58.289 89.5158 78.7491 88.2328 99.414 90.2263C114.549 91.0758 125.328 92.0964 133.378 84.9196C142.375 76.8983 140.778 64.064 124.375 45.7004L118.628 39.2661L157.695 4.43712C161.739 7.91785 165.767 12.4265 170.633 17.8745C211.872 64.0418 210.354 104.625 180.284 131.433C158.265 151.064 137.385 151.877 117.098 150.306C102.005 149.504 91.1843 148.436 83.1342 155.613L83.1762 155.66Z" fill="#BF63F3"/>
<path d="M1120.7 188.278L1167.17 165.226L1177.11 116.458L1143.04 78.6759L1090.6 80.3475L1059.29 120.213L1072.68 168.238L1120.7 188.278Z" fill="#1868DB"/>
<path d="M1365.12 169.998C1360.27 200.662 1353.18 228.112 1345.92 251.147L1445.23 297.342C1455.37 267.164 1465.61 229.838 1472.35 187.265C1479.08 144.692 1480.84 106 1480.52 74.1372L1371.89 86.8457C1371.69 111.019 1369.97 139.334 1365.12 169.998Z" fill="#1868DB"/>
<path d="M184.943 272.962C214.22 270.521 233.398 270.896 245.587 274.808L224.294 199.687C213.221 200.033 201.37 200.834 188.539 202.186C160.208 204.442 142.304 203.434 131.116 198.567L152.92 275.492C162.921 275.048 173.542 274.233 184.943 272.962Z" fill="#1868DB"/>
</g>
<defs>
<clipPath id="clip0_1461_26831">
<rect width="3597" height="220" fill="white"/>
</clipPath>
</defs>
</svg>
`;

export const svgStringDesktopFooterWiderUrl = `data:image/svg+xml;base64,${Buffer.from(
  svgStringDesktopFooterWider,
).toString('base64')}`;

const svgStringDesktopFooterWide = `<svg width="3305" height="220" viewBox="0 0 3305 220" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1461_26940)">
<path d="M2132.54 314.529C2146.63 313.033 2161.59 314.218 2177.19 318.272C2192.78 322.325 2207.65 328.995 2219.68 337.581L2245.71 236.527C2232.41 230.415 2218.06 225.333 2202.74 221.351C2187.42 217.369 2172.81 214.822 2158.49 213.604L2132.48 314.564L2132.54 314.529Z" fill="#BF63F3"/>
<path d="M1905.63 13.0095L1932.77 57.7351C1902.11 76.8306 1895.31 95.1589 1908.88 117.522C1922.45 139.884 1942.19 142.885 1973.45 124.776L2000.59 169.502C1939.18 206.46 1898.36 193.057 1867.63 142.412C1836.9 91.7667 1844.25 50.0145 1905.66 13.0565L1905.63 13.0095Z" fill="#BF63F3"/>
<path d="M2653.64 67.1608C2651.12 70.158 2648.74 73.5509 2646.59 77.2837C2644.43 81.0165 2642.62 84.8928 2641.3 88.6374C2633.3 110.896 2640.22 131.445 2658.16 146.049C2661.21 148.541 2664.61 150.872 2668.29 152.996C2671.97 155.121 2675.99 157.022 2679.83 158.505C2701.49 166.665 2722.75 162.381 2737.96 144.444C2740.54 141.43 2742.94 137.998 2745.14 134.187C2747.35 130.375 2749.05 126.695 2750.38 123.013C2758.29 100.911 2751.37 80.3628 2733.5 65.6405C2730.31 63.0181 2726.79 60.6186 2722.96 58.4028C2719.12 56.187 2715.6 54.5204 2711.88 53.1059C2690.25 44.8669 2668.94 49.1675 2653.72 67.2065L2653.64 67.1608Z" fill="#FCA700"/>
<path d="M1895.14 242.555C1892.93 245.176 1890.84 248.144 1888.96 251.41C1887.07 254.676 1885.48 258.069 1884.33 261.347C1877.35 280.834 1883.48 298.855 1899.28 311.691C1901.96 313.881 1904.95 315.93 1908.19 317.8C1911.43 319.669 1914.97 321.344 1918.34 322.652C1937.4 329.85 1956.07 326.142 1969.39 310.457C1971.66 307.822 1973.76 304.819 1975.69 301.484C1977.61 298.149 1979.1 294.929 1980.27 291.705C1987.17 272.356 1981.05 254.335 1965.3 241.396C1962.5 239.091 1959.4 236.981 1956.03 235.031C1952.65 233.081 1949.55 231.613 1946.28 230.365C1927.26 223.099 1908.54 226.821 1895.21 242.595L1895.14 242.555Z" fill="#1868DB"/>
<path d="M2377.56 187.36C2332.41 205.776 2303.33 188.768 2293.49 181.225L2286.73 268.152C2295.75 275.232 2321.35 289.599 2365.56 271.568C2407.5 254.47 2435.57 267.92 2447.25 275.96L2454 189.016C2443.32 181.668 2418.37 170.713 2377.56 187.36Z" fill="#1868DB"/>
<path d="M1956.63 392.322L2067.8 363.764L2103.25 229.834L2021.88 185.761L1956.63 392.322Z" fill="#82B536"/>
<path d="M1814.81 214.821C1777.79 204.861 1754.37 195.571 1741.02 184.518L1735.75 299.502C1749.51 304.079 1764.42 308.373 1780.76 312.342C1816.54 322.129 1838.14 331.724 1849.86 343.657L1855.25 225.91C1842.76 221.962 1829.36 218.25 1814.81 214.821Z" fill="#FCA700"/>
<path d="M1717.71 131.43L1767.34 121.105L1788.77 77.5333L1765.89 33.5097L1715.9 22.1945L1676.46 52.1123L1677.27 100.718L1717.71 131.43Z" fill="#82B536"/>
<path d="M2287.3 0.329368C2292.26 17.6796 2294.03 36.6867 2292.33 57.0839C2290.62 77.481 2285.46 97.5178 2277.28 114.391L2410.19 125.941C2414.99 107.988 2418.26 88.9353 2419.94 68.8981C2421.61 48.8609 2421.63 30.0094 2420.04 11.8036L2287.25 0.264012L2287.3 0.329368Z" fill="#82B536"/>
<path d="M2732.21 188.352C2731.07 192.109 2730.22 196.176 2729.74 200.467C2729.26 204.759 2729.16 209.049 2729.49 213.022C2731.28 236.704 2746.68 252.942 2770.15 259.359C2774.14 260.46 2778.41 261.273 2782.85 261.786C2787.29 262.299 2791.96 262.473 2796.29 262.331C2820.59 261.339 2839.19 249.028 2846.17 226.497C2847.37 222.7 2848.22 218.588 2848.72 214.207C2849.21 209.825 2849.28 205.76 2848.99 201.838C2847.18 178.337 2831.78 162.098 2808.32 155.546C2804.15 154.377 2799.74 153.548 2795.11 153.013C2790.48 152.478 2786.38 152.326 2782.2 152.484C2757.91 153.385 2739.25 165.736 2732.31 188.363L2732.21 188.352Z" fill="#82B536"/>
<path d="M2889.07 147.28C2915.86 136.154 2934.18 130.899 2947.27 131.344L2897.58 60.2224C2887.23 63.8352 2876.31 68.1465 2864.68 73.3134C2838.72 83.9714 2821.36 88.1994 2808.84 86.4762L2859.72 159.306C2869.02 155.908 2878.77 151.943 2889.07 147.28Z" fill="#BF63F3"/>
<path d="M1500.61 92.78C1499.48 96.4691 1498.65 100.462 1498.18 104.676C1497.71 108.89 1497.62 113.103 1497.94 117.004C1499.7 140.257 1514.82 156.202 1537.86 162.503C1541.78 163.584 1545.97 164.382 1550.33 164.886C1554.69 165.39 1559.28 165.56 1563.52 165.421C1587.38 164.447 1605.65 152.359 1612.51 130.235C1613.69 126.507 1614.52 122.47 1615 118.167C1615.49 113.864 1615.56 109.873 1615.27 106.022C1613.5 82.9459 1598.37 67.0012 1575.34 60.5673C1571.24 59.4199 1566.92 58.6058 1562.37 58.0804C1557.82 57.5551 1553.8 57.4053 1549.7 57.5605C1525.84 58.4455 1507.52 70.573 1500.7 92.7908L1500.61 92.78Z" fill="#FCA700"/>
<path d="M2476.23 171.901L2512.57 187.615L2580.4 132.718L2540.7 72.2981L2469.59 84.8102L2476.23 171.901Z" fill="#FCA700"/>
<path d="M1590.18 276.392C1581.18 284.413 1582.82 297.295 1599.18 315.611C1600.94 317.584 1603.22 319.181 1604.98 321.154L1565.44 356.405C1561.81 353.394 1557.79 348.885 1552.5 342.967C1511.68 297.27 1513.72 256.222 1543.27 229.879C1565.29 210.248 1585.75 208.965 1606.41 210.958C1621.55 211.808 1632.33 212.828 1640.38 205.651C1649.37 197.63 1647.78 184.796 1631.38 166.432L1625.63 159.998L1664.69 125.169C1668.74 128.65 1672.77 133.158 1677.63 138.606C1718.87 184.774 1717.35 225.357 1687.28 252.165C1665.26 271.796 1644.39 272.609 1624.1 271.038C1609.01 270.236 1598.18 269.168 1590.13 276.345L1590.18 276.392Z" fill="#BF63F3"/>
<path d="M2627.7 309.01L2674.17 285.958L2684.11 237.19L2650.04 199.408L2597.6 201.079L2566.29 240.945L2579.68 288.97L2627.7 309.01Z" fill="#1868DB"/>
<path d="M2872.12 290.73C2867.27 321.394 2860.18 348.844 2852.92 371.879L2952.23 418.074C2962.37 387.895 2972.61 350.569 2979.35 307.997C2986.08 265.424 2987.84 226.732 2987.52 194.869L2878.89 207.578C2878.69 231.751 2876.97 260.066 2872.12 290.73Z" fill="#1868DB"/>
<path d="M2029.07 76.709L2081.8 60.3305C2094.17 97.6481 2111.48 109.727 2137.85 101.538C2164.21 93.3484 2172.04 73.4588 2160.83 35.7799L2213.56 19.4014C2237.02 93.7899 2213.37 132.978 2153.67 151.525C2093.96 170.071 2052.58 151.08 2029.12 76.6918L2029.07 76.709Z" fill="#1868DB"/>
<path d="M3183.71 263.373L3218.75 257.454C3262.92 249.994 3285.29 225.656 3295.17 194.436C3275.45 168.157 3246.29 152.584 3202.18 160.035L3140.03 170.531L3156.62 268.008L3183.67 263.44L3183.71 263.373Z" fill="#82B536"/>
<path d="M3409.05 183.497L3432.31 152.723L3395.5 75.4747L3328.88 98.91L3324.57 169.298L3409.05 183.497Z" fill="#BF63F3"/>
<path d="M3096.28 193.6L3071.36 147.313L2999.69 124.541L3013.98 207.767L3075.67 265.657L3096.28 193.6Z" fill="#FCA700"/>
<path d="M3419.15 246.402C3387.51 238.086 3359.1 229.101 3335.23 220.826L3290.48 267.987C3321.77 279.173 3360.41 291.729 3404.34 303.274C3448.28 314.818 3488.09 322.876 3520.82 328.514L3504.79 265.379C3479.94 260.853 3450.8 254.717 3419.15 246.402Z" fill="#1868DB"/>
<path d="M3194.92 119.479C3224.2 117.038 3243.38 117.413 3255.57 121.325L3234.28 46.2045C3223.2 46.5499 3211.35 47.3509 3198.52 48.703C3170.19 50.9595 3152.29 49.9514 3141.1 45.084L3162.9 122.009C3172.9 121.566 3183.52 120.75 3194.92 119.479Z" fill="#1868DB"/>
<path d="M1080.67 276.053C1089.16 285.428 1102.4 283.893 1120.89 267.24C1122.88 265.447 1124.47 263.118 1126.46 261.324L1163.76 302.525C1160.75 306.228 1156.2 310.327 1150.22 315.707C1104.1 357.253 1061.73 354.658 1033.86 323.868C1013.08 300.924 1011.27 279.808 1012.82 258.521C1013.33 242.923 1014.12 231.819 1006.53 223.431C998.038 214.056 984.845 215.548 966.31 232.244L959.816 238.093L922.961 197.386C926.452 193.256 931.003 189.157 936.501 184.204C983.099 142.231 1024.98 144.284 1053.34 175.616C1074.12 198.56 1075.46 220.103 1074.33 241.006C1073.87 256.561 1073.03 267.708 1080.63 276.096L1080.67 276.053Z" fill="#82B536"/>
<path d="M625.543 193.797C639.63 192.301 654.593 193.486 670.188 197.54C685.782 201.593 700.654 208.263 712.683 216.849L738.712 115.795C725.409 109.683 711.058 104.601 695.739 100.619C680.42 96.6367 665.808 94.0903 651.489 92.8723L625.485 193.832L625.543 193.797Z" fill="#BF63F3"/>
<path d="M1266.86 164.218L1230.53 158.808L1182.68 226.256L1232.61 271.992L1293.45 242.75L1266.86 164.218Z" fill="#FCA700"/>
<path d="M399.069 336.98L422.334 306.205L385.52 228.958L318.902 252.393L314.592 322.781L399.069 336.98Z" fill="#BF63F3"/>
<path d="M388.137 121.823C385.928 124.444 383.843 127.412 381.957 130.678C380.072 133.944 378.484 137.337 377.334 140.615C370.355 160.102 376.477 178.123 392.278 190.959C394.96 193.149 397.955 195.199 401.193 197.068C404.431 198.938 407.967 200.612 411.344 201.92C430.4 209.118 449.068 205.41 462.395 189.725C464.659 187.09 466.763 184.087 468.689 180.752C470.614 177.418 472.102 174.197 473.267 170.973C480.168 151.624 474.045 133.603 458.303 120.664C455.502 118.359 452.403 116.249 449.026 114.299C445.649 112.349 442.55 110.881 439.278 109.634C420.261 102.367 401.539 106.09 388.207 121.863L388.137 121.823Z" fill="#1868DB"/>
<path d="M870.565 66.6285C825.409 85.0438 796.327 68.0356 786.485 60.4931L779.735 147.42C788.749 154.5 814.346 168.867 858.558 150.836C900.496 133.738 928.568 147.188 940.25 155.228L947.002 68.2839C936.315 60.9356 911.368 49.9809 870.565 66.6285Z" fill="#1868DB"/>
<path d="M743.463 217.247C740.532 228.564 734.449 235.337 724.723 239.407C714.173 243.821 704.241 242.98 695.082 237.49L605.16 275.114C646.399 339.254 703.508 358.315 762.522 333.623C817.497 310.622 843.195 267.293 832.726 179.9L743.381 217.282L743.463 217.247Z" fill="#1868DB"/>
<path d="M449.631 271.59L560.8 243.032L596.253 109.102L514.879 65.0287L449.631 271.59Z" fill="#82B536"/>
<path d="M307.812 94.0893C270.79 84.1287 247.365 74.8387 234.016 63.7859L228.75 178.77C242.51 183.347 257.418 187.641 273.761 191.61C309.542 201.397 331.135 210.992 342.854 222.925L348.246 105.178C335.764 101.23 322.362 97.5184 307.812 94.0893Z" fill="#FCA700"/>
<path d="M1225.21 67.6199C1224.07 71.377 1223.22 75.4437 1222.74 79.7352C1222.26 84.0267 1222.16 88.3172 1222.49 92.2903C1224.28 115.972 1239.68 132.21 1263.15 138.627C1267.14 139.728 1271.41 140.541 1275.85 141.054C1280.29 141.567 1284.96 141.741 1289.29 141.599C1313.59 140.607 1332.19 128.296 1339.17 105.765C1340.37 101.968 1341.22 97.8564 1341.72 93.4746C1342.21 89.0927 1342.28 85.0281 1341.99 81.1056C1340.18 57.6047 1324.78 41.3663 1301.32 34.8139C1297.15 33.6454 1292.74 32.8162 1288.11 32.2812C1283.48 31.7462 1279.38 31.5936 1275.2 31.7517C1250.91 32.653 1232.25 45.0039 1225.31 67.631L1225.21 67.6199Z" fill="#82B536"/>
<path d="M83.1762 155.66C74.1789 163.681 75.8177 176.563 92.1789 194.879C93.9408 196.852 96.2182 198.449 97.9802 200.422L58.4398 235.673C54.8145 232.662 50.7872 228.153 45.5013 222.235C4.68234 176.538 6.72077 135.49 36.2695 109.147C58.289 89.5158 78.7491 88.2328 99.414 90.2263C114.549 91.0758 125.328 92.0964 133.378 84.9196C142.375 76.8983 140.778 64.064 124.375 45.7004L118.628 39.2661L157.695 4.43712C161.739 7.91785 165.767 12.4265 170.633 17.8745C211.872 64.0418 210.354 104.625 180.284 131.433C158.265 151.064 137.385 151.877 117.098 150.306C102.005 149.504 91.1843 148.436 83.1342 155.613L83.1762 155.66Z" fill="#BF63F3"/>
<path d="M1120.7 188.278L1167.17 165.226L1177.11 116.458L1143.04 78.6759L1090.6 80.3475L1059.29 120.213L1072.68 168.238L1120.7 188.278Z" fill="#1868DB"/>
<path d="M1365.12 169.998C1360.27 200.662 1353.18 228.112 1345.92 251.147L1445.23 297.342C1455.37 267.164 1465.61 229.838 1472.35 187.265C1479.08 144.692 1480.84 106 1480.52 74.1372L1371.89 86.8457C1371.69 111.019 1369.97 139.334 1365.12 169.998Z" fill="#1868DB"/>
<path d="M184.943 272.962C214.22 270.521 233.398 270.896 245.587 274.808L224.294 199.687C213.221 200.033 201.37 200.834 188.539 202.186C160.208 204.442 142.304 203.434 131.116 198.567L152.92 275.492C162.921 275.048 173.542 274.233 184.943 272.962Z" fill="#1868DB"/>
</g>
<defs>
<clipPath id="clip0_1461_26940">
<rect width="3305" height="220" fill="white"/>
</clipPath>
</defs>
</svg>
`;

export const svgStringDesktopFooterWideUrl = `data:image/svg+xml;base64,${Buffer.from(
  svgStringDesktopFooterWide,
).toString('base64')}`;

const svgStringMobileFooter = `<svg width="907" height="139" viewBox="0 0 907 139" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_1421_26676" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="907" height="139">
<rect width="907" height="138.569" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_1421_26676)">
<path d="M395.637 190.948C403.967 189.968 412.814 190.744 422.035 193.4C431.255 196.056 440.048 200.427 447.161 206.052L462.551 139.842C454.685 135.837 446.2 132.507 437.142 129.898C428.085 127.289 419.445 125.62 410.979 124.822L395.603 190.971L395.637 190.948Z" fill="#BF63F3"/>
<path d="M261.469 -6.60718L277.516 22.697C259.386 35.2084 255.368 47.2171 263.392 61.8691C271.415 76.5212 283.086 78.4874 301.57 66.6224L317.617 95.9265C281.308 120.141 257.173 111.36 239.002 78.1771C220.831 44.9945 225.177 17.6384 261.486 -6.57639L261.469 -6.60718Z" fill="#BF63F3"/>
<path d="M703.749 28.8725C702.258 30.8363 700.85 33.0593 699.576 35.505C698.302 37.9507 697.228 40.4904 696.449 42.9439C691.72 57.5278 695.81 70.9912 706.42 80.56C708.221 82.1926 710.233 83.7199 712.408 85.1118C714.584 86.5037 716.96 87.7495 719.23 88.7212C732.041 94.0679 744.608 91.2609 753.601 79.5082C755.128 77.5337 756.55 75.2849 757.851 72.7877C759.152 70.2905 760.158 67.8795 760.948 65.4667C765.623 50.9859 761.533 37.5224 750.963 27.8764C749.082 26.1581 747 24.586 744.731 23.1342C742.462 21.6824 740.379 20.5905 738.179 19.6637C725.396 14.2655 712.792 17.0833 703.796 28.9024L703.749 28.8725Z" fill="#FCA700"/>
<path d="M255.266 143.791C253.96 145.508 252.727 147.453 251.612 149.593C250.497 151.733 249.559 153.956 248.879 156.104C244.752 168.871 248.372 180.679 257.715 189.089C259.3 190.524 261.071 191.867 262.985 193.092C264.9 194.316 266.991 195.414 268.987 196.271C280.255 200.986 291.293 198.557 299.172 188.28C300.511 186.554 301.755 184.587 302.894 182.402C304.032 180.217 304.912 178.106 305.601 175.994C309.681 163.316 306.061 151.509 296.753 143.031C295.097 141.521 293.265 140.139 291.268 138.861C289.271 137.584 287.439 136.622 285.504 135.804C274.26 131.044 263.19 133.482 255.307 143.817L255.266 143.791Z" fill="#1868DB"/>
<path d="M540.511 107.628C513.812 119.693 496.617 108.55 490.798 103.608L486.806 160.563C492.136 165.201 507.271 174.615 533.412 162.8C558.209 151.598 574.807 160.41 581.715 165.678L585.707 108.712C579.388 103.898 564.637 96.7202 540.511 107.628Z" fill="#1868DB"/>
<path d="M291.626 241.918L357.357 223.207L378.319 135.456L330.205 106.579L291.626 241.918Z" fill="#82B536"/>
<path d="M207.772 125.619C185.882 119.093 172.032 113.006 164.139 105.765L161.026 181.102C169.161 184.101 177.976 186.914 187.639 189.515C208.795 195.927 221.563 202.214 228.492 210.032L231.68 132.885C224.3 130.298 216.376 127.866 207.772 125.619Z" fill="#FCA700"/>
<path d="M150.358 70.9821L179.701 64.217L192.375 35.669L178.846 6.8247L149.287 -0.588974L125.967 19.0132L126.445 50.8598L150.358 70.9821Z" fill="#82B536"/>
<path d="M487.144 -14.9153C490.073 -3.54747 491.12 8.90597 490.112 22.2702C489.104 35.6344 486.055 48.7625 481.214 59.8175L559.804 67.3853C562.64 55.6224 564.574 43.1392 565.564 30.0109C566.554 16.8825 566.566 4.53097 565.627 -7.39742L487.11 -14.9582L487.144 -14.9153Z" fill="#82B536"/>
<path d="M750.205 108.277C749.527 110.739 749.028 113.403 748.743 116.215C748.458 119.027 748.402 121.838 748.596 124.441C749.655 139.957 758.761 150.597 772.638 154.801C774.997 155.523 777.517 156.055 780.143 156.391C782.77 156.727 785.533 156.841 788.088 156.748C802.457 156.099 813.458 148.032 817.586 133.27C818.296 130.782 818.798 128.088 819.089 125.217C819.38 122.346 819.421 119.683 819.252 117.113C818.181 101.715 809.075 91.0758 795.207 86.7827C792.738 86.0171 790.133 85.4738 787.394 85.1233C784.655 84.7727 782.233 84.6728 779.762 84.7763C765.4 85.3669 754.367 93.4592 750.262 108.284L750.205 108.277Z" fill="#82B536"/>
<path d="M842.951 81.367C858.788 74.077 869.621 70.6339 877.359 70.9258L847.983 24.3268C841.859 26.6939 835.405 29.5186 828.529 32.904C813.18 39.8871 802.914 42.6573 795.515 41.5283L825.596 89.2466C831.096 87.0197 836.858 84.4222 842.951 81.367Z" fill="#BF63F3"/>
<path d="M21.9939 45.6584C21.3276 48.0755 20.8378 50.6918 20.558 53.4527C20.2782 56.2137 20.2233 58.9739 20.4137 61.5301C21.454 76.7656 30.395 87.2126 44.021 91.3409C46.3368 92.0494 48.8118 92.5722 51.3906 92.9022C53.9694 93.2322 56.6827 93.3439 59.1918 93.2528C73.3009 92.6149 84.1031 84.6944 88.1561 70.199C88.853 67.7564 89.3458 65.111 89.6315 62.292C89.9171 59.4729 89.9574 56.8579 89.7918 54.3344C88.7397 39.2151 79.7987 28.7681 66.1814 24.5527C63.7577 23.8009 61.1995 23.2675 58.5098 22.9233C55.8201 22.5791 53.4424 22.4809 51.0165 22.5826C36.9134 23.1624 26.0805 31.1084 22.0493 45.6655L21.9939 45.6584Z" fill="#FCA700"/>
<path d="M598.85 97.498L620.339 107.794L660.44 71.8259L636.967 32.2385L594.924 40.4365L598.85 97.498Z" fill="#FCA700"/>
<path d="M74.9517 165.961C69.6319 171.216 70.6008 179.656 80.2747 191.657C81.3165 192.95 82.6631 193.996 83.7049 195.289L60.3258 218.385C58.1823 216.412 55.801 213.458 52.6756 209.581C28.5406 179.64 29.7458 152.746 47.2172 135.485C60.2366 122.623 72.3341 121.783 84.5526 123.089C93.5012 123.645 99.8746 124.314 104.634 119.612C109.954 114.356 109.01 105.947 99.3113 93.9154L95.9131 89.6996L119.012 66.8797C121.404 69.1603 123.785 72.1144 126.662 75.6839C151.045 105.933 150.148 132.523 132.369 150.087C119.349 162.95 107.004 163.482 95.0086 162.453C86.0848 161.927 79.6867 161.228 74.9268 165.93L74.9517 165.961Z" fill="#BF63F3"/>
<path d="M688.409 187.332L715.888 172.229L721.763 140.276L701.619 115.521L670.609 116.616L652.097 142.736L660.019 174.202L688.409 187.332Z" fill="#1868DB"/>
<path d="M832.929 175.355C830.063 195.446 825.869 213.432 821.573 228.524L880.295 258.791C886.287 239.018 892.347 214.562 896.327 186.668C900.306 158.775 901.351 133.424 901.158 112.547L836.93 120.874C836.813 136.712 835.795 155.264 832.929 175.355Z" fill="#1868DB"/>
<path d="M334.456 35.1284L365.632 24.3972C372.95 48.8477 383.184 56.7618 398.772 51.3962C414.36 46.0306 418.993 32.9989 412.363 8.3117L443.539 -2.41947C457.409 46.3198 443.431 71.9961 408.128 84.1476C372.826 96.2991 348.359 83.8565 334.489 35.1171L334.456 35.1284Z" fill="#1868DB"/>
</g>
</svg>
`;

export const svgStringMobileFooterUrl = `data:image/svg+xml;base64,${Buffer.from(svgStringMobileFooter).toString(
  'base64',
)}`;
