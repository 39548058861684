import React, { createContext, useState, useEffect, ReactNode, useMemo } from 'react';

import { getAtlassianUser } from 'services/http/user';
import { User } from 'typings/types';

interface UserContextProps {
  user: User | null | undefined;
  userIsInitialized?: boolean;
}

interface UserProviderProps {
  userInfo?: User | null;
  userAccountsInitialized?: boolean;
  children: ReactNode;
}

export const UserContext = createContext<UserContextProps | undefined>(undefined);

export const UserProvider: React.FC<UserProviderProps> = ({ children, userInfo, userAccountsInitialized }) => {
  const [user, setUser] = useState<User | null | undefined>(userInfo);
  const [userIsInitialized] = useState<boolean>(userAccountsInitialized ?? false);
  const memoizedUser = useMemo(() => ({ user, userIsInitialized }), [user]);

  useEffect(() => {
    const fetchUser = async (): Promise<void> => {
      const userData = await getAtlassianUser();

      setUser(userData);
    };

    // safeguard in case SSR redirect won't work
    if (!user) {
      fetchUser();
    }
  }, []);

  return <UserContext.Provider value={memoizedUser}> {children}</UserContext.Provider>;
};