import React from 'react';

interface DangerTriangleProps extends React.SVGProps<SVGSVGElement> {}

export const DangerTriangle: React.FC<DangerTriangleProps> = (props: DangerTriangleProps) => {
  return (
    <svg width="96" height="89" viewBox="0 0 96 89" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0.576172 88.4576L0.628536 88.5623H95.366L95.4242 88.4576L48.0002 0.8125L0.576172 88.4576Z"
        fill="#DDDEE1"
      />
      <path d="M48.1641 32.2012V64.207" stroke="white" strokeWidth="7.33" strokeMiterlimit="10" />
      <path
        d="M50.4025 74.1797C50.4316 74.0284 50.4433 73.8655 50.4433 73.6968C50.4433 73.528 50.4316 73.3593 50.4025 73.208C50.2396 72.2946 49.6054 71.736 48.7153 71.5906C48.564 71.5673 48.4069 71.5557 48.2382 71.5557C48.0694 71.5557 47.9007 71.5673 47.7436 71.5964C46.8534 71.7418 46.2251 72.3062 46.0564 73.2138C46.0273 73.3651 46.0156 73.528 46.0156 73.7026C46.0156 73.8771 46.0273 74.0342 46.0564 74.1855C46.2193 75.0931 46.8534 75.6517 47.7436 75.8029C47.9007 75.832 48.0694 75.8437 48.2382 75.8437C48.4069 75.8437 48.564 75.832 48.7153 75.8088C49.6054 75.6633 50.2396 75.0989 50.4025 74.1913V74.1797Z"
        stroke="white"
        strokeWidth="7.33"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
