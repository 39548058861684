// stolen from https://bitbucket.org/atlassian/learning-app-fe/src/master/src/utils/analytics.ts
import { UIEventPayload } from '@atlassiansox/analytics-web-client';
import { Attributes } from '@atlassiansox/analytics-web-client/dist/types/types';
import { CommunityAnalyticsClient, eventType } from '@atlassian/community-library';

let communityClientInstance: CommunityAnalyticsClient | null = null;

export const getCommunityAnalyticsClient = async (userId?: string): Promise<CommunityAnalyticsClient> => {
  if (communityClientInstance) {
    return communityClientInstance;
  }

  const { CommunityAnalyticsClient } = await import('@atlassian/community-library');

  communityClientInstance = CommunityAnalyticsClient;

  CommunityAnalyticsClient.initializeClient(
    CommunityAnalyticsClient.SUB_APP.HOME,
    {},
    null,
    userId || null,
  );

  return communityClientInstance;
};

export const sendScreenEvent = async (name: string, attributes: Attributes, userId?: string): Promise<void> => {
  const analyticsCommunityClient = await getCommunityAnalyticsClient(userId);

  (analyticsCommunityClient as typeof CommunityAnalyticsClient).sendEvent(eventType.SCREEN, {
    name,
    attributes,
  });
};

export const sendOperationalEvent = async (
  source: string,
  actionSubject: string,
  action: string,
  attributes: Attributes,
  userId?: string,
): Promise<void> => {
  const analyticsCommunityClient = await getCommunityAnalyticsClient(userId);

  (analyticsCommunityClient as typeof CommunityAnalyticsClient).sendEvent(eventType.OPERATIONAL, {
    source,
    action,
    actionSubject,
    attributes,
  });
};

export const sendTrackEvent = async (
  source: string,
  actionSubject: string,
  action: string,
  attributes: Attributes,
  userId?: string,
): Promise<void> => {
  const analyticsCommunityClient = await getCommunityAnalyticsClient(userId);

  (analyticsCommunityClient as typeof CommunityAnalyticsClient).sendEvent(eventType.TRACK, {
    source,
    action,
    actionSubject,
    attributes,
  });
};

export const sendUIEvent = async (
  source: string,
  actionSubject: string,
  action: string,
  actionSubjectId?: string,
  attributes?: Attributes,
  userId?: string,
): Promise<void> => {
  const analyticsCommunityClient = await getCommunityAnalyticsClient(userId);

  (analyticsCommunityClient as typeof CommunityAnalyticsClient).sendEvent(eventType.UI, {
    source,
    action,
    actionSubject,
    actionSubjectId,
    attributes,
  });
};

export const sendMarketingUIEvent = async (payload: UIEventPayload, userId?: string): Promise<void> => {
  const analyticsCommunityClient = await getCommunityAnalyticsClient(userId);
  const extendedPayload = {
    ...payload,
    attributes: {
      ...payload.attributes,
    },
  };

  (analyticsCommunityClient as typeof CommunityAnalyticsClient).sendEvent(eventType.UI, extendedPayload);
};
