import { useCallback, useState } from 'react';
import { Button, ButtonAppearance, Link, TagProps, Icon, Tag, Divider, Heading } from '@components/shared';
import { InspectModeContentful } from '@types';
import { inspectorProps } from '@utils/index';
import { css } from '@emotion/react';
import { media } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { ContentType } from '@components/learning/types';

export interface ContentOverviewCardProps {
  /** entryId and fieldId from contentful for the inspector mode */
  inspectMode?: InspectModeContentful;
  /** Defines the states of the card to show or not different buttons */
  state?: ContentOverviewCardState;
  /** Main title of the card */
  heading: string;
  /** Descriptive text of the card */
  subheading: string;
  /** Set of tags that describe the content */
  tags?: TagProps[];
  /** Section title displayed when expanded */
  collapsedSectionTitle?: string;
  /** List of items that are displayed when expanding the section */
  collapsedSectionItems?: string[];
  /** Content displayed when the card section is expanded. It is optional and can be used to receive content in custom markdown */
  collapsedSectionContent?: React.ReactNode;
  /** Type of contentType that the card represents */
  contentType?: ContentType;
  /** Function to be executed when click button card */
  onClickButton?: () => void;
}

export enum ContentOverviewCardState {
  LOADING = 'loading',
  DEFAULT = 'default',
  IN_PROGRESS = 'in_progress',
  FINISHED = 'finished',
}

/**
 * Displays card to show Learning content
 * @param {ContentOverviewCard} MlContentOverviewCardProps
 * @returns {React.ReactElement} React.ReactElement
 *
 * @example
 * <ContentOverviewCard heading="title text" />
 */
export const ContentOverviewCard: React.FC<ContentOverviewCardProps> = ({
  inspectMode,
  state = ContentOverviewCardState.DEFAULT,
  heading,
  subheading,
  tags = [],
  collapsedSectionItems: itemsSectionCollapsed = [],
  collapsedSectionContent: infoSectionCollapsed = '',
  collapsedSectionTitle: titleSectionCollapsed,
  contentType,
  onClickButton,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClickButton = useCallback((): void => {
    if (onClickButton) {
      onClickButton();
    }
  }, [onClickButton]);

  const handleToggleVisibility = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  return (
    <article
      aria-label={heading}
      css={[
        articleContainerStyles,
        state === ContentOverviewCardState.FINISHED && css({ borderLeftColor: token('color.border.success') }),
      ]}
    >
      <div css={containerStyles}>
        <button
          data-testid="toggle-visibility"
          aria-label={isOpen ? 'collapse' : 'expand'}
          onClick={handleToggleVisibility}
          css={buttonStyles}
        >
          {isOpen ? (
            <span css={buttonIconSpanStyles}>
              <Icon type="chevron-down" />
            </span>
          ) : (
            <span css={buttonIconSpanStyles}>
              <Icon type="chevron-right" />
            </span>
          )}
        </button>
        <div css={gridContainerStyles}>
          <div css={gridItemStyles}>
            {tags.length > 0 && (
              <div css={tagContainerStyles}>
                {tags?.map((tag, index) => (
                  <div css={tagStyles} key={`tag-${index}`}>
                    <Tag inspectMode={tag.inspectMode && { tag: tag.inspectMode.duration }} label={tag.label} />
                  </div>
                ))}
              </div>
            )}
            <div {...inspectorProps('title', inspectMode)} css={titleContainerStyles}>
              <Heading level={5}>{heading}</Heading>
            </div>

            <p {...inspectorProps('description', inspectMode)} css={descriptionStyles}>
              {subheading}
            </p>
          </div>

          <div css={gridSideItemStyles}>
            {state === ContentOverviewCardState.LOADING && <Button label="" isLoading={true} />}

            {state === ContentOverviewCardState.DEFAULT && (
              <Button label="Get Started" appearance={ButtonAppearance.PRIMARY} onClick={handleClickButton} />
            )}
            {state === ContentOverviewCardState.IN_PROGRESS && (
              <Button label="Continue" appearance={ButtonAppearance.SECONDARY} onClick={handleClickButton} />
            )}
            {state === ContentOverviewCardState.FINISHED && (
              <div css={finishedStateContainerStyles}>
                <div css={css({ marginRight: token('space.100') })}>
                  <Link
                    link="#"
                    label={`Review ${ContentType.COURSE === contentType ? 'lesson' : 'course'}`}
                    onClick={handleClickButton}
                  />
                </div>
                <Icon type="check-circle" color={token('color.icon.success')} />
              </div>
            )}
          </div>
        </div>
      </div>

      <div data-testid="section-expanded" css={[sectionExpandedStyles, css({ display: isOpen ? 'block' : 'none' })]}>
        <div css={sectionContentStyles}>
          <Divider color={token('color.border')} height={1} />
        </div>
        <div {...inspectorProps('sectionList', inspectMode)} css={sectionListStyles}>
          {titleSectionCollapsed && (
            <div css={sectionTitleStyles}>
              <Heading level={6}>{titleSectionCollapsed}</Heading>
            </div>
          )}
          {itemsSectionCollapsed.length > 0 && (
            <ul css={listStyles} aria-label="collapsed section items">
              {itemsSectionCollapsed?.map((item, index) => (
                <li css={itemStyles} key={`item-${index}`}>
                  {item}
                </li>
              ))}
            </ul>
          )}
          {infoSectionCollapsed && infoSectionCollapsed}
        </div>
      </div>
    </article>
  );
};

const articleContainerStyles = css({
  backgroundColor: token('color.background.input'),
  borderLeft: `8px solid ${token('color.border.brand')}`,
  borderRadius: '8px',
  padding: '16px 24px',
  boxShadow: token('elevation.shadow.raised'),
});

const containerStyles = css({
  padding: '16px 0',
  display: 'flex',
  alignItems: 'flex-start',
});

const buttonStyles = css({
  padding: 0,
  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer',
});

const buttonIconSpanStyles = css({
  marginRight: '16px',
});

const gridContainerStyles = css({
  flex: 1,
  display: 'grid',
  gridTemplateColumns: 'repeat(12, minmax(0, 1fr))',

  [media.above.sm]: {
    columnGap: '16px',
    alignItems: 'center',
  },
});

const gridItemStyles = css({
  gridColumn: 'span 12',
  color: token('color.text'),

  [media.above.sm]: {
    gridColumn: 'span 9',
    color: token('color.text'),
  },
});

const tagContainerStyles = css({
  display: 'flex',
  flexWrap: 'wrap',
});

const tagStyles = css({
  marginRight: '12px',

  '&:last-of-type': {
    marginRight: 0,
  },
});

const titleContainerStyles = css({
  margin: '16px auto',

  [media.above.sm]: {
    marginRight: '1px',
  },
});

const descriptionStyles = css({
  display: 'none',
  fontSize: '16px',
  margin: 0,
  color: token('color.text'),

  [media.above.sm]: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    lineHeight: '1.5',
    color: token('color.text'),
  },
});

const gridSideItemStyles = css({
  gridColumn: 'span 12',

  [media.above.sm]: {
    gridColumn: 'span 3',
    justifySelf: 'end',
  },
});

const finishedStateContainerStyles = css({
  display: 'flex',
  marginRight: '4px',
});

const sectionExpandedStyles = css({
  overflow: 'hidden',
  transition: 'ease-in-out 1000ms',
  paddingLeft: '40px',
});

const sectionContentStyles = css({
  margin: '8px 0',
});

const sectionListStyles = css({
  padding: '24px 0',
});

const sectionTitleStyles = css({
  marginBottom: '8px',
  textTransform: 'uppercase',
});

const listStyles = css({
  color: token('color.text'),
  fontSize: '14px',
  lineHeight: '20px',
  listStyleType: 'disc',
  listStylePosition: 'inside',
  paddingLeft: '4px',
  marginTop: 0,

  [media.above.sm]: {
    fontSize: '16px',
    lineHeight: '1.714',
  },
});

const itemStyles = css({
  marginTop: 0,
  marginLeft: '20px',
  textIndent: '-20px',
});
