import { css } from '@compiled/react';

import {
  Accordion,
  AccordionSize,
  type AccordionTabProps,
} from '@atlassian/learning-components';

/* styles */

const faqContainerStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1440px;
  width: 100%;
  margin: 40px auto 0;
  padding: 40px 20px;
`;

const faqTitleStyles = css`
  font-family: 'Charlie Display';
  text-align: center;
  margin-bottom: 40px;
  font-size: 40px;
  font-weight: 700;
`;

const faqContentStyles = css`
  h4 {
    font-family: 'Charlie Display';
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
  }
  p {
    font-family: 'Inter';
    font-size: 20px;
    line-height: 32px;
    font-weight: 400;
  }
`;


/* texts */
const Q1 = "Who's eligible to become an Atlassian Champion?";
const Q2 = 'How do I maintain my Atlassian Champion status?';
const Q3 = 'Can I join more than one Atlassian Champion track?';

const A1 = (
  <div css={faqContentStyles}>
    <h4>Community Champions</h4>
    <p>
      To be accepted into the Community Champions track as an Event Organizer
      you can apply before your first event, but you must be able to commit to
      contributing to at least one of the following each quarter (90 days):
    </p>
    <ul>
      <li>
        <strong>Host</strong> an Atlassian Community Event in your city (we'll
        cover up to $20 per person, and provide resources to get you started!)
      </li>
    </ul>
    <p>
      For community forums contributors, content creators, speakers, and
      certification SMEs you must already be contributing in one of the
      following ways to be eligible:
    </p>
    <ul>
      <li>
        <strong>Community Forums:</strong> Contribute to the Atlassian Community
        Forums by writing articles, generating discussions, or answering
        questions. Get started by creating a profile and achieving Rising Star
        status.
      </li>
      <li>
        <strong>Creators:</strong> contribute at least two pieces of original
        Atlassian-focused thought leadership or learning content on social media
        per month in the past three months. Get started by sharing your first
        piece of content.
      </li>
      <li>
        <strong>Certification SME:</strong> be an active member of the
        Certification SME program, and have contributed to three Atlassian
        Certification Development projects in the past 12 months.
      </li>
      <li>
        <strong>Speaking:</strong> contribute your expertise as a speaker at at
        least four Atlassian Community Events in the past 12 months.
      </li>
    </ul>
    <h4>Success Champions</h4>
    <p>
      Currently, the Success Champion track is in Beta. Please submit an
      application to join our waitlist. If you'd like to run a company user
      group before the Success Champion track is generally available, head to
      our{' '}
      <a href="https://www.atlassian.com/community/company-user-groups">
        all-in-one user group kit
      </a>
      , and join our{' '}
      <a href="https://community.atlassian.com/forums/Company-User-Groups/gh-p/cug">
        Company User Group online group
      </a>
      .
    </p>
  </div>
);

const A2 = (
  <div css={faqContentStyles}>
    <h4>Community Champions</h4>
    <p>
      To keep your Champion status, you must complete at least one of the
      following activities each quarter (90 days):
    </p>
    <ul>
      <li>Organize and host an Atlassian community event.</li>
      <li>
        Contribute to the Atlassian Community Forums: writing articles,
        generating discussions, or answering questions earning at least 200
        points.
      </li>
      <li>
        Post at least two pieces of original, Atlassian-focused thought
        leadership or learning content on social media each month.
      </li>
      <li>
        Contribute to an Atlassian Certification development project (with a
        minimum of three projects per year).
      </li>
      <li>Present at an Atlassian Community Event.</li>
      <li>
        Host a company user group or knowledge-sharing event (for example, a
        brown bag talk) in your organization.
      </li>
      <li>
        Host a study group for Atlassian Certification preparation in your
        organization.
      </li>
    </ul>
    <p>
      If you contribute to more than one activity you are not required to
      fulfill the quarterly commitments for every single activity you are part
      of. As long as you are contributing to the full requirements for at least
      one you will retain your Atlassian Champion status.
    </p>
    <h4>Success Champions</h4>
    <p>
      While in Beta, activity criteria for Success Champions will be discussed
      at the time of onboarding into the program.
    </p>
  </div>
);

const A3 = (
  <div css={faqContentStyles}>
    <p>
      Yes, Atlassian customers can apply for both the Community Champion and
      Success Champion tracks. Solution and Marketplace Partners can apply for
      the Community Champion track.
    </p>
  </div>
);

const tabsContent: AccordionTabProps[] = [
  {
    title: Q1,
    isExpanded: false,
    children: A1,
  },
  {
    title: Q2,
    isExpanded: false,
    children: A2,
  },
  {
    title: Q3,
    isExpanded: false,
    children: A3,
  },
];

export const FAQ = () => {

  return (
    <section css={faqContainerStyles}>
      <h1 css={faqTitleStyles}>FAQs</h1>
      <Accordion
        tabsContent={tabsContent}
        size={AccordionSize.DEFAULT}
        shouldSingleTabBeOpen={true}
        onChange={() => {}}
      />
    </section>
  );
};
