import { compile } from 'path-to-regexp';
import { qs } from 'url-parse';
export function generateLocationFromPath(pattern, options) {
  if (pattern === void 0) {
    pattern = '/';
  }

  if (options === void 0) {
    options = {};
  }

  const {
    params = {},
    query = {},
    basePath = ''
  } = options; // @ts-ignore stringify accepts two params but it's type doesn't say so

  const stringifiedQuery = qs.stringify(query, true);
  const pathname = pattern === '/' ? pattern : compile(pattern)(params);
  return {
    pathname: `${basePath}${pathname}`,
    search: stringifiedQuery,
    hash: ''
  };
}