"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var types_1 = require("../api/types");
var maptoNewXidCandidate = function (item, promiseStartTime) {
    var state = item.state, type = item.type, value = item.value, createdAt = item.createdAt;
    if (item && state && type && value && createdAt && (state === types_1.XIDState.NEW || state === types_1.XIDState.CHANGED)) {
        return {
            state: state,
            type: type,
            value: value,
            createdAt: createdAt,
            timeTaken: getCurrentTime() - promiseStartTime,
        };
    }
    return null;
};
var maptoExistingXid = function (item) {
    var state = item.state, type = item.type, value = item.value, createdAt = item.createdAt;
    if (item && state && type && value && createdAt && state === types_1.XIDState.EXISTING) {
        return { state: state, type: type, value: value, createdAt: createdAt };
    }
    return null;
};
var mapToAmbiguousXid = function (item) {
    var state = item.state, type = item.type;
    if (item && state && type) {
        switch (state) {
            case types_1.XIDState.TIMEOUT:
            case types_1.XIDState.ERROR:
            case types_1.XIDState.UNAVAILABLE:
            case types_1.XIDState.UNKNOWN:
            case types_1.XIDState.MALFORMED:
                return { type: type, state: state };
        }
    }
    return null;
};
var mapToMalformedXid = function (item) {
    var type = item.type;
    if (item && type) {
        return { type: type, state: types_1.XIDState.MALFORMED };
    }
    return null;
};
function promiseWithTimer(fn) {
    var addTimeTaken = function (item) {
        var fallbackXid = { type: types_1.XIDItemType.UID, state: types_1.XIDState.MALFORMED };
        return (maptoNewXidCandidate(item, start) ||
            maptoExistingXid(item) ||
            mapToAmbiguousXid(item) ||
            mapToMalformedXid(item) ||
            fallbackXid);
    };
    var onPromiseDone = function (data) {
        return data.map(function (item) { return addTimeTaken(item); });
    };
    var start = getCurrentTime();
    return fn().then(onPromiseDone);
}
exports.promiseWithTimer = promiseWithTimer;
var getCurrentTime = function () {
    if (performance && performance.now) {
        return performance.now();
    }
    return new Date().getTime();
};
