import * as Sentry from '@sentry/react';

export const sentryConfig = {
  dsn: 'https://b7c532f28d40f02328083f86125270de@o55978.ingest.us.sentry.io/4507901407592448',
  environment: process.env.REACT_APP_ENVI,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', process.env.HOME_BASE_URL || ''],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
};
