import { useContext, useState, useEffect } from "react";
import { UserContext } from "context/UserContext";
import Error from 'components/error/Error';
import { initializeCommunity } from "@atlassian/community-library";
import LoadingSpinner from "@atlassian/community-library/dist/components/loading-spinner";

type InitValidatorProps = {
  children: React.ReactNode;
};

export const InitValidator: React.FC<InitValidatorProps> = ({children}) => {
  const data = useContext(UserContext);
  const isLoggedIn = !!data?.user;

  const [userIsInitialized, setUserIsInitialized] = useState<boolean>(data?.userIsInitialized ?? false);
  const [initializationError, setInitializationError] = useState<boolean>(false);

  const initializeUser = async () => {
    if (isLoggedIn && data.user?.account_id && !userIsInitialized ) {
      const response = await initializeCommunity(data.user.account_id);
      if (!response) {
        setInitializationError(true);
      }
      setUserIsInitialized(true);
      setInitializationError(false);
    }
  };

  useEffect(() => {
    initializeUser();
  }, [data?.user?.account_id]);

  if (initializationError) {
    return <Error />
  }
  if (!isLoggedIn || userIsInitialized) {
    return <>{children}</>;
  }
  return <LoadingSpinner />
}