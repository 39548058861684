import React from 'react';
import { css } from '@emotion/react';
import { token } from '@atlaskit/tokens';
import { inspectorProps } from '@utils/index';
import { InspectModeContentful } from '@types';

interface CardContentProps {
  /** Title of the card */
  title: string;
  /** Description text */
  description?: string;
  /** Indicates whether the card is part of learning progress, affects title styling and hierarchy */
  isLearningProgress?: boolean;
  /** Contentful inspect mode configuration */
  inspectMode?: InspectModeContentful;
}

export const CardContent: React.FC<CardContentProps> = ({ title, description, isLearningProgress, inspectMode }) => (
  <div data-testid="card-content" css={titleAndDetailsContainerStyles}>
    {isLearningProgress ? (
      <h4 {...inspectorProps('title', inspectMode)} css={withProgressTitleStyles}>
        {title}
      </h4>
    ) : (
      <h5 {...inspectorProps('title', inspectMode)} css={catalogTitleStyles}>
        {title}
      </h5>
    )}
    {description && (
      <p data-testid="description" {...inspectorProps('description', inspectMode)} css={descriptionStyles}>
        {description}
      </p>
    )}
  </div>
);

const titleAndDetailsContainerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: `0 ${token('space.300')} ${token('space.300')}`,
});

const baseTitleStyles = css({
  display: '-webkit-box',
  WebkitLineClamp: 3,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  marginBottom: 0,
  fontFamily: 'Charlie Display, sans-serif',
  color: token('color.text'),
});

const learningProgressTitleStyles = css({
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  marginTop: '20px !important',
  marginBottom: 0,
  fontFamily: 'Charlie Display, sans-serif',
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: '32px',
  color: token('color.text'),
  height: '64px',
});

const withProgressTitleStyles = css([
  learningProgressTitleStyles,
  {
    fontSize: '24px',
    lineHeight: '32px',
    marginTop: '0 !important',
  },
]);

const catalogTitleStyles = css([
  baseTitleStyles,
  {
    fontSize: '20px',
    lineHeight: '28px',
    marginTop: `${token('space.150')} !important`,
  },
]);

const descriptionStyles = css({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  overflow: 'hidden',
  marginTop: token('space.250'),
  marginBottom: 0,
  fontFamily: 'Inter, sans-serif',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  color: token('color.text.subtle'),
});
