import React, { useEffect, useState } from 'react';
import {
  Button,
  ButtonAppearance,
  Checkbox,
  CheckboxSize,
  CheckboxType,
  Icon,
  Radio,
  RadioSize,
  RadioType,
  InlineFeedbackMessage,
  InlineFeedbackMessageAppearance,
  ProgressBar,
  Image,
} from '@components/index';
import { InspectModeContentful } from '../../../types';
import { inspectorProps } from '../../../utils';
import { css } from '@emotion/react';
import { media } from '@atlaskit/primitives/responsive';
import { useDeviceSize } from '../../../hooks/useDeviceSize';
import shuffle from 'lodash.shuffle';
import { InputStatus, QuizInputs } from './children/QuizInputs';
import { token } from '@atlaskit/tokens';

export interface QuizProps {
  /** entryId and fieldId from contentful for the inspector mode */
  inspectMode?: InspectModeContentful;
  title: string;
  questions: QuizQuestion[];
  startingQuestion?: number;
  onCompletion: () => void;
  step: number;
  setStep: (step: number) => void;
  onInteraction: () => void;
  isCompleted?: boolean;
  setIsCompleted: (completed: boolean) => void;
  isLoggedInUser: boolean;
  handleUserLogin: () => void;
  lessonId: string;
  onAnswerSubmit?: (type: QuestionType, status: QuestionStatus) => void;
}

export interface QuizQuestion {
  /** entryId and fieldId from contentful for the inspector mode */
  inspectMode?: InspectModeContentful;
  title: React.ReactElement;
  imageUrl?: string;
  feedbackMessage: React.ReactElement;
  answers: Answer[];
  leftAnswers?: Answer[];
  rightAnswers?: Answer[];
  type: QuestionType;
  onCompletion?: () => void;
}

export interface Answer {
  /** entryId and fieldId from contentful for the inspector mode */
  inspectMode?: InspectModeContentful;
  title: string;
  correct: boolean;
  matchingAnswer?: MatchingAnswer;
  isLeftMatchingAnswer?: boolean;
}

export interface MatchingAnswer {
  /** entryId and fieldId from contentful for the inspector mode */
  inspectMode?: InspectModeContentful;
  title: string;
}

export enum QuestionType {
  SINGLE_SELECT = 'SINGLE_SELECT',
  MULTI_SELECT = 'MULTI_SELECT',
  MATCHING = 'MATCHING',
}

export enum QuizStatus {
  DEFAULT = 'DEFAULT',
  ERROR = 'ERROR',
  PARTLY_CORRECT = 'PARTLY_CORRECT',
  SUCCESS = 'SUCCESS',
}

export enum QuestionStatus {
  INCORRECT = 'INCORRECT',
  PARTLY_CORRECT = 'PARTLY_CORRECT',
  CORRECT = 'CORRECT',
}

export enum MatchingAnswerStatus {
  DEFAULT = 'DEFAULT',
  INCORRECT = 'INCORRECT',
  CORRECT = 'CORRECT',
}

export enum ActionDescription {
  SINGLE_SELECT = 'Select the correct answer.',
  MULTI_SELECT = 'Select all correct answers.',
  MATCHING = 'Select the matching pairs.',
}

const QuizStatusToCheckboxType: Record<QuizStatus, CheckboxType> = {
  [QuizStatus.DEFAULT]: 'DEFAULT' as CheckboxType.DEFAULT,
  [QuizStatus.SUCCESS]: 'SUCCESS' as CheckboxType.SUCCESS,
  [QuizStatus.ERROR]: 'DANGER' as CheckboxType.DANGER,
  [QuizStatus.PARTLY_CORRECT]: 'DANGER' as CheckboxType.DANGER,
};

const QuizStatusToRadioType: Record<QuizStatus, RadioType> = {
  [QuizStatus.DEFAULT]: 'DEFAULT' as RadioType.DEFAULT,
  [QuizStatus.SUCCESS]: 'SUCCESS' as RadioType.SUCCESS,
  [QuizStatus.ERROR]: 'DANGER' as RadioType.DANGER,
  [QuizStatus.PARTLY_CORRECT]: 'DANGER' as RadioType.DANGER,
};

const MOBILE_SCREEN_WIDTH = 480;
const NAVBAR_HEIGHT = 124;

export const Quiz: React.FC<QuizProps> = ({
  inspectMode,
  title,
  questions = [],
  onCompletion,
  step = 0,
  setStep,
  onInteraction,
  isCompleted = false,
  setIsCompleted,
  isLoggedInUser,
  handleUserLogin,
  lessonId,
  onAnswerSubmit,
}) => {
  const [questionAnswers, setQuestionAnswers] = useState<Answer[]>([]);
  const [selectedAnswers, setSelectedAnswers] = useState<Answer[]>([]);
  const [status, setStatus] = useState<QuizStatus>(QuizStatus.DEFAULT);
  const [answersStatus, setAnswersStatus] = useState<Record<string, QuizStatus>>({});
  const [matchingAnswersStatus, setMatchingAnswersStatus] = useState<null | string>(MatchingAnswerStatus.DEFAULT);
  const [correctAnswersArray, setCorrectAnswersArray] = useState<Answer[]>([]);
  const [incorrectAnswersArray, setIncorrectAnswersArray] = useState<Answer[]>([]);
  const [lastSubmittedQuestion, setLastSubmittedQuestion] = useState<{ lessonId: string; step: number }>({
    lessonId: '',
    step: 0,
  });

  const width = useDeviceSize();
  const isNextButtonDisabled = step + 1 !== questions.length ? !isCompleted && status !== QuizStatus.SUCCESS : true;
  const isMatchingFeedbackVisible =
    questions[step]?.type === QuestionType.MATCHING &&
    matchingAnswersStatus !== MatchingAnswerStatus.DEFAULT &&
    !isCompleted;

  //set quiz status after initialization
  useEffect(() => {
    if (lastSubmittedQuestion.lessonId !== lessonId) {
      setLastSubmittedQuestion({ lessonId: lessonId, step: 0 });
    }

    if (isCompleted && lessonId && questions.length) {
      setStatus(QuizStatus.SUCCESS);
      onSubmittedQuestionsRecheck(questions.length - 1);
    } else {
      setStatus(QuizStatus.DEFAULT);
      setSelectedAnswers([]);
    }
  }, [lessonId, isCompleted]);

  //set quiz last submitted question step after initialization
  useEffect(() => {
    setLastSubmittedQuestion((prevState) => {
      if (prevState.lessonId === lessonId && !prevState.step) {
        return { ...prevState, step: step };
      } else {
        return { ...prevState };
      }
    });
  }, [step, lastSubmittedQuestion.lessonId]);

  useEffect(() => {
    checkMatchingQuestionCompleted();
  }, [correctAnswersArray]);

  useEffect(() => {
    const isLastCompletedQuestion = status === QuizStatus.SUCCESS && lastSubmittedQuestion.step === questions.length;
    if (questions.length && !isLastCompletedQuestion) {
      setQuestionAnswers(questions[step]?.answers);
    }
  }, [step, questions]);

  const handleOnAnswerSubmit = (type: QuestionType, status: QuestionStatus): void => {
    if (onAnswerSubmit) {
      onAnswerSubmit(type, status);
    }
  };

  const toggleAnswer = (answer: Answer): void => {
    onInteraction();
    if (status !== QuizStatus.DEFAULT) {
      return;
    }
    if (questions[step]?.type === QuestionType.SINGLE_SELECT) {
      setSelectedAnswers([answer]);
    }
    if (questions[step]?.type === QuestionType.MULTI_SELECT) {
      if (selectedAnswers.includes(answer)) {
        setSelectedAnswers((a) => a.filter((ans) => ans !== answer));
      } else {
        setSelectedAnswers((a) => [...a, answer]);
      }
    }
    if (questions[step]?.type === QuestionType.MATCHING) {
      if (isLoggedInUser) {
        toggleMatchingQuestion(answer);
      } else {
        handleUserLogin();
      }
    }
  };

  const toggleMatchingQuestion = (answer: Answer): void => {
    if (
      selectedAnswers.length &&
      selectedAnswers[0].title !== answer.title &&
      selectedAnswers[0].isLeftMatchingAnswer !== answer.isLeftMatchingAnswer
    ) {
      if (selectedAnswers[0].matchingAnswer?.title === answer.title && !correctAnswersArray.includes(answer)) {
        const correctAnswers = [...selectedAnswers, answer];
        handleOnAnswerSubmit(QuestionType.MATCHING, QuestionStatus.CORRECT);
        setMatchingAnswersStatus(MatchingAnswerStatus.CORRECT);
        setSelectedAnswers([...correctAnswers]);
        setCorrectAnswersArray((a) => [...a, ...correctAnswers]);
        setTimeout(() => {
          setSelectedAnswers([]);
          setMatchingAnswersStatus(MatchingAnswerStatus.DEFAULT);
        }, 500);
      } else {
        const incorrectAnswers = [...selectedAnswers, answer];
        handleOnAnswerSubmit(QuestionType.MATCHING, QuestionStatus.INCORRECT);
        setMatchingAnswersStatus(MatchingAnswerStatus.INCORRECT);
        setSelectedAnswers([...incorrectAnswers]);
        setIncorrectAnswersArray((a) => [...a, ...incorrectAnswers]);
        setTimeout(() => {
          setSelectedAnswers([]);
          setMatchingAnswersStatus(MatchingAnswerStatus.DEFAULT);
        }, 500);
      }
    } else {
      setSelectedAnswers([answer]);
      setIncorrectAnswersArray([]);
    }
  };

  const checkMatchingQuestionCompleted = (): void => {
    const leftAnswers = questions[step]?.leftAnswers!;
    const rightAnswers = questions[step]?.rightAnswers!;
    if (correctAnswersArray.length === leftAnswers?.length + rightAnswers?.length) {
      setStatus(QuizStatus.SUCCESS);
      setLastSubmittedQuestion((prevState) => {
        return { ...prevState, step: step + 1 };
      });
      const questionOnCompletion = questions[step]?.onCompletion;
      if (questionOnCompletion) {
        questionOnCompletion();
      }
      if (onCompletion && step + 1 === questions.length) {
        onCompletion();
        setIsCompleted(true);
      }
    }
  };

  const handleAnswerSubmit = (): void => {
    if (isLoggedInUser) {
      onSubmit();
    } else {
      handleUserLogin();
    }
  };

  const onSubmit = (): void => {
    const answers = questions[step]?.answers;
    const correctAnswers = answers.filter((ans) => ans.correct);
    const totalSteps = questions.length;
    const questionOnCompletion = questions[step]?.onCompletion;
    const atLeastOneCorrectAnswer = !!correctAnswers.filter((ans) => selectedAnswers.includes(ans)).length;
    if (
      correctAnswers.length === selectedAnswers.length &&
      correctAnswers.every((ans) => selectedAnswers.includes(ans))
    ) {
      if (step + 1 === totalSteps) {
        /** completed quiz */
        setIsCompleted(true);

        if (questionOnCompletion) {
          questionOnCompletion();
        }
        if (onCompletion) {
          onCompletion();
        }
      } else {
        /** unfinished quiz */
        if (questionOnCompletion) {
          questionOnCompletion();
        }
      }
      setStatus(QuizStatus.SUCCESS);
      handleOnAnswerSubmit(questions[step]?.type, QuestionStatus.CORRECT);
      setLastSubmittedQuestion((prevState) => {
        return { ...prevState, step: step + 1 };
      });
    } else {
      if (atLeastOneCorrectAnswer) {
        setStatus(QuizStatus.PARTLY_CORRECT);
        handleOnAnswerSubmit(questions[step]?.type, QuestionStatus.PARTLY_CORRECT);
      } else {
        setStatus(QuizStatus.ERROR);
        handleOnAnswerSubmit(questions[step]?.type, QuestionStatus.INCORRECT);
      }
    }

    setAnswersStatus(
      selectedAnswers.reduce(
        (prev, curr) => ({
          ...prev,
          [curr.title]: answers.find((a) => a.title === curr.title)?.correct ? QuizStatus.SUCCESS : QuizStatus.ERROR,
        }),
        {},
      ),
    );
  };

  const onTryAgain = (): void => {
    const element = document.getElementById('quiz-question-container');

    if (element) {
      const topPosition = element.getBoundingClientRect().top + window.scrollY - NAVBAR_HEIGHT;
      window.scrollTo({ top: topPosition, behavior: 'smooth' });
    }

    setStatus(QuizStatus.DEFAULT);
    setSelectedAnswers([]);
    setAnswersStatus({});
    setQuestionAnswers((prevState) => shuffle(prevState));
  };

  const onSubmittedQuestionsRecheck = (step: number): void => {
    const prevQuestionAnswers = questions[step].answers;
    const correctAnswers = prevQuestionAnswers.filter((answer) => answer.correct);
    setStep(step);
    setStatus(QuizStatus.SUCCESS);
    setSelectedAnswers(correctAnswers);

    setAnswersStatus(
      correctAnswers.reduce(
        (prev, curr) => ({
          ...prev,
          [curr.title]: questions[step].answers.find((a) => a.title === curr.title)?.correct
            ? QuizStatus.SUCCESS
            : QuizStatus.ERROR,
        }),
        {},
      ),
    );
  };

  const onNextQuestionClick = (): void => {
    const nextQuestionStep = step + 1;
    const element = document.getElementById('quiz-question-container');

    if (element) {
      const topPosition = element.getBoundingClientRect().top + window.scrollY - NAVBAR_HEIGHT;
      window.scrollTo({ top: topPosition, behavior: 'smooth' });
    }

    if (nextQuestionStep < lastSubmittedQuestion.step || isCompleted) {
      onSubmittedQuestionsRecheck(step + 1);
    } else {
      setStatus(QuizStatus.DEFAULT);
      setSelectedAnswers([]);
      setCorrectAnswersArray([]);
      setStep(step + 1);
      setAnswersStatus({});
    }
  };

  const onPrevQuestionClick = (): void => {
    onInteraction();
    onSubmittedQuestionsRecheck(step - 1);
  };

  const getQuizProgress = (): number => {
    const progress = isCompleted ? questions.length : lastSubmittedQuestion.step;
    return (progress / questions.length) * 100;
  };

  const getInputStatus = (answer: Answer): InputStatus => {
    if (correctAnswersArray.includes(answer) && selectedAnswers.includes(answer)) {
      return InputStatus.CORRECT;
    }
    if (incorrectAnswersArray.includes(answer) && selectedAnswers.includes(answer)) {
      return InputStatus.INCORRECT;
    }
    if (correctAnswersArray.includes(answer) || status === QuizStatus.SUCCESS) {
      return InputStatus.DISABLED;
    }
    if (selectedAnswers.includes(answer)) {
      return InputStatus.SELECTED;
    }
    return InputStatus.DEFAULT;
  };

  const getAnswerStatus = (answer: Answer): boolean => !!selectedAnswers.find((a) => a.title === answer.title);

  return (
    <div css={quizContainerStyles}>
      <p {...inspectorProps('title', inspectMode)} css={quizTitleStyles}>
        {title}
      </p>
      <div css={quizContentContainerStyles} id="quiz-question-container" data-testid="quiz-question-container">
        <div css={quizHeaderContainerStyles}>
          <div css={quizActionPanelContainerStyles}>
            <Button
              label="back-button"
              disabled={!step}
              iconBefore={<Icon type="arrow-left-circle" />}
              appearance={ButtonAppearance.ICON}
              onClick={onPrevQuestionClick}
              iconType="arrow-left-circle"
            />
            <span css={questionsStepStyles}>
              QUESTION {step + 1} 0F {questions.length}
            </span>
            <span css={desktopScreenProgressContainerStyles}>
              <ProgressBar progress={getQuizProgress()} />
            </span>
            <Button
              label="next-button"
              disabled={isNextButtonDisabled}
              iconBefore={<Icon type="arrow-right-circle" />}
              appearance={ButtonAppearance.ICON}
              onClick={onNextQuestionClick}
              iconType="arrow-right-circle"
            />
          </div>
          <span css={mobileScreenProgressContainerStyles}>
            <ProgressBar progress={getQuizProgress()} />
          </span>
        </div>
        <p {...inspectorProps('title', questions[step]?.inspectMode)} css={questionTitleStyles}>
          {questions[step]?.title}
        </p>
        {questions[step] && questions[step].imageUrl && (
          <div css={questionImageStyles}>
            <Image src={questions[step].imageUrl!} alt={questions[step]?.title.props.children} />
          </div>
        )}
        <p css={chooseCorrectAnswerStyles}>{ActionDescription[questions[step]?.type]}</p>
        {questions[step]?.type === QuestionType.MATCHING && (
          <div>
            {questions[step]?.leftAnswers?.map((leftAnswer, index) => (
              <div
                css={quizInputsContainerStyles}
                key={leftAnswer.title + questions[step]?.rightAnswers?.[index]?.title}
              >
                <QuizInputs
                  inspectMode={leftAnswer?.inspectMode}
                  elementId={leftAnswer.title}
                  text={leftAnswer.title}
                  onClick={(): void => toggleAnswer(leftAnswer)}
                  inputStatus={getInputStatus(leftAnswer)}
                />
                <QuizInputs
                  inspectMode={questions[step]?.rightAnswers?.[index]?.inspectMode}
                  elementId={questions[step]?.rightAnswers?.[index]?.title}
                  text={questions[step]?.rightAnswers?.[index]?.title}
                  onClick={(): void => toggleAnswer(questions[step]?.rightAnswers?.[index]!)}
                  inputStatus={getInputStatus(questions[step]?.rightAnswers?.[index]!)}
                />
              </div>
            ))}
          </div>
        )}
        {(questions[step]?.type === QuestionType.SINGLE_SELECT ||
          questions[step]?.type === QuestionType.MULTI_SELECT) &&
          questionAnswers?.map((answer) => (
            <div css={answerContainerStyles} key={answer.title} {...inspectorProps('title', answer?.inspectMode)}>
              {questions[step]?.type === QuestionType.SINGLE_SELECT ? (
                <Radio
                  size={width >= MOBILE_SCREEN_WIDTH ? RadioSize.LARGE : RadioSize.DEFAULT}
                  label={answer.title}
                  isChecked={getAnswerStatus(answer)}
                  setIsChecked={(): void => toggleAnswer(answer)}
                  type={QuizStatusToRadioType[answersStatus[answer.title]]}
                  isBold={getAnswerStatus(answer)}
                />
              ) : (
                <Checkbox
                  size={width >= MOBILE_SCREEN_WIDTH ? CheckboxSize.LARGE : CheckboxSize.DEFAULT}
                  label={answer.title}
                  onChange={(): void => toggleAnswer(answer)}
                  isChecked={getAnswerStatus(answer)}
                  type={QuizStatusToCheckboxType[answersStatus[answer.title]]}
                  isBold={getAnswerStatus(answer)}
                />
              )}
            </div>
          ))}
        {!isCompleted && status === QuizStatus.DEFAULT && questions[step]?.type !== QuestionType.MATCHING && (
          <div
            css={[
              buttonContainerStyles,
              css({
                borderTop: `1px solid ${token('color.border')}`,
                paddingTop: '32px',
              }),
            ]}
          >
            <Button
              label="Submit answer"
              appearance={ButtonAppearance.PRIMARY}
              onClick={handleAnswerSubmit}
              disabled={selectedAnswers.length === 0 || questions[step]?.type === QuestionType.MATCHING}
            />
          </div>
        )}
        {(status === QuizStatus.SUCCESS || questions[step]?.type === QuestionType.MATCHING) && (
          <div css={buttonContainerStyles}>
            <div
              css={[
                feedbackContainerStyles,
                status !== QuizStatus.SUCCESS && css({ borderTop: `1px solid ${token('color.border')}` }),
              ]}
            >
              {status === QuizStatus.SUCCESS && (
                <InlineFeedbackMessage
                  title={questions[step]?.type === QuestionType.MATCHING ? 'Correct' : 'That’s correct'}
                  appearance={InlineFeedbackMessageAppearance.CORRECT}
                  description={questions[step]?.feedbackMessage}
                />
              )}
              {(!isCompleted || (questions[step]?.type === QuestionType.MATCHING && !isCompleted)) && (
                <span css={css({ marginTop: '32px' })}>
                  <Button
                    disabled={status !== QuizStatus.SUCCESS}
                    label="Next question"
                    appearance={ButtonAppearance.PRIMARY}
                    onClick={onNextQuestionClick}
                    iconAfter={<Icon type="arrow-right" />}
                  />
                </span>
              )}
            </div>
          </div>
        )}
        {(status === QuizStatus.ERROR || status === QuizStatus.PARTLY_CORRECT) && (
          <div css={buttonContainerStyles}>
            <div css={feedbackContainerStyles}>
              <InlineFeedbackMessage
                title={status === QuizStatus.PARTLY_CORRECT ? 'That’s partly correct' : 'That’s not correct'}
                appearance={InlineFeedbackMessageAppearance.INCORRECT}
                description={questions[step]?.feedbackMessage}
              />
              <span css={css({ marginTop: '32px' })}>
                <Button
                  label="Try again"
                  iconBefore={<Icon type="refresh" />}
                  appearance={ButtonAppearance.PRIMARY}
                  onClick={onTryAgain}
                />
              </span>
            </div>
          </div>
        )}
        {isCompleted && <p css={completionMessageStyles}>🎉 Quiz complete! Great work.</p>}
      </div>
      {isMatchingFeedbackVisible && (
        <span css={matchingFeedbackContainerStyles}>
          <InlineFeedbackMessage
            title={matchingAnswersStatus === MatchingAnswerStatus.INCORRECT ? 'That’s incorrect' : 'That’s correct'}
            appearance={
              matchingAnswersStatus === MatchingAnswerStatus.INCORRECT
                ? InlineFeedbackMessageAppearance.INCORRECT
                : InlineFeedbackMessageAppearance.CORRECT
            }
          />
        </span>
      )}
    </div>
  );
};

const quizContainerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const quizContentContainerStyles = css({
  padding: '32px 48px',
  borderRadius: '8px',
  boxShadow: token('elevation.shadow.raised'),
  maxWidth: '720px',
  width: '100%',
  backgroundColor: token('color.background.input'),

  [media.below.md]: {
    maxWidth: '720px',
    width: '100%',
  },

  [media.below.xs]: {
    padding: '24px 16px',
  },
});

const quizHeaderContainerStyles = css({
  marginBottom: '32px',
  paddingBottom: '28px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${token('color.border')}`,
  flexDirection: 'row',

  [media.below.sm]: {
    flexDirection: 'column',
  },

  [media.below.xs]: {
    marginBottom: '24px',
    paddingBottom: '24px',
  },
});

const quizActionPanelContainerStyles = css({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const quizTitleStyles = css({
  fontFamily: 'Charlie Display, sans-serif',
  fontSize: '40px',
  fontWeight: 700,
  lineHeight: '48px',
  letterSpacing: '-0.12px',
  marginTop: 0,
  marginBottom: '32px',
  textAlign: 'center',

  [media.below.xs]: {
    display: 'none',
  },
});

const questionTitleStyles = css({
  fontFamily: 'Charlie Display, sans-serif',
  fontSize: '20px',
  fontWeight: 600,
  lineHeight: '28px',
  color: token('color.text'),
  marginTop: 0,
  marginBottom: '32px',

  [media.below.xs]: {
    fontSize: '16px',
    lineHeight: '24px',
    marginBottom: '24px',
  },
});

const questionImageStyles = css({
  padding: '24px 0',
});

const desktopScreenProgressContainerStyles = css({
  width: '100%',
  margin: '0 24px 0 13px',

  [media.below.sm]: {
    display: 'none',
  },
});

const mobileScreenProgressContainerStyles = css({
  [media.below.sm]: {
    width: '100%',
    margin: 0,
    marginTop: '12px',
  },
});

const chooseCorrectAnswerStyles = css({
  fontFamily: 'Charlie Display, sans-serif',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
  color: token('color.text.subtlest'),
  marginTop: 0,
  marginBottom: '24px',

  [media.below.xs]: {
    fontSize: '14px',
    lineHeight: '22px',
    marginBottom: '16px',
  },
});

const answerContainerStyles = css({
  marginBottom: '24px',

  [media.below.xs]: {
    marginBottom: '20px',
  },
});

const quizInputsContainerStyles = css({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  gap: '24px',
  marginTop: '24px',
});

const buttonContainerStyles = css({
  display: 'flex',
  alignItems: 'center',
  marginTop: '32px',

  [media.below.xs]: {
    marginTop: '24px',
  },
});

const feedbackContainerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

const questionsStepStyles = css({
  minWidth: '115px',
  marginLeft: '24px',
  color: token('color.text'),
  fontFamily: 'Charlie Text, sans-serif',
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '18px',
});

const completionMessageStyles = css({
  marginTop: '40px',
  marginBottom: 0,
  color: token('color.text'),
  fontFamily: 'Charlie Display, sans-serif',
  fontSize: '20px',
  fontWeight: 600,
  lineHeight: '28px',
  textAlign: 'center',
});

const matchingFeedbackContainerStyles = css({
  marginTop: '40px',
  maxWidth: '624px',
  width: '100%',

  [media.below.xs]: {
    display: 'none',
  },
});
