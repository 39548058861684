/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { R400 } from '@atlaskit/theme/colors';
var requiredIndicatorStyles = css({
  color: "var(--ds-text-danger, ".concat(R400, ")"),
  fontFamily: "var(--ds-font-family-body, ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)",
  paddingInlineStart: "var(--ds-space-025, 2px)"
});
export default function RequiredAsterisk() {
  return jsx("span", {
    css: requiredIndicatorStyles,
    "aria-hidden": "true",
    title: "required"
  }, "*");
}