import React, { ReactNode, useRef, useState } from 'react';
import { useMediaQuery } from '@hooks/useMediaQuery';
import { useOutsideClickHandler } from '@hooks/useOutsideClickHandler';
import { BREAKPOINTS } from '@constants/breakpoints';
import { NavBarExpandableItem } from '@components/shared/NavigationBar/NavBarLinkItem/NavBarExpandableItem';
import Avatar from '@atlaskit/avatar';
import { css } from '@emotion/react';
import { token } from '@atlaskit/tokens';

export interface LoggedInUser {
  id: string;
  publicName: string;
  username?: string;
  avatar: string;
  email?: string;
}

export interface NavBarProfileItemProps {
  /** @type {LoggedInUser} the current logged in user */
  user: LoggedInUser;
  /** @type {ReactNode} the content to be displayed in the mobile/tablet vertical menu on clicking user's name */
  expandableMenuContent?: ReactNode;
  /** @type {ReactNode} the content to be displayed in the desktop on clicking user avatar */
  profileMenuContent?: ReactNode;
  /** (optional) additional function to be called when the user clicks on the profile menu, called immediately after
   * the menu is opened/closed (can be used for sending analytics events)
   */
  onProfileMenuClick?: (isMenuOpen: boolean) => void;
}

const getAvatarUserHeaderForMobile = (user: LoggedInUser, isExpanded: boolean): React.ReactNode => (
  <div css={[headerContentStyles, isExpanded && avatarUserSelectedStyles]}>
    <Avatar appearance="circle" key={user.publicName} src={user.avatar} size="small" name={user.publicName} />
    <div css={nameStyles}>{user.publicName}</div>
  </div>
);

export const NavBarProfileItem: React.FC<NavBarProfileItemProps> = ({
  user,
  expandableMenuContent,
  profileMenuContent,
  onProfileMenuClick,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isBreakpoint = useMediaQuery(`(max-width: ${BREAKPOINTS.TABLET.MAX_WIDTH}px)`);

  const avatarMenuRef = useRef(null);
  useOutsideClickHandler([avatarMenuRef], () => setIsMenuOpen(false));

  const onProfileMenuClickHandler = (): void => {
    setIsMenuOpen(!isMenuOpen);
    if (onProfileMenuClick) {
      onProfileMenuClick(isMenuOpen);
    }
  };

  if (isBreakpoint) {
    return (
      <NavBarExpandableItem
        headerContent={getAvatarUserHeaderForMobile(user, false)}
        expandedHeaderContent={getAvatarUserHeaderForMobile(user, true)}
        bodyContent={expandableMenuContent}
        onExpandClick={onProfileMenuClick}
      />
    );
  }
  return (
    <span ref={avatarMenuRef}>
      <button css={[avatarStyles, isMenuOpen && avatarUserSelectedStyles]} onClick={onProfileMenuClickHandler}>
        <Avatar appearance="circle" key={user.publicName} src={user.avatar} size="medium" name={user.publicName} />
      </button>
      {isMenuOpen && profileMenuContent ? (
        <ul css={openMenuStyles} aria-label="Profile menu" role="menu">
          {profileMenuContent}
        </ul>
      ) : null}
    </span>
  );
};

const headerContentStyles = css({
  display: 'flex',
  gap: '8px',
  fontSize: '1rem',
});

const nameStyles = css({
  alignSelf: 'center',
});

const avatarStyles = css({
  all: 'unset',
  cursor: 'pointer',
  marginTop: '3px',

  '&:focus-visible': {
    outline: `1px solid ${token('color.border.selected')}`,
  },
});

const avatarUserSelectedStyles = css({
  color: token('color.text.selected'),
  '& > * > span': {
    outline: `2px ${token('color.text.selected')} solid`,
  },
});

const openMenuStyles = css({
  backgroundColor: '#FFFFFF',
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  right: 0,
  zIndex: 10,
  borderRadius: '4px',
  boxShadow: '0 0 1px 0 #ccc,0 5px 20px -5px rgba(0,0,0,.1)',
  listStyle: 'none',
  margin: 0,
  padding: 0,
});
