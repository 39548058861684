import React from 'react';
import { css } from '@emotion/react';
import { token } from '@atlaskit/tokens';

interface CardImageProps {
  /** URL of the background image */
  imageUrl?: string;
  /** URL of the product icon */
  productIcon?: string;
  /** Background color to use when no image is provided */
  backgroundColor?: string;
  /** Indicates whether the card is part of learning progress, affects image height */
  isLearningProgress?: boolean;
}

export const CardImage: React.FC<CardImageProps> = ({ imageUrl, backgroundColor, productIcon, isLearningProgress }) => (
  <div
    css={[
      imageBaseStyles,
      !imageUrl && noImageStyles,
      css(
        {
          backgroundColor,
        },
        isLearningProgress && { height: '96px' },
        !isLearningProgress && { height: '80px' },
      ),
    ]}
    style={{ backgroundImage: `url(${imageUrl})` }}
    data-testid="card-image"
  >
    {productIcon && <img css={css({ marginLeft: '26px' })} src={productIcon} alt="product icon" />}
  </div>
);

const imageBaseStyles = css({
  width: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  borderRadius: '7px 7px 0 0',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
});

const noImageStyles = css({
  background: token('color.background.accent.gray.subtlest'),
});
