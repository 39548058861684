import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/objectWithoutPropertiesLoose";
const _excluded = ["context"],
      _excluded2 = ["error", "expiresAt", "loading"];
import { createActionsHook, createContainer, createHook, createStore, createSubscriber, defaultRegistry } from 'react-sweet-state';
import { isServerEnvironment } from '../../../common/utils';
import { getResourceStoreContext, getSliceForResource } from './selectors';
import { deserializeError, getAccessedAt, getExpiresAt, isFromSsr, serializeError, setExpiresAt, shouldUseCache, transformData, TimeoutError, setSsrDataPromise, getResourceState, setResourceState, deleteResourceState, validateLRUCache, actionWithDependencies, mapActionWithDependencies, executeForDependents, getDependencies, getDefaultStateSlice, getPrefetchSlice, setPrefetchSlice, createLoadingSlice } from './utils';
export { createResource, ResourceDependencyError } from './utils';
export const privateActions = {
  /**
   * Clears a resource for the current key, or where context is not provided all keys.
   */
  clearResource: (resource, routerStoreContext) => _ref => {
    let {
      getState,
      dispatch
    } = _ref;
    const {
      type,
      getKey
    } = resource;
    const {
      context
    } = getState();

    if (routerStoreContext) {
      const key = getKey(routerStoreContext, context);
      dispatch(deleteResourceState(type, key));
      dispatch(executeForDependents(resource, dependentResource => privateActions.clearResource(dependentResource, routerStoreContext)));
    } else {
      dispatch(deleteResourceState(type));
    }
  },

  /**
   * Update the data property for a resource in the cache and reset expiresAt based
   * on maxAge.
   */
  updateResourceState: (resource, routerStoreContext, getNewSliceData) => _ref2 => {
    let {
      getState,
      dispatch
    } = _ref2;
    const {
      type,
      getKey,
      maxAge
    } = resource;

    const _getState = getState(),
          {
      context
    } = _getState,
          resourceStoreState = _objectWithoutPropertiesLoose(_getState, _excluded);

    const key = getKey(routerStoreContext, context);
    const prevSlice = getSliceForResource(resourceStoreState, {
      type,
      key
    });
    const data = getNewSliceData(prevSlice.data);
    const changes = prevSlice.loading ? {
      data,
      error: null,
      loading: true // promise: existing value retained

    } : {
      data,
      error: null,
      loading: false,
      promise: Promise.resolve(data)
    };

    const newSlice = _extends({}, prevSlice, changes, {
      expiresAt: getExpiresAt(maxAge),
      accessedAt: getAccessedAt()
    });

    dispatch(setResourceState(type, key, newSlice)); // trigger dependent resources on change

    if (newSlice.data !== prevSlice.data) {
      dispatch(executeForDependents(resource, dependentResource => privateActions.getResourceFromRemote(dependentResource, routerStoreContext, {})));
    }
  },

  /**
   * Get a single resource, either from the cache if it exists and has not expired, or
   * the remote if it has expired.
   */
  getResource: (resource, routerStoreContext, options) => async _ref3 => {
    let {
      getState,
      dispatch
    } = _ref3;
    const {
      type,
      getKey,
      maxAge
    } = resource;
    const {
      context
    } = getState();
    const key = getKey(routerStoreContext, context);
    let cached = dispatch(getResourceState(type, key));

    if (cached && shouldUseCache(cached)) {
      if (isFromSsr(cached)) {
        const withResolvedPromise = setSsrDataPromise(cached);
        cached = setExpiresAt(withResolvedPromise, maxAge);
      }

      cached.accessedAt = getAccessedAt();
      dispatch(setResourceState(type, key, cached));
      return cached;
    }

    return dispatch(privateActions.getResourceFromRemote(resource, routerStoreContext, options));
  },

  /**
   * Request a single resource and update the resource cache.
   */
  getResourceFromRemote: (resource, routerStoreContext, options) => async _ref4 => {
    let {
      getState,
      dispatch
    } = _ref4;
    const {
      type,
      getKey,
      maxAge
    } = resource;
    const {
      context
    } = getState();
    const key = getKey(routerStoreContext, context);
    const prevSlice = dispatch(getResourceState(type, key)) || getDefaultStateSlice(); // abort request if already in flight

    if (prevSlice.loading) {
      return prevSlice;
    }

    dispatch(validateLRUCache(resource, key));
    const loadingSlice = dispatch(getPrefetchSlice(type, key)) || createLoadingSlice({
      context,
      dependencies: () => dispatch(getDependencies(resource, routerStoreContext, options)),
      options,
      resource,
      routerStoreContext
    });
    let resolvedSlice;

    if (loadingSlice.data === prevSlice.data) {
      // same data (by reference) means nothing has changed and we can avoid loading state
      resolvedSlice = {};
    } else {
      // enter loading state
      dispatch(setResourceState(type, key, _extends({}, prevSlice, loadingSlice, {
        data: maxAge === 0 ? null : prevSlice.data,
        error: maxAge === 0 ? null : prevSlice.error,
        loading: true,
        accessedAt: getAccessedAt()
      }))); // trigger dependent resources to also load

      dispatch(executeForDependents(resource, dependentResource => privateActions.getResourceFromRemote(dependentResource, routerStoreContext, options))); // in case another action occurred while loading promise may not be the one we started with
      // we need to re-assign promise consistent with error/data that we are assigning here

      try {
        var _loadingSlice$data;

        const data = (_loadingSlice$data = loadingSlice.data) != null ? _loadingSlice$data : await loadingSlice.promise;
        resolvedSlice = {
          data,
          error: null,
          // any existing error is cleared
          loading: false,
          promise: loadingSlice.promise
        };
      } catch (error) {
        if (error instanceof TimeoutError) {
          resolvedSlice = {
            // data: do not replace existing data
            error,
            loading: true,
            // this condition cannot recover so must only be present in static/server router
            promise: null // special case for timeout

          };
        } else {
          resolvedSlice = {
            // data: do not replace existing data
            // @ts-ignore
            error,
            loading: false,
            promise: loadingSlice.promise
          };
        }
      }
    } // ensure most recent data when return occurs


    const recentSlice = dispatch(getResourceState(type, key));

    const finalSlice = _extends({}, recentSlice || prevSlice, resolvedSlice, {
      accessedAt: getAccessedAt(),
      expiresAt: getExpiresAt(maxAge)
    }); // protect against race conditions: if resources get cleared while await happens, we discard the result


    if (recentSlice || loadingSlice.data !== undefined) {
      dispatch(setResourceState(type, key, finalSlice));
    }

    return finalSlice;
  },

  /**
   * Prefetch a single resource and store in the prefetch cache.
   */
  prefetchResourceFromRemote: (resource, routerStoreContext, options) => async _ref5 => {
    let {
      getState,
      dispatch
    } = _ref5;
    const {
      type,
      getKey
    } = resource;
    const {
      context
    } = getState();
    const key = getKey(routerStoreContext, context);
    const loadingSlice = dispatch(getPrefetchSlice(type, key)) || createLoadingSlice({
      context,
      dependencies: () => dispatch(getDependencies(resource, routerStoreContext, options)),
      options,
      resource,
      routerStoreContext
    }); // save deferred value to be used also by dependants

    dispatch(setPrefetchSlice(type, key, loadingSlice)); // trigger dependent resources to also prefetch

    dispatch(executeForDependents(resource, dependentResource => privateActions.prefetchResourceFromRemote(dependentResource, routerStoreContext, options)));
  }
};
export const actions = {
  /**
   * Clears a resource for the current key, or where context is not provided all keys.
   * Execute such that dependencies on current route will be cleared.
   */
  clearResource: (resource, routerStoreContext) => actionWithDependencies(routerStoreContext == null ? void 0 : routerStoreContext.route.resources, resource, privateActions.clearResource(resource, routerStoreContext != null ? routerStoreContext : null)),

  /**
   * Update the data property for a resource in the cache and reset expiresAt based
   * on maxAge.
   * Execute such that dependencies on current route will be updated.
   */
  updateResourceState: (resource, routerStoreContext, getNewSliceData) => actionWithDependencies(routerStoreContext.route.resources, resource, privateActions.updateResourceState(resource, routerStoreContext, getNewSliceData)),

  /**
   * Get a single resource, either from the cache if it exists and has not expired, or
   * the remote if it has expired.
   * Execute such that dependencies on current route will be updated.
   */
  getResource: (resource, routerStoreContext, options) => actionWithDependencies(routerStoreContext.route.resources, resource, privateActions.getResource(resource, routerStoreContext, options)),

  /**
   * Request a single resource and update the resource cache.
   * Execute such that dependencies on current route will be updated.
   */
  getResourceFromRemote: (resource, routerStoreContext, options) => actionWithDependencies(routerStoreContext.route.resources, resource, privateActions.getResourceFromRemote(resource, routerStoreContext, options)),

  /**
   * Request all resources.
   */
  requestAllResources: function requestAllResources(routerStoreContext, options) {
    if (options === void 0) {
      options = {};
    }

    return _ref6 => {
      let {
        dispatch
      } = _ref6;
      const {
        route
      } = routerStoreContext || {};

      if (!route || !route.resources) {
        return Promise.all([]);
      }

      return Promise.all(dispatch(actions.requestResources(route.resources, routerStoreContext, options)));
    };
  },

  /**
   * Cleans expired resources and resets them back to their initial state.
   * We need to do this when transitioning into a route.
   */
  cleanExpiredResources: (resources, routerStoreContext) => _ref7 => {
    let {
      getState,
      dispatch
    } = _ref7;
    const {
      context: resourceContext
    } = getState();
    resources.forEach(resource => {
      const {
        type,
        getKey
      } = resource;
      const key = getKey(routerStoreContext, resourceContext);
      const slice = dispatch(getResourceState(type, key));

      if (slice && (!slice.expiresAt || slice.expiresAt < Date.now())) {
        dispatch(deleteResourceState(type, key));
      }
    });
  },

  /**
   * Requests a specific set of resources.
   */
  requestResources: (resources, routerStoreContext, options) => {
    var _routerStoreContext$r;

    const predicate = isServerEnvironment() ? _ref8 => {
      let {
        isBrowserOnly
      } = _ref8;
      return !isBrowserOnly;
    } : () => true;
    return mapActionWithDependencies((_routerStoreContext$r = routerStoreContext.route.resources) == null ? void 0 : _routerStoreContext$r.filter(predicate), resources.filter(predicate), resource => privateActions.getResource(resource, routerStoreContext, options));
  },

  /**
   * Prefetch a specific set of resources.
   */
  prefetchResources: (resources, routerStoreContext, options) => mapActionWithDependencies(routerStoreContext.route.resources, resources, resource => privateActions.prefetchResourceFromRemote(resource, routerStoreContext, _extends({}, options, {
    prefetch: true
  }))),

  /**
   * Hydrates the store with state.
   * Will not override pre-hydrated state.
   */
  hydrate: _ref9 => {
    let {
      resourceData,
      resourceContext
    } = _ref9;
    return _ref10 => {
      let {
        getState,
        setState
      } = _ref10;
      const {
        data,
        context
      } = getState();

      function getNextStateValue(prev, next) {
        if (!Object.keys(prev).length && next && Object.keys(next).length) {
          return next;
        }

        return prev;
      }

      const hydratedData = transformData(getNextStateValue(data, resourceData), _ref11 => {
        let {
          error,
          expiresAt,
          loading
        } = _ref11,
            rest = _objectWithoutPropertiesLoose(_ref11, _excluded2);

        const deserializedError = !error ? null : deserializeError(error);
        const isTimeoutError = (deserializedError == null ? void 0 : deserializedError.name) === 'TimeoutError';
        return _extends({}, rest, {
          expiresAt: isTimeoutError ? Date.now() - 1 : expiresAt,
          loading: isTimeoutError ? false : loading,
          error: deserializedError
        });
      });
      setState({
        data: hydratedData,
        context: getNextStateValue(context, resourceContext)
      });
    };
  },

  /**
   * Gets the store's context
   */
  getContext: () => _ref12 => {
    let {
      getState
    } = _ref12;
    return getState().context;
  },

  /**
   * Returns safe, portable and rehydratable data.
   */
  getSafeData: () => _ref13 => {
    let {
      getState
    } = _ref13;
    return transformData(getState().data, _ref14 => {
      let {
        data,
        key,
        error,
        loading
      } = _ref14;
      return {
        data,
        key,
        promise: null,
        expiresAt: null,
        accessedAt: null,
        error: !error ? null : serializeError(error instanceof Error ? error : new Error(JSON.stringify(error))),
        loading: error instanceof TimeoutError ? loading : false
      };
    });
  }
};
export const ResourceStore = createStore({
  initialState: {
    data: {},
    context: {},
    executing: null,
    prefetching: null
  },
  actions,
  name: 'router-resources'
});
export const ResourceContainer = createContainer(ResourceStore, {
  displayName: 'ResourceContainer'
});
export const ResourceSubscriber = createSubscriber(ResourceStore, {
  displayName: 'ResourceSelectorSubscriber',
  selector: (state, props) => getSliceForResource(state, {
    type: props.resourceType,
    key: props.resourceKey
  })
});
export const getResourceStore = () => // @ts-ignore not providing a scopeId param
defaultRegistry.getStore(ResourceStore);
export const useResourceStore = createHook(ResourceStore, {
  selector: getSliceForResource
});
export const useResourceStoreActions = createActionsHook(ResourceStore);
export const useResourceStoreContext = createHook(ResourceStore, {
  selector: getResourceStoreContext
});