import { useState } from 'react';
import DropdownMenu, { DropdownItemRadio, DropdownItemRadioGroup } from '@atlaskit/dropdown-menu';
import { token } from '@atlaskit/tokens';
import { css } from '@emotion/react';
import { ReactElement } from 'react';

type Placement =
  | 'auto-start'
  | 'auto'
  | 'auto-end'
  | 'top-start'
  | 'top'
  | 'top-end'
  | 'right-start'
  | 'right'
  | 'right-end'
  | 'bottom-end'
  | 'bottom'
  | 'bottom-start'
  | 'left-end'
  | 'left'
  | 'left-start';

export interface DropdownMenuCheckboxProps {
  /** Object containing the items to be displayed in the dropdown with selected and disabled states */
  items: Record<string, { isDisabled: boolean; isSelected: boolean }>;
  /** Function to handle item click events */
  onItemClick: (label: string) => void;
  /** Placement of the dropdown */
  placement?: Placement;
}

/**
 * DropdownMenuCheckbox component renders a dropdown menu with checkbox items.
 *
 * @param {DropdownMenuCheckboxProps} props - The properties for the DropdownMenuCheckbox component.
 * @param {Record<string, { isSelected: boolean, isDisabled: boolean }>} props.items - An object representing the items in the dropdown menu, where the key is the item label and the value is an object containing `isSelected` and `isDisabled` properties.
 * @param {(label: string) => void} props.onItemClick - A function to handle item click events.
 *
 * @returns {ReactElement} The rendered DropdownMenuCheckbox component.
 */

export const DropdownMenuCheckbox = ({ items, placement, onItemClick }: DropdownMenuCheckboxProps): ReactElement => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [itemsList, setItemList] = useState(items);

  const handleItemClick = (label: string): void => {
    setItemList((prev) => {
      const updatedState = { ...prev };
      Object.keys(updatedState).forEach((key) => {
        updatedState[key].isSelected = key === label;
      });
      return updatedState;
    });

    onItemClick(label);
  };

  const getFirstSelectedItem = (itemsChecked: DropdownMenuCheckboxProps['items']): string => {
    const selectedItem = Object.keys(itemsChecked).find((key) => itemsChecked[key].isSelected);
    return selectedItem || Object.keys(itemsChecked)[0];
  };

  const isAnyItemSelected = Object.values(items).some(({ isSelected }) => isSelected);

  return (
    <div css={[triggerRewriteStyles, isAnyItemSelected && isDropdownOpen && triggerSelectedRewriteStyles]}>
      <DropdownMenu
        onOpenChange={({ isOpen }): void => setIsDropdownOpen(isOpen)}
        trigger={getFirstSelectedItem(itemsList)}
        shouldRenderToParent={true}
        placement={placement}
      >
        <DropdownItemRadioGroup id="actions">
          {Object.entries(itemsList).map(([label, value]) => (
            <DropdownItemRadio
              testId={`dropdown-item-checkbox-${label}`}
              isDisabled={value.isDisabled}
              key={label}
              id={label}
              onClick={(): void => handleItemClick(label)}
              isSelected={itemsList[label].isSelected}
            >
              {label}
            </DropdownItemRadio>
          ))}
        </DropdownItemRadioGroup>
      </DropdownMenu>
    </div>
  );
};

const triggerRewriteStyles = css({
  '& > button': {
    border: `1px solid ${token('color.border.brand')}`,
    padding: `${token('space.100')} ${token('space.100')} ${token('space.100')} ${token('space.150')}`,
    background: '#fff',
    alignItems: 'center',
    height: '40px',
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 600,
    fontFamily: 'Charlie Text, sans-serif',
    color: token('color.text.brand'),
    borderRadius: '4px',
    ':hover': {
      background: token('color.background.selected'),
      color: token('color.text.selected'),
    },
  },
  '#actions': {
    'button[disabled]': {
      background: token('color.background.disabled'),
      span: {
        color: token('color.text.disabled'),
        ':hover': {
          color: token('color.text.disabled'),
        },
      },
      rect: {
        stroke: token('color.border.disabled'),
      },
    },
    ':before, :after': {
      display: 'none',
    },
    button: {
      padding: `${token('space.200')} ${token('space.200')} ${token('space.200')} 24px`,
      width: '220px',
      height: '48px',
      '[aria-checked="true"]:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '2px',
        height: '100%',
        background: `${token('color.text.selected')}`,
      },
      span: {
        gap: 0,
        span: {
          fontFamily: 'Inter, sans-serif',
          fontSize: '16px',
          lineHeight: '20px',
        },
      },
    },
  },
  svg: {
    width: '30px',
    height: '30px',
  },
});

const triggerSelectedRewriteStyles = css({
  '& > button': {
    border: 0,
    background: token('color.background.selected'),
  },
});
