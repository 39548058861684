import React from 'react';
import { token } from '@atlaskit/tokens';
import { css } from '@compiled/react';
import { LinkWithAnalytics as Link } from './analytics-wrapped/LinkWithAnalytics';
import ArrowRightIcon from '@atlaskit/icon/glyph/arrow-right';
import { LinkData } from 'components/common/analytics-wrapped/LinkData';
import { MOBILE } from '../../utils/constants';

type SectionButtonProps = LinkData;

const SectionButton: React.FC<SectionButtonProps> = ({
  href,
  text,
  target,
  clickEventPayload,
  hoverEventPayload,
  hasArrow,
}) => (
  <Link
    css={buttonStyle}
    href={href}
    target={target}
    clickEventPayload={clickEventPayload}
    hoverEventPayload={hoverEventPayload}
  >
    <span
      css={[
        buttonLabelStyle,
        hasArrow && css({ paddingLeft: token('space.200') }),
      ]}
    >
      {text}
    </span>
    {hasArrow && <ArrowRightIcon label={text} />}
  </Link>
);

const buttonStyle = css({
  display: 'flex',
  backgroundColor: 'var(--ds-background-brand-bold, #0052CC)',
  borderRadius: '4px',
  color: token('color.text.inverse'),
  paddingTop: token('space.100'),
  paddingBottom: token('space.100'),
  paddingLeft: token('space.150'),
  paddingRight: token('space.150'),
  height: '48px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: token('space.050'),
  ':hover': {
    backgroundColor: '#0065FF',
    color: token('color.text.inverse'),
    textDecoration: 'none',
  },
  [MOBILE.MEDIA_QUERY]: {
    paddingTop: token('space.100'),
    paddingBottom: token('space.100'),
    paddingLeft: token('space.150'),
    paddingRight: token('space.150'),
  },
});

const buttonLabelStyle = css({
  fontSize: '20px',
  fontWeight: 600,
  fontFamily: 'Charlie Text, sans-serif',
  lineHeight: '24px',
});

export default SectionButton;
