import { ReactElement } from 'react';
import { css } from '@emotion/react';
import { token } from '@atlaskit/tokens';
import { DangerTriangle } from './DangerTriangle';
import { Button, ButtonAppearance } from '../Button';

export type ErrorProps = {
  onClickLogout: () => void;
  errorMessage: string;
};

export const Error = ({ onClickLogout, errorMessage }: ErrorProps): ReactElement => {
  return (
    <div css={pageStyles}>
      <div css={containerStyles}>
        <DangerTriangle />
        <h1 css={headingStyles}>Error</h1>
        <p css={descriptionStyles}>{errorMessage}</p>
        <Button label="Log out" appearance={ButtonAppearance.LINK} onClick={onClickLogout} />
      </div>
    </div>
  );
};

export default Error;

const pageStyles = css({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const containerStyles = css({
  maxWidth: '800px',
  minWidth: '375px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: token('space.600'),
});

const headingStyles = css({
  marginTop: token('space.300'),
  marginBottom: token('space.200'),
  fontFamily: token('font.family.brand.heading'),
  fontSize: '24px',
  fontWeight: token('font.weight.semibold'),
  lineHeight: '32px',
  textAlign: 'center',
});

const descriptionStyles = css({
  marginBottom: token('space.200'),
  color: token('color.text'),
  fontFamily: 'Inter',
  fontWeight: '400',
  lineHeight: '20px',
  textAlign: 'center',
  fontSize: '16px',
});
