import { FunctionComponent } from 'react';
import { css } from '@compiled/react';
import {
  EventsHeroArtworkLeftDesktop,
  EventsHeroArtworkLeftMobile,
  EventsHeroArtworkLeftTablet,
  EventsHeroArtworkRightDesktop,
  EventsHeroArtworkRightTablet,
  EventsHeroArtworkRightMobile,
  AceLogo,
} from '../../assets/images';
import SectionButton from '../common/SectionButton';
import { MOBILE } from '../../utils/constants';
import HeroHeadings from 'components/common/HeroHeadings';

// all process.env variables has to be declared this way to make sure atlaspack can inline them properly
// const EVENTS_BASE_URL = process.env.EVENTS_BASE_URL;

const EventsHero: FunctionComponent = () => {
  const headerText = 'Connect globally, meet locally';
  const subheaderText =
    'Meet other Atlassian enthusiasts at free, user-led gatherings hosted by the Community, for the Community.';

  return (
    <section css={styles.container}>
      <div css={styles.heroLeftArtworkContainer}>
        <img
          src={EventsHeroArtworkLeftDesktop}
          css={[styles.heroLeftArtwork, styles.artworkDisplay.desktop]}
        />
        <img
          src={EventsHeroArtworkLeftTablet}
          css={[styles.heroLeftArtwork, styles.artworkDisplay.tabletLeft]}
        />
        <img
          src={EventsHeroArtworkLeftMobile}
          css={[styles.heroLeftArtwork, styles.artworkDisplay.mobileLeft]}
        />
      </div>

      <div css={styles.content}>
        <div css={styles.logo}>
          <img src={AceLogo} />
        </div>
        <HeroHeadings headerText={headerText} subheaderText={subheaderText} />
        <div css={styles.buttonContainer}>
          {/** Events page hero button links to ACE/Bevy sub-app (under /events/community-events) */}
          <SectionButton
            href={'https://ace.atlassian.com'}
            text="Find an event"
            target="_blank"
            hasArrow={false}
          />
        </div>
      </div>

      <div css={styles.heroRightArtworkContainer}>
        <img
          src={EventsHeroArtworkRightDesktop}
          css={[styles.heroRightArtwork, styles.artworkDisplay.desktop]}
        />
        <img
          src={EventsHeroArtworkRightTablet}
          css={[styles.heroRightArtwork, styles.artworkDisplay.tabletRight]}
        />
        <img
          src={EventsHeroArtworkRightMobile}
          css={[styles.heroRightArtwork, styles.artworkDisplay.mobileRight]}
        />
      </div>
    </section>
  );
};

const styles = {
  container: css({
    backgroundColor: '#F8F8F8',
    width: '100%',
    height: '508px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    overflow: 'hidden',
    '@media (max-width: 1279px)': { height: '450px' },
    '@media (max-width: 768px)': { height: '438px' },
  }),

  content: css({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '720px',
    textAlign: 'center',
    zIndex: 1,
    h1: {
      maxWidth: '450px',  // ensure that 'Connect globally, meet locally' appears on two lines with two words each
    },
  }),

  logo: css({
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    justifyContent: 'center',
  }),

  buttonContainer: css({
    display: 'inline-flex',
    alignItems: 'center',
    marginTop: '32px',
    width: '162px',
    justifyContent: 'center',
    [MOBILE.MEDIA_QUERY]: {
      marginTop: '16px',
    },
  }),

  heroLeftArtwork: css({
    position: 'absolute',
    left: '0',
    top: '0',
    zIndex: 0,
    display: 'none', // Hide all by default
  }),

  heroLeftArtworkContainer: css({
    position: 'absolute',
    left: '0',
    top: '0',
    zIndex: 0,
    width: '100%',
    height: '100%',
  }),

  heroRightArtworkContainer: css({
    position: 'absolute',
    right: '0',
    top: '0',
    zIndex: 0,
    width: '100%',
    height: '100%',
  }),

  heroRightArtwork: css({
    position: 'absolute',
    right: '0',
    top: '0',
    zIndex: 0,
    display: 'none', // Hide all by default
  }),

  // Responsive display rules
  artworkDisplay: {
    desktop: css({
      display: 'block',
      '@media (max-width: 1279px)': { display: 'none' },
    }),

    tabletLeft: css({
      display: 'none',
      '@media (min-width: 769px) and (max-width: 1279px)': { display: 'block' },
      '@media (max-width: 768px)': { display: 'none' },
    }),
    tabletRight: css({
      display: 'none',
      '@media (min-width: 769px) and (max-width: 1279px)': { display: 'block', top: '0px', height: '100%'},
      '@media (max-width: 768px)': { display: 'none' },
    }),

    mobile: css({
      display: 'none',
      '@media (max-width: 768px)': {
        display: 'block',
        position: 'absolute',
        height: 'auto',
        width: 'auto',
      },
    }),

    mobileRight: css({
      display: 'none',
      '@media (max-width: 768px)': {
        display: 'block',
        position: 'absolute',
        height: 'auto',
        right: '-15px',
        width: '225px',
        bottom: '0px',
        top: 'auto',
      },
    }),

    mobileLeft: css({
      display: 'none',
      '@media (max-width: 768px)': {
        display: 'block',
        position: 'absolute',
        height: 'auto',
        width: 'auto',
        left: 0,
        top: '20px',
      },
    }),
  },
};

export default EventsHero;
