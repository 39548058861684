import { FunctionComponent } from 'react';
import { css } from '@compiled/react';
import { AdvocatingIcon, ConnectingIcon, MentoringIcon, DrivingChangeIcon } from 'assets/images';
import { MOBILE, TABLET } from '../../utils/constants';
import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Image from '@atlaskit/image';

type IntroCardProps = {
    title: string;
    image: string;
    description: string;
};
  
  const IntroCard: React.FC<IntroCardProps> = ({
    title,
    image,
    description,
  }) => (
    <Box padding="space.200" css={boxStyling}>
      <div css={stackContainerStyle}>
        <div css={imageContainerStyle}>
        <Image src={image} alt={title} css={imageStyle} />
        </div>
        <span css={titleStyle}>{title}</span>
      </div>
      <p css={descriptionStyle}>{description}</p>
    </Box>
  );
  
  const titleStyle = css({
    fontSize: '32px',
    fontFamily: 'Charlie Display, sans-serif',
    fontWeight: 700,
    lineHeight: '40px',
    [TABLET.MEDIA_QUERY]: {
      fontSize: '24px',
      lineHeight: '32px',
    },
  });
  
  const boxStyling = css({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
    [TABLET.MEDIA_QUERY]: {
      gap: '24px',
    },
    [MOBILE.MEDIA_QUERY]: {
      padding: '40px 16px',
      gap: '64px',
    },
  });
  
  const descriptionStyle = css({
    color: token('color.text.subtle'),
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    height: '122px',
    marginBottom: token('space.200'),
    [TABLET.MEDIA_QUERY]: {
      height: '120px',
      lineHeight: '24px',
    },
    [MOBILE.MEDIA_QUERY]: {
      height: 'auto',
    },
  });
  
  const stackContainerStyle = css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '166px',
    [TABLET.MEDIA_QUERY]: {
      fontSize: '24px',
    },
  });
  
  const imageContainerStyle = css({
    width: '110px',
    height: '110px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  });
  
  const imageStyle = css({
    maxHeight: '100%',
  });
  

const TITLE = 'Who are Atlassian Champions?';
const SUBHEADING = 'Atlassian Champions are passionate about driving change for the better. They support growth and success by improving the way we all work — both at their companies and in the broader community. These are the ways Atlassian Champions unleash potential.';
const CARDS = [
  {
    title: 'Connecting',
    image: ConnectingIcon,
    description:
      'Supporting other members and Atlassian users by networking, sharing knowledge and creating belonging.'
  },
  {
    title: 'Advocating',
    image: AdvocatingIcon,
    description:
      'Partnering with Atlassian teams to drive product and community improvements, and serving as brand ambassadors.',
  },
  {
    title: 'Mentoring',
    image: MentoringIcon,
    description:
      'Helping others learn by answering questions, organizing events, hosting groups, and more.',
  },
  {
    title: 'Driving Change',
    image: DrivingChangeIcon,
    description:
      'Promoting and facilitating transformation, implementing new ideas, and driving innovation.',
  },
];

export const ChampionsIntro: FunctionComponent = () => {
  return (
    <section css={containerStyle}>
      <h2 css={headingStyle}>{TITLE}</h2>
      <p css={subheadingStyle}> {SUBHEADING}</p>
      <div css={cardContainerStyle}>
        {CARDS.map((card) => (
          <div key={card.title} css={cardWrapperStyle}>
            <IntroCard {...card} />
          </div>
        ))}
      </div>
    </section>
  );
};

const headingStyle = css({
  textAlign: 'center',
  fontFamily: 'Charlie Display,sans-serif',
  fontSize: '40px',
  fontWeight: 700,
  lineHeight: '48px',
  letterSpacing: '-0.12px',
  marginBottom: '24px',
  [TABLET.MEDIA_QUERY]: {
    fontSize: '32px',
  },
});

const subheadingStyle = css({
  textAlign: 'center',
  fontSize: '20px',
  fontFamily: 'Inter',
  lineHeight: '32px',
  maxWidth: '816px',
  margin: '0 auto 30px',
});

const containerStyle = css({
  background: '#FFFFFF',
  display: 'flex',
  flexDirection: 'column',
  padding: '30px 24px',
  maxWidth: '1232px',
  margin: '48px auto 42px',
  [TABLET.MEDIA_QUERY]: {
    margin: '32px 16px 0px',
  },
  [MOBILE.MEDIA_QUERY]: {
    margin: '16px',
    padding: '16px',
  },
});

const cardWrapperStyle = css({
  width: '290px',
  flex: '0 0 290px',
  [TABLET.MEDIA_QUERY]: {
    width: 'auto',
    flex: 'auto',
  },
  [MOBILE.MEDIA_QUERY]: {
    width: '100%',
    flex: 'auto',
  },
});

const cardContainerStyle = css({
  display: 'flex',
  justifyContent: 'center',
  gap: '24px',
  [TABLET.MEDIA_QUERY]: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    columnGap: '24px',
    rowGap: '0px',
  },
  [MOBILE.MEDIA_QUERY]: {
    display: 'flex',
    flexDirection: 'column',
    gridTemplateColumns: 'unset',
  },
});

