import { css } from '@emotion/react';
import { token } from '@atlaskit/tokens';

export interface EyebrowProps {
  /** indicates the font-weight of eyebrow */
  fontWeight?: 'regular' | 'semibold';
  /** indicates the size of eyebrow */
  size?: 'small' | 'medium';
  /** Text to be displayed */
  children: React.ReactNode;
  /** color to be applied on the text */
  color?: string;
}

/**
 * Displays Eyebrow
 * @param {Eyebrow} EyebrowProps
 * @returns {React.ReactElement} React.ReactElement
 *
 * @example
 * <Eyebrow fontWeight="semibold" size="medium" children="Eyebrow text" />
 */
export const Eyebrow: React.FC<EyebrowProps> = ({
  fontWeight = 'regular',
  size = 'small',
  color = token('color.text'),
  children,
}) => {
  return (
    <h4
      css={[
        eyebrowSmallStyles,
        size === 'medium' && eyebrowMediumStyles,
        fontWeight === 'semibold' && css({ fontWeight: 600 }),
        color && css({ color }),
      ]}
    >
      {children}
    </h4>
  );
};

const eyebrowSmallStyles = css({
  fontFamily: '"Charlie Text", sans-serif',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '16px',
  color: token('color.text'),
  textTransform: 'uppercase',
});

const eyebrowMediumStyles = css({
  fontSize: '14px',
  lineHeight: '18px',
});
