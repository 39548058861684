import { FunctionComponent } from 'react';
import { css } from '@compiled/react';
import SectionButton from 'components/common/SectionButton';
import { ReadyToGetInvolved } from 'assets/images';
import { token } from '@atlaskit/tokens';
import {MOBILE, TABLET} from '../../utils/constants';


const GetInvolved: FunctionComponent = () => {
  const headerText = 'Ready to get involved?';
  const descriptionText = 'Sign up for a free event in your local area.';
  return (
    <div css={containerStyle}>
      <div css={mainStyle}>
        <div css={textContentStyle}>
          <div css={headerStyle}>{headerText}</div>
          <p css={descriptionStyle}>{descriptionText}</p>
          <div css={buttonStyle}>
            <SectionButton
              href="https://ace.atlassian.com"
              text="Explore events"
              target="_blank"
              hasArrow={false}
            />
          </div>
        </div>
      </div>
       <div css={imageContainerStyle}>
        <img src={ReadyToGetInvolved} alt="Ready to Get Involved" css={imageStyle}/>
      </div>
    </div>
  );
};

export default GetInvolved;

const containerStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  width: '955px',
  alignItems: 'center', 
  height: '100%', 
  '@media (max-width: 1279px)': {
    flexDirection: 'column-reverse',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '24px',
  },
    [MOBILE.MEDIA_QUERY]: {
    padding: '0 16px', 
    width: '100%', 
  },
});

const mainStyle = css({
  flexDirection: 'column',
  display: 'flex',
  

  width: 'calc(100% - 455px)', 
  '@media (max-width: 1279px)': {
    alignItems: 'center', 
    width: '100%', 

  },
});

const headerStyle = css({
  fontFamily: token('font.family.brand.heading'),
  color: '#000',
  fontSize: '40px',
  fontWeight: 700,
  lineHeight: '48px',
  letterSpacing: '-0.12px',
  fontFeatureSettings: `'liga' off, 'clig' off`,
  textAlign: 'left', 
  maxWidth: '650px',
});

const descriptionStyle = css({
  marginTop: '24px',
  color: '#000',
  fontSize: '20px',
  lineHeight: '32px',
  fontFamily: 'Inter',
  fontWeight: '400',
  textAlign: 'left', 
  [TABLET.MEDIA_QUERY]: {
   marginTop: '16px'
  },
  [MOBILE.MEDIA_QUERY]: {
   marginTop: '0px'
  },
});

const buttonStyle = css({

  marginTop: '24px',
  width: '165px',
   textAlign: 'left', 
});

const textContentStyle = css({
width: '455px',
  [MOBILE.MEDIA_QUERY]: {
    
    width: '327px', 
   
  },

});

const imageContainerStyle = css({
  flexShrink: 0,
  width: '455px',
  overflow: 'hidden',
  [MOBILE.MEDIA_QUERY]: {
    width: '100%', 
  },
});
const imageStyle = css({
  width: '100%', 
  height: '332px',
  objectFit: 'cover',
  '@media (max-width: 1279px)': {
    width: '100%', 
    height: '332px',
  },
  [MOBILE.MEDIA_QUERY]: {
    width: '100%', 
    height: '332px',
  },
});