import { IconButton } from '@atlaskit/button/new';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import MoreIcon from '@atlaskit/icon/glyph/more';
import { css } from '@emotion/react';

export interface Action {
  text: string;
  onClick: () => void;
}
export interface UnifiedMoreDropdownMenuProps {
  actions: Action[];
  isDisabled?: boolean;
}

const UnifiedMoreDropdownMenu: React.FC<UnifiedMoreDropdownMenuProps> = ({ actions, isDisabled }) => (
  <DropdownMenu<HTMLButtonElement>
    trigger={({ triggerRef, ...props }): JSX.Element => (
      <div css={buttonWrapperStyle} data-testid="dropdown-trigger">
        <IconButton
          {...props}
          icon={MoreIcon}
          label="more"
          ref={triggerRef}
          appearance="default"
          isDisabled={isDisabled}
        />
      </div>
    )}
    placement="bottom-end"
    shouldRenderToParent
  >
    <DropdownItemGroup>
      {actions.map((action, index) => (
        <DropdownItem key={index} onClick={action.onClick}>
          {action.text}
        </DropdownItem>
      ))}
    </DropdownItemGroup>
  </DropdownMenu>
);

export default UnifiedMoreDropdownMenu;

const buttonWrapperStyle = css({
  backgroundColor: '#FFFFFF',
  borderRadius: '3px',
});
