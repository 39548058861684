import { RefObject, useEffect } from 'react';

/**
 * Hook that allows custom handling of clicks outside of the passed refs
 * @param refs   RefObject<HTMLDivElement>[]   The references to the elements that should be clicked outside of
 * @param onOutsideClick  () => void   The function to call when the elements are clicked outside of
 */

export const useOutsideClickHandler = (refs: RefObject<HTMLDivElement>[], onOutsideClick: () => void): void => {
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent): void => {
      // if click has occurred outside of all refs, then call onOutsideClick
      if (refs.every((ref) => ref.current && !ref.current.contains(event.target as Node))) {
        onOutsideClick();
      }
    };
    // bind the event listener
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      // unbind the event listener on clean up
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [refs, onOutsideClick]);
};
