import { css } from '@emotion/react';
import React, { SyntheticEvent, useCallback, useEffect, useRef } from 'react';
import { token } from '@atlaskit/tokens';

import { Icon } from '@components/shared';
import { AccordionTabProps } from '..';

const NAVBAR_HEIGHT = 124;

export const AccordionTab: React.FC<AccordionTabProps> = ({
  title,
  index,
  children,
  isFirstTab,
  isExpanded,
  shouldSingleTabBeOpen,
  setTabState,
  setExpansionMap,
}) => {
  const tabRef = useRef<HTMLDivElement>(null);

  const handleExpand = useCallback((): void => {
    if (!setExpansionMap) {
      return;
    }

    setExpansionMap((prevState) =>
      prevState.map((tab, i) => {
        if (setTabState && index !== undefined && i === index) {
          setTabState({ [index + 1]: !tab.isExpanded ? 'expand' : 'collapse' });
        }

        return {
          ...tab,
          isExpanded: i === index ? !tab.isExpanded : shouldSingleTabBeOpen ? false : tab.isExpanded,
        };
      }),
    );
  }, [setExpansionMap, title]);

  const disableClickEventBubbling = (event: SyntheticEvent): void => event.stopPropagation();

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.code === 'Space') {
        // to handle cases when there are clickable items within accordion
        if (event.target !== event.currentTarget) {
          event.preventDefault();
          event.stopPropagation();
        } else {
          event.preventDefault();
          handleExpand();
        }
      }
    },
    [handleExpand],
  );

  useEffect(() => {
    if (isExpanded && tabRef.current) {
      const topPosition = tabRef.current.getBoundingClientRect().top + window.scrollY - NAVBAR_HEIGHT;
      window.scrollTo({ top: topPosition, behavior: 'smooth' });
    }
  }, [isExpanded]);

  return (
    <div
      tabIndex={0}
      ref={tabRef}
      onKeyDown={handleKeyDown}
      css={[accordionTabStyles, isFirstTab && firstTabStyles, isExpanded && expandedTabStyles]}
      onClick={handleExpand}
      data-testid={`accordion-tab-${title}`}
    >
      <div css={[isExpanded && expandedTabContentStyles]}>
        {isExpanded ? (
          <Icon type="chevron-down" alt="Chevron down" />
        ) : (
          <Icon type="chevron-right" alt="Chevron right" />
        )}
      </div>
      <div css={contentContainer}>
        <p css={titleStyles}>{title}</p>
        {isExpanded && (
          <div onClick={disableClickEventBubbling} css={contentStyles} data-testid="accordion-tab-content">
            {children}
          </div>
        )}
      </div>
    </div>
  );
};

const accordionTabStyles = css({
  display: 'flex',
  width: '100%',
  alignContent: 'center',
  alignItems: 'flex-start',
  padding: '24px',
  cursor: 'pointer',
  borderBottom: `1px solid ${token('color.border')}`,
});

const firstTabStyles = css({
  borderTop: `1px solid ${token('color.border')}`,
});

const expandedTabStyles = css({
  borderLeft: `${token('space.100')} solid ${token('color.border.accent.blue')}`,
});

const expandedTabContentStyles = css({ marginLeft: '-0.5rem' });

const contentContainer = css({
  marginLeft: '0.75rem',
});

const titleStyles = css({
  fontFamily: 'Charlie Display, sans-serif',
  fontSize: '20px',
  fontWeight: 600,
  lineHeight: '28px',
  marginTop: 0,
  color: token('color.text'),
});

const contentStyles = css({
  marginTop: '16px',
  fontFamily: 'Inter, sans-serif',
  fontSize: '20px',
  fontWeight: 400,
  lineHeight: '30px',
  color: token('color.text'),

  ol: {
    margin: 0,
  },

  ul: {
    margin: 0,
  },

  a: {
    fontSize: '20px !important',
    lineHeight: '28px !important',
  },
});
