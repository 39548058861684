import { FunctionComponent } from 'react';
import { LinkData } from 'components/common/analytics-wrapped/LinkData';
import { css } from '@compiled/react';
import EventsCard from 'components/events/EventsCard';
import {CommunityLedClasses} from 'assets/images';
import {CompanyUserGroups} from 'assets/images';
import {DeveloperEvents} from 'assets/images';
import {LocalMeetups} from 'assets/images';

const CARDS = [
  {
    id: 'eventCardMeetups',
    title: 'Local events',
    image: LocalMeetups,
    description:
      'Meet, connect, and learn with other Community members, online or in-person. Local, user-run meetups range from small, virtual events to large, community-powered conferences.',
    linkData: {
      href: 'https://ace.atlassian.com/events/#/list',
      text: 'Find a meetup',
      target: '_blank',
    } as LinkData,
  },
  {
    id: 'eventCardClasses',
    title: 'Community-led classes',
    image: CommunityLedClasses,
    description:
      "Learn from a Community expert — and passionate Atlassian power users — for free. These online training classes are designed to help you build skills and expertise in Atlassian products.",
    linkData: {
      href: 'https://ace.atlassian.com/community-led-classes/',
      text: 'Join a class',
      target: '_blank',
    } as LinkData,
  },
  {
    id: 'eventCardDeveloper',
    title: 'Developer events',
    image: DeveloperEvents,
    description: 'Join Atlassian Developers around the world learning about and building on the Atlassian Developer Platform. Dive into the conversation, and connect with local developers.',
    linkData: {
      href: 'https://ace.atlassian.com/atlassian-developers/',
      text: 'Find a development event',
      target: '_blank',
    } as LinkData,
  },
  {
    id: 'EventCardUserGroups',
    title: 'Company user groups',
    image: CompanyUserGroups,
    description:
      'Whether you’re a beginner, admin, or just a fan, anyone can spark change at their company. Running a company user group is a great way to improve Atlassian product adoption and deepen collaboration in your organization.',
    linkData: {
      href: 'https://www.atlassian.com/community/company-user-groups',
      text: 'Get started',
      target: '_blank',
    } as LinkData,
  },
];

const EventsCards: FunctionComponent = () => {
  return (
    <div css={contentStyles}>
      {CARDS.map((card) => (
        <EventsCard {...card} key={card.id} />
      ))}
    </div>
  );
};

const contentStyles = css({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
    gap: '32px', 
  '@media (max-width: 1279px)': {
    gridTemplateColumns: '1fr',
      gap: '16px', 
  },
});

export default EventsCards;
