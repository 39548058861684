"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _testComponent = require("./test-component");
Object.keys(_testComponent).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _testComponent[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _testComponent[key];
    }
  });
});
var _consentModal = require("./consent-modal");
Object.keys(_consentModal).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _consentModal[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _consentModal[key];
    }
  });
});
var _consentValidator = require("./consent-validator");
Object.keys(_consentValidator).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _consentValidator[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _consentValidator[key];
    }
  });
});
var _suspenseExtender = require("./suspense-extender");
Object.keys(_suspenseExtender).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _suspenseExtender[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _suspenseExtender[key];
    }
  });
});
var _loadingSpinner = require("./loading-spinner");
Object.keys(_loadingSpinner).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _loadingSpinner[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _loadingSpinner[key];
    }
  });
});