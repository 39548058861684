import React from 'react';
import { css } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import SectionLink from 'components/common/SectionLink';
import { UITrackEventPayload } from '@atlassian/community-library';
import { LinkData } from 'components/common/analytics-wrapped/LinkData';
import { MOBILE, TABLET } from '../../utils/constants';

type EventsCardProps = {
  id: string;
  title: string;
  image: string;
  description: string;
  linkData: LinkData;
};
const EventsCard: React.FC<EventsCardProps> = ({
  id,
  title,
  image,
  description,
  linkData: { href, text: linkText, target },
}) => (
  <div css={containerStyle}>
    <div css={imageContainerStyle}>
      <img src={image} alt="Map of events" css={imageStyle} />
    </div>
    <h2 css={titleStyle}>{title}</h2>
    <p css={descriptionStyle}>{description}</p>
    <div css={linkContainerStyle}>
      <SectionLink
        href={href}
        text={linkText}
        {...(target ? { target } : {})}
        clickEventPayload={getLinkEventPayload('clicked', href, linkText, id)}
        hoverEventPayload={getLinkEventPayload('hovered', href, linkText, id)}
        textSize={'small'}
      />
    </div>
  </div>
);
const getLinkEventPayload = (
  action: string,
  href: string,
  label: string,
  id: string,
): UITrackEventPayload => {
  return {
    action,
    actionSubject: 'link',
    actionSubjectId: 'eventsCardLink',
    source: 'eventsSection',
    attributes: {
      href,
      label,
      communityCardId: id,
    },
  } as UITrackEventPayload;
};
const containerStyle = css({
  textAlign: 'left',
  padding: '24px',
  boxShadow: token('elevation.shadow.raised'),
  borderRadius: token('space.100'),
  background: token('elevation.surface'),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  margin: '0 auto',

  width: '568px',
  height: '552px',

  justifyContent: 'space-between',
  [MOBILE.MEDIA_QUERY]: {
    maxWidth: '343px',
    height: '100%',
  },
});

const linkContainerStyle = css({
  marginTop: 'auto',
  marginLeft: '-24px',
  marginBottom: '-24px',
  paddingLeft: '24px',
  paddingBottom: '24px',
});
const imageContainerStyle = css({
  overflow: 'hidden',
  width: '100%',
});
const imageStyle = css({
  width: '100%',
  height: 'auto',
  objectFit: 'cover',
});
const titleStyle = css({
  fontSize: '24px',
  fontWeight: '700',
  lineHeight: '32px',
  marginTop: '16px',
  textAlign: 'left',
});
const descriptionStyle = css({
  fontSize: '16px',
  fontFamily: 'Inter',
  color: token('color.text.subtle'),
  lineHeight: '24px',
  marginBottom: token('space.200'),
  textAlign: 'left',
  [TABLET.MEDIA_QUERY]: {
    fontSize: '16px',
    height: '120px',
    lineHeight: '24px',
  },
  [MOBILE.MEDIA_QUERY]: {
    height: 'auto',
  },
});
export default EventsCard;
