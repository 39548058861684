import { AtlassianLogo } from '@atlaskit/logo';
import { css } from '@emotion/react';
import { media } from '@atlaskit/primitives/responsive';
import { token } from '@atlaskit/tokens';

export interface FooterProps {
  onOpenReportProblemModal?: () => void;
}

export const Footer: React.FC<FooterProps> = ({ onOpenReportProblemModal }) => (
  <footer data-testid="footer" css={footerContainerStyles}>
    <a href="https://www.atlassian.com" css={[paddingRightStyles, logoLinkStyles]}>
      <AtlassianLogo appearance="neutral" size="small" />
    </a>
    <div>
      <ul css={listStyles} aria-label="footer navigation">
        {onOpenReportProblemModal && (
          <li css={[listItemStyles, paddingRightStyles]}>
            <span css={[listItemLinkStyles, css({ cursor: 'pointer' })]} onClick={onOpenReportProblemModal}>
              Report problem
            </span>
          </li>
        )}
        <li css={[listItemStyles, paddingRightStyles]}>
          <a css={listItemLinkStyles} href="https://www.atlassian.com/legal/privacy-policy">
            Privacy Policy
          </a>
        </li>
        <li css={[listItemStyles, paddingRightStyles]}>
          <a css={listItemLinkStyles} href="https://community.atlassian.com/t5/user/termsofservicepage">
            Terms of Use
          </a>
        </li>
        <li css={[listItemStyles, paddingRightStyles]}>
          <a css={listItemLinkStyles} href="https://www.atlassian.com/security">
            Security
          </a>
        </li>
        <li css={listItemStyles}>© {new Date().getUTCFullYear()} Atlassian</li>
      </ul>
    </div>
  </footer>
);

const footerContainerStyles = css({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  alignItems: 'center',
  maxWidth: '1440px',
  backgroundColor: token('color.background.input'),
  zIndex: 4,
  padding: '48px 104px',
  boxSizing: 'border-box',
  margin: '0 auto',
  width: '100%',

  [media.below.md]: {
    padding: '48px 60px',
  },

  [media.below.sm]: {
    padding: '72px 32px',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
});

const listStyles = css({
  display: 'flex',
  paddingLeft: 0,
  margin: 0,
  flexDirection: 'row',
  justifyContent: 'flex-end',
  listStyle: 'none',

  [media.below.sm]: {
    flexDirection: 'column',
  },
});

const listItemStyles = css({
  fontFamily: 'Charlie Text, sans-serif',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '20px',
  marginTop: 0,
  color: token('color.text.subtlest'),

  [media.below.sm]: {
    paddingTop: '24px',
  },
});

const paddingRightStyles = css({
  paddingRight: '24px',
});

const logoLinkStyles = css({
  lineHeight: '24px',
});

const listItemLinkStyles = css({
  color: token('color.text.subtlest'),
  textDecoration: 'none',

  '&:hover': {
    color: token('color.text.subtlest'),
    textDecoration: 'none',
  },
});
