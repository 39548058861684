"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("../constants");
function getUserAgent() {
    try {
        return navigator.userAgent || constants_1.UNAVAILABLE;
    }
    catch (d) {
        return constants_1.ERROR;
    }
}
exports.getUserAgent = getUserAgent;
