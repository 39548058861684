import _extends from "@babel/runtime/helpers/extends";
import { useCallback, useMemo } from 'react';
import { createHook } from 'react-sweet-state';
import { RouterStore, useRouterStoreActions } from '../../../index';
import { useResourceStore, useResourceStoreActions } from '../resource-store';
export const useResource = (resource, options) => {
  const actions = useResourceStoreActions();
  const {
    getContext: getRouterContext
  } = useRouterStoreActions(); // Dynamically generate a router subscriber based on the resource:
  // makes the component re-render only when key changes instead of
  // after every route change

  const useKey = useMemo(() => createHook(RouterStore, {
    selector: (_ref, keyArg) => {
      let {
        match,
        query,
        route
      } = _ref;
      return resource.getKey(keyArg != null ? keyArg : {
        match,
        query,
        route
      }, actions.getContext());
    }
  }), [resource, actions]); // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain

  const key = useKey(options == null ? void 0 : options.routerContext)[0];
  const [slice] = useResourceStore({
    type: resource.type,
    key
  }); // we keep route context bound to key, so route context won't refresh
  // unless key changes. This allows refresh to be called on effect cleanup
  // or asynchronously, when route context might already have changed

  const routerContext = useMemo( // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  () => (options == null ? void 0 : options.routerContext) || getRouterContext(), // eslint-disable-next-line react-hooks/exhaustive-deps
  [key]);
  const update = useCallback(updater => {
    actions.updateResourceState(resource, routerContext, updater);
  }, [resource, routerContext, actions]);
  const clear = useCallback(() => {
    actions.clearResource(resource, routerContext);
  }, [resource, routerContext, actions]);
  const clearAll = useCallback(() => {
    actions.clearResource(resource);
  }, [resource, actions]);
  const refresh = useCallback(() => {
    actions.getResourceFromRemote(resource, routerContext, {
      prefetch: false
    });
  }, [resource, routerContext, actions]);
  return _extends({}, slice, {
    update,
    key,
    refresh,
    clear,
    clearAll
  });
};