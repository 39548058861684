import { Variables } from 'react-relay';
import {
  Environment,
  Network,
  RecordSource,
  Store,
  GraphQLResponse,
  RequestParameters,
} from 'relay-runtime';

const fetchRelay = async (
  params: RequestParameters,
  variables: Variables,
): Promise<GraphQLResponse> => {
  const response = await fetch(process.env.REACT_APP_GRAPHQL_URL as string, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: params.text,
      variables,
    }),
  }).then((res) => res.json());

  // GraphQL returns exceptions (for example, a missing required variable) in the "errors"
  // property of the response. If any exceptions occurred when processing the request,
  // throw an error to indicate to the developer what went wrong.
  if (Array.isArray(response.errors)) {
    console.log(response.errors);
    throw new Error(
      `Error fetching GraphQL query '${
        params.name
      }' with variables '${JSON.stringify(variables)}': ${JSON.stringify(
        response.errors,
      )}`,
    );
  }

  return response;
};

// Export a singleton instance of Relay Environment configured with our network layer:
export default new Environment({
  network: Network.create(fetchRelay),
  store: new Store(new RecordSource(), {
    // This property tells Relay to not immediately clear its cache when the user
    // navigates around the app. Relay will hold onto the specified number of
    // query results, allowing the user to return to recently visited pages
    // and reusing cached data if its available/fresh.
    gcReleaseBufferSize: 10,
  }),
});
