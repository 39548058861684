import { FunctionComponent } from 'react';
import { css } from '@compiled/react';
import {
  AtlassianLogoFull,
  ChampionsHeroArtworkLeftDesktop,
  ChampionsHeroArtworkLeftMobile,
  ChampionsHeroArtworkLeftTablet,
  ChampionsHeroArtworkRightDesktop,
  ChampionsHeroArtworkRightTablet,
  ChampionsHeroArtworkRightMobile,
} from '../../assets/images';
import SectionButton from '../common/SectionButton';
import { MOBILE, TABLET } from '../../utils/constants';
import HeroHeadings from '../common/HeroHeadings';

const CHAMPIONS_SIGNUP_URL = process.env.CHAMPIONS_SIGNUP_URL || 'https://atlassian--uat.sandbox.my.site.com/atlassianchampions/s/';

export const ChampionsHero: FunctionComponent = () => {
  const headerText = 'Become an Atlassian Champion';
  const subheaderText =
    'Unleash the potential of the people and teams around you with dedicated resources, support, and recognition from Atlassian.';

  return (
    <section css={styles.container}>
      <div css={styles.heroLeftArtworkContainer}>
        <img
          src={ChampionsHeroArtworkLeftDesktop}
          css={[styles.heroLeftArtwork, styles.artworkDisplay.desktop]}
        />
        <img
          src={ChampionsHeroArtworkLeftTablet}
          css={[styles.heroLeftArtwork, styles.artworkDisplay.tabletLeft]}
        />
        <img
          src={ChampionsHeroArtworkLeftMobile}
          css={[styles.heroLeftArtwork, styles.artworkDisplay.mobileLeft]}
        />
      </div>

      <div css={styles.content}>
        <div css={styles.logo}>
          <img src={AtlassianLogoFull} /> <span>Champions</span>
        </div>
        <HeroHeadings headerText={headerText} subheaderText={subheaderText} />
        <div css={styles.buttonContainer}>
          <SectionButton
            href={CHAMPIONS_SIGNUP_URL}
            text="Apply now"
            target="_blank"
            hasArrow={false}
          />
        </div>
      </div>

      <div css={styles.heroRightArtworkContainer}>
        <img
          src={ChampionsHeroArtworkRightDesktop}
          css={[styles.heroRightArtwork, styles.artworkDisplay.desktop]}
        />
        <img
          src={ChampionsHeroArtworkRightTablet}
          css={[styles.heroRightArtwork, styles.artworkDisplay.tabletRight]}
        />
        <img
          src={ChampionsHeroArtworkRightMobile}
          css={[styles.heroRightArtwork, styles.artworkDisplay.mobileRight]}
        />
      </div>
    </section>
  );
};

const styles = {
  container: css({
    backgroundColor: '#F8F8F8',
    width: '100%',
    height: '516px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    overflow: 'hidden',
  }),

  content: css({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '720px',
    textAlign: 'center',
    zIndex: 1,
    h1: {
      maxWidth: '600px', // ensure header text matches the design
    },
  }),

  logo: css({
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    justifyContent: 'center',
    marginBottom: '14px',
  }),

  buttonContainer: css({
    display: 'inline-flex',
    alignItems: 'center',
    marginTop: '32px',
    width: '162px',
    justifyContent: 'center',
    [MOBILE.MEDIA_QUERY]: {
      marginTop: '16px',
    },
  }),

  heroLeftArtwork: css({
    position: 'absolute',
    left: '0',
    top: '0',
    zIndex: 0,
    display: 'none', // Hide all by default
  }),

  heroLeftArtworkContainer: css({
    position: 'absolute',
    left: '0',
    top: '0',
    zIndex: 0,
    width: '100%',
    height: '100%',
  }),

  heroRightArtworkContainer: css({
    position: 'absolute',
    right: '0',
    top: '0',
    zIndex: 0,
    width: '100%',
    height: '100%',
  }),

  heroRightArtwork: css({
    position: 'absolute',
    right: '0',
    top: '0',
    zIndex: 0,
    display: 'none', // Hide all by default
  }),

  // Responsive display rules
  artworkDisplay: {
    desktop: css({
      display: 'block',
      [TABLET.MEDIA_QUERY]: { display: 'none' },
      [MOBILE.MEDIA_QUERY]: { display: 'none' },
    }),

    tabletLeft: css({
      display: 'none',
      [TABLET.MEDIA_QUERY]: { display: 'block' },
      [MOBILE.MEDIA_QUERY]: { display: 'none' },
    }),
    tabletRight: css({
      display: 'none',
      [TABLET.MEDIA_QUERY]: { display: 'block', top: '0px', height: '100%'},
      [MOBILE.MEDIA_QUERY]: { display: 'none' },
    }),

    mobile: css({
      display: 'none',
      [MOBILE.MEDIA_QUERY]: {
        display: 'block',
        position: 'absolute',
        height: 'auto',
        width: 'auto',
      },
    }),

    mobileRight: css({
      display: 'none',
      [MOBILE.MEDIA_QUERY]: {
        display: 'block',
        position: 'absolute',
        height: 'auto',
        right: '-15px',
        width: '225px',
        bottom: '0px',
        top: 'auto',
      },
    }),

    mobileLeft: css({
      display: 'none',
      [MOBILE.MEDIA_QUERY]: {
        display: 'block',
        position: 'absolute',
        height: 'auto',
        width: '225px',
        left: '-15px',
  
      },
    }),
  },
};