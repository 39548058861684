/**
 * This is a wrapper component that allows passing in an onClick handler to a div. It can be used to wrap components
 * that are meant to be clicked (e.g. buttons and links), for additional click handling.
 */
import React, { ReactNode } from 'react';

interface OnClickWrapperProps {
  children: ReactNode;
  onClick: (() => void) | undefined;
}

export const OnClickWrapper: React.FC<OnClickWrapperProps> = ({ children, onClick }) => {
  return <span onClick={onClick}>{children}</span>;
};
