import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["src", "srcDark", "alt", "testId"];
/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { useEffect, useRef } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { UNSAFE_useColorModeForMigration } from '@atlaskit/app-provider';
import { useThemeObserver } from '@atlaskit/tokens';
var baseImageStyles = css({
  maxWidth: '100%',
  height: 'auto'
});
var themedImageStyles = css({
  content: "url(var(--img-source))",
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
  'html[data-color-mode=dark] &': {
    content: "var(--img-source-dark)"
  }
});

/**
 * __Image__
 *
 * This component can be used interchangeably with the native `img` element. It includes additional functionality, such as theme support.
 *
 * - [Examples](https://atlassian.design/components/image/examples)
 * - [Code](https://atlassian.design/components/image/code)
 * - [Usage](https://atlassian.design/components/image/usage)
 */
export default function Image(_ref) {
  var src = _ref.src,
    srcDark = _ref.srcDark,
    alt = _ref.alt,
    testId = _ref.testId,
    props = _objectWithoutProperties(_ref, _excluded);
  var imgRef = useRef(null);
  var providedColorMode = UNSAFE_useColorModeForMigration();
  var _useThemeObserver = useThemeObserver(),
    observedColorMode = _useThemeObserver.colorMode;
  var colorMode = providedColorMode || observedColorMode;
  useEffect(function () {
    if (imgRef === null || imgRef.current === null) {
      return;
    }
    if (srcDark && colorMode === 'dark') {
      imgRef.current.src = srcDark;
    } else if (src) {
      imgRef.current.src = src;
    }
  }, [src, srcDark, colorMode]);
  return jsx("img", _extends({
    alt: alt,
    "data-testid": testId,
    src: src
    // eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
    ,
    style: {
      '--img-source': src,
      '--img-source-dark': srcDark || src
    },
    css: [baseImageStyles, themedImageStyles],
    ref: imgRef
    // The spread operator is necessary since the component can accept all the props of an `img` element.
    // eslint-disable-next-line @repo/internal/react/no-unsafe-spread-props
  }, props));
}