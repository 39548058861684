import { getResourceIdentifier, getResourceIdentifiers } from '../get-resource-identifier';
import { routeHasChanged, routeHasResources } from '../route-checks';
/**
 * Gets the requestable resources for the next location.
 */

export const getResourcesForNextLocation = (prevRouterStoreContext, nextRouterStoreContext, resourceStoreContext) => {
  const {
    route: prevRoute
  } = prevRouterStoreContext;
  const {
    resources: prevResources = []
  } = prevRoute || {};
  const {
    route: nextRoute
  } = nextRouterStoreContext;
  const {
    resources: nextResources = []
  } = nextRoute || {};

  if (!routeHasResources(nextRoute)) {
    return [];
  }

  if (routeHasChanged(prevRoute, nextRoute)) {
    return nextResources;
  }

  const prevResourceIdentifiers = getResourceIdentifiers(prevResources, prevRouterStoreContext, resourceStoreContext);
  return nextResources.filter(resource => !prevResourceIdentifiers.includes(getResourceIdentifier(resource, nextRouterStoreContext, resourceStoreContext)));
};