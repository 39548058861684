import { ChampionsPage } from './pages/ChampionsPage';
import EventsPage from './pages/EventsPage';
import HomePage from './pages/HomePage';

export const homeRoute = {
  name: 'home',
  path: '/',
  exact: true,
  component: HomePage,
};

export const eventsRoute = {
  name: 'events',
  path: '/events',
  exact: true,
  component: EventsPage,
};

export const championsRoute = {
  name: 'champions',
  path: '/champions',
  exact: true,
  component: ChampionsPage,
};
export const routes = [homeRoute, eventsRoute, championsRoute];