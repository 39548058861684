import { User } from 'typings/types';
import type { Request } from '@atlassian/tesseract-runtime-globals';

export const getAtlassianUser = async (): Promise<User | null> => {
  const typedGlobalThis = globalThis as typeof globalThis & { __COOKIES__: Request['headers']['cookie'] };
  const HOME_URL = process.env['REACT_APP_ENVI'] === 'local' ? 'http://localhost:9000' : process.env['HOME_BASE_URL'];

  const res = await fetch(`${HOME_URL}/gateway/api/me`, {
    credentials: 'include',
    headers: {
      ...(typedGlobalThis.__COOKIES__ ? { cookie: typedGlobalThis.__COOKIES__ } : {}),
      'accept-language': 'application/json',
    },
  });

  if (res.status !== 200) {
    return null;
  }

  return res.json();
};