import { isKeyboardEvent, isModifiedEvent } from '../../../common/utils/event';
export const handleNavigation = (event, _ref) => {
  let {
    onClick,
    target,
    replace,
    routerActions,
    href,
    to
  } = _ref;

  if (isKeyboardEvent(event) && event.keyCode !== 13) {
    return;
  }

  onClick && onClick(event);

  if (!event.defaultPrevented && ( // onClick prevented default
  isKeyboardEvent(event) && event.keyCode === 13 || event.button === 0) && ( // ignore everything but left clicks and Enter key
  !target || target === '_self') && // let browser handle "target=_blank" etc.
  !isModifiedEvent(event) // ignore clicks with modifier keys
  ) {
    event.preventDefault();

    if (to) {
      const method = replace ? routerActions.replaceTo : routerActions.pushTo;
      method(...to);
    } else {
      const method = replace ? routerActions.replace : routerActions.push;
      method(href);
    }
  }
};