import React from 'react';
import { LessonOverview } from './children/LessonOverview/LessonOverview';
import { Section } from './children/LessonOverview/LessonSection';

/**
 * Lesson list renders a list of lessons with their sections.
 * @param {LessonListProps} LessonListProps
 * @returns {React.ReactElement} React.ReactElement
 *
 * @example
 * ```tsx
 * const lessons = [
 *   { lessonTitle: 'Lesson 1', duration: '3 min' },
 *   { lessonTitle: 'Lesson 2', duration: '30 min' },
 * ];
 * <LessonList lessons={lessons} />
 * ```
 */

export interface Lesson {
  // Represents the title of the lesson
  lessonTitle: string;
  // The link to the lesson.
  slug: string;
  // The duration of the lesson.
  duration: string;
  // Indicates whether the lesson is completed or not.
  isCompleted: boolean;
  // Indicates whether the lesson is the currently selected lesson or not.
  isCurrentLesson: boolean;
  // The sections of the lesson.
  sections: Section[];
  // Function to call when click on lesson or section title
  onNavigation: (options: { [key: string]: string }) => void;
}

export interface LessonListProps {
  lessons: Lesson[];
}

export const LessonList: React.FC<LessonListProps> = ({ lessons }) => {
  const currentLessonIndx = lessons.findIndex((lesson) => lesson.isCurrentLesson);
  const updatedLessons = lessons.map((lesson, indx) => ({
    ...lesson,
    hasDivider: !(indx === currentLessonIndx || indx === currentLessonIndx - 1),
  }));

  return updatedLessons.map((lesson) => <LessonOverview {...lesson} key={`lesson-${lesson.slug}`} />);
};
