import { parse } from 'cookie';
import FeatureGates, { FeatureGateEnvironment } from '@atlaskit/feature-gate-js-client';
import { Identifiers, FrontendExperimentsResponse } from '@atlassian/feature-gate-node-client-bootstrapper';
import { getAtlassianUser } from 'services/http/user';
import { User } from 'typings/types';
import { getClientOptions } from './featureGates';
import { getEnvironment } from './environment';


export type FeatureGatesBootstrapValues = FrontendExperimentsResponse & {
  identifiers: Identifiers;
  environment: FeatureGateEnvironment;
};

export const initializeFeatureGatesClient = async (): Promise<void> => {
  const typedGlobalThis = globalThis as typeof globalThis & { __CURRENT_USER__: User };
  const analyticsAnonymousId = decodeURIComponent(parse(document?.cookie)?.ajs_anonymous_id || '').replace(
    /['"]+/g,
    '',
  );
  const user = typedGlobalThis ? typedGlobalThis.__CURRENT_USER__ || (await getAtlassianUser()) : { account_id: '' };

  const identifiers: Identifiers = {
    analyticsAnonymousId,
    atlassianAccountId: user?.account_id,
  };

  await FeatureGates.initialize(getClientOptions(getEnvironment()), identifiers);
};
