import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/objectWithoutPropertiesLoose";
import _extends from "@babel/runtime/helpers/extends";

function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }

function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }

export const getPrefetchSlice = (type, key) => _ref => {
  var _prefetching$type;

  let {
    getState
  } = _ref;
  const {
    prefetching
  } = getState();
  const slice = prefetching == null ? void 0 : (_prefetching$type = prefetching[type]) == null ? void 0 : _prefetching$type[key]; // check if slice is still fresh

  if (slice && Date.now() < Number(slice.expiresAt)) {
    return slice;
  }

  return undefined;
};
export const setPrefetchSlice = (type, key, slice) => _ref2 => {
  var _prefetching$type2, _slice$promise;

  let {
    setState,
    getState
  } = _ref2;
  const {
    prefetching
  } = getState(); // avoid doing extra set if same value

  if ((prefetching == null ? void 0 : (_prefetching$type2 = prefetching[type]) == null ? void 0 : _prefetching$type2[key]) === slice) return; // cheap optimisation to provide prefetched result syncronously

  slice == null ? void 0 : (_slice$promise = slice.promise) == null ? void 0 : _slice$promise.then(maybeData => slice.data = maybeData);
  setState({
    prefetching: _extends({}, prefetching, {
      [type]: _extends({}, prefetching == null ? void 0 : prefetching[type], {
        [key]: slice
      })
    })
  });
};
export const setResourceState = (type, key, state) => _ref3 => {
  let {
    setState,
    getState,
    dispatch
  } = _ref3;
  const {
    data
  } = getState(); // every time we override a resource we kill its prefetched

  dispatch(setPrefetchSlice(type, key, undefined));
  setState({
    data: _extends({}, data, {
      [type]: _extends({}, data[type] || {}, {
        [key]: state
      })
    })
  });
};
export const getResourceState = (type, key) => _ref4 => {
  let {
    getState
  } = _ref4;
  const {
    data: {
      [type]: resourceDataForType
    }
  } = getState();
  return resourceDataForType == null ? void 0 : resourceDataForType[key];
};
export const deleteResourceState = (type, key) => _ref5 => {
  let {
    getState,
    setState
  } = _ref5;
  const {
    data
  } = getState();

  const {
    [type]: resourceForType
  } = data,
        remainingData = _objectWithoutPropertiesLoose(data, [type].map(_toPropertyKey));

  if (key === undefined) {
    setState({
      data: remainingData
    });
  } else if (resourceForType) {
    const remainingForType = _objectWithoutPropertiesLoose(resourceForType, [key].map(_toPropertyKey));

    setState({
      data: _extends({}, remainingData, {
        [type]: remainingForType
      })
    });
  }
};