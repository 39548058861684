import { css } from '@emotion/react';
import { Inline, Stack, xcss } from '@atlaskit/primitives';
import { Button, ButtonAppearance, Heading, Text } from '@components/shared';
import { AtlassianCommunityLogo } from './AtlassianCommunityLogo';
import { layers } from '@atlaskit/theme/constants';
import { token } from '@atlaskit/tokens';
import { NewLaunchIcon } from './NewLaunchIcon';

type GreetingCardProps = {
  onClickLogIn: () => void;
  onClickContinue: () => void;
};

export const GreetingCard = ({ onClickLogIn, onClickContinue }: GreetingCardProps): React.ReactElement => {
  return (
    <div css={overlayStyles}>
      <div css={containerStyles}>
        <Stack alignInline="center" alignBlock="center" grow="fill" xcss={containerStackStyles}>
          <div css={logoStyles}>
            <NewLaunchIcon />
          </div>
          <Stack alignInline="center" alignBlock="center" space="space.200" xcss={middleStackStyles}>
            <div css={communityLogoStyles}>
              <AtlassianCommunityLogo />
            </div>
            <Heading level={3}>We've launched something new</Heading>
            <Text>We’ve logged you out. Log back in to see what’s changed</Text>
          </Stack>
          <Inline space="space.200" shouldWrap>
            <div css={buttonContainerStyles}>
              <Button
                appearance={ButtonAppearance.SECONDARY}
                label="Continue without logging in"
                fullWidth={true}
                onClick={onClickContinue}
              />
            </div>
            <div css={buttonContainerStyles}>
              <Button
                appearance={ButtonAppearance.PRIMARY}
                label="Log back in"
                fullWidth={true}
                onClick={onClickLogIn}
              />
            </div>
          </Inline>
        </Stack>
      </div>
    </div>
  );
};

const overlayStyles = css({
  position: 'fixed',
  zIndex: layers.blanket(),
  inset: 0,
  backgroundColor: 'white',
  overflowY: 'auto',
  pointerEvents: 'initial',
  fontFamily: 'Charlie Display, sans-serif',
});

const containerStackStyles = xcss({
  height: '100%',
});

const middleStackStyles = xcss({
  marginBottom: 'space.500',
});

const containerStyles = css({
  position: 'absolute',
  width: '100%',
  marginLeft: token('space.200'),
  marginRight: token('space.200'),
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  '@media(min-width: 1280px)': {
    width: '720px',
    height: '480px',
  },
  '@media (min-width: 768px) and (max-width: 1279px)': {
    width: '656px',
  },
});

const communityLogoStyles = css({
  width: '406px',
  '@media(max-width: 767px)': {
    width: '90%',
  },
});

const logoStyles = css({
  width: '112px',
  height: '112px',
  borderRadius: '50%',
  border: '1px solid black',
  marginBottom: '24px',
});

const buttonContainerStyles = css({
  '@media(max-width: 767px)': {
    width: '100%',
  },
});
