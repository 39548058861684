"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Environment;
(function (Environment) {
    Environment["TEST"] = "test";
    Environment["LOCAL"] = "local";
    Environment["DEV"] = "dev";
    Environment["STAGE"] = "stage";
    Environment["PROD"] = "prod";
})(Environment = exports.Environment || (exports.Environment = {}));
;
var XIDItemType;
(function (XIDItemType) {
    XIDItemType["XC"] = "xc";
    XIDItemType["UID"] = "uid";
})(XIDItemType = exports.XIDItemType || (exports.XIDItemType = {}));
;
var XIDState;
(function (XIDState) {
    XIDState["NEW"] = "NEW";
    XIDState["CHANGED"] = "CHANGED";
    XIDState["EXISTING"] = "EXISTING";
    XIDState["TIMEOUT"] = "TIMEOUT";
    XIDState["ERROR"] = "ERROR";
    XIDState["UNAVAILABLE"] = "UNAVAILABLE";
    XIDState["MALFORMED"] = "MALFORMED";
    XIDState["UNKNOWN"] = "UNKNOWN";
})(XIDState = exports.XIDState || (exports.XIDState = {}));
var XIDGenerateMode;
(function (XIDGenerateMode) {
    XIDGenerateMode["XC_ONLY"] = "XC_ONLY";
    XIDGenerateMode["UID_ONLY"] = "UID_ONLY";
    XIDGenerateMode["CACHE_ONLY"] = "CACHE_ONLY";
    XIDGenerateMode["XC_UID"] = "XC_UID";
    XIDGenerateMode["CACHE_XC_UID"] = "CACHE_XC_UID";
})(XIDGenerateMode = exports.XIDGenerateMode || (exports.XIDGenerateMode = {}));
