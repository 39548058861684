"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("../constants");
var fontsToCheck_1 = require("./fontsToCheck");
var DetectFonts = (function () {
    function DetectFonts() {
        var _this = this;
        this.baseFonts = ['monospace', 'sans-serif', 'serif'];
        this.testString = 'mmmmmmmmmmlli';
        this.testSize = '72px';
        this.defaultWidth = {};
        this.defaultHeight = {};
        this.createSpan = function () {
            var s = document.createElement('span');
            s.style.position = 'absolute';
            s.style.left = '-9999px';
            s.style.fontSize = _this.testSize;
            s.style.fontStyle = 'normal';
            s.style.fontWeight = 'normal';
            s.style.letterSpacing = 'normal';
            s.style.lineBreak = 'auto';
            s.style.lineHeight = 'normal';
            s.style.textTransform = 'none';
            s.style.textAlign = 'left';
            s.style.textDecoration = 'none';
            s.style.textShadow = 'none';
            s.style.whiteSpace = 'normal';
            s.style.wordBreak = 'normal';
            s.style.wordSpacing = 'normal';
            s.innerHTML = _this.testString;
            return s;
        };
        this.createSpanWithFonts = function (fontToDetect, baseFont) {
            var s = _this.createSpan();
            s.style.fontFamily = "'" + fontToDetect + "'," + baseFont;
            return s;
        };
        this.initializeBaseFontsSpans = function () {
            var spans = [];
            for (var _i = 0, _a = _this.baseFonts; _i < _a.length; _i++) {
                var font = _a[_i];
                var s = _this.createSpan();
                s.style.fontFamily = font;
                _this.baseFontsDiv.appendChild(s);
                spans.push(s);
            }
            return spans;
        };
        this.initializeFontsSpans = function () {
            var spans = {};
            for (var _i = 0, fontsToCheck_2 = fontsToCheck_1.fontsToCheck; _i < fontsToCheck_2.length; _i++) {
                var fontToDetect = fontsToCheck_2[_i];
                var fontSpans = [];
                for (var _a = 0, _b = _this.baseFonts; _a < _b.length; _a++) {
                    var baseFont = _b[_a];
                    var s = _this.createSpanWithFonts(fontToDetect, baseFont);
                    _this.fontsDiv.appendChild(s);
                    fontSpans.push(s);
                }
                var key = fontToDetect;
                spans[key] = fontSpans;
            }
            return spans;
        };
        this.isFontAvailable = function (fontSpans) {
            var detected = false;
            for (var i = 0; i < _this.baseFonts.length; i++) {
                var fontWidth = fontSpans[i].offsetWidth;
                var fontDefaultWidth = _this.defaultWidth[_this.baseFonts[i]];
                var fontHeight = fontSpans[i].offsetHeight;
                var fontDefaultHeight = _this.defaultHeight[_this.baseFonts[i]];
                detected = (fontWidth !== fontDefaultWidth || fontHeight !== fontDefaultHeight);
                if (detected) {
                    return detected;
                }
            }
            return detected;
        };
        this.bodyElement = document.getElementsByTagName('body')[0];
        this.baseFontsDiv = document.createElement('div');
        this.fontsDiv = document.createElement('div');
    }
    DetectFonts.prototype.detect = function () {
        var baseFontsSpans = this.initializeBaseFontsSpans();
        this.bodyElement.appendChild(this.baseFontsDiv);
        for (var index = 0; index < this.baseFonts.length; index++) {
            this.defaultWidth[this.baseFonts[index]] = baseFontsSpans[index].offsetWidth;
            this.defaultHeight[this.baseFonts[index]] = baseFontsSpans[index].offsetHeight;
        }
        var fontsSpans = this.initializeFontsSpans();
        this.bodyElement.appendChild(this.fontsDiv);
        var available = [];
        for (var _i = 0, fontsToCheck_3 = fontsToCheck_1.fontsToCheck; _i < fontsToCheck_3.length; _i++) {
            var fontToCheck = fontsToCheck_3[_i];
            var result = 0;
            if (this.isFontAvailable(fontsSpans[fontToCheck])) {
                result = 1;
            }
            available.push(result);
        }
        this.bodyElement.removeChild(this.fontsDiv);
        this.bodyElement.removeChild(this.baseFontsDiv);
        return available.join("|");
    };
    DetectFonts.prototype.result = function () {
        try {
            return this.detect();
        }
        catch (error) {
            return constants_1.ERROR;
        }
    };
    return DetectFonts;
}());
exports.DetectFonts = DetectFonts;
