import { envType } from '@atlassiansox/analytics-web-client';

export enum ENVIRONMENT {
  LOCAL = 'local',
  DEV = 'development',
  STAGING = 'staging',
  PROD = 'production',
}

export const ENV_TO_ANALYTICS_ENV_TYPE = {
  [ENVIRONMENT.LOCAL]: envType.LOCAL,
  [ENVIRONMENT.DEV]: envType.DEV,
  [ENVIRONMENT.STAGING]: envType.STAGING,
  [ENVIRONMENT.PROD]: envType.PROD,
};

export const getEnvironment = (defaultEnv = ENVIRONMENT.LOCAL): string => {
  return process.env.NODE_ENV || defaultEnv;
};

export const getAnalyticsEnvType = (): envType => {
  return ENV_TO_ANALYTICS_ENV_TYPE[
    getEnvironment() as keyof typeof ENV_TO_ANALYTICS_ENV_TYPE
  ];
};
