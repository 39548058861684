import React, { useCallback } from 'react';
import { Image, ImageProps } from '@components/shared';

import { withFocusInteraction } from '@utils/index';
import { css } from '@emotion/react';
import { token } from '@atlaskit/tokens';

export interface BadgeCardProps {
  /** Image component rendered in the component */
  image?: Omit<ImageProps, 'height' | 'width'>;
  /** Title of the Badge */
  badgeTitle: string;
  /** Badge earned date */
  earnedDate: string;
  /** Link that is used to redirect the badge */
  link: string;
  /** Optional onClick handler */
  onClick?: (link: string, href: string) => void;
}

/**
 * Component that renders a card used in different pages
 * @param {BadgeCardProps} BadgeCardProps
 * @returns {React.ReactElement} React.ReactElement
 *
 * @example
 * <BadgeCard badgeTitle="text" earnedDate="Nov 21, 2023"/>
 */
export const BadgeCard: React.FC<BadgeCardProps> = ({ image, badgeTitle, earnedDate, link, onClick }) => {
  const clickHandler = useCallback(() => {
    if (onClick) {
      onClick(link, '_self');
    }
  }, [onClick, link]);

  const handleKeyDown = useCallback(withFocusInteraction(clickHandler), []);

  return (
    <div css={containerStyles} tabIndex={0} onClick={clickHandler} onKeyDown={handleKeyDown} data-testid="badge-card">
      {image && (
        <div>
          <Image
            {...image}
            width={64}
            height={64}
            preventModal
            style={{
              height: '64px',
              objectFit: 'contain',
            }}
          />
        </div>
      )}

      <div css={contentContainerStyles}>
        <p css={badgeTitleStyles}>{badgeTitle}</p>
        <p css={earnedDateStyles}>Earned: {earnedDate}</p>
      </div>
    </div>
  );
};

const containerStyles = css({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  background: token('color.background.input'),
  border: `0 solid ${token('color.border')}`,
  borderRadius: '4px',
  padding: '16px 12px',
  transition: 'all 0.2s ease-in-out',
  boxShadow: token('elevation.shadow.raised'),

  '&:hover': {
    cursor: 'pointer',
    boxShadow: token('elevation.shadow.overlay'),
  },
});

const contentContainerStyles = css({
  marginLeft: '16px',
});

const badgeTitleStyles = css({
  paddingBottom: '4px',
  fontSize: '16px',
  fontFamily: 'system-ui',
  fontWeight: 600,
  lineHeight: '20px',
  color: token('color.text'),
  wordBreak: 'break-word',
  margin: 0,
});

const earnedDateStyles = css({
  fontSize: '14px',
  lineHeight: '20px',
  color: token('color.text.subtlest'),
  fontFamily: 'system-ui',
  fontWeight: 'normal',
  wordBreak: 'break-word',
  margin: 0,
});
