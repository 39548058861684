"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("../constants");
function getPlatform() {
    try {
        return navigator.platform || constants_1.UNAVAILABLE;
    }
    catch (error) {
        return constants_1.ERROR;
    }
}
exports.getPlatform = getPlatform;
