import React from 'react';
import { css } from '@compiled/react';

interface YoutubeEmbedProps {
  videoId: string;
  title: string;
  width: number;
  height: number;
}

const YoutubeEmbed: React.FC<YoutubeEmbedProps> = ({
  videoId,
  title,
  width,
  height,
}) => (
  <div css={responsiveYoutubeEmbedStyle}>
    <iframe
      width={width}
      height={height}
      src={`https://www.youtube.com/embed/${videoId}?fs=0&modestbranding=1&playsinline=1&rel=0`}
      title={title}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerPolicy="strict-origin-when-cross-origin"
      allowFullScreen
    ></iframe>
  </div>
);

export default YoutubeEmbed;

const responsiveYoutubeEmbedStyle = css({
  position: 'relative',
  overflow: 'hidden',
  paddingBottom: '56.25%',  // 16:9 aspect ratio
  height: 0,
  '& iframe': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
});
