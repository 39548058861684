import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { fg } from '@atlaskit/platform-feature-flags';
import { COLOR_MODE_ATTRIBUTE, CONTRAST_MODE_ATTRIBUTE, CUSTOM_THEME_ATTRIBUTE, THEME_DATA_ATTRIBUTE } from './constants';
import { themeStateDefaults } from './theme-config';
import { themeObjectToString } from './theme-state-transformer';
import { isValidBrandHex } from './utils/color-utils';
import { hash } from './utils/hash';
var defaultColorMode = 'light';
var defaultContrastMode = 'no-preference';

/**
 * Server-side rendering utility. Generates the valid HTML attributes for a given theme.
 * Note: this utility does not handle automatic theme switching.
 *
 * @param {Object<string, string>} themeOptions - Theme options object
 * @param {string} themeState.colorMode Determines which color theme is applied. If set to `auto`, the theme applied will be determined by the OS setting.
 * @param {string} themeState.dark The color theme to be applied when the color mode resolves to 'dark'.
 * @param {string} themeState.light The color theme to be applied when the color mode resolves to 'light'.
 * @param {string} themeState.spacing The spacing theme to be applied.
 * @param {string} themeState.typography The typography theme to be applied.
 * @param {Object} themeState.UNSAFE_themeOptions The custom branding options to be used for custom theme generation
 *
 * @returns {Object} Object of HTML attributes to be applied to the document root
 */
var getThemeHtmlAttrs = function getThemeHtmlAttrs() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    _ref$colorMode = _ref.colorMode,
    colorMode = _ref$colorMode === void 0 ? themeStateDefaults['colorMode'] : _ref$colorMode,
    _ref$dark = _ref.dark,
    dark = _ref$dark === void 0 ? themeStateDefaults['dark'] : _ref$dark,
    _ref$light = _ref.light,
    light = _ref$light === void 0 ? themeStateDefaults['light'] : _ref$light,
    _ref$contrastMode = _ref.contrastMode,
    contrastMode = _ref$contrastMode === void 0 ? themeStateDefaults['contrastMode'] : _ref$contrastMode,
    _ref$shape = _ref.shape,
    shape = _ref$shape === void 0 ? themeStateDefaults['shape'] : _ref$shape,
    _ref$spacing = _ref.spacing,
    spacing = _ref$spacing === void 0 ? themeStateDefaults['spacing'] : _ref$spacing,
    _ref$typography = _ref.typography,
    typography = _ref$typography === void 0 ? themeStateDefaults['typography']() : _ref$typography,
    _ref$UNSAFE_themeOpti = _ref.UNSAFE_themeOptions,
    UNSAFE_themeOptions = _ref$UNSAFE_themeOpti === void 0 ? themeStateDefaults['UNSAFE_themeOptions'] : _ref$UNSAFE_themeOpti;
  var themeAttribute = themeObjectToString({
    dark: dark,
    light: light,
    shape: shape,
    spacing: spacing,
    typography: typography
  });
  var result = _defineProperty(_defineProperty({}, THEME_DATA_ATTRIBUTE, themeAttribute), COLOR_MODE_ATTRIBUTE, colorMode === 'auto' ? defaultColorMode : colorMode);
  if (fg('platform_increased-contrast-themes')) {
    result = _objectSpread(_objectSpread({}, result), {}, _defineProperty({}, CONTRAST_MODE_ATTRIBUTE, contrastMode === 'auto' ? defaultContrastMode : contrastMode));
  }
  if (UNSAFE_themeOptions && isValidBrandHex(UNSAFE_themeOptions.brandColor)) {
    var optionString = JSON.stringify(UNSAFE_themeOptions);
    var uniqueId = hash(optionString);
    result[CUSTOM_THEME_ATTRIBUTE] = uniqueId;
  }
  return result;
};
export default getThemeHtmlAttrs;