/**
 * This file contains the sub-components that are wrapped with the OnClickWrapper component to send analytics events
 * with the relevant payload attributes.
 */
import React from 'react';
import Button from '@atlaskit/button/new';
import { OnClickWrapper } from '@components/community/CommunityNavigation/OnClickWrapper';
import { AtlassianNavLogo, NavBarLogoItemProps } from '@components/shared/NavigationBar/AtlassianNavLogo';
import { css } from '@emotion/react';
import type { SerializedStyles } from '@emotion/react';
import { token } from '@atlaskit/tokens';
import { LOG_IN, SIGN_UP } from '@components/community/constants';
import {
  CommunityNavBarLinkItem,
  CommunityNavBarLinkItemProps,
} from '@components/community/CommunityNavigation/CommunityNavBarLinkItem';
import { NavBarProfileItem, NavBarProfileItemProps } from '@components/shared/NavigationBar/NavBarProfileItem';
import { MenuItem, MenuItemProps } from '@components/shared/NavigationBar/NavBarProfileItem/MenuItem';
import { NavigationBar, NavigationBarProps } from '@components/shared/NavigationBar';
import { AnalyticsActivityType, CommunityApp, EventType } from '@components/community/constants';
import { UITrackEventPayload } from '@components/community/types';

// TODO: Change type of payload to match expected event payload params
export interface AnalyticsInfo {
  currentApp: CommunityApp;
  isLoggedIn: boolean;
  isMobileOrTablet: boolean;
  onAnalyticsSendEvent: (type: EventType, payload: UITrackEventPayload, activityType: AnalyticsActivityType) => void;
}

export const enrich = (payload: any, analyticsInfo: AnalyticsInfo): UITrackEventPayload => {
  payload.source = 'communityNavigation';
  payload.attributes = {
    ...(payload.attributes || {}),
    currentApp: analyticsInfo.currentApp,
    isLoggedIn: analyticsInfo.isLoggedIn,
    isNavMobileOrTablet: analyticsInfo.isMobileOrTablet,
  };
  return payload as UITrackEventPayload;
};

export const NavBarLogoItemWithAnalytics: React.FC<
  NavBarLogoItemProps & {
    analyticsInfo?: AnalyticsInfo;
  }
> = ({ analyticsInfo, subtitleHref, ...rest }) => {
  const getSendEventFn = (analyticsInfo: AnalyticsInfo) => (): void => {
    analyticsInfo.onAnalyticsSendEvent(
      EventType.UI,
      enrich(
        {
          action: 'clicked',
          actionSubject: 'logo',
          actionSubjectId: 'communityNavigationHome',
          attributes: {
            href: subtitleHref,
            destinationApp: CommunityApp.HOME,
          },
        },
        analyticsInfo,
      ),
      AnalyticsActivityType.NAV_LOGO_CLICK,
    );
  };

  return (
    <OnClickWrapper onClick={analyticsInfo && getSendEventFn(analyticsInfo)}>
      <AtlassianNavLogo subtitleHref={subtitleHref} {...rest} />
    </OnClickWrapper>
  );
};

export const NavBarLinkItemWithAnalytics: React.FC<
  CommunityNavBarLinkItemProps & {
    analyticsInfo?: AnalyticsInfo;
  }
> = ({ analyticsInfo, destinationApp, link, ...rest }) => {
  const getSendEventFn = (analyticsInfo: AnalyticsInfo) => (): void => {
    analyticsInfo.onAnalyticsSendEvent(
      EventType.UI,
      enrich(
        {
          action: 'clicked',
          actionSubject: 'link',
          actionSubjectId: 'communityNavigationLink',
          attributes: {
            href: link.href,
            label: link.text,
            destinationApp: destinationApp,
          },
        },
        analyticsInfo,
      ),
      AnalyticsActivityType.NAV_ITEM_CLICK,
    );
  };

  return (
    <OnClickWrapper onClick={analyticsInfo && getSendEventFn(analyticsInfo)}>
      <CommunityNavBarLinkItem link={link} destinationApp={destinationApp} {...rest} />
    </OnClickWrapper>
  );
};

const loginSignupWrapperStyles = (isMobile: boolean): SerializedStyles | undefined =>
  isMobile
    ? css({
        padding: '0 24px',
      })
    : undefined;

const loginButtonStyles = (isMobile: boolean): SerializedStyles =>
  isMobile
    ? css({
        button: {
          width: '100%',
          borderRadius: '0.25rem',
          borderStyle: 'solid',
          borderWidth: '1px',
          fontSize: '1rem',
          fontWeight: 600,
          fontFamily: 'Charlie Text',
          color: token('color.text.brand'),
          borderColor: token('color.border.brand'),
          '&:active': {
            backgroundColor: token('color.background.selected.bold.pressed'),
            borderColor: token('color.border.selected'),
            color: token('color.text.inverse'),
          },
          '&:hover': {
            backgroundColor: token('color.background.selected.bold.hovered'),
            borderColor: token('color.border.selected'),
            color: token('color.text.inverse'),
            textDecoration: 'none',
          },
        },
      })
    : css({
        button: {
          fontWeight: 650,
          fontFamily: 'Charlie Text',
          fontSize: '1rem',
          color: token('color.text'),
          width: 'auto',
          '&:hover': {
            backgroundColor: 'transparent',
            color: token('color.text.brand'),
            textDecoration: 'none',
          },
        },
      });

const signupButtonStyles = (isMobile: boolean): SerializedStyles =>
  css({
    button: {
      fontWeight: 550,
      fontFamily: 'Charlie Text',
      fontSize: '1rem',
      width: isMobile ? '100%' : 'auto',
    },
  });

export const LoginButtonWithAnalytics: React.FC<{
  href: string;
  analyticsInfo?: AnalyticsInfo;
  isMobile?: boolean;
}> = ({ href, analyticsInfo, isMobile = false }) => {
  const getSendEventFn = (analyticsInfo: AnalyticsInfo) => (): void => {
    analyticsInfo.onAnalyticsSendEvent(
      EventType.UI,
      enrich(
        {
          action: 'clicked',
          actionSubject: 'button',
          actionSubjectId: 'communityNavigationLogin',
          attributes: {
            href: href,
          },
        },
        analyticsInfo,
      ),
      AnalyticsActivityType.LOG_IN_CLICK,
    );
  };

  return (
    <OnClickWrapper onClick={analyticsInfo && getSendEventFn(analyticsInfo)}>
      <div css={loginSignupWrapperStyles(isMobile)}>
        <a css={loginButtonStyles(isMobile)} href={href}>
          <Button appearance="subtle">{LOG_IN}</Button>
        </a>
      </div>
    </OnClickWrapper>
  );
};

export const SignupButtonWithAnalytics: React.FC<{
  href: string;
  analyticsInfo?: AnalyticsInfo;
  isMobile?: boolean;
}> = ({ href, analyticsInfo, isMobile = false }) => {
  const getSendEventFn = (analyticsInfo: AnalyticsInfo) => (): void => {
    analyticsInfo.onAnalyticsSendEvent(
      EventType.UI,
      enrich(
        {
          action: 'clicked',
          actionSubject: 'button',
          actionSubjectId: 'communityNavigationSignup',
          attributes: {
            href: href,
          },
        },
        analyticsInfo,
      ),
      AnalyticsActivityType.SIGN_UP_CLICK,
    );
  };

  return (
    <OnClickWrapper onClick={analyticsInfo && getSendEventFn(analyticsInfo)}>
      <div css={loginSignupWrapperStyles(isMobile)}>
        <a css={signupButtonStyles(isMobile)} href={href}>
          <Button appearance="primary">{SIGN_UP}</Button>
        </a>
      </div>
    </OnClickWrapper>
  );
};

export const ProfileWithAnalytics: React.FC<
  NavBarProfileItemProps & {
    analyticsInfo?: AnalyticsInfo;
  }
> = ({ analyticsInfo, ...rest }) => {
  const getSendEventFn =
    (analyticsInfo: AnalyticsInfo) =>
    (isMenuOpen: boolean): void => {
      analyticsInfo.onAnalyticsSendEvent(
        EventType.UI,
        enrich(
          {
            action: 'clicked',
            actionSubject: 'button',
            actionSubjectId: !isMenuOpen ? 'communityNavigationProfileMenuOpen' : 'communityNavigationProfileMenuClose',
          },
          analyticsInfo,
        ),
        !isMenuOpen ? AnalyticsActivityType.PROFILE_MENU_OPEN : AnalyticsActivityType.PROFILE_MENU_CLOSE,
      );
    };

  return <NavBarProfileItem onProfileMenuClick={analyticsInfo && getSendEventFn(analyticsInfo)} {...rest} />;
};

export const MenuItemWithAnalytics: React.FC<
  MenuItemProps & {
    analyticsInfo?: AnalyticsInfo;
  }
> = ({ analyticsInfo, link, type, ...rest }) => {
  const getSendEventFn = (analyticsInfo: AnalyticsInfo) => (): void => {
    analyticsInfo.onAnalyticsSendEvent(
      EventType.UI,
      enrich(
        {
          action: 'clicked',
          actionSubject: 'button',
          actionSubjectId: 'communityNavigationMenuItem',
          attributes: {
            href: link.href,
            label: link.text,
            menuItemId: link.id,
          },
        },
        analyticsInfo,
      ),
      AnalyticsActivityType.PROFILE_MENU_ITEM_CLICK,
    );
  };

  return (
    <OnClickWrapper onClick={analyticsInfo && getSendEventFn(analyticsInfo)}>
      <MenuItem type={type} link={link} {...rest} />
    </OnClickWrapper>
  );
};

export const NavigationBarWithAnalytics: React.FC<
  NavigationBarProps & {
    analyticsInfo?: AnalyticsInfo;
  }
> = ({ analyticsInfo, ...rest }) => {
  // event when clicking on the menu toggle button in mobile/tablet view
  const getSendEventMenuToggleFn =
    (analyticsInfo: AnalyticsInfo) =>
    (isMenuOpen: boolean): void => {
      analyticsInfo.onAnalyticsSendEvent(
        EventType.UI,
        enrich(
          {
            action: 'clicked',
            actionSubject: 'button',
            actionSubjectId: !isMenuOpen ? 'communityNavigationMobileMenuOpen' : 'communityNavigationMobileMenuClose',
          },
          analyticsInfo,
        ),
        // TODO: Fix bug with outside click handler in NavigationBar (currently, it doesn't register toggling the menu closed)
        !isMenuOpen ? AnalyticsActivityType.MOBILE_MENU_OPEN : AnalyticsActivityType.MOBILE_MENU_CLOSE,
      );
    };
  // event when clicking outside of the menu in mobile/tablet view, which closes the menu
  const getSendEventOutsideClickFn = (analyticsInfo: AnalyticsInfo) => (): void => {
    analyticsInfo.onAnalyticsSendEvent(
      EventType.UI,
      enrich(
        {
          action: 'clicked',
          actionSubject: 'component',
          actionSubjectId: 'communityNavigationMobileMenuClose',
        },
        analyticsInfo,
      ),
      AnalyticsActivityType.MOBILE_MENU_CLOSE,
    );
  };

  return (
    <NavigationBar
      onMenuToggleClick={analyticsInfo && getSendEventMenuToggleFn(analyticsInfo)}
      onMenuOutsideClick={analyticsInfo && getSendEventOutsideClickFn(analyticsInfo)}
      {...rest}
    />
  );
};
