import { FunctionComponent } from 'react';
import { css } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { DangerTriangle } from 'assets/images';
import { Button, ButtonAppearance } from '@atlassian/learning-components';
import { getAuthUrl } from 'utils/url-generator';

const Error: FunctionComponent = () => {
  const onClickLogout = () => {
    if (typeof window !== 'undefined') {
      const currentPage = encodeURIComponent(window.location.href);

      window.location.replace(
        getAuthUrl('logout', {
          redirectUrl: currentPage,
          experience: 'entry-auth',
        }),
      );
    }
  };
  return (
    <div css={pageStyles}>
      <div css={containerStyles}>
        <img src={DangerTriangle} alt="Error" css={dangerTriangleStyles} />
        <h1 css={headingStyles}>Error</h1>
        <p css={descriptionStyles}>
          We were unable to create your account. Please log out and try again.
        </p>
        <Button
          label="Log out"
          appearance={ButtonAppearance.LINK}
          onClick={onClickLogout}
        />
      </div>
    </div>
  );
};

export default Error;

const pageStyles = css({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const containerStyles = css({
  maxWidth: '800px',
  minWidth: '375px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: token('space.600'),
});

const dangerTriangleStyles = css({
  marginBottom: token('space.300'),
});

const headingStyles = css({
  marginBottom: token('space.200'),
  fontFamily: token('font.family.brand.heading'),
  fontSize: '24px',
  fontWeight: token('font.weight.semibold'),
  lineHeight: '32px',
  textAlign: 'center',
});

const descriptionStyles = css({
  marginBottom: token('space.200'),
  color: token('color.text'),
  fontFamily: 'Inter',
  fontWeight: '400',
  lineHeight: '20px',
  textAlign: 'center',
  fontSize: '16px',
});
