import React from 'react';
import { css } from '@emotion/react';
import { media } from '@atlaskit/primitives';

import { Icon } from '@components/shared';
import { token } from '@atlaskit/tokens';

export interface InlineFeedbackMessageProps {
  /** Message title */
  title: string;
  /** Descriptive markdown text of the message */
  description?: React.ReactNode;
  /** Appearance changes the color, and icon of the message */
  appearance?: InlineFeedbackMessageAppearance;
}

export enum InlineFeedbackMessageAppearance {
  INCORRECT = 'INCORRECT',
  CORRECT = 'CORRECT',
}
/**
 * @param {InlineFeedbackMessageProps} MlInlineFeedbackMessageProps
 * @returns {React.ReactElement} React.ReactElement
 *
 * @example
 * <InlineFeedbackMessage title='title' />
 */

export const InlineFeedbackMessage: React.FC<InlineFeedbackMessageProps> = ({
  title,
  description,
  appearance = InlineFeedbackMessageAppearance.CORRECT,
}) => {
  const isIncorrectAppearance = appearance === InlineFeedbackMessageAppearance.INCORRECT;

  return (
    <div css={feedbackMessageContainerStyles}>
      <div
        css={[
          feedbackMessageLeftBorderStyles,
          css({
            backgroundColor: `${isIncorrectAppearance ? token('color.border.danger') : token('color.border.success')}`,
          }),
        ]}
      ></div>
      <div data-testid={`feedback-message-${appearance}`} css={feedbackMessageContentContainerStyles}>
        {isIncorrectAppearance ? (
          <Icon type="error" color={token('color.border.danger')} />
        ) : (
          <Icon type="check-circle" color={token('color.border.success')} />
        )}
        <div css={feedbackMessageContentStyles}>
          <p css={feedbackMessageTitleStyles}>{title}</p>
          {description && <p css={feedbackMessageDescriptionStyles}>{description}</p>}
        </div>
      </div>
    </div>
  );
};

const feedbackMessageContainerStyles = css({
  width: '100%',
  display: 'flex',
  backgroundColor: token('color.background.input'),
});

const feedbackMessageContentContainerStyles = css({
  width: '100%',
  padding: '24px 24px 24px 16px',
  display: 'flex',
  border: `1px solid ${token('color.border')}`,
  borderRadius: '0 3px 3px 0',
  borderLeft: 'none',

  [media.below.xs]: {
    padding: '16px 16px 16px 8px',
  },
});

const feedbackMessageLeftBorderStyles = css({
  maxHeight: '100%',
  width: '8px',
  borderRadius: '3px 0 0 3px',
});

const feedbackMessageContentStyles = css({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginLeft: '16px',

  [media.below.xs]: {
    marginLeft: '8px',
  },
});

const feedbackMessageTitleStyles = css({
  fontFamily: 'Charlie Display, sans-serif',
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '24px',
  color: token('color.text'),
  margin: 0,

  [media.below.xs]: {
    fontSize: '16px',
  },
});

const feedbackMessageDescriptionStyles = css({
  marginTop: '16px',
  fontFamily: 'Inter, sans-serif',
  fontSize: '20px',
  lineHeight: '32px',
  color: token('color.text'),
  marginBottom: 0,

  p: {
    margin: 0,
  },

  [media.below.xs]: {
    marginTop: '8px',
    fontSize: '16px',
    lineHeight: '24px',
  },
});
