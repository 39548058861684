var _process, _process2;
// We can't rely on NODE_ENV === 'test' if its value is already configured by the consumer to some other value, so better to use JEST_WORKER_ID
// https://jestjs.io/docs/environment-variables#jest_worker_id
var TESTS_MODE = (globalThis === null || globalThis === void 0 || (_process = globalThis.process) === null || _process === void 0 || (_process = _process.env) === null || _process === void 0 ? void 0 : _process.JEST_WORKER_ID) !== undefined;
var DEBUG_MODE = !TESTS_MODE && (globalThis === null || globalThis === void 0 || (_process2 = globalThis.process) === null || _process2 === void 0 || (_process2 = _process2.env) === null || _process2 === void 0 ? void 0 : _process2.NODE_ENV) !== 'production';
export var debug = function debug() {
  var _console;
  if (!DEBUG_MODE) {
    return;
  }

  // eslint-disable-next-line no-console
  (_console = console).debug.apply(_console, arguments);
};