import React from 'react';
import { Link } from '@components/shared/NavigationBar/NavBarLinkItem';
import { css } from '@emotion/react';
import { token } from '@atlaskit/tokens';

export interface MenuItemProps {
  link: Link;
  type: MenuItemType;
}

export type MenuItemType = 'profile' | 'subMenu';

export const MenuItem: React.FC<MenuItemProps> = ({ link, type }) => {
  const { href, onClick, text } = link;
  return (
    <a css={menuLinkStyles} href={href} onClick={onClick}>
      <li css={[menuItemContainerBaseStyles, menuItemContainerStyles[type]]} role="treeitem">
        {text}
      </li>
    </a>
  );
};

const menuItemContainerBaseStyles = css({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
});

const menuItemContainerStyles = {
  subMenu: css({
    height: '32px',
    padding: '0 40px',
  }),
  profile: css({
    height: '48px',
    padding: '0 24px',
  }),
};

const menuLinkStyles = css({
  fontSize: '1rem',
  fontWeight: 400,
  color: '#172B4D',
  cursor: 'pointer',
  '&:hover': {
    color: '#0C66E4',
    textDecoration: 'none',
  },
  '&:active': {
    color: '#0055CC',
    li: {
      background: token('color.background.accent.blue.subtlest'),
    },
  },
});
