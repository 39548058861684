const StarFilledIcon: React.FC = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icon / &#60;StarFilledIcon&#62;">
      <rect width="12" height="12" rx="6" fill="#44546F" />
      <path
        id="Vector"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.0357 8.64198L4.0832 9.66848C4.00063 9.7119 3.90755 9.73135 3.8145 9.72463C3.72146 9.71791 3.63214 9.68529 3.55666 9.63046C3.48118 9.57563 3.42255 9.50078 3.38739 9.41437C3.35223 9.32796 3.34194 9.23344 3.3577 9.14149L3.7302 6.96698L2.1507 5.42698C2.08399 5.36186 2.03683 5.27937 2.01452 5.18886C1.99222 5.09834 1.99566 5.00338 2.02447 4.91472C2.05328 4.82605 2.10631 4.74721 2.17756 4.68709C2.24881 4.62697 2.33545 4.58797 2.4277 4.57448L4.6107 4.25698L5.5872 2.27898C5.62846 2.19525 5.69234 2.12474 5.7716 2.07544C5.85087 2.02613 5.94235 2 6.0357 2C6.12905 2 6.22053 2.02613 6.29979 2.07544C6.37906 2.12474 6.44294 2.19525 6.4842 2.27898L7.4602 4.25698L9.6432 4.57448C9.73549 4.58789 9.82219 4.62684 9.89351 4.68693C9.96482 4.74701 10.0179 4.82585 10.0468 4.91453C10.0756 5.00321 10.0791 5.09819 10.0569 5.18875C10.0346 5.2793 9.98741 5.36182 9.9207 5.42698L8.3407 6.96698L8.7137 9.14149C8.72939 9.23337 8.71908 9.32781 8.68394 9.41414C8.64879 9.50048 8.59022 9.57527 8.51482 9.63008C8.43942 9.68488 8.3502 9.71752 8.25724 9.72431C8.16427 9.7311 8.07126 9.71176 7.9887 9.66848L6.0357 8.64198Z"
        fill="white"
      />
    </g>
  </svg>
);
export default StarFilledIcon;
