import React from 'react';
import { token } from '@atlaskit/tokens';
import { css } from '@compiled/react';
import { LinkWithAnalytics as Link } from './analytics-wrapped/LinkWithAnalytics';
import ArrowRightIcon from '@atlaskit/icon/core/arrow-right';
import { LinkData } from 'components/common/analytics-wrapped/LinkData';

type SectionLinkProps = LinkData & {
  textSize?: 'small' | 'medium';
};
type TargetOption = '_self' | '_blank' | '_parent' | '_top' | undefined;

const SectionLink: React.FC<SectionLinkProps> = ({
  href,
  text,
  target,
  clickEventPayload,
  hoverEventPayload,
  textSize = 'medium',
}) => (
  <Link
    css={buttonStyle}
    href={href}
    target={target as TargetOption}
    clickEventPayload={clickEventPayload}
    hoverEventPayload={hoverEventPayload}
  >
    <span
      css={[
        buttonLabelStyle,
        textSize === 'medium' ? mediumTextStyle : smallTextStyle,
      ]}
    >
      {text}
    </span>
    <ArrowRightIcon label={text} />
  </Link>
);

const buttonStyle = css({
  display: 'inline-flex',
  gap: '4px',
  span: {
    // vertically center svg inside span (necessary for homepage GetStarted section links)
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const buttonLabelStyle = css({
  padding: `${token('space.100')}, ${token('space.100')}, ${token(
    'space.100',
  )}, ${token('space.150')}`,
  color: token('color.text.brand'),
  fontFamily: 'Charlie Text, sans-serif',
});

const smallTextStyle = css({
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '20px',
});

const mediumTextStyle = css({
  fontSize: '20px',
  fontWeight: 600,
  lineHeight: '24px',
});

export default SectionLink;
