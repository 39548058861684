import URL, { qs } from 'url-parse';
import { isSameRoute } from '../../../common/utils';

const stripTrailingSlash = path => path.charAt(path.length - 1) === '/' ? path.slice(0, -1) : path;

const addLeadingSlash = path => path.charAt(0) === '/' ? path : '/' + path;

export const sanitizePath = function sanitizePath(path, prefix) {
  if (prefix === void 0) {
    prefix = '';
  }

  if (prefix) {
    prefix = stripTrailingSlash(prefix);
    prefix = addLeadingSlash(prefix);
    path = addLeadingSlash(path);
  }

  return prefix + path;
};
export const isAbsolutePath = path => {
  const regex = new RegExp('^([a-z]+://|//)', 'i');
  return regex.test(path);
};
export const isExternalAbsolutePath = path => {
  if (typeof path !== 'string' || !isAbsolutePath(path)) {
    return false;
  }

  const pathHostname = new URL(path).hostname;
  const currentHostname = new URL(window.location.href).hostname;
  return pathHostname !== currentHostname;
};
export const getRelativePath = function getRelativePath(path, prefix) {
  if (prefix === void 0) {
    prefix = '';
  }

  if (typeof path !== 'string') {
    return path;
  }

  if (!isAbsolutePath(path)) {
    path = sanitizePath(path, prefix);
    return path;
  }

  const url = new URL(path);
  return `${url.pathname}${url.query}${url.hash}`;
};
export const updateQueryParams = (location, query) => {
  // @ts-ignore stringify accepts two params but it's type doesn't say so
  const stringifiedQuery = qs.stringify(query, true);
  return `${location.pathname}${stringifiedQuery}${location.hash}`;
};
export const getRelativeURLFromLocation = location => {
  return `${location.pathname}${location.search}${location.hash}`;
};
export const shouldReload = _ref => {
  let {
    context,
    prevContext,
    pluginId
  } = _ref;
  const sameRoute = isSameRoute({
    prevContextMatch: prevContext.match,
    nextContextMatch: context.match
  }); // keep old behaviour for Resources plugin
  // load Route only if path/query/params changed, and ignore the rest of query-params

  const defaultShouldReload = pluginId === 'resources-plugin' || !sameRoute;

  if (context.route === prevContext.route && context.route.EXPERIMENTAL__shouldReload) {
    const routeChoice = context.route.EXPERIMENTAL__shouldReload({
      context,
      prevContext,
      pluginId,
      defaultShouldReload
    });

    if (typeof routeChoice === 'boolean') {
      return routeChoice;
    }
  }

  return defaultShouldReload;
};