import { ClientOptions } from '@atlaskit/feature-gate-js-client';
import {
  FeatureGateEnvironment,
} from '@atlaskit/feature-gate-js-client';
import { STATSIG_TARGET_APP } from 'utils/constants';

export const statsigEnvironment = () => {
  switch (process.env.REACT_APP_ENVI) {
    case 'production':
      return FeatureGateEnvironment.Production;
    case 'staging':
      return FeatureGateEnvironment.Staging;
    default:
      return FeatureGateEnvironment.Development;
  }
};

export const getClientOptions = (env: string): ClientOptions => {
  const apiKey = process.env.REACT_APP_STATSIG_API_KEY || '';
  return {
    apiKey: apiKey,
    environment: statsigEnvironment(),
    targetApp: STATSIG_TARGET_APP,
  };
}