import { Icon, IconType, Image, ImageProps } from '@components/shared';
import { Popup, ArrowPosition } from '@components/learning';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { css, keyframes } from '@emotion/react';
import { SerializedStyles } from '~node_modules/@emotion/react';
import { token } from '@atlaskit/tokens';

export interface MarkerInfo {
  isSelected?: boolean;
  posX: number;
  posY: number;
  title: string;
  icon?: IconType;
  arrowPosition: ArrowPosition;
  children: React.ReactNode;
}

export interface LabelledGraphicProps {
  /** Image to be labelled with markers */
  image: ImageProps;
  /** List of markers information to set them within image  */
  markers: MarkerInfo[];
  /** onClick callback for markers */
  onClickMarker?: (markerIndex: number) => void;
  /** onClick callback for next button */
  onClickNext?: () => void;
  /** onClick callback for prev button */
  onClickPrevious?: () => void;
  /** onClick callback for dismiss button */
  onClickDismiss?: () => void;
  /** pulseAnimationColor indicates color of the pulse animation */
  pulseAnimationColor?: string;
}

/**
 * Component that renders a labelled image with clickable markers
 * @param {LabelledGraphic} LabelledGraphicProps
 * @returns {React.ReactElement} React.ReactElement
 *
 * @example
 * <LabelledGraphic />
 */
export const LabelledGraphic: React.FC<LabelledGraphicProps> = ({
  image,
  markers,
  onClickMarker,
  onClickNext,
  onClickPrevious,
  onClickDismiss,
  pulseAnimationColor = token('color.background.input'),
}): React.ReactElement => {
  const [activeId, setActiveId] = useState<number | null>(null);
  const [popoverPosX, setPopoverPosX] = useState<number | null>(null);
  const [popoverPosY, setPopoverPosY] = useState<number | null>(null);
  const [clickedMarkers, setClickedMarkers] = useState<number[]>([]);

  const containerDivRef = useRef<HTMLDivElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  const imageContainerRef = useRef<HTMLDivElement>(null);
  const maxPopupHeight = 540;
  const maxPopupWidth = 340;
  const defaultDesktopWidth = 1040;

  const iconNumbers = ['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'zero'];

  const handleNextStep = (idx: number): void => {
    if (onClickNext) {
      onClickNext();
    }
    setClickedMarkers((prevState) => [...prevState, idx + 1]);
    setActiveId((prevId: number | null) => {
      return prevId !== null ? prevId + 1 : null;
    });
  };

  const handlePreviousStep = (idx: number): void => {
    if (onClickPrevious) {
      onClickPrevious();
    }
    setClickedMarkers((prevState) => [...prevState, idx - 1]);
    setActiveId((prevId: number | null) => {
      return prevId !== null ? prevId - 1 : null;
    });
  };

  const handleDismiss = useCallback(() => {
    if (onClickDismiss) {
      onClickDismiss();
    }
    setActiveId(null);
  }, []);

  const handleClickMarker = useCallback((idx: number): void => {
    if (onClickMarker) {
      onClickMarker(idx + 1);
    }
    setClickedMarkers((prevState) => [...prevState, idx]);
    setActiveId(idx);
  }, []);

  useEffect(() => {
    if (containerDivRef.current && activeId != null) {
      const parentWidth = containerDivRef.current.clientWidth;
      const parentHeight = containerDivRef.current.clientHeight;
      const popoverHeight = popupRef?.current?.clientHeight ?? 0;

      let posX = (parentWidth * markers[activeId].posX) / 100;
      let posY = (parentHeight * markers[activeId].posY) / 100;

      switch (markers[activeId].arrowPosition) {
        case ArrowPosition.LEFT_START:
          posX = posX + 70;
          posY = posY - 15;
          break;
        case ArrowPosition.LEFT_CENTER:
          posX = posX + 70;
          posY = posY - Math.ceil(popoverHeight / 2.5) - 15;
          break;
        case ArrowPosition.LEFT_END:
          posX = posX + 70;
          posY = posY - popoverHeight + 70;
          break;
        case ArrowPosition.TOP_START:
          posX = posX - 24;
          posY = posY + 70;
          break;
        case ArrowPosition.TOP_CENTER:
          posX = posX - Math.ceil(maxPopupWidth / 2) + 27;
          posY = posY + 70;
          break;
        case ArrowPosition.TOP_END:
          posX = posX - maxPopupWidth + 60;
          posY = posY + 70;
          break;
        case ArrowPosition.RIGHT_START:
          posX = posX - maxPopupWidth - 24;
          posY = posY - 13;
          break;
        case ArrowPosition.RIGHT_CENTER:
          posX = posX - maxPopupWidth - 24;
          posY = posY - Math.ceil(popoverHeight / 2) + 32;
          break;
        case ArrowPosition.RIGHT_END:
          posX = posX - maxPopupWidth - 24;
          posY = posY - popoverHeight + 70;
          break;
        case ArrowPosition.BOTTOM_START:
          posX = posX - 25;
          posY = posY - popoverHeight - 20;
          break;
        case ArrowPosition.BOTTOM_CENTER:
          posX = posX - Math.ceil(maxPopupWidth / 2) + 25;
          posY = posY - popoverHeight - 20;
          break;
        case ArrowPosition.BOTTOM_END:
          posX = posX - maxPopupWidth + 70;
          posY = posY - popoverHeight - 18;
          break;
        default:
          break;
      }

      setPopoverPosX((posX / parentWidth) * 100);
      setPopoverPosY((posY / parentHeight) * 100);

      // Focus the popup when it is opened
      popupRef.current?.focus();
    }
  }, [activeId, markers, imageContainerRef.current?.clientHeight]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.code === 'Space') {
        event.preventDefault();
        handleClickMarker(Number(event.currentTarget.getAttribute('data-marker-index')));
      }
    },
    [handleClickMarker],
  );

  return (
    <div css={labelledGraphicContainerStyles} ref={containerDivRef}>
      {markers?.map((marker: MarkerInfo, idx: number) => (
        <div
          role="button"
          tabIndex={0}
          onKeyDown={handleKeyDown}
          key={`marker-${idx}`}
          data-testid="graphic-marker"
          css={[
            markerPulseStyles,
            clickedMarkers.includes(idx) && 'none',
            !clickedMarkers.includes(idx) &&
              pulseAnimationColor === '#FFFFFF' &&
              css({ animation: `${pulseWhiteAnimationStyles} 3000ms cubic-bezier(0.55, 0.06, 0.68, 0.19) infinite` }),
            !clickedMarkers.includes(idx) &&
              pulseAnimationColor !== '#FFFFFF' &&
              css({ animation: `${pulsePurpleAnimationStyles} 3000ms cubic-bezier(0.55, 0.06, 0.68, 0.19) infinite` }),
          ]}
          style={{ left: marker.posX + '%', top: marker.posY + '%' }}
        >
          <div
            onClick={(e: React.MouseEvent<HTMLDivElement>): void => {
              e.preventDefault();
              e.stopPropagation();
              handleClickMarker(idx);
            }}
            draggable
            css={[
              graphicMarkerContainerStyles['base'],
              activeId === idx && graphicMarkerContainerStyles['selected'],
              css({ border: `2px solid ${pulseAnimationColor}` }),
            ]}
          >
            <>
              {marker.icon && iconNumbers.includes(marker.icon) && (
                <span
                  css={[
                    activeId === idx && css({ color: token('color.text.inverse') }),
                    activeId !== idx && css({ color: token('color.text') }),
                  ]}
                >
                  <Icon type={marker.icon} />
                </span>
              )}
              {marker.icon && !iconNumbers.includes(marker.icon) && (
                <span css={css({ display: 'flex', alignItems: 'center' })}>
                  <Icon
                    size="small"
                    color={`${activeId === idx ? token('color.icon.inverse') : token('color.icon.warning.inverse')}`}
                    type={marker.icon}
                  />
                </span>
              )}
            </>
          </div>
        </div>
      ))}
      {activeId != null && imageContainerRef.current && containerDivRef.current && (
        <Popup
          popupHeight={imageContainerRef.current.clientHeight > 540 ? 540 : imageContainerRef.current.clientHeight}
          title={markers[activeId].title}
          totalSteps={markers.length}
          step={activeId + 1}
          popupStyle={{
            position: 'absolute',
            left: popoverPosX + '%',
            top: popoverPosY + '%',
            maxHeight: `${maxPopupHeight}px`,
            maxWidth: `${maxPopupWidth}px`,
          }}
          arrowPosition={markers[activeId].arrowPosition}
          onNext={(): void => handleNextStep(activeId)}
          onPrevious={(): void => handlePreviousStep(activeId)}
          onClose={handleDismiss}
          ref={popupRef}
        >
          {markers[activeId].children}
        </Popup>
      )}
      <div ref={imageContainerRef}>
        <Image {...image} css={[imageStyles, css({ maxWidth: `${defaultDesktopWidth}px` })]} preventModal />
      </div>
    </div>
  );
};

const labelledGraphicContainerStyles = css({
  position: 'relative',
  cursor: 'default',
});

const pulseWhiteAnimationStyles = keyframes({
  '0%': { boxShadow: `0 0 0 2px ${token('color.border.inverse')}, 0 0 0 ${token('color.border.inverse')}` },
  '33%': { boxShadow: `0 0 0 2px ${token('color.border.inverse')}, 0 0 0 ${token('color.border.inverse')}` },
  '66%': { boxShadow: `0 0 0 2px ${token('color.border.inverse')}, 0 0 0 10px ${token('color.border.inverse')}` },
  '100%': { transform: `0 0 0 2px ${token('color.border.inverse')}, 0 0 0 10px ${token('color.border.inverse')}` },
});

const pulsePurpleAnimationStyles = keyframes({
  '0%': { boxShadow: `0 0 0 2px ${token('color.text.discovery')}, 0 0 0 ${token('color.text.discovery')}` },
  '33%': { boxShadow: `0 0 0 2px ${token('color.text.discovery')}, 0 0 0 ${token('color.text.discovery')}` },
  '66%': { boxShadow: `0 0 0 2px ${token('color.text.discovery')}, 0 0 0 10px ${token('color.text.discovery')}` },
  '100%': { transform: `0 0 0 2px ${token('color.text.discovery')}, 0 0 0 10px ${token('color.text.discovery')}` },
});

const markerPulseStyles = css({
  height: '46px',
  width: '46px',
  padding: '6px 12px',
  backgroundColor: 'transparent',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  borderRadius: '50%',
  zIndex: 2,
  cursor: 'pointer',
});

const graphicMarkerContainerStyles = {
  base: css({
    height: '40px',
    width: '40px',
    padding: '6px 12px',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: token('color.background.accent.yellow.subtle'),
    fontFamily: 'Charlie Display, sans-serif',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
    color: token('color.text'),
    boxShadow: token('elevation.shadow.overlay'),

    '&:hover': {
      backgroundColor: token('color.background.accent.yellow.subtle.hovered'),
    },

    '&:active': {
      backgroundColor: token('color.background.accent.yellow.subtle.pressed'),
    },
  }),

  selected: css({
    backgroundColor: token('color.text'),

    '&:hover': {
      backgroundColor: token('color.text'),
    },

    '&:active': {
      backgroundColor: token('color.text'),
    },
  }),
} as { [key: string]: SerializedStyles };

const imageStyles = css({
  height: 'auto',
  width: '100%',
});
