"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _analytics = require("./analytics");
Object.keys(_analytics).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _analytics[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _analytics[key];
    }
  });
});
var _gatekeeper = require("./gatekeeper");
Object.keys(_gatekeeper).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _gatekeeper[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _gatekeeper[key];
    }
  });
});
var _consent = require("./consent");
Object.keys(_consent).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _consent[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _consent[key];
    }
  });
});
var _community = require("./community");
Object.keys(_community).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _community[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _community[key];
    }
  });
});