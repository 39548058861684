import React from 'react';
import { Icon, IconSize } from '@components/shared';
import { token } from '@atlaskit/tokens';

interface LessonOverviewProps {
  isCompleted: boolean;
  size: IconSize;
}

export const IconChange: React.FC<LessonOverviewProps> = ({ isCompleted, size }) =>
  isCompleted ? (
    <Icon type="check-circle-filled" size={size} color={token('color.icon.success')} />
  ) : (
    <Icon type="check-circle-outline" size={size} color={token('color.background.accent.gray.subtler.pressed')} />
  );
