import React, { MouseEvent, MouseEventHandler, useCallback } from 'react';
import AtlaskitButton, { Appearance } from '@atlaskit/button';
import Tooltip from '@atlaskit/tooltip';
import Spinner from '@atlaskit/spinner';
import { css, SerializedStyles } from '@emotion/react';
import { token } from '@atlaskit/tokens';
import { media } from '@atlaskit/primitives';

export enum ButtonSize {
  MEDIUM = 'medium',
  LARGE = 'large',
}

export interface ButtonProps {
  /** Displaying text of the button */
  label: string;
  /** Style of the button */
  appearance?: ButtonAppearance;
  /** Optional onClick handler */
  onClick?: MouseEventHandler<HTMLElement>;
  /** Url for buttons being used as a link */
  href?: string;
  /** Specifies where the linked URL will be opened */
  target?: React.HTMLAttributeAnchorTarget;
  /** Icon to render before label */
  iconBefore?: React.ReactElement;
  /** Icon to render after label */
  iconAfter?: React.ReactElement;
  /** Type of icon */
  iconType?: string;
  /** Attribute type of the button */
  type?: 'button' | 'submit' | 'reset';
  /** Indicates if the button should fit container */
  fullWidth?: boolean;
  /** Button size */
  size?: 'medium' | 'large';
  /** Indicates if the button is disabled. Default: false*/
  disabled?: boolean;
  /**Indicates if the button is selected.Default: true */
  selected?: boolean;
  /**Indicates if the button is in loading state */
  isLoading?: boolean;
  /**Indicates if button has extra padding */
  withPadding?: boolean;
  /** Tooltip for the button */
  tooltip?: string | null;
  /** Enables responsive styles for Primary and Secondary appearances */
  isResponsive?: boolean;
}

/**
 * @description Style of the button
 * @enum {string} DEFAULT | DANGER | LINK | PRIMARY | SUBTLE | SUBTLE_LINK | WARNING
 * @example ButtonAppearance.DEFAULT
 */
export enum ButtonAppearance {
  DEFAULT = 'default',
  DANGER = 'danger',
  LINK = 'link',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUBTLE = 'subtle',
  WARNING = 'warning',
  ICON = 'icon',
  SUCCESS = 'success',
}

/**
 * @param {ButtonProps} ButtonProps
 * @returns {React.ReactElement} React.ReactElement
 *
 * @example
 * <Button label="CTA" />
 */
export const Button: React.FC<ButtonProps> = ({
  label,
  appearance = ButtonAppearance.DEFAULT,
  onClick,
  href,
  target,
  iconBefore,
  iconAfter,
  type = 'button',
  fullWidth = false,
  size = ButtonSize.MEDIUM,
  disabled = false,
  selected = false,
  isLoading = false,
  withPadding = true,
  iconType,
  tooltip,
  isResponsive = false,
}) => {
  const isButtonWithoutIcon = !iconBefore && !iconAfter;
  const showBeforeIcon = !isLoading && iconBefore;
  const showAfterIcon = !isLoading && iconAfter;
  const isResponsiveSpacing =
    isResponsive &&
    [ButtonAppearance.PRIMARY, ButtonAppearance.SECONDARY].includes(appearance) &&
    size === ButtonSize.LARGE;

  const clickHandler = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      if (onClick) {
        onClick(e);
      }
    },
    [onClick],
  );

  if (appearance === ButtonAppearance.DEFAULT) {
    return (
      <AtlaskitButton
        aria-label={label.replaceAll('-', ' ')}
        type={type}
        onClick={clickHandler}
        iconBefore={iconBefore}
        iconAfter={iconAfter}
        appearance={appearance as Appearance}
        testId={`button-${appearance}${selected ? '-selected' : ''}`}
        href={href}
        target={target}
        shouldFitContainer={fullWidth}
        isDisabled={disabled || isLoading}
        isSelected={selected}
        css={[
          isLoading &&
            !label &&
            learningButtonStyles['basic'] &&
            css({
              padding: '21px 49px',
              alignItems: 'center',
            }),
          fullWidthStyles(fullWidth),
        ]}
      >
        <span css={[isLoading && label && css({ marginRight: token('space.100') })]}>{label}</span>
        {isLoading && <Spinner testId="spinner" size="small" />}
      </AtlaskitButton>
    );
  }

  if (appearance === ButtonAppearance.ICON) {
    return tooltip ? (
      <Tooltip content={tooltip}>
        <button
          tabIndex={0}
          aria-label={iconType ? iconType.replaceAll('-', ' ') : 'button icon'}
          data-testid={`button-${appearance}${selected ? '-selected' : ''}`}
          disabled={disabled}
          css={[learningButtonIconStyles['base'], selected && learningButtonIconStyles['selected']]}
          onClick={clickHandler}
        >
          {isLoading && <Spinner testId="spinner" size="small" />}
          {showBeforeIcon}
        </button>
      </Tooltip>
    ) : (
      <button
        tabIndex={0}
        aria-label={iconType ? iconType.replaceAll('-', ' ') : 'button icon'}
        data-testid={`button-${appearance}${selected ? '-selected' : ''}`}
        disabled={disabled}
        css={[learningButtonIconStyles['base'], selected && learningButtonIconStyles['selected']]}
        onClick={clickHandler}
      >
        {isLoading && <Spinner testId="spinner" size="small" />}
        {showBeforeIcon}
      </button>
    );
  }

  if (appearance === ButtonAppearance.LINK) {
    return (
      <button
        aria-label={label.replaceAll('-', ' ')}
        disabled={disabled}
        onClick={clickHandler}
        data-testid={`button-${appearance}${selected ? '-selected' : ''}`}
        css={[
          learningButtonLinkStyles['basic'],
          disabled && learningButtonLinkStyles['disabled'],
          isButtonWithoutIcon && learningButtonSpacingStyles[size],
          iconBefore && learningButtonSpacingStyles[`${size}-icon-before`],
          iconAfter && learningButtonSpacingStyles[`${size}-icon-after`],
          !withPadding && css({ padding: 0 }),
        ]}
      >
        {showBeforeIcon && (
          <span css={[buttonIconContainerStyles, css({ marginRight: token('space.050') })]}>{iconBefore}</span>
        )}
        <a
          target={target}
          href={!disabled ? href : undefined}
          rel={target === '_blank' ? 'noreferrer' : ''}
          tabIndex={0}
          css={[linkStyles, disabled && disabledLinkStyles]}
        >
          <span>{label}</span>
        </a>
        {showAfterIcon && (
          <span
            css={[buttonIconContainerStyles, css({ marginLeft: token('space.050') }), disabled && disabledIconStyles]}
          >
            {iconAfter}
          </span>
        )}
      </button>
    ) as React.ReactNode;
  }

  return (
    <button
      aria-label={label.replaceAll('-', ' ')}
      type={type}
      data-testid={`button-${appearance}${selected ? '-selected' : ''}`}
      onClick={clickHandler}
      disabled={disabled || isLoading}
      css={[
        learningButtonStyles['basic'],
        learningButtonStyles[appearance],
        learningButtonFontStyles[size],
        selected && learningButtonStyles['selected'],
        isButtonWithoutIcon && learningButtonSpacingStyles[size],
        iconBefore && learningButtonSpacingStyles[`${size}-icon-before`],
        iconAfter && learningButtonSpacingStyles[`${size}-icon-after`],
        isResponsiveSpacing && learningButtonResponsiveSpacing[size],
        isResponsiveSpacing && learningButtonResponsiveFontStyles,
        iconBefore && isResponsiveSpacing && learningButtonResponsiveSpacing[`${size}-icon-before`],
        iconAfter && isResponsiveSpacing && learningButtonResponsiveSpacing[`${size}-icon-after`],
        fullWidthStyles(fullWidth),
      ]}
    >
      {isLoading && <Spinner testId="spinner" size="small" />}
      {showBeforeIcon && (
        <span css={[buttonIconContainerStyles, css({ marginRight: token('space.050') })]}>{iconBefore}</span>
      )}
      <span css={[isLoading && css({ marginLeft: token('space.100') })]}>{label}</span>
      {showAfterIcon && (
        <span css={[buttonIconContainerStyles, css({ marginLeft: token('space.050') })]}>{iconAfter}</span>
      )}
    </button>
  );
};

const fullWidthStyles = (fullWidth: boolean): SerializedStyles | undefined =>
  fullWidth ? css({ width: '100%', justifyContent: 'center' }) : undefined;

const learningButtonStyles = {
  basic: css({
    fontFamily: 'Charlie Text, sans-serif',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
    borderStyle: 'solid',
    borderWidth: '1px',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: token('color.background.selected.bold.hovered'),
      borderColor: token('color.border.selected'),
      color: token('color.text.inverse'),
    },

    '&:active': {
      backgroundColor: token('color.background.selected.bold.pressed'),
      borderColor: token('color.border.selected'),
      color: token('color.text.inverse'),
    },

    '&:disabled': {
      backgroundColor: token('color.background.disabled'),
      borderColor: 'transparent',
      color: token('color.text.disabled'),
      cursor: 'not-allowed',
    },
  }),

  primary: css({
    backgroundColor: token('color.background.brand.bold'),
    borderColor: token('color.border.brand'),
    color: token('color.text.inverse'),

    '&:focus-visible': {
      outline: `2px solid ${token('color.border.focused')}`,
    },
  }),

  secondary: css({
    backgroundColor: 'transparent',
    borderColor: token('color.border.brand'),
    color: token('color.text.brand'),
  }),

  selected: css({
    backgroundColor: token('color.background.selected'),
    borderColor: 'transparent',
    color: token('color.text.brand'),

    '&:hover': {
      backgroundColor: token('color.background.selected'),
      borderColor: 'transparent',
      color: token('color.text.brand'),
    },
  }),
} as { [key: string]: SerializedStyles };

const learningButtonFontStyles = {
  medium: css({
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 600,
  }),
  large: css({
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: 600,
  }),
} as { [key: string]: SerializedStyles };

const learningButtonSpacingStyles = {
  medium: css({
    padding: `10px ${token('space.150')}`,
  }),
  'medium-icon-before': css({
    padding: `10px ${token('space.150')} 10px 10px`,
  }),
  'medium-icon-after': css({
    padding: `10px 10px 10px ${token('space.150')}`,
  }),
  large: css({
    padding: `${token('space.150')} ${token('space.200')}`,
  }),
  'large-icon-before': css({
    padding: `${token('space.150')} ${token('space.200')} ${token('space.150')} ${token('space.150')}`,
  }),
  'large-icon-after': css({
    padding: `${token('space.150')} ${token('space.150')} ${token('space.150')} ${token('space.200')}`,
  }),
} as { [key: string]: SerializedStyles };

const learningButtonResponsiveSpacing = {
  large: css({
    [media.below.sm]: {
      padding: `10px ${token('space.150')}`,
    },
  }),
  'large-icon-before': css({
    [media.below.sm]: {
      padding: `10px ${token('space.150')} 10px 10px`,
    },
  }),
  'large-icon-after': css({
    [media.below.sm]: {
      padding: `10px 10px 10px ${token('space.150')}`,
    },
  }),
} as { [key: string]: SerializedStyles };

const learningButtonResponsiveFontStyles = css({
  [media.below.sm]: {
    fontSize: '16px',
    lineHeight: '20px',
  },
});

const learningButtonLinkStyles = {
  basic: css({
    display: 'flex',
    width: 'fit-content',
    padding: `10px ${token('space.150')}`,
    border: 'none',
    alignItems: 'center',
    fontFamily: 'Charlie Text, sans-serif',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
    color: token('color.text.brand'),
    background: 'transparent',
    outline: 'none',
    cursor: 'pointer',

    '&:hover': {
      color: token('color.text.brand'),
      textDecoration: 'underline',
      textUnderlineOffset: '4px',
    },

    '&:active': {
      color: token('color.text.brand'),
      textDecoration: 'underline',
    },
  }),

  disabled: {
    color: token('color.text.disabled'),

    '&:hover': {
      textDecoration: 'none',
    },

    '&:active': {
      textDecoration: 'none',
    },
  },
};

const linkStyles = css({
  color: token('color.text.brand'),
  cursor: 'pointer',

  '&:active': {
    color: token('color.link.pressed'),
  },
});

const disabledLinkStyles = css({
  color: token('color.text.disabled'),
  cursor: 'not-allowed',

  '&:hover': {
    color: token('color.text.disabled'),
    textDecoration: 'none',
  },

  '&:active': {
    color: token('color.text.disabled'),
    textDecoration: 'none',
  },
});

const buttonIconContainerStyles = css({
  width: '24px',
  height: '24px',
});

const disabledIconStyles = css({
  color: token('color.icon.disabled'),
  cursor: 'not-allowed',
});

const learningButtonIconStyles = {
  base: css({
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    border: 'none',
    color: token('color.icon'),
    backgroundColor: 'transparent',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: token('color.background.accent.gray.subtlest.hovered'),
    },

    '&:active': {
      backgroundColor: token('color.background.accent.gray.subtlest.pressed'),
    },

    '&:disabled': {
      backgroundColor: token('color.background.disabled'),
      color: token('color.icon.disabled'),
      cursor: 'not-allowed',
    },
  }),

  selected: css({
    backgroundColor: token('color.background.selected'),
    color: token('color.icon.selected'),

    '&:hover': {
      backgroundColor: token('color.background.selected.hovered'),
      color: token('color.icon.selected'),
    },
  }),
} as { [key: string]: SerializedStyles };
