import { getResourceStore } from '../controllers/resource-store';
import { getResourcesForNextLocation } from '../controllers/resource-store/utils';
export const PLUGIN_ID = 'resources-plugin';

const loadOnUrlChange = (context, prevContext) => {
  const {
    requestResources,
    getContext: getResourceStoreContext
  } = getResourceStore().actions;
  const nextResources = getResourcesForNextLocation(prevContext, context, getResourceStoreContext());
  return Promise.all(requestResources(nextResources, context, {}));
};

const beforeLoad = _ref => {
  let {
    context,
    nextContext
  } = _ref;
  const {
    cleanExpiredResources,
    getContext: getResourceStoreContext
  } = getResourceStore().actions;
  const nextResources = getResourcesForNextLocation(context, nextContext, getResourceStoreContext());
  cleanExpiredResources(nextResources, nextContext);
};

export const createResourcesPlugin = _ref2 => {
  let {
    context: initialResourceContext,
    resourceData: initialResourceData,
    timeout
  } = _ref2;
  let latestLoadedResources = Promise.resolve([]);
  getResourceStore().actions.hydrate({
    resourceContext: initialResourceContext,
    resourceData: initialResourceData
  });
  return {
    id: PLUGIN_ID,
    beforeRouteLoad: beforeLoad,
    routeLoad: _ref3 => {
      let {
        context,
        prevContext
      } = _ref3;
      const {
        route,
        match,
        query
      } = context; // TODO: in next refactoring add `if (route.resources)` check
      // For now requesting resources for every route even if `resources` prop is missing on Route

      if (prevContext) {
        latestLoadedResources = loadOnUrlChange(context, prevContext);
      } else {
        latestLoadedResources = getResourceStore().actions.requestAllResources({
          route,
          match,
          query
        }, {
          timeout
        });
      }
    },
    routePrefetch: _ref4 => {
      let {
        context,
        nextContext
      } = _ref4;
      const {
        prefetchResources,
        getContext: getResourceStoreContext
      } = getResourceStore().actions;
      const nextResources = getResourcesForNextLocation(context, nextContext, getResourceStoreContext());
      return {
        resources: prefetchResources(nextResources, nextContext, {})
      };
    },
    getLatestResources: () => latestLoadedResources,
    getSerializedResources: async () => {
      await latestLoadedResources;
      return getResourceStore().actions.getSafeData();
    }
  };
};