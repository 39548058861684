import React from 'react';

import { InspectModeContentful } from '@types';
import { Environment, IDENTITY_URL } from '@components/community/constants';

export const objectValuesToControls = (
  obj: Record<string, string>,
  control = 'select',
): { control: string; options: object } => ({
  control,
  options: Object.values(obj),
});

export const withFocusInteraction =
  (handler: Function) =>
  (e: React.KeyboardEvent<HTMLElement>): void => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      e.stopPropagation();
      handler();
    }
  };

export const inspectorProps: (
  key: string,
  inspectMode?: InspectModeContentful,
) => { [key: string]: string } | undefined = (key: string, inspectMode?: InspectModeContentful) => {
  if (inspectMode && inspectMode[key]) {
    const { entryId, fieldId } = inspectMode[key];
    return { 'data-contentful-entry-id': entryId, 'data-contentful-field-id': fieldId };
  }
};

export const formatMinutes = (minutes: number): string => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  let output = '';

  if (hours > 0) {
    output += `${hours} hour`;
    if (hours > 1) {
      output += 's';
    }
  }

  if (remainingMinutes > 0) {
    if (hours > 0) {
      output += ' and ';
    }
    output += `${remainingMinutes} minute`;
    if (remainingMinutes > 1) {
      output += 's';
    }
  }

  return output;
};

export const getRedirectUrl = (url: string, environment: Environment): string => {
  const idUrl = IDENTITY_URL[environment] || IDENTITY_URL[Environment.STAGING];
  return `${idUrl}/login?continue=${encodeURIComponent(url)}`;
};

export const calculateLearningProgress = (completed: number, total: number): number => {
  if (total === 0) {
    return 0;
  }

  return (completed / total) * 100;
};
