import { Button, ButtonAppearance, Icon } from '@components/shared';
import { forwardRef, MutableRefObject, Ref, useEffect } from 'react';

import { css, SerializedStyles } from '@emotion/react';
import { token } from '@atlaskit/tokens';
import { BREAKPOINTS } from '@/constants';
import { media } from '@atlaskit/primitives/responsive';

export enum ArrowPosition {
  LEFT_START = 'leftStart',
  LEFT_CENTER = 'leftCenter',
  LEFT_END = 'leftEnd',
  TOP_START = 'topStart',
  TOP_CENTER = 'topCenter',
  TOP_END = 'topEnd',
  RIGHT_START = 'rightStart',
  RIGHT_CENTER = 'rightCenter',
  RIGHT_END = 'rightEnd',
  BOTTOM_START = 'bottomStart',
  BOTTOM_CENTER = 'bottomCenter',
  BOTTOM_END = 'bottomEnd',
}

export interface PopupProps {
  /** Title of the popup */
  title: string;
  /** Markdown text used as description of the popup */
  children?: React.ReactNode;
  /** Number of the current step displayed */
  step: number;
  /** Number of total steps */
  totalSteps: number;
  /** Position of the popup arrow */
  arrowPosition: ArrowPosition;
  /** Popup height value (px)*/
  popupHeight: number;
  /** Additional custom styles, used to handle popup positioning */
  popupStyle?: React.CSSProperties;
  /** Function to be executed to close popup */
  onClose?: () => void;
  /** Function to be executed to show previous step */
  onPrevious?: () => void;
  /** Function to be executed to show next step */
  onNext?: () => void;
}

/**
 * Component that renders a small window that is displayed on left or right of the trigger element
 * @param {PopupProps} PopupProps
 * @returns {React.ReactElement} React.ReactElement
 *
 * @example
 * <Popup title="Step 1" />
 */

export const Popup = forwardRef(
  (
    {
      popupHeight,
      title,
      children,
      step,
      totalSteps,
      arrowPosition,
      onClose,
      onPrevious,
      onNext,
      popupStyle,
    }: PopupProps,
    popoverRef: Ref<HTMLDivElement>,
  ) => {
    const CONTAINER_SIZE_PX = 88;

    const handlePrevious = (): void => {
      if (onPrevious) {
        onPrevious();
      }
    };
    const handleNext = (): void => {
      if (onNext) {
        onNext();
      }
    };

    const handleClose = (): void => {
      if (onClose) {
        onClose();
      }
    };

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent): void => {
        if (
          (popoverRef as MutableRefObject<HTMLDivElement>) &&
          !(popoverRef as MutableRefObject<HTMLDivElement>).current.contains(event.target as Node)
        ) {
          handleClose();
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return (): void => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [onClose]);

    return (
      <div
        data-testid={`popup-${arrowPosition}`}
        css={popupContainerStyles}
        style={popupStyle}
        ref={popoverRef}
        tabIndex={0}
      >
        <div css={[popupContentStyles['base'], popupContentStyles[arrowPosition]]}>
          <div css={contentHeaderStyles}>
            <p css={counterContainerStyles}>
              {step}/{totalSteps}
            </p>
            <div css={buttonsContainerStyles}>
              <Button
                disabled={step === 1}
                appearance={ButtonAppearance.ICON}
                label="Previous"
                iconBefore={<Icon type="chevron-left" alt="next icon" />}
                onClick={handlePrevious}
              />
              <Button
                disabled={step === totalSteps}
                appearance={ButtonAppearance.ICON}
                label="Next"
                iconBefore={<Icon type="chevron-right" alt="previous icon" />}
                onClick={handleNext}
              />
            </div>
          </div>
          <div
            css={css({
              overflow: 'auto',
              maxHeight: `calc(${popupHeight}px - ${CONTAINER_SIZE_PX}px)`,
              padding: '0 16px',
            })}
          >
            <p css={titleContainerStyles}>{title}</p>
            <p css={descriptionContainerStyles}> {children}</p>
          </div>
        </div>
      </div>
    );
  },
);

const popupContainerStyles = css({
  width: '340px',
  position: 'absolute',
  zIndex: 10,
  backgroundColor: token('color.background.input'),
  borderRadius: '8px',
  boxShadow: token('elevation.shadow.overlay'),

  [`@media (max-width: ${BREAKPOINTS.TABLET.MAX_WIDTH}px)`]: {
    top: '0 !important',
    left: '0 !important',
  },

  [media.below.xs]: {
    height: '100%',
    width: '100%',
    border: '1px solid',
    borderColor: token('color.border'),
    borderRadius: 0,
  },
});

const popupContentStyles = {
  base: css({
    position: 'relative',
    padding: '24px 8px',
    color: token('color.text'),
    display: 'flex',
    flexDirection: 'column',

    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      border: '16px solid transparent',
      width: 0,
      height: 0,
      marginTop: '-10px',
    },

    [`@media (max-width: ${BREAKPOINTS.TABLET.MAX_WIDTH}px)`]: {
      '&:after': {
        display: 'none',
      },
    },
  }),

  leftStart: css({
    '&::after': {
      top: '35px',
      left: '-5px',
      borderTop: '12px solid transparent',
      borderBottom: '12px solid transparent',
      borderLeft: 0,
      borderRightColor: token('color.border.inverse'),
      marginLeft: '-10px',
      filter: 'drop-shadow(-2px 1px 1px rgb(178, 176, 176))',
    },
  }),

  leftCenter: css({
    '&::after': {
      top: 'calc(50%)',
      left: '-5px',
      borderTop: '12px solid transparent',
      borderBottom: '12px solid transparent',
      borderLeft: 0,
      borderRightColor: token('color.border.inverse'),
      marginLeft: '-10px',
      filter: 'drop-shadow(-2px 1px 1px rgb(178, 176, 176))',
    },
  }),

  leftEnd: css({
    '&::after': {
      left: '-5px',
      bottom: '35px',
      borderTop: '12px solid transparent',
      borderBottom: '12px solid transparent',
      borderLeft: 0,
      borderRightColor: token('color.border.inverse'),
      marginLeft: '-10px',
      filter: 'drop-shadow(-2px 1px 1px rgb(178, 176, 176))',
    },
  }),

  topStart: css({
    '&::after': {
      top: '-21px',
      left: '35px',
      borderRight: '12px solid transparent',
      borderLeft: '12px solid transparent',
      borderBottom: `16px solid ${token('color.border.inverse')}`,
      marginRight: '-10px',
      filter: 'drop-shadow(0 -1px 0.4px rgb(178, 176, 176))',
    },
  }),

  topCenter: css({
    '&::after': {
      top: '-5px',
      left: 'calc(50% - 16px)',
      borderRight: '12px solid transparent',
      borderLeft: '12px solid transparent',
      borderBottom: `16px solid ${token('color.border.inverse')}`,
      borderTop: 0,
      marginRight: '-10px',
      filter: 'drop-shadow(0 -1px 0.4px rgb(178, 176, 176))',
    },
  }),

  topEnd: css({
    '&::after': {
      top: '-5px',
      right: '35px',
      borderRight: '12px solid transparent',
      borderLeft: '12px solid transparent',
      borderBottom: `16px solid ${token('color.border.inverse')}`,
      borderTop: 0,
      marginRight: '-10px',
      filter: 'drop-shadow(0 -1px 0.4px rgb(178, 176, 176))',
    },
  }),

  rightStart: css({
    '&::after': {
      top: '35px',
      right: '-5px',
      borderTop: '12px solid transparent',
      borderBottom: '12px solid transparent',
      borderLeft: `16px solid ${token('color.border.inverse')}`,
      borderRight: 0,
      marginRight: '-10px',
      filter: 'drop-shadow(2px 0 1px #939393)',
    },
  }),

  rightCenter: css({
    '&::after': {
      top: 'calc(50% - 10px)',
      right: '-5px',
      borderTop: '12px solid transparent',
      borderBottom: '12px solid transparent',
      borderLeft: `16px solid ${token('color.border.inverse')}`,
      borderRight: 0,
      marginRight: '-10px',
      filter: 'drop-shadow(2px 0 1px #939393)',
    },
  }),

  rightEnd: css({
    '&::after': {
      bottom: '35px',
      right: '-5px',
      borderTop: '12px solid transparent',
      borderBottom: '12px solid transparent',
      borderLeft: `16px solid ${token('color.border.inverse')}`,
      borderRight: 0,
      marginRight: '-10px',
      filter: 'drop-shadow(2px 0 1px #939393)',
    },
  }),

  bottomStart: css({
    '&::after': {
      left: '35px',
      bottom: '-14px',
      borderLeft: '12px solid transparent',
      borderRight: '12px solid transparent',
      borderTop: `16px solid ${token('color.border.inverse')}`,
      borderBottom: 0,
      filter: 'drop-shadow(0 4px 2px #939393)',
    },
  }),

  bottomCenter: css({
    '&::after': {
      left: 'calc(50% - 16px)',
      bottom: '-14px',
      borderLeft: '12px solid transparent',
      borderRight: '12px solid transparent',
      borderTop: `16px solid ${token('color.border.inverse')}`,
      borderBottom: 0,
      filter: 'drop-shadow(0 4px 2px #939393)',
    },
  }),

  bottomEnd: css({
    '&::after': {
      right: '35px',
      bottom: '-14px',
      borderLeft: '12px solid transparent',
      borderRight: '12px solid transparent',
      borderTop: `16px solid ${token('color.border.inverse')}`,
      borderBottom: 0,
      filter: 'drop-shadow(0 4px 2px #939393)',
    },
  }),
} as { [key: string]: SerializedStyles };

const contentHeaderStyles = css({
  padding: '0 16px',
  marginBottom: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const titleContainerStyles = css({
  marginBottom: '16px',
  fontFamily: 'Charlie Display, sans-serif',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
});

const descriptionContainerStyles = css({
  fontFamily: 'Inter, sans-serif',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
});

const counterContainerStyles = css({
  fontFamily: 'Charlie Text, sans-serif',
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '18px',
  color: token('color.text.subtlest'),
});

const buttonsContainerStyles = css({
  display: 'flex',
  alignItems: 'center',
});
