import React from 'react';
import { css } from '@emotion/react';

export interface UnifiedAccountTagContainerProps {
  children: React.ReactNode;
}

export const UnifiedAccountTagContainer: React.FC<UnifiedAccountTagContainerProps> = ({ children }) => {
  return <div css={containerStyles}>{children}</div>;
};

const containerStyles = css({
  maxWidth: '84px',
  height: '28px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  boxSizing: 'border-box',
  borderRadius: '4px',
});
