"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("../constants");
function isWebDriver() {
    try {
        return navigator.webdriver ? constants_1.YES : constants_1.NO;
    }
    catch (error) {
        return constants_1.ERROR;
    }
}
exports.isWebDriver = isWebDriver;
