import React from 'react';
import { css } from '@emotion/react';
import { token } from '@atlaskit/tokens';
import UnifiedMoreDropdownMenu, { Action } from '../UnifiedMoreDropdownMenu';

export interface UnifiedAccountCardContentProps {
  /** User's email */
  email: string;
  /** Array of tag components */
  tags: React.ReactNode[];
  /** Array of dropdown actions */
  actions: Action[];
  isDisabled?: boolean;
}

export const UnifiedAccountCardContent: React.FC<UnifiedAccountCardContentProps> = ({
  email,
  tags,
  actions,
  isDisabled,
}) => {
  return (
    <div css={contentWrapperStyles}>
      <div css={dropdownWrapperStyles}>
        <UnifiedMoreDropdownMenu actions={actions} isDisabled={isDisabled} />
      </div>
      <div css={coverImageWrapperStyles}>
        <div css={coverImageStyles}>
          <svg width="350" height="82" viewBox="0 0 350 82" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_985_48434)">
              <path
                d="M167.322 38.821C177.93 34.78 184.789 30.2722 188.858 25.4255C193.11 20.3612 194.263 14.9878 193.538 9.5957C192.822 4.25652 190.271 -1.06544 187.204 -6.12471C185.194 -9.43933 182.915 -12.7134 180.746 -15.8286C179.821 -17.1581 178.915 -18.4586 178.059 -19.7211L221.313 -48.8693C222.054 -47.7618 222.787 -46.6694 223.511 -45.5908C241.665 -18.5315 254.013 -0.126895 247.42 31.5817C243.994 48.0569 230.739 60.1325 218.142 68.1357C211.864 72.1242 205.799 75.0696 201.302 77.0177C199.054 77.9913 197.201 78.7147 195.913 79.1939C195.383 79.3909 194.949 79.5465 194.622 79.6613L167.322 38.821Z"
                stroke="#0C66E4"
                stroke-width="2"
              />
              <path
                d="M72.3053 -15.1135C82.328 -17.928 92.5012 -22.9961 101.629 -29.5552C110.755 -36.1128 118.158 -43.5947 123.819 -51.8086L166.607 8.14819C159.501 15.3002 151.561 22.0298 142.555 28.5017C133.545 34.9761 124.385 40.4509 115.146 44.9177L72.3053 -15.1135Z"
                stroke="#0C66E4"
                stroke-width="2"
              />
              <path
                d="M145.593 48.2832L177.327 107.164L160.851 130.97L94.7005 121.821L95.1645 67.6983L145.593 48.2832Z"
                stroke="#0C66E4"
                stroke-width="2"
              />
              <path
                d="M315.34 72.2206L315.355 72.2331L315.372 72.2449C315.778 72.5416 316.18 72.8355 316.577 73.1258C320.114 75.7149 323.269 78.0239 326.324 79.6083C329.758 81.3894 333.158 82.3068 336.975 81.7093C341.291 81.0335 344.5 78.5429 346.241 74.1008C347.949 69.7418 348.232 63.5491 346.967 55.3884L346.241 50.7055L378.755 45.6149C379.596 48.7568 380.163 52.4046 380.848 56.8254C383.982 77.0371 381.669 92.493 375.423 103.369C369.195 114.213 358.984 120.632 346.012 122.663C327.026 125.636 315.363 118.74 304.711 110.615L304.701 110.608L304.691 110.601C304.22 110.259 303.755 109.92 303.296 109.586C299.84 107.069 296.744 104.815 293.745 103.255C290.311 101.469 286.909 100.54 283.091 101.138L282.103 101.293L282.115 101.369C278.291 102.255 275.438 104.702 273.833 108.804C272.126 113.168 271.843 119.36 273.105 127.5C273.249 128.428 273.503 129.339 273.738 130.185L273.746 130.211C273.89 130.731 274.028 131.226 274.142 131.711L241.242 136.862C240.465 134.058 239.898 130.414 239.159 125.649C236.059 105.652 238.499 90.2821 244.81 79.446C251.105 68.6378 261.321 62.2203 274.06 60.2258C283.596 58.7327 291.242 59.6302 297.818 61.9301C304.407 64.2347 309.975 67.9653 315.34 72.2206Z"
                stroke="#0C66E4"
                stroke-width="2"
              />
              <path
                d="M292.179 -56.9295L292.37 -57.2966L292.352 -57.3526L301.854 -75.6471L361.496 -44.2691L338.629 -0.241775C322.356 31.0905 300.658 42.9482 277.565 44.2706C265.48 24.4864 262.733 -0.232558 279.024 -31.6005L292.179 -56.9295Z"
                stroke="#0C66E4"
                stroke-width="2"
              />
            </g>
            <defs>
              <clipPath id="clip0_985_48434">
                <path d="M0 8C0 3.58172 3.58172 0 8 0H386C390.418 0 394 3.58172 394 8V96H0V8Z" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
      <div css={textWrapperStyles}>
        <p css={emailStyles}>{email}</p>
        <div css={tagsWrapperStyles}>
          {tags.map((tag, index) => (
            <div key={index} css={tagItemStyles}>
              {tag}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const contentWrapperStyles = css({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: 0,
  margin: 0,
  height: '100%',
  borderRadius: token('space.100'),
});

const coverImageWrapperStyles = css({
  width: '100%',
  overflow: 'hidden',
  borderTopLeftRadius: token('space.100'),
  borderTopRightRadius: token('space.100'),
});

const coverImageStyles = css({
  background: token('elevation.surface.sunken'),
  width: '100%',
  height: '82px',
  objectFit: 'cover',
  margin: 0,
  padding: 0,
  display: 'block',
});

const textWrapperStyles = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '24px',
  boxSizing: 'border-box',
  width: '100%',
});

const emailStyles = css({
  fontFamily: 'Inter',
  marginBottom: '12px',
  fontSize: '16px',
  color: '#44546F',
  lineHeight: '24px',
});

const tagsWrapperStyles = css({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
});

const tagItemStyles = css({
  display: 'inline-block',
});

const dropdownWrapperStyles = css({
  position: 'absolute',
  top: token('space.150'),
  right: token('space.150'),
});
