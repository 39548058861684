import { css } from '@compiled/react';
import { token } from '@atlaskit/tokens';


type BenefitsCardProps = {
  id: string;
  title: string;
  description: string;
  image: string;
};

const BenefitsCard: React.FC<BenefitsCardProps> = ({
  id,
  title,
  description,
  image
}) => (
  <div key={id}>
<img src={image}/>
    <h4 css={titleStyles}>{title}</h4>
    <p css={descriptionStyles}>{description}</p>
  </div>
);

const titleStyles = css({
  fontSize: '24px',
  marginTop: '0px',
});

const descriptionStyles = css({
  fontSize: '16px',
  fontFamily: 'Inter',
  color: token('color.text.subtle'),
  lineHeight: '24px',
  marginTop: '8px',
});

export default BenefitsCard;
