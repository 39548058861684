import React from 'react';
import { css } from '@emotion/react';
import { InspectModeContentful } from '@types';
import { Divider, Heading, Tag, TagProps } from '@components/shared';
import { PopupShare } from '@components/index';
import { token } from '@atlaskit/tokens';

export interface LessonHeaderProps {
  /** entryId and fieldId from contentful for the inspector mode */
  inspectMode?: InspectModeContentful;
  /** Main title */
  heading: string;
  /** List of labels that describe course in terms of duration and difficulty level */
  tags: TagProps[];
  /** Lesson description */
  description: React.ReactNode;
  /** Url of the lesson to be shared */
  urlToShare: string;
  /** Function to be executed on click of listen button */
  onListenClick?: () => void;
}

export const LessonHeader: React.FC<LessonHeaderProps> = ({
  heading,
  tags,
  description,
  urlToShare,
  onListenClick,
}) => (
  <div>
    <div css={headingWrapperStyles}>
      <Heading level={1}>{heading}</Heading>
    </div>
    <div css={metadataStyles}>
      <div css={flexContainerStyles}>
        {tags.map((tag, index) => (
          <div key={index} css={tagStyles}>
            <Tag {...tag} />
          </div>
        ))}
      </div>
      <div css={flexContainerStyles}>
        <button
          css={listenButtonStyles}
          onClick={(): void => onListenClick && onListenClick()}
          data-testid="listen-button"
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.5 17.3333V13.5C8.5 12.9477 8.05228 12.5 7.5 12.5C6.94772 12.5 6.5 12.9477 6.5 13.5V15.6667C6.5 16.6792 7.32081 17.5 8.33333 17.5C8.42538 17.5 8.5 17.4254 8.5 17.3333ZM7.5 11C6.11929 11 5 12.1193 5 13.5V15.6667C5 17.5076 6.49238 19 8.33333 19C9.25381 19 10 18.2538 10 17.3333V13.5C10 12.1193 8.88071 11 7.5 11Z"
              fill="#44546F"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.5 17.3333V13.5C15.5 12.9477 15.9477 12.5 16.5 12.5C17.0523 12.5 17.5 12.9477 17.5 13.5V15.6667C17.5 16.6792 16.6792 17.5 15.6667 17.5C15.5746 17.5 15.5 17.4254 15.5 17.3333ZM16.5 11C17.8807 11 19 12.1193 19 13.5V15.6667C19 17.5076 17.5076 19 15.6667 19C14.7462 19 14 18.2538 14 17.3333V13.5C14 12.1193 15.1193 11 16.5 11Z"
              fill="#44546F"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 5.5C8.96243 5.5 6.5 7.96243 6.5 11V14.25H5V11C5 7.13401 8.13401 4 12 4C15.866 4 19 7.13401 19 11V14.25H17.5V11C17.5 7.96243 15.0376 5.5 12 5.5Z"
              fill="#44546F"
            />
          </svg>
          <span css={listenTextWrapperStyles}>Listen</span>
        </button>
        <PopupShare urlToShare={urlToShare} alignToLeft />
      </div>
    </div>
    <div css={lineWrapperStyles}>
      <Divider color={token('color.border')} />
    </div>
    <div css={descriptionStyles}>{description}</div>
  </div>
);

const metadataStyles = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const flexContainerStyles = css({
  display: 'flex',
});

const listenButtonStyles = css({
  display: 'flex',
  alignItems: 'center',
  marginRight: '1.5rem',
  cursor: 'pointer',
  padding: 0,
  border: 'none',
  background: 'transparent',
});

const listenTextWrapperStyles = css({
  marginLeft: '0.25rem',
  color: token('color.text.subtle'),
  fontSize: '16px',
  fontFamily: 'Charlie Text, sans-serif',
});

const tagStyles = css({
  marginRight: '0.5rem',
});

const lineWrapperStyles = css({
  padding: '1.5rem 0 2.5rem 0',
});

const headingWrapperStyles = css({
  padding: '5rem 0 1.5rem 0',
});

const descriptionStyles = css({
  paddingBottom: token('space.1000'),
  fontFamily: 'Inter, sans-serif',
  fontSize: '20px',
  lineHeight: '32px',
  color: token('color.text'),

  p: {
    margin: 0,
  },

  a: {
    fontSize: '20px',
    lineHeight: '32px',
  },

  ul: {
    paddingLeft: '20px',
    marginTop: 0,
    marginBottom: 0,

    li: {
      marginTop: '16px',
    },

    'li + li': {
      marginTop: '16px !important',
    },
  },
});
