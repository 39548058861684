import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Icon } from '@components/shared/Icon';
import ScrollContainer from 'react-indiana-drag-scroll';
import { InspectModeContentful } from '@types';
import { inspectorProps } from '@utils/index';
import { css } from '@emotion/react';
import { DN600, Y300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

export interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  /** entryId and fieldId from contentful for the inspector mode */
  inspectMode?: InspectModeContentful;
  /** The url of the image */
  src: string;
  /** Description text of the image */
  alt: string;
  /** Descriptive text shown below the image */
  caption?: string;
  /** Width dimension in px */
  width?: number;
  /** Height dimension in px */
  height?: number;
  /** Size in bytes of the image*/
  size?: number;
  /** Indicates that the modal will not be displayed when the image is clicked */
  preventModal?: boolean;
  /** Indicates whether the auto margin is set to center the image in space */
  center?: boolean;
  /** onClick callback */
  onClick?: () => void;
}

export interface CustomizableImageProps {
  /** Width dimension in px */
  width?: number;
  /** Height dimension in px */
  height?: number;
}

/**
 * It takes a url and returns the last part of the url which is the name of the image.
 * @param {string} url - The URL of the image you want to get the name.
 * @returns name of the image file.
 */

const getImageName = (url: string): string => {
  const urlSplitted = url.split('/');
  return urlSplitted[urlSplitted.length - 1];
};

/**
 * Convert bytes into KB or MB
 * @param {number} [bytes] - The number of bytes to format.
 * @returns a string with the KB o MB converted
 */

const formatBytes = (bytes?: number): string | undefined => {
  const marker = 1024;
  const decimal = 0;
  const kiloBytes = marker;
  const megaBytes = marker * marker;
  return bytes
    ? bytes < kiloBytes
      ? bytes + ' Bytes'
      : bytes < megaBytes
      ? (bytes / kiloBytes).toFixed(decimal) + ' KB'
      : (bytes / megaBytes).toFixed(decimal) + ' MB'
    : undefined;
};

/**
 * Download image
 * @param {string} src - The URL of the image you want to download.
 * @param {string} name - The name of the file you want to save the image as.
 */

const downloadImage = async (src: string, name: string): Promise<void> => {
  const image = await fetch(src);
  const imageBlog = await image.blob();
  const imageURL = URL.createObjectURL(imageBlog);

  const link = document.createElement('a');
  link.href = imageURL;
  link.download = name;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/**
 * Component that renders a customizable image
 * @param {ImageProps} ImageProps
 * @returns {React.ReactElement} React.ReactElement
 *
 * @example
 * <Image src="src.png" />
 */

export const Image: React.FC<ImageProps> = ({
  inspectMode = undefined,
  src,
  alt,
  width,
  height,
  caption = '',
  size,
  center = true,
  preventModal = false,
  onClick,
  ...props
}) => {
  const rightToolsRef = useRef<HTMLDivElement>(null);
  const downloadButtonRef = useRef<HTMLButtonElement>(null);
  const modalImgRef = useRef<HTMLImageElement>(null);
  const originalImgRef = useRef<HTMLImageElement>(null);
  const [showModal, setShowModal] = useState(false);
  const [showControls, setShowControls] = useState(false);
  const [totalZoom, setTotalZoom] = useState(100);
  const [initialWidthModalImage, setInitialWidthModalImage] = useState(0);
  const [initialHeightImage, setInitialHeightImage] = useState(0);
  const [initialWidthOriginalImage, setInitialWidthOriginalImage] = useState(0);

  useEffect(() => {
    if (showModal) {
      setShowControls(true);
      setTotalZoom(100);
      if (rightToolsRef.current) {
        rightToolsRef.current.focus();
      }
    }
  }, [showModal]);

  useEffect(() => {
    let timer: any = null;
    if (showControls) {
      timer = setTimeout(() => setShowControls(false), 5000);
    }
    return () => clearTimeout(timer);
  }, [showControls]);

  useEffect(() => {
    const close = (e: KeyboardEvent): void => {
      if (e.key === 'Escape') {
        setShowModal(false);
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);

  const onHoverModal = (): void => {
    setShowControls(true);
  };

  const onZoomOut = (): void => {
    if (totalZoom > 20) {
      setTotalZoom(totalZoom - 20);
      if (modalImgRef.current) {
        const widthImageModal = Math.round(modalImgRef.current?.offsetWidth - initialWidthModalImage * 0.2);
        const heightImageModal = Math.round(modalImgRef.current?.offsetHeight - initialHeightImage * 0.2);
        modalImgRef.current.style.width = `${widthImageModal}px`;
        modalImgRef.current.style.height = `${heightImageModal}px`;
      }
    }
  };

  const onZoomIn = (): void => {
    if (totalZoom < 320) {
      setTotalZoom(totalZoom + 20);
      if (modalImgRef.current) {
        const widthImageModal = Math.round(modalImgRef.current?.offsetWidth + initialWidthModalImage * 0.2);
        const heightImageModal = Math.round(modalImgRef.current?.offsetHeight + initialHeightImage * 0.2);
        modalImgRef.current.style.width = `${widthImageModal}px`;
        modalImgRef.current.style.height = `${heightImageModal}px`;
      }
    }
  };

  const onBlur = (): void => {
    if (rightToolsRef.current && downloadButtonRef.current) {
      rightToolsRef.current.setAttribute('tabIndex', '-1');
      downloadButtonRef.current.focus();
    }
  };

  const onClickImage = useCallback((): void => {
    if (onClick) {
      onClick();
    }
    if (!preventModal) {
      setShowModal(true);
    }
  }, [onClick]);

  const onLoadImageModal = (): void => {
    setInitialWidthModalImage(modalImgRef.current?.offsetWidth ?? 0);
    setInitialHeightImage(modalImgRef.current?.offsetHeight ?? 0);
  };

  useEffect(() => {
    if (originalImgRef.current?.offsetWidth) {
      setInitialWidthOriginalImage(originalImgRef.current.offsetWidth);
    }
  }, [originalImgRef.current?.offsetWidth]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLImageElement>): void => {
      if (e.code === 'Space') {
        onClickImage();
      }
    },
    [onClickImage],
  );

  return (
    <>
      {showModal && (
        <div data-testid="modal" id="myModal" css={modalStyles} onMouseMove={onHoverModal}>
          <div css={modalContentStyles}>
            <div css={[modalControlsStyles, showControls && css({ opacity: 1 }), !showControls && css({ opacity: 0 })]}>
              <div css={containerStyles}>
                <div css={textContainerStyles}>
                  <div css={iconContainerStyles}>
                    <Icon type="image" size="large" color={Y300} />
                  </div>
                  <div css={imageContainerStyles}>
                    <p css={badgeContentStyles}>{getImageName(src)}</p>
                    <p css={badgeContentStyles}>image · {formatBytes(size)}</p>
                  </div>
                </div>
                <div className="right" tabIndex={0} ref={rightToolsRef}>
                  <button
                    data-testid="download-button"
                    ref={downloadButtonRef}
                    tabIndex={0}
                    css={css({ marginRight: '1rem', border: 'none', cursor: 'pointer' })}
                    aria-label="download image"
                    onClick={(): void => {
                      downloadImage(src, getImageName(src));
                    }}
                  >
                    <Icon type="download" size="medium" />
                  </button>

                  <button
                    css={{ border: 'none', cursor: 'pointer' }}
                    tabIndex={0}
                    data-testid="close-modal-button"
                    aria-label="close modal"
                    onClick={(): void => {
                      setShowModal(false);
                    }}
                  >
                    <Icon type="cross" size="medium" />
                  </button>
                </div>
              </div>
            </div>
            <ScrollContainer css={scrollContainerStyles}>
              <img src={src} alt={alt} ref={modalImgRef} onLoad={onLoadImageModal} css={css({ margin: 'auto' })} />
            </ScrollContainer>
            <div css={[modalToolsStyles, css({ opacity: showControls ? 1 : 0 })]}>
              <div css={buttonContainerStyles}>
                <div>
                  <button
                    data-testid="zoom-out-button"
                    onClick={(): void => onZoomOut()}
                    css={css({ marginRight: '0.625rem', border: 'none', cursor: 'pointer' })}
                    aria-label="zoom out"
                    tabIndex={0}
                  >
                    <Icon type="zoom-out" />
                  </button>
                  <button
                    css={{ border: 'none', cursor: 'pointer' }}
                    data-testid="zoom-in-button"
                    onClick={(): void => onZoomIn()}
                    onBlur={onBlur}
                    aria-label="zoom in"
                    tabIndex={0}
                  >
                    <Icon type="zoom-in" />
                  </button>
                </div>
                <div css={css({ color: DN600 })}>{totalZoom}%</div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        {...inspectorProps('image', inspectMode)}
        css={css({ width: '100%', overflow: 'hidden' })}
        data-testid="image"
        onClick={onClickImage}
      >
        <figure css={figureStyles}>
          <img
            tabIndex={0}
            width={width}
            height={height}
            ref={originalImgRef}
            alt={alt}
            src={src}
            onKeyDown={handleKeyDown}
            css={[
              css({ transform: 'scale(1)', objectFit: 'contain', height: 'auto' }),
              center && css({ margin: 'auto' }),
              !preventModal && css({ cursor: 'pointer' }),
              !width && css({ width: '100%' }),
            ]}
            {...props}
          />
          {!!initialWidthOriginalImage && !!caption && (
            <figcaption
              style={{
                width: `${initialWidthOriginalImage}px`,
              }}
              css={captionContainerStyles}
            >
              {caption}
            </figcaption>
          )}
        </figure>
      </div>
    </>
  );
};

const modalStyles = css({
  position: 'fixed',
  zIndex: 100000,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  overflow: 'auto',
  backgroundColor: token('color.background.input'),

  '& button': {
    background: 'transparent',
    borderRadius: '3px',
    verticalAlign: 'middle',
    display: 'inline-flex',
    padding: '0.125rem !important',
    height: 'fit-content',
    '&:hover': {
      backgroundColor: token('color.background.accent.gray.bolder.hovered'),
      borderRadius: '3px',
    },
  },
});

const modalContentStyles = css({
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
  padding: 0,
  position: 'relative',

  '& img': [
    {
      maxWidth: '320%',
      width: '-moz-available',
    },
    {
      width: '-webkit-fill-available',
    },
  ],
});

const modalControlsStyles = css({
  backgroundImage: 'linear-gradient(rgb(14, 22, 36), rgba(14, 22, 36, 0))',
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '6.125rem',
  zIndex: 1,
  fontWeight: 500,
  padding: '1.5rem',
  transition: 'opacity 0.3s ease 0s',

  '& .right:focus': {
    outline: 'none',
  },
  '& .right svg': {
    color: `${DN600} !important`,
  },
});

const containerStyles = css({
  display: 'flex',
  justifyContent: 'space-between',
});

const textContainerStyles = css({
  display: 'flex',
  color: DN600,
});

const iconContainerStyles = css({
  marginRight: '0.75rem',
  display: 'flex',
  alignItems: 'center',
});

const imageContainerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  color: 'filter',
  fontSize: '.875rem',
  lineHeight: '1.25rem',
});

const badgeContentStyles = css({
  margin: 0,
});

const scrollContainerStyles = css({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const modalToolsStyles = css({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
  position: 'fixed',
  bottom: 0,
  width: '100%',
  height: '6.125rem',
  padding: '0.625rem',
  backgroundImage: 'linear-gradient(rgba(14, 22, 36, 0), rgb(14, 22, 36))',

  '& svg': {
    color: `${DN600} !important`,
  },
});

const buttonContainerStyles = css({
  width: '50%',
  display: 'flex',
  justifyContent: 'space-between',
});

const figureStyles = css({
  display: 'flex',
  flexDirection: 'column',
  margin: 0,
  textAlign: 'center',
});

const captionContainerStyles = css({
  margin: '16px auto',
  textAlign: 'center',
  fontStyle: 'italic',
  fontSize: '.875rem',
  lineHeight: '1.25rem',
  color: token('color.text.subtlest'),
});
