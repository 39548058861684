import React, { ReactElement, useCallback } from 'react';
import { css } from '@emotion/react';
import { token } from '@atlaskit/tokens';
import { Button, ButtonAppearance, Divider, Heading, Icon, Radio, Text } from '@components/shared';
import { TextField } from '@components/upp';
import { media } from '@atlaskit/primitives';

export enum ThumbType {
  THUMB_UP = 'thumb-up',
  THUMB_DOWN = 'thumb-down',
}

export enum FeedbackButtonType {
  DISMISS = 'dismiss',
  SUBMIT = 'submit',
}

export const THUMBS_UP_FEEDBACK_OPTIONS = [
  'It was easy to understand',
  'I learned something useful',
  'It was engaging',
  'It improved my confidence in the subject',
  'Other',
];

export const THUMBS_DOWN_FEEDBACK_OPTIONS = [
  'It wasn’t clear',
  'It wasn’t accurate',
  'I didn’t learn what I wanted to know',
  'Other',
];

export interface QuickFeedbackProps {
  /** Function to handle feedback submission */
  onSubmitFeedback: (buttonType: FeedbackButtonType) => void;
  /** Function to handle changes in the thoughts input field */
  onChangeThoughts: (e: React.ChangeEvent<HTMLInputElement>) => void;
  /** Function to handle changes in the selected feedback option */
  onChangeOption: (option: string) => void;
  /** Function to handle thumb click events */
  onThumbClick: (thumbType: ThumbType) => void;
  /** Whether to show the divider */
  shouldShowDivider?: boolean;
}

/**
 * QuickFeedback component provides a user interface for collecting quick feedback from users.
 * @param {QuickFeedbackProps} QuickFeedbackProps
 * @returns {ReactElement} ReactElement
 *
 * @example
 *  <QuickFeedback
 *    onSubmitFeedback={(buttonType: ButtonType) => handleSubmit(buttonType)}
 *    onChangeThoughts={handleChangeThoughts}
 *    onChangeOption={handleChangeOption}
 *    onThumbClick={handleThumbClick}
 *    shouldShowDivider={true}
 *   />
 */

export const QuickFeedback = ({
  onSubmitFeedback,
  onChangeThoughts,
  onChangeOption,
  onThumbClick,
  shouldShowDivider = true,
}: QuickFeedbackProps): ReactElement => {
  const [isPopupOpened, setIsPopupOpened] = React.useState<{ isOpen: boolean; thumbType: ThumbType | null }>({
    isOpen: false,
    thumbType: null,
  });
  const [selectedOption, setSelectedOption] = React.useState('');
  const [isThumbClicked, setIsThumbClicked] = React.useState(false);

  const handleThumbClick = (thumbType: ThumbType): void => {
    setIsThumbClicked(true);
    setIsPopupOpened({ isOpen: true, thumbType });
    onThumbClick(thumbType);
  };

  const toggleAnswer = (option: string): void => {
    setSelectedOption(option);
    onChangeOption(option);
  };

  const getOptionStatus = (option: string): boolean => selectedOption === option;

  const handleChangeThoughts = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      e.preventDefault();
      if (onChangeThoughts) {
        onChangeThoughts(e);
      }
    },
    [onChangeThoughts],
  );

  const handleSubmitFeedback = (): void => {
    setIsPopupOpened({ isOpen: false, thumbType: null });
    onSubmitFeedback(FeedbackButtonType.SUBMIT);
  };

  const handleDismissFeedback = (): void => {
    setIsPopupOpened({ isOpen: false, thumbType: null });
    setSelectedOption('');
    onChangeThoughts({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement>);
    onChangeOption('');
    onSubmitFeedback(FeedbackButtonType.DISMISS);
  };

  return (
    <div data-testid="quick-feedback-component" css={quickFeedbackStyles}>
      <div css={feedbackContainerStyles}>
        <div css={thumbsContainerStyles}>
          <div data-testid="thumb-down-container" css={thumbButtonContainerStyles}>
            <Button
              tooltip="Needs improvement"
              iconType="thumb-down"
              label=""
              iconBefore={<Icon type="thumb-down" />}
              appearance={ButtonAppearance.ICON}
              onClick={(): void => handleThumbClick(ThumbType.THUMB_DOWN)}
            />
          </div>
          <div css={thumbButtonContainerStyles} data-testid="thumb-up-container">
            <Button
              tooltip="Good"
              iconType="thumb-up"
              label=""
              iconBefore={<Icon type="thumb-up" />}
              appearance={ButtonAppearance.ICON}
              onClick={(): void => handleThumbClick(ThumbType.THUMB_UP)}
            />
          </div>
        </div>
        <div>
          <Heading level={6} color={token('color.text.subtle')}>
            {isThumbClicked ? 'Thank you for your feedback!' : 'How was this lesson?'}
          </Heading>
        </div>
      </div>
      {shouldShowDivider && (
        <div css={dividerStyles}>
          <Divider color={token('color.border')} height={2} />
        </div>
      )}
      {isPopupOpened.isOpen && (
        <div data-testid="popup" css={popupStyles}>
          <div>
            <Text size="small" fontWeight="semibold">
              Why did you choose this response?
            </Text>
            <div css={optionsContentStyles}>
              {isPopupOpened.thumbType === ThumbType.THUMB_UP
                ? THUMBS_UP_FEEDBACK_OPTIONS.map((option) => (
                    <div css={radioContainerStyles} key={option}>
                      <Radio
                        setIsChecked={(): void => toggleAnswer(option)}
                        isChecked={getOptionStatus(option)}
                        label={option}
                        name="thumb-up"
                      />
                    </div>
                  ))
                : THUMBS_DOWN_FEEDBACK_OPTIONS.map((option) => (
                    <div css={radioContainerStyles} key={option}>
                      <Radio
                        setIsChecked={(): void => toggleAnswer(option)}
                        isChecked={getOptionStatus(option)}
                        label={option}
                        name="thumb-down"
                      />
                    </div>
                  ))}
            </div>
          </div>
          <div css={feedbackFormStyles}>
            <div css={inputContainerStyles}>
              <Text size="small" fontWeight="semibold">
                Share your thoughts
              </Text>
              <TextField placeholder="Start typing..." onChange={handleChangeThoughts} />
            </div>
            <div css={buttonsContainerStyles}>
              <Button label="Dismiss" appearance={ButtonAppearance.LINK} onClick={handleDismissFeedback} />
              <Button label="Submit" appearance={ButtonAppearance.PRIMARY} onClick={handleSubmitFeedback} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const quickFeedbackStyles = css({
  position: 'relative',
  maxWidth: '720px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
});

const feedbackContainerStyles = css({
  width: '100%',
  display: 'flex',
  gap: token('space.150'),
  alignItems: 'center',
});

const thumbsContainerStyles = css({
  display: 'flex',
  gap: token('space.100'),
});

const thumbButtonContainerStyles = css({
  backgroundColor: token('color.background.disabled'),
  borderRadius: '3px',

  'button:focus': {
    backgroundColor: token('color.background.selected'),
  },
});

const dividerStyles = css({
  width: '100%',
  margin: `${token('space.400')} 0`,
});

const popupStyles = css({
  position: 'absolute',
  bottom: '100px',
  maxWidth: '476px',
  width: '100%',
  padding: token('space.200'),
  borderRadius: '8px',
  background: token('color.background.input'),
  boxShadow: `0px 0px 1px 0px rgba(9, 30, 66, 0.31), 0px 1px 1px 0px rgba(9, 30, 66, 0.25)`,

  [media.below.sm]: {
    position: 'relative',
    bottom: '60px',
  },
});

const optionsContentStyles = css({
  margin: `${token('space.100')} 0 ${token('space.150')}`,
});

const radioContainerStyles = css({
  marginTop: token('space.050'),
});

const feedbackFormStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: token('space.150'),
});

const inputContainerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: token('space.050'),
});

const buttonsContainerStyles = css({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: token('space.100'),
});
