import { FunctionComponent } from 'react';
import { css } from '@compiled/react';
import {
  GetStarted,
  HomepageHero,
  ForumsSection,
  EventsSection,
  LearningSection,
} from 'components/sections/homepage/HomepageSections';
import { PageWithAnalytics } from 'components/common/analytics-wrapped/PageWithAnalytics';

const HomePage: FunctionComponent = () => {
  return (
    <PageWithAnalytics eventScreenName="homeScreen">
        <div css={containerStyle}>
          <HomepageHero />
          <main css={mainStyles}>
            <GetStarted />
            <ForumsSection />
            <LearningSection />
            <EventsSection />
          </main>
        </div>
    </PageWithAnalytics>
  );
};

export default HomePage;

const containerStyle = css({
  width: '100%',
});

const mainStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: '112px',
  margin: '0 auto',
  width: '1232px',
  '@media (max-width: 1279px)': {
    gap: '72px',
    width: '100%',
  },
  '@media (max-width: 767px)': {
    gap: '64px',
    width: '100%',
  },
});
