import { qs } from 'url-parse';
import execRouteMatching from './exec-route-matching';
import { matchRouteCache } from './utils';
/**
 * Does the given `pathname` and `queryStr` match a route in `routes`.
 *
 * Heavily based on https://github.com/ReactTraining/react-router/blob/master/packages/react-router-config/modules/matchRoute.js
 *
 * Note: This does not support nested routes at this stage.
 */

const matchRoute = function matchRoute(routes, pathname, queryParams, basePath) {
  if (queryParams === void 0) {
    queryParams = {};
  }

  if (basePath === void 0) {
    basePath = '';
  }

  const queryParamObject = typeof queryParams === 'string' ? qs.parse(queryParams) : queryParams;
  const cachedMatch = matchRouteCache.get(pathname, queryParamObject, basePath);
  if (cachedMatch && routes.includes(cachedMatch.route)) return cachedMatch;

  for (let i = 0; i < routes.length; i++) {
    const matchedRoute = execRouteMatching(routes[i], pathname, queryParamObject, basePath);

    if (matchedRoute) {
      matchRouteCache.set(pathname, queryParamObject, basePath, matchedRoute);
      return matchedRoute;
    }
  }

  return null;
};

export const matchInvariantRoute = function matchInvariantRoute(routes, pathname, queryParams, basePath) {
  if (basePath === void 0) {
    basePath = '';
  }

  return matchRoute(routes, pathname, queryParams, basePath);
};
/**
 * Performance optimisation to fast-match a single route
 * instead of looping thorugh all defined routes
 */

export const warmupMatchRouteCache = function warmupMatchRouteCache(route, pathname, queryParams, basePath) {
  if (basePath === void 0) {
    basePath = '';
  }

  matchRoute([route], pathname, queryParams, basePath);
};
export default (function (routes, pathname, queryParams, basePath) {
  if (basePath === void 0) {
    basePath = '';
  }

  return matchRoute(routes, pathname, queryParams, basePath);
});