import { HelperMessage, ValidMessage, ErrorMessage } from '@atlaskit/form';
import { css } from '@emotion/react';

export interface TextFieldMessageProps {
  /** The message to be displayed */
  message: string;
  /** The type of message to display */
  type: 'helper' | 'error' | 'valid';
}

export const TextFieldMessage: React.FC<TextFieldMessageProps> = ({
  message = 'Example text here',
  type = 'valid',
}) => (
  <div css={spacing}>
    {type === 'helper' && (
      <HelperMessage>
        <p css={helperMessageStyle}>{message}</p>
      </HelperMessage>
    )}
    {type === 'error' && (
      <ErrorMessage>
        <p css={errorMessageStyle}>{message}</p>
      </ErrorMessage>
    )}
    {type === 'valid' && (
      <ValidMessage>
        <p css={validMessageStyles}>{message}</p>
      </ValidMessage>
    )}
  </div>
);

const spacing = css({
  marginTop: '2px',
});

const helperMessageStyle = css({
  fontFamily: 'Inter',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '16px',
});

const errorMessageStyle = css({
  paddingLeft: '6px',
  fontFamily: 'Inter',
  marginTop: '0.5px',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '16px',
});

const validMessageStyles = css({
  paddingLeft: '6px',
  fontFamily: 'Inter',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '16px',
});
