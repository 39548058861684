const IDENTITY_BASE_URL = process.env.REACT_APP_IDENTITY_BASE_URL;

type AuthQueryParamValues = {
  email?: string;
  redirectUrl?: string;
  prompt?: string;
  experience?: string;
};

const buildAuthQueryParams = (
  urlType: 'identity' | 'forums',
  {
    email,
    redirectUrl,
    prompt,
    experience = 'entry-auth',
  }: AuthQueryParamValues,
) => {
  const queryParams = [];
  if (email) {
    queryParams.push(`login_hint=${email}`);
  }
  if (experience) {
    queryParams.push(`application=community--${experience}`);
  }
  if (redirectUrl) {
    queryParams.push(
      urlType === 'identity'
        ? `continue=${redirectUrl}`
        : `referer=${redirectUrl}`,
    );
  }
  if (prompt) {
    queryParams.push(`prompt=${prompt}`);
  }
  return queryParams;
};

export const getAuthUrl = (
  type: 'login' | 'signup' | 'logout',
  queryParamValues: AuthQueryParamValues,
): string => {
  const queryParams = buildAuthQueryParams('identity', queryParamValues);
  return `${IDENTITY_BASE_URL}/${type}?${queryParams.join('&')}`;
};

export const getForumsSignInUrl = (
  queryParamValues: AuthQueryParamValues,
): string => {
  queryParamValues.prompt = 'none';
  const queryParams = buildAuthQueryParams('forums', queryParamValues);
  return `/forums/s/plugins/common/feature/oauth2sso_v2/sso_login_redirect?${queryParams.join(
    '&',
  )}`;
};

export const getForumsSignOutUrl = (redirectUrl?: string): string =>
  `forums/community/page.logoutpage?t:cp=authentication/contributions/unticketedauthenticationactions&referer=${redirectUrl}&lia-action-token=vqbaOmbKji5FT8GhRqcJgN-gWIFDCKe-W-WZBph1FU0k_rYZvSl2UDf6uiPc8XUx&lia-action-token-id=logoff`;
