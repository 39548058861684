import React from 'react';
import { css } from '@emotion/react';
import { Divider, Tag } from '@components/shared';
import { token } from '@atlaskit/tokens';

export interface FeedbackHeaderProps {
  /** Main title */
  heading: string;
  /** Time needed to complete the survey */
  duration: string;
  /** Survey description */
  description: React.ReactNode;
}

/**
 * FeedbackHeader component displays a header section for feedback, including a main title,
 * duration tag, survey tag, a divider, a subheading, and a description.
 *
 * @param {FeedbackHeaderProps} props - The props for the component.
 * @param {string} props.heading - The main title of the feedback header.
 * @param {string} props.duration - The time needed to complete the survey.
 * @param {React.ReactNode} props.description - The survey description.
 * @returns {JSX.Element} The rendered FeedbackHeader component.
 */

export const FeedbackHeader: React.FC<FeedbackHeaderProps> = ({ heading, duration, description }) => (
  <section aria-labelledby="feedback-header">
    <div css={headingWrapperStyles}>
      <h1 css={headingStyles} id="feedback-header">
        {heading}
      </h1>
    </div>
    <div css={metadataStyles}>
      <div css={flexContainerStyles}>
        <div css={css({ display: 'flex' })}>
          <div css={tagStyles}>
            <Tag label={duration} />
          </div>
          <div css={tagStyles}>
            <Tag label="survey" />
          </div>
        </div>
      </div>
    </div>
    <div css={lineWrapperStyles}>
      <Divider color={token('color.border')} />
    </div>
    <div css={descriptionStyles}>{description}</div>
  </section>
);

const metadataStyles = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const flexContainerStyles = css({
  display: 'flex',
});

const tagStyles = css({
  marginRight: token('space.100'),
});

const lineWrapperStyles = css({
  padding: `${token('space.300')} 0 ${token('space.500')} 0`,
});

const headingWrapperStyles = css({
  padding: `${token('space.1000')} 0 ${token('space.300')} 0`,
});

const headingStyles = css({
  color: token('color.text'),
  fontFamily: 'Charlie Display, sans-serif',
  fontSize: '61.333px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '81.778px',
});

const descriptionStyles = css({
  paddingBottom: token('space.1000'),
  fontFamily: 'Inter, sans-serif',
  fontSize: '20px',
  lineHeight: '32px',
  color: token('color.text'),

  p: {
    margin: 0,
  },
});
