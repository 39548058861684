"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setUserIsConsentedMutation = exports.initializeCommunityMutation = void 0;
var setUserIsConsentedMutation = exports.setUserIsConsentedMutation = function setUserIsConsentedMutation(aaid, consentKey) {
  return "\n  mutation SET_USER_CONSENTED_MUTATION {\n    unified {\n      consent {\n        setConsent(\n          consentObj: {consentKey: \"".concat(consentKey, "\", consentStatus: \"true\"}\n          type: \"AAID\"\n          value: \"").concat(aaid, "\"\n        ) {\n          errors {\n            message\n          }\n          message\n          success\n      }\n    }\n  }\n}");
};
var initializeCommunityMutation = exports.initializeCommunityMutation = function initializeCommunityMutation(emailId) {
  return "\nmutation INITIALIZE_COMMUNITY_MUTATION {\n  unified {\n    community {\n      initializeCommunity(emailId: \"".concat(emailId, "\") {\n        success\n        errors {\n          code\n          message\n          extensions {\n            errorType\n            statusCode\n          }\n        }\n        forumsProfile {\n          id\n          login\n        }\n        gamificationProfile {\n          firstName\n          userId\n        }\n        unifiedProfile {\n          accountInternalId\n        }\n      }\n    }\n  }\n}");
};