import React, { useCallback, useState } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { Eyebrow } from '@components/shared';
import { token } from '@atlaskit/tokens';
import { AtlassianIcon } from '@atlaskit/logo';
import {
  svgStringDesktopFooterWiderUrl,
  svgStringDesktopFooterWideUrl,
  svgStringMobileFooterUrl,
} from './assets/backgrounds';
import { CommunityApp, APP_URLS } from '@components/community/constants';
import { getForumsSignInUrl } from '@components/community/utils';
import { ModalTransition } from '@atlaskit/modal-dialog';
import { LicenseModal, LicenseModalProps } from '@components/shared/LicenseModal';

const TABLET_MEDIA_QUERY = '@media (min-width: 768px) and (max-width: 1279px)';
const MOBILE_MEDIA_QUERY = '@media (max-width: 767px)';
const DESKTOP_MEDIA_QUERY = '@media (min-width: 1280px)';

const YEAR = new Date().getFullYear();
const COPYRIGHT = `Copyright © ${YEAR} Atlassian`;

export interface SharedFooterProps {
  reportAProblemCallback?: () => void;
  isLoggedIn?: boolean;
  /**
   * (optional) email of the current user, if user is logged in. Currently used for SSO / auth on Forums links.
   */
  email?: string;
  /**
   *  (optional) license data to show in the about modal window
   */
  licenseData?: LicenseModalProps;
  showChampions?: boolean; // TODO: This prop is for feature gating. Clean up once fullly released.
}

const FooterLink: React.FC<{ href?: string; onClick?: () => void; text: string; styles?: SerializedStyles[] }> = ({
  href,
  onClick,
  text,
  styles = [],
}) => (
  <a href={href} onClick={onClick} css={[linkStyle, ...styles]}>
    {text}
  </a>
);

const FORUMS_URL = APP_URLS[CommunityApp.FORUMS];
const getForumsUrl = (url: string, isLoggedIn: boolean, email?: string): string =>
  isLoggedIn ? getForumsSignInUrl({ email: email, redirectUrl: url }) : url;

export const SharedFooter: React.FC<SharedFooterProps> = ({
  reportAProblemCallback,
  isLoggedIn = false,
  showChampions = false,
  email,
  licenseData,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => setIsOpen(false), []);

  return (
    <div css={footerBackgroundStyles}>
      <div css={footerWrapperStyles}>
        <div css={footerStyle}>
          <div css={innerDivStyle}>
            <div css={logoContainerStyle}>
              <div css={logoStyle}>
                <AtlassianIcon />
              </div>
            </div>
            <div css={columnsContainerStyle}>
              <div css={columnStyle}>
                <div css={headerStyle}>
                  <a href="/" css={linkStyle}>
                    <Eyebrow size="medium" fontWeight="semibold">
                      Community
                    </Eyebrow>
                  </a>
                </div>
              </div>
              <div css={columnStyle}>
                <div css={headerStyle}>
                  <a href={getForumsUrl(FORUMS_URL, isLoggedIn, email)} css={linkStyle}>
                    <Eyebrow size="medium" fontWeight="semibold">
                      Forums
                    </Eyebrow>
                  </a>
                </div>
                <FooterLink href={getForumsUrl(`${FORUMS_URL}/help/faqpage`, isLoggedIn, email)} text="FAQs" />
                <FooterLink
                  href={getForumsUrl(`${FORUMS_URL}/custom/page/page-id/rules-of-engagement`, isLoggedIn, email)}
                  text="Forums guidelines"
                />
              </div>
              <div css={columnStyle}>
                <div css={headerStyle}>
                  <a href={APP_URLS[CommunityApp.LEARNING]} css={linkStyle}>
                    <Eyebrow size="medium" fontWeight="semibold">
                      Learning
                    </Eyebrow>
                  </a>
                </div>
                <FooterLink
                  href={'https://university.atlassian.com/student/activity/836764-about-us'}
                  text={'About learning'}
                />
                <FooterLink
                  href={'https://university.atlassian.com/student/activity/1938006-resources-and-support'}
                  text={'Resources'}
                />
              </div>
              <div css={columnStyle}>
                <div css={headerStyle}>
                  <a href={APP_URLS[CommunityApp.EVENTS]} css={linkStyle}>
                    <Eyebrow size="medium" fontWeight="semibold">
                      Events
                    </Eyebrow>
                  </a>
                </div>
              </div>
              {showChampions && (
                <div css={columnStyle}>
                  <div css={headerStyle}>
                    <a href={APP_URLS[CommunityApp.CHAMPIONS]} css={linkStyle}>
                      <Eyebrow size="medium" fontWeight="semibold">
                        Champions
                      </Eyebrow>
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div css={bottomDivStyle}>
            <div css={bottomContentStyle}>
              <span css={copyrightText}>{COPYRIGHT}</span>
              <div css={bottomLinksContainerStyle}>
                {reportAProblemCallback && (
                  <FooterLink onClick={reportAProblemCallback} text={'Report a problem'} styles={[bottomLinkStyle]} />
                )}
                <FooterLink
                  href={'https://www.atlassian.com/legal/privacy-policy#what-this-policy-covers'}
                  text={'Privacy Policy'}
                  styles={[bottomLinkStyle]}
                />
                <FooterLink
                  href={getForumsUrl(`${FORUMS_URL}/user/termsofservicepage`, isLoggedIn, email)}
                  text={'Terms'}
                  styles={[bottomLinkStyle]}
                />
                <FooterLink
                  href={'https://www.atlassian.com/trust/data-protection'}
                  text={'Security'}
                  styles={[bottomLinkStyle]}
                />
                {licenseData && (
                  <>
                    <FooterLink onClick={openModal} text={'About'} styles={[bottomLinkStyle]} />
                    <ModalTransition>
                      {isOpen && <LicenseModal {...licenseData} closeModal={closeModal} />}
                    </ModalTransition>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const footerBackgroundStyles = css({
  width: '100%',
  backgroundImage: `url(${svgStringDesktopFooterWiderUrl})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  paddingTop: token('space.200'),
  paddingBottom: token('space.200'),
  boxSizing: 'border-box',
  [TABLET_MEDIA_QUERY]: {
    backgroundImage: `url(${svgStringDesktopFooterWideUrl})`,
  },
  [MOBILE_MEDIA_QUERY]: {
    backgroundImage: `url(${svgStringMobileFooterUrl})`,
  },
});

const footerWrapperStyles = css({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  margin: '0 auto',
  maxWidth: '100%',
  fontFamily: 'Inter, sans-serif',
  [DESKTOP_MEDIA_QUERY]: {
    maxWidth: '1234px',
  },
  [TABLET_MEDIA_QUERY]: {
    maxWidth: '720px',
  },
  [MOBILE_MEDIA_QUERY]: {
    paddingLeft: '20px',
    paddingRight: '20px',
  },
});

const footerStyle = css({
  borderRadius: '0px 0px 24px 24px',
  width: '100%',
  margin: '0 auto',
  backgroundColor: '#F8F8F8',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  [TABLET_MEDIA_QUERY]: {
    padding: '0 16px',
  },
  [MOBILE_MEDIA_QUERY]: {
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingTop: '24px',
    textAlign: 'left',
  },
});

const linkStyle = css({
  display: 'inline',
  fontSize: '12px',
  fontWeight: 400,
  color: token('color.text'),
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
    textDecorationColor: token('color.text'),
    color: token('color.text'),
    cursor: 'pointer', // explicitly add for <a> tags without href
  },
});

const innerDivStyle = css({
  padding: '24px 24px',
  boxSizing: 'border-box',
  width: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  [TABLET_MEDIA_QUERY]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  [MOBILE_MEDIA_QUERY]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingTop: '0px',
    paddingBottom: '8px',
  },
});

const logoContainerStyle = css({
  width: '420px',
  display: 'flex',
  [TABLET_MEDIA_QUERY]: {
    justifyContent: 'flex-start',
    width: '120px',
  },
  [MOBILE_MEDIA_QUERY]: {
    marginBottom: '16px',
    width: '120px',
  },
});

const logoStyle = css({
  svg: {
    color: token('color.icon.brand'),
    transform: `scale(0.9)`,
  },
  marginLeft: '24px',
  [TABLET_MEDIA_QUERY]: {
    marginLeft: '0',
    marginBottom: '8px',
  },
  [MOBILE_MEDIA_QUERY]: {
    marginLeft: '0',
    paddingLeft: '0',
  },
});

const columnsContainerStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '24px',
  [TABLET_MEDIA_QUERY]: {
    gap: '16px',
  },
  [MOBILE_MEDIA_QUERY]: {
    width: '100%',
    flexDirection: 'column',
    gap: '0',
  },
});

const columnStyle = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start', // prevents child <a> links from taking up entire width
  width: '120px',
  textAlign: 'left',
  gap: '12px',
  [MOBILE_MEDIA_QUERY]: {
    width: '100%',
    marginBottom: '16px',
  },
});

const headerStyle = css({
  marginBottom: '4px',
});

const bottomDivStyle = css({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  boxSizing: 'border-box',
  [TABLET_MEDIA_QUERY]: {
    padding: '0 0px',
  },
  [MOBILE_MEDIA_QUERY]: {
    justifyContent: 'flex-start',
    width: '100%',
    paddingLeft: '0',
    paddingRight: '0',
  },
});

const copyrightText = css({
  marginLeft: '0px',
  fontSize: '12px',
  color: token('color.text.subtle'),
});

const bottomContentStyle = css({
  width: '100%',
  maxWidth: 'calc(100% - 48px)',
  paddingTop: '16px',
  paddingBottom: '24px',
  paddingLeft: '24px',
  paddingRight: '24px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxSizing: 'border-box',
  borderTop: `1px solid ${token('color.border')}`,
  [TABLET_MEDIA_QUERY]: {
    paddingLeft: '0',
    paddingRight: '0',
  },
  [MOBILE_MEDIA_QUERY]: {
    maxWidth: '100%',
    justifyContent: 'flex-start',
    flexDirection: 'column-reverse',
    alignItems: 'flex-start',
    paddingLeft: '24px',
    paddingRight: '0',
  },
});

const bottomLinkStyle = css({
  display: 'inline',
  marginLeft: '32px',
  [MOBILE_MEDIA_QUERY]: {
    marginLeft: '0',
  },
});

const bottomLinksContainerStyle = css({
  [MOBILE_MEDIA_QUERY]: {
    // match columnStyle
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start', // prevents child <a> links from taking up entire width
    width: '100%',
    textAlign: 'left',
    marginTop: '0',
    marginBottom: '32px',
    gap: '12px',
  },
});

export default SharedFooter;
