import { token } from '@atlaskit/tokens';
import React, { useCallback } from 'react';
import { css } from '@emotion/react';
import { media } from '@atlaskit/primitives/responsive';
import { withFocusInteraction } from '@utils/index';

export interface RadioProps {
  /** A string representing the label of the input */
  label?: string;
  /** name ensures each group of radio buttons is treated as a separate group */
  name?: string;
  /** Boolean to control whether radio is checked */
  isChecked: boolean;
  /** Callback that is invoked when the radio is clicked */
  setIsChecked: (isChecked: boolean) => void;
  /** Type of the radio, changes the color of the radio input */
  type?: RadioType;
  /** type to control whether radio is large */
  size?: RadioSize.DEFAULT | RadioSize.LARGE;
  /** Boolean to control whether radio is required */
  isRequired?: boolean;
  /** Boolean to control the radio label fontWeight*/
  isBold?: boolean;
  /** TabIndex to allow correctly tabbing through the radios for accessibility*/
  tabIndex?: number;
}

export enum RadioType {
  DEFAULT = 'DEFAULT',
  /** TODO: remove success styles in scope of the QUIZ component update */
  SUCCESS = 'SUCCESS',
  DANGER = 'DANGER',
  DISABLED = 'DISABLED',
}

export enum RadioSize {
  DEFAULT = 'DEFAULT',
  LARGE = 'LARGE',
}

export const Radio: React.FC<RadioProps> = ({
  label,
  name,
  isChecked,
  setIsChecked,
  type = RadioType.DEFAULT,
  size = RadioSize.DEFAULT,
  isRequired = false,
  isBold,
  tabIndex = 0,
}) => {
  const handleChange = useCallback(() => {
    setIsChecked(!isChecked);
  }, [isChecked, setIsChecked]);

  const handleKeyDown = useCallback(withFocusInteraction(handleChange), [isChecked]);

  const labelAndInputConnection = name
    ? `${name}-${label?.toLowerCase().replace(/\s+/g, '-')}` + '-radio-input'
    : label?.toLowerCase().replace(/\s+/g, '-') + '-radio-input';

  return (
    <div css={[radioContainerStyles, size === RadioSize.LARGE && css({ columnGap: '8px' })]}>
      <label
        htmlFor={labelAndInputConnection}
        css={[
          labelStyles,
          size === RadioSize.LARGE && labelLargeStyles,
          type === RadioType.DISABLED && variantClasses.label.DISABLED,
          isBold && css({ fontWeight: 700 }),
          !isBold && css({ fontWeight: 400 }),
        ]}
      >
        <input
          data-testid="radio-input"
          name={name}
          tabIndex={-1}
          id={labelAndInputConnection}
          type="radio"
          role="radio"
          css={inputStyles}
          checked={isChecked}
          onChange={(e: any): void => setIsChecked(e.currentTarget.checked)}
          aria-checked={isChecked}
        />
        {label}
        <span
          role="radio"
          aria-checked={isChecked}
          aria-label={label}
          tabIndex={tabIndex}
          onKeyDown={handleKeyDown}
          css={[
            variantClasses.checkbox[type],
            checkmarkStyles,
            size === RadioSize.LARGE && checkmarkLargeStyles,
            isChecked && checkedCheckmarkStyles,
            isChecked && type === RadioType.DANGER && css({ backgroundColor: token('color.border.danger') }),
          ]}
        />
        {isRequired && (
          <span css={[requiredMarkStyles, size === RadioSize.LARGE && css({ marginLeft: '4px' })]}>*</span>
        )}
      </label>
    </div>
  );
};

const radioContainerStyles = css({
  display: 'flex',
  columnGap: '6px',
});

const requiredMarkStyles = css({
  color: token('color.text.subtle'),
  marginLeft: '2px',
});

const inputStyles = css({
  position: 'absolute',
  height: 0,
  width: 0,
  opacity: 0,
  cursor: 'pointer',
});

const checkmarkStyles = css({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '16px',
  height: '16px',
  marginTop: '2px',
  backgroundColor: token('color.background.input'),
  borderRadius: '50%',
  border: `1px solid ${token('color.border.input')}`,
  cursor: 'pointer',

  '&:after': {
    content: '""',
    position: 'absolute',
    display: 'none',
  },

  [media.below.xs]: {
    marginTop: '4px',
  },
});

const checkmarkLargeStyles = css({
  width: '20px',
  height: '20px',
  marginRight: '8px',
  marginTop: '6px',

  '&:after': {
    left: '5px !important',
    top: '5px !important',
    width: '8px !important',
    height: '8px !important',
  },
});

const checkedCheckmarkStyles = css({
  backgroundColor: token('color.background.selected.bold'),
  borderColor: token('color.border.selected'),

  '&:after': {
    display: 'block',
    left: '4px',
    top: '4px',
    width: '6px',
    height: '6px',
    borderRadius: '50%',
    backgroundColor: token('color.background.input'),
  },
});

const labelStyles = css({
  display: 'flex',
  position: 'relative',
  paddingLeft: '22px',
  userSelect: 'none',
  color: token('color.text'),
  fontFamily: 'Inter, sans-serif',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '22px',
});

const labelLargeStyles = css({
  paddingLeft: '28px',
  fontSize: '20px',
  lineHeight: '32px',
});

const variantClasses = {
  label: {
    DISABLED: css({ pointerEvents: 'none', opacity: '31%' }),
  },
  checkbox: {
    DEFAULT: css({
      ':hover': {
        borderColor: token('color.border.selected'),
      },
    }),
    SUCCESS: css({
      ':hover': {
        borderColor: token('color.border.selected'),
      },
    }),
    DANGER: css({
      '&, &:hover': {
        borderColor: token('color.border.danger'),
      },
    }),
    DISABLED: css({ pointerEvents: 'none', opacity: '31%' }),
  },
};
