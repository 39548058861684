"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("../constants");
function studyPrint() {
    try {
        var canvasElement = document.createElement("canvas");
        canvasElement.width = 240;
        canvasElement.height = 140;
        canvasElement.style.display = "inline";
        var canvasContext = canvasElement.getContext("2d");
        if (canvasContext) {
            canvasContext.rect(0, 0, 10, 10);
            canvasContext.rect(2, 2, 6, 6);
            canvasContext.isPointInPath(5, 5, "evenodd");
            canvasContext.textBaseline = "alphabetic";
            canvasContext.fillStyle = "#f60";
            canvasContext.fillRect(125, 1, 62, 20);
            canvasContext.fillStyle = "#069";
            canvasContext.font = "11pt no-real-font-123";
            canvasContext.fillText("Cwm fjordbank 😃 gly", 2, 15);
            canvasContext.fillStyle = "rgba(102, 204, 0, 0.2)";
            canvasContext.font = "18pt Arial";
            canvasContext.fillText("Cwm fjordbank 😃 gly", 4, 45);
            canvasContext.globalCompositeOperation = "multiply";
            canvasContext.fillStyle = "rgb(255,0,255)";
            canvasContext.beginPath();
            canvasContext.arc(50, 50, 50, 0, 2 * Math.PI, !0);
            canvasContext.closePath();
            canvasContext.fill();
            canvasContext.fillStyle = "rgb(0,255,255)";
            canvasContext.beginPath();
            canvasContext.arc(100, 50, 50, 0, 2 * Math.PI, !0);
            canvasContext.closePath();
            canvasContext.fill();
            canvasContext.fillStyle = "rgb(255,255,0)";
            canvasContext.beginPath();
            canvasContext.arc(75, 100, 50, 0, 2 * Math.PI, !0);
            canvasContext.closePath();
            canvasContext.fill();
            canvasContext.fillStyle = "rgb(255,0,255)";
            canvasContext.arc(75, 75, 75, 0, 2 * Math.PI, !0);
            canvasContext.arc(75, 75, 25, 0, 2 * Math.PI, !0);
            canvasContext.fill("evenodd");
            return canvasElement.toDataURL();
        }
        return constants_1.UNAVAILABLE;
    }
    catch (error) {
        return constants_1.ERROR;
    }
}
exports.studyPrint = studyPrint;
