import { type FunctionComponent } from 'react';
import { css } from '@compiled/react';
import { ChampionsHero }  from 'components/champions/ChampionsHero';
import { PageWithAnalytics } from 'components/common/analytics-wrapped/PageWithAnalytics';
import { ChampionsIntro } from 'components/champions/ChampionsIntro';
import { ChampionsAdventure } from 'components/champions/ChampionsAdventure';
import { ChampionsAwards } from 'components/champions/ChampionsAwards';
import { FAQ } from 'components/champions/FAQ';
import { ChampionsQuote } from 'components/champions/ChampionsQuote';
import { championsIsActive } from 'services/statsig/featureGates';

export const ChampionsPage: FunctionComponent = () => {
  const isChampionsActive = championsIsActive();
  if (!isChampionsActive) {
    return <div></div>;
  }
  return (
    <PageWithAnalytics eventScreenName="championsScreen">
      <div css={containerStyles}>
        <ChampionsHero />
        <ChampionsIntro />
        <ChampionsAdventure />
        <ChampionsAwards />
        <ChampionsQuote />
        <FAQ />
      </div>
    </PageWithAnalytics>
  );
};


const containerStyles = css({
  width: '100%',
});
